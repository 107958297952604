import React, { useEffect, useState } from 'react'
import Box from '../../../component/Box';
import SetBagLimit from './SetBagLimit';
import SuffixString from './SuffixString';
import Icon from '../../../component/Icon/Icon';
import TatTab from './TatTab';
import ReceivedTat from './ReceivedTat';
import EmailSettings from './EmailSettings';
import { useLocation, useNavigate } from 'react-router-dom';

const SettingTab = () => {
  const [selectedSegment, setselectedSegment] = useState("Information");
//   const [showComponent, setShowComponent] = useState("initial");
const location = useLocation();
const navigate = useNavigate();
// Extracting query parameters
const queryParams = new URLSearchParams(location.search);
const tab = queryParams.get('tab');
// console.log(tab);
  
useEffect(() => {
  if(tab){ 
    setselectedSegment(tab)
  }
  // console.log("info");
},[tab])
const segmentChange = (segment) => {
 navigate('/settings?tab='+segment)
}
  const setSegment = (segment) => {
    setselectedSegment(segment);
  };
  return (
    <div>
      <div className='heading_select'>
            <h1>Settings</h1>
        </div>
       <Box display="flex" alignItems="center" cursor="pointer" justifyContent="left">
        <Box fontSize="22px" fontWeight={700}>{selectedSegment === "Information"}</Box>
        <ul className="d-flex tab_setting">
          <li
            className="information"
            style={{
              fontWeight: selectedSegment === "Information" ? "600" : "600",
              backgroundColor:
                selectedSegment === "Information" ? "#F8B552" : "#EBEEF2",
              color: selectedSegment === "Information" ? "#1C1C1C" : "#1C1C1C",
              marginRight:selectedSegment === "Information" ? "7px" : "7px",
              padding:selectedSegment === "Information" ? "7px" : "7px",
              borderRadius:
                selectedSegment === "Information"
                  ? "5px 5px 0px 0px"
                  : "5px 5px 0px 0px",
            }}
            onClick={() => segmentChange("Information")}
          >
            <Icon
               iconName="tabOne"
               width="30px"
               height="30px"
               />
                 Set Bag Limit{" "}
          </li>
          <li
            className="screen-details"
            style={{
              fontWeight: selectedSegment === "Screen details" ? "600" : "600",
              backgroundColor:
                selectedSegment === "Screen details" ? "#F8B552" : "#EBEEF2",
              color: selectedSegment === "Screen details" ? "#1C1C1C" : "#1C1C1C",
              borderRadius:
                selectedSegment === "Screen details"
                  ? "5px 5px 0px 0px"
                  : "5px 5px 0px 0px",
            }}
            onClick={() => segmentChange("Screen details")}
          >
             <Icon
               iconName="tabTwo"
               width="30px"
               height="30px"
               />
               Suffix String{" "}
          </li>
          <li
            className="screen-details"
            style={{
              fontWeight: selectedSegment === "TAT" ? "600" : "600",
              backgroundColor:
                selectedSegment === "TAT" ? "#F8B552" : "#EBEEF2",
              color: selectedSegment === "TAT" ? "#1C1C1C" : "#1C1C1C",
              borderRadius:
                selectedSegment === "TAT"
                  ? "5px 5px 0px 0px"
                  : "5px 5px 0px 0px",
            }}
            onClick={() => segmentChange("TAT")}
          >
              <Icon
               iconName="tabThree"
               width="30px"
               height="30px"
               />
            Open TAT{" "}
          </li>
          <li
            className="screen-details"
            style={{
              fontWeight: selectedSegment === "RTAT" ? "600" : "600",
              backgroundColor:
                selectedSegment === "RTAT" ? "#F8B552" : "#EBEEF2",
              color: selectedSegment === "RTAT" ? "#1C1C1C" : "#1C1C1C",
              borderRadius:
                selectedSegment === "RTAT"
                  ? "5px 5px 0px 0px"
                  : "5px 5px 0px 0px",
            }}
            onClick={() => segmentChange("RTAT")}
          >
            <Icon
               iconName="tabThree"
               width="30px"
               height="30px"
               />
            Received TAT{" "}
          </li>
          <li
            className="screen-details"
            style={{
              fontWeight: selectedSegment === "EMS" ? "600" : "600",
              backgroundColor:
                selectedSegment === "EMS" ? "#F8B552" : "#EBEEF2",
              color: selectedSegment === "EMS" ? "#1C1C1C" : "#1C1C1C",
              borderRadius:
                selectedSegment === "EMS"
                  ? "5px 5px 0px 0px"
                  : "5px 5px 0px 0px",
            }}
            onClick={() => segmentChange("EMS")}
          >
            <Icon
               iconName="tabFour"
               width="30px"
               height="30px"
               />
            Email Settings{" "}
          </li>
        </ul>
      </Box>
      <Box className='tab_body'>
        {selectedSegment === "Information" ? (
            <SetBagLimit 
            setSegment={setSegment}
            />
        ) : null}
        {selectedSegment === "Screen details" ? (
            <SuffixString 
            setSegment={setSegment}
            />
        ) : null}
        {selectedSegment === "TAT" ? (
            <TatTab
            setSegment={setSegment}
            />
        ) : null}
        {selectedSegment === "RTAT" ? (
            <ReceivedTat
            setSegment={setSegment}
            />
        ) : null}
        {selectedSegment === "EMS" ? (
            <EmailSettings
            setSegment={setSegment}
            />
        ) : null}
      </Box>
    </div>
  )
}

export default SettingTab
