
import React, { useEffect, useState } from 'react'
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { getreturnedContainerBoxRead } from '../../store/user/actions';
import { useDispatch, useSelector } from 'react-redux';
import BoxEditScanTwoFill from './BoxEditScanTwoFill';
import BoxEditScanTwoNoteFill from './BoxEditScanTwoNoteFill';

const BoxEditScanTwo = () => {
  const returnedContainerBoxRead = useSelector(state => state.returnedContainerBoxRead)
  const dispatch = useDispatch()
  const params = useParams()
  useEffect(() => {
    // const storedFormData = JSON.parse(localStorage.getItem('formData'));
   dispatch(getreturnedContainerBoxRead({ returned_container_id: params.id }));
  }, [params.id]);
  return (
    <div>
      {
        (returnedContainerBoxRead.isSuccess && returnedContainerBoxRead.data.length > 0 )?<BoxEditScanTwoFill/>:<BoxEditScanTwoNoteFill/>
      }
    </div>
  )
}

export default BoxEditScanTwo
