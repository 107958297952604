import React from 'react'
import FbiForm from './component/FbiForm'
import { Link } from 'react-router-dom'

const FBImport = () => {
  const handleDownload = () => {
    // Define the URL of the Excel file
  const excelFileUrl = '/Images/forward_backward_number_import_sample_file.csv'; // Update this with your actual file path
    // Create a temporary anchor element
    const anchor = document.createElement('a');
    anchor.href = excelFileUrl;
    // Set the file name
    anchor.download = 'forward_backward_number_import_sample_file.csv'; // Update this with the desired file name
    // Programmatically click the anchor to trigger the download
    anchor.click();
  };
  return (
    <div>

      <div className='heading_select align-items-baseline'>
        <h1>Forward & Backward Import</h1>
        <div className="primary_but">
          <Link onClick={handleDownload}><img src="/Images/document-download.svg" />Download Sample</Link>
        </div>

      </div>
      <FbiForm />
    </div>
  )
}

export default FBImport
