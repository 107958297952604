import axios from "axios";
import {showNotification} from "../util/utility"


export const getUser = () => {
    const userStr = localStorage.getItem('user');
    if (userStr) return JSON.parse(userStr);
    else return null;
  }
  
  // return the token from the session storage
  export const getToken = () => {
    return localStorage.getItem('token') || null;
  }
  
  // remove the token and user from the session storage
  export const removeUserSession = () => {
    localStorage.removeItem('token');
    localStorage.removeItem('user');
  }
  
  // set the token and user from the session storage
  export const setUserSession = (token, user) => {
    localStorage.setItem('token', token);
    localStorage.setItem('user', JSON.stringify(user));
  }

  export const apiCall = async (endPoint, type, body) =>{
    // const navigate = useNavigate();
    let api = ""
    if(type ==="GET"){
      api=await axios.get(process.env.REACT_APP_API_URL+endPoint,body)
    }else{
      api=await axios.post(process.env.REACT_APP_API_URL+endPoint,body)
      .then((response) => response.data)
    .then(response => {
      if(response.status===1){
        showNotification("success", response.message);
      }else{
        showNotification("error", response.message);
      }
     
      return response
    }).catch(error => {
      if (error.response.status === 401 ){
        // navigate("/login");
      }
       else if( error.response.status === 400) {
        return {status:0, message:error.response.data.message};
      }
      else {
        return {status:0, message:"somthing went wrong"}; 
      }
      // console.error("error >>>", error);
    })
     }
    return api
  }