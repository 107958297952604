import { Pagination} from 'antd';
import React, { useEffect, useState } from 'react'
import Table from '../../component/Table/Table';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { getclaimRemark } from '../../store/user/actions';
import Loader from '../../component/Loader';

const History = () => {

  const [limitData, setLimitData] = useState(10);
  const [current, setCurrent] = useState(1);
  const [tableData, setTableData] = useState([]);
  const [totalData, setTotalData] = useState(0);
  const claimRemark = useSelector(state => state.claimRemark)
  const dispatch = useDispatch()
  const navigate = useNavigate();
   const { id } = useParams();

   useEffect(() => {
    // let data = {
    //   ...formValues,
    //   claim_reason_id: id,
    //   party_id: id,
    //   page: current,
    //   limit: 10,
    //   ...selectBox, // Spread the selectBox object to preserve filters
    // };
    dispatch(getclaimRemark({claim_id:id}));
  }, []);

//   const handlePageChange = (page,limit) => {
//     setCurrent(page);
//     setLimitData(limit)
//     dispatch(getclaimRemark({page,limit,claim_id:id}))
// };
console.log(claimRemark);
  useEffect(() => {
    setTotalData(claimRemark.data.total)
    // let sno = (current - 1) * 10 + 1;
    const FakeTableData = claimRemark && claimRemark.data.data.map((item, i) => {
      return [
        <>
          <h6>{item.id}</h6>
        </>,

       
        // <div className='user_image_box'>
          <h6>{item.username}</h6>
        // </div>
        ,
        <div>
          <h6>{item.created}</h6>
        </div>,
        <>

          <h6>{item.remark}</h6>
        </>,


      ]
    })
    setTableData(FakeTableData);
  }, [claimRemark])
  const TemplateTableData = [

    {
        heading: "Sr.No.",
    },
    {
        heading: "Username",
    },
    {
        heading: "Date and Time",
    },
    {
        heading: "Remarks",
    },
    
   

];
const handleGoBack = () => {
  // Navigate back to the previous item
  navigate(-1);
};
  return (
    <div >
      <div className='heading_select'>
              <div className="d-flex align-items-baseline" onClick={handleGoBack}>
                  <img style={{ cursor: "pointer" }} src="/Images/arrow-left.svg" />
                  <h1>History</h1>
              </div>
          </div>
          <div className='white_box'>
      <div className="position-relative">
          {
            claimRemark.isLoading && <div className="loader_bg"><Loader /></div>
          }
          <div className='table-bg'>
            <Table
              gridTemplateColumnsHeader="10% 20% 20% 50%"
              gridTemplateColumnsBody="10% 20% 20% 50%"
              columns={TemplateTableData}
              data={tableData}
              Loader={true}
            />
            {/* <Pagination
              current={current}
              onChange={handlePageChange}
              // total={claimRemark.data.pagination ? claimRemark.data.pagination.total : 0}
              total={claimRemark?.data?.data ? claimRemark?.data?.data?.total : 0}
              pageSize={limitData}
              /> */}
          </div>

        </div>
        </div>
    </div>
  )
}

export default History