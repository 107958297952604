



import React, { useEffect, useState } from 'react'
import { Link, useNavigate, useParams, useLocation, useSearchParams } from 'react-router-dom';
import { useDropzone } from 'react-dropzone'
import { getuploadCount } from '../../store/user/actions';
import { useDispatch, useSelector } from 'react-redux';
import Loader from '../../component/Loader';
import { resetUpdateCountState } from '../../store/user/userSlice';





const UploadCountPieces = () => {
  const initialValues = {
    short_qty: 0,
    received_packages:""
  };
  const navigate = useNavigate();
  const [formValues, setFormValue] = useState(initialValues);
  // const [recivedInput, setRecivedInput] = useState(false);
  const [looseReturnId, setLooseReturnId] = useState(0)
  const [formErrors, setFormErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [scanCount, setScanCount] = useState(0);
  // const { total, totalScan } = useParams();
  const [files, setFiles] = useState([]);
  const [index, setIndex] = useState(0);
  const uploadCount = useSelector(state => state.uploadCount)
  const dispatch = useDispatch()
  const {id} = useParams()
  const location = useLocation();

  // Extracting query parameters
  const queryParams = new URLSearchParams(location.search);
  const total = queryParams.get('total');
  const totalScan = queryParams.get('totalScan');
  // const shortQty = Math.abs(total - totalScan);

  const recivedInput = total !== null;


// Inside the handleChange function
const handleChange = (name, value) => {
  console.log("Handle Change:", name, value);
  let data = { ...formValues };
  data[name] = value;
  setFormValue(data);
};
  
  useEffect(() => {
    // Calculate short_qty based on query parameters
    const shortQty = Math.max(totalScan - total, 0);
    // console.log(shortQty);
    setFormValue(prevState => ({ ...prevState, short_qty: shortQty }));
  }, [total, totalScan]);
  

  // console.log(formValues.short_qty);
  useEffect(() => {
    const storedFormData = JSON.parse(localStorage.getItem('primaryScanData'));
    // setRecivedInput(storedFormData.scan_type)
    // const shortQty = formValues.total_packages_counter - formValues.total_scan_counter;
    // // Update formValues with Short Qty
    // setFormValue(prevState => ({
    //   ...prevState,
    //   short_qty: shortQty
    // }));
    if (storedFormData?.LooseReturn) {
      setLooseReturnId(storedFormData.LooseReturn.id)
    }
    // setScanCount(scanCount);

  }, [formValues.total_packages_counter, formValues.total_scan_counter, id]);


  const handleGoBack = () => {
    // Navigate back to the previous item
    navigate(-1);
  };

  const handleFileChange = (e, index) => {
    const selectedFiles = e.target.files;
    const oldFiles = [...files];
    const newFiles = [];
    for (let i = 0; i < selectedFiles.length; i++) {
      newFiles.push(selectedFiles[i]);
    }
    oldFiles[index] = newFiles;
    setFiles(oldFiles);
  };

  const handleRemoveFile = (index, fileindex) => {
    const newFiles = [...files];
    newFiles[index].splice(fileindex, 1);

    setFiles(newFiles);
  };
  const handleSubmit = (e, name, value) => {
    setError(null);
    setLoading(true);
    let check = validate(formValues);
    let data = { ...initialValues };
    data[name] = value;
    e.preventDefault();
    setFormErrors(check.errors);
    if (check.isValid) {
      setLoading(false);
    const formData = new FormData();
    
    formData.append('received_packages', total);
    if (files.length > 0) {
      for (let i = 0; i < files.length; i++) {
        formData.append(`images[${i}]`, files[i][0]);
      }
    }
    // Construct boxes data
    formData.append('id', id);
        console.log(id);
        
    // formData.append("total_scan",id);
    dispatch(getuploadCount(formData));
    // console.log(formData);
  }
  };
  useEffect(() => {
    // Check if id exists before using it
    if (id) {
      // Now you can safely use the id
      console.log(id);
      // Other code that depends on id
    }
  }, [id]);
  
 // navigate call
 useEffect(() => {
  if (uploadCount.isSuccess || uploadCount.status === 1) {
    dispatch(resetUpdateCountState())
    navigate('/primary-scan');
  }
}, [uploadCount.isSuccess, uploadCount.status]);
// end
  // validation-start
  useEffect(() => {
    if (Object.keys(formErrors).length > 0) {
    }
  }, [formErrors]);

  const validate = (values) => {
    let isValid = true;
    const errors = {};
    if (!files.length) {
      isValid = false;
      errors.files = "files is required";
    }
    return {
      isValid,
      errors,
    };
  };
  // console.log(id);
  return (
    <div>
      <div className='heading_select'>
        <div className="d-flex align-items-baseline" onClick={handleGoBack}>
          <img style={{ cursor: "pointer" }} src="/Images/arrow-left.svg" />
          <h1>Upload Pictures</h1>
        </div>
      </div>
      <div className='upload_box '>
        <div className='primary_input_section'>
          <div className='col-12 mb-4 position-relative'>
            <label className='select-label'>Total Received Qty</label>
            {recivedInput ?
              <div className='col-12 mb-4 position-relative'>
                <div className='disabled_box'>{total}</div>
              </div> : <input
                className='input-custom-primary'
                type='number'
                onChange={(e) => handleChange("received_packages", e.target.value)}
                value={formValues.received_packages}
                disabled={recivedInput} // Disable input if recivedInput is true
              />}

          </div>
          <div className='col-12 mb-4 position-relative'>
            <label className='select-label'>Short Qty</label>
            <div className='disabled_box'>{formValues.short_qty || 0}</div>
          </div>
          <div className="file-preview-container position-relative upload-pic-box">
            <input
              type="file"
              multiple
              className="hidden-file-input"
              onChange={(e) => handleFileChange(e, index)}
              accept='image/*'
            />
            <div className='mb-2'>
              <img src="/Images/camera.svg" />
              <div>
                <h5>Upload Image</h5>
                <small>You can upload JPG, PNG</small>
              </div>
            </div>
            <div className='d-flex'>
              {files[index] && files[index].map((file, fileIndex) => (
                <div className="file-preview">
                  <img
                    src={URL.createObjectURL(file)}
                    alt={`Preview ${index}`}
                  />
                  <button onClick={() => handleRemoveFile(index, fileIndex)} className='close_red'><img src='/Images/redClose.svg' /></button>
                </div>
              ))}
            </div>
          
            {
              uploadCount.isLoading && <div className="loader_bg"><Loader /></div>
               }
          </div>
          <span className='error-msg'>{formErrors.files}</span>
        </div>
      
        <div className='package_butn mt-4 mb-2 position-relative'>
       
          <Link className='suffix_bag' to="/upload-count" onClick={handleSubmit}>Submit</Link>
        </div>
      </div>
    </div>
  )
}

export default UploadCountPieces

