import React from 'react'
import Table from '../../../component/Table/Table'
// import { Pagination } from 'antd'

const CountTableModal = ({requestId}) => {
  console.log(requestId.data);
    const FakeTableData = requestId.map((item, i) => {
        return [
            <>
                <h6>{i+1} </h6>
            </>,
            <div className="samplePic">
                <h6>{item.detail}</h6>
            </div>,
            <div>
                <h6>{item.created_on}</h6>
            </div>,
            <>

                <h6>{item.created_by}</h6>
            </>,
       
        ]
    })

    const TemplateTableData = [

        {
            heading: "Sr.No.",
        },
        {
            heading: "Dispute",
        },
        {
            heading: "Created on",
        },
        {
            heading: "Create by",
        },
    ];
  return (
    <div>
      <div className='table-bg mt-4'>
                        <Table
                            gridTemplateColumnsHeader="20% 30% 25% 25%"
                            gridTemplateColumnsBody="20% 30% 25% 25%"
                            columns={TemplateTableData}
                            data={FakeTableData}
                            Loader={true}
                        />
                        {/* <Pagination
                            current={current}
                            onChange={onChange}
                            total={50} /> */}
                    </div>
    </div>
  )
}

export default CountTableModal
