



import React, { useEffect, useState } from 'react'
import ReactApexChart from 'react-apexcharts';
import { getgraphClaimAccept, getgraphLostAmountScanDelay } from '../../../store/user/actions';
import DateRangePicker from '@wojtekmaj/react-daterange-picker'
import '@wojtekmaj/react-daterange-picker/dist/DateRangePicker.css';
import { useDispatch, useSelector } from 'react-redux';
const optionsSelect = [

  { value: "today", label: "Today" },
  { value: "this_week", label: "This Week" },
  { value: "last_week", label: "Last Week" },
  { value: "this_month", label: "This Month" },
  { value: "last_month", label: "Last Month" },
  { value: "last_three_month", label: "Last 3 Month" },
  { value: "this_year", label: "This Year" },
  { value: "last_year", label: "Last Year" },
  { value: "all", label: "All" },
];
const TotalLossAmounChart = () => {
  const initialValues = {
    end_date: "",
    start_date: "",
  };
  const rangeDate = [new Date(), new Date]
  const [formValues, setFormValue] = useState(initialValues);
  const [filter, setFilter] = useState("select_date");
  const [rangePickerDate, setrangePickerDate] = useState(rangeDate);
  const graphLostAmountScanDelay = useSelector(state => state.graphLostAmountScanDelay)
  const dispatch = useDispatch()
  const [selectedDuration, setSelectedDuration] = useState(
    optionsSelect[0].value // Set default value to "duration"
  );
  const [chartData, setChartData] = useState({
    series: [],
  options: {
    chart: {
      type: 'bar',
      height: 450,
      stacked: true,
      borderRadius:10,
      toolbar: {
        show: false,
      },
    },
    stroke: {
      width: 1,
      colors: ['#fff']
    },
    dataLabels: {
      // rotate: -90,
      // rotateAlways:true,
      // orientation: 'vertical',
      rotate: -45,
      style: {
          fontSize: '10px',
          fontWeight: 500,
          fontFamily:'Poppins',
        },
      formatter: (val) => {
        return val / 1000 + 'K';
      }
    },
    
    plotOptions: {
      bar: {
        horizontal: false,
         columnWidth: '33%',
         endingShape: "rounded",
            borderRadius: 10,
            borderRadiusApplication: "end", // "around" / "end" 
            borderRadiusWhenStacked: "last" // "all"/"last"  

      }
    },
    xaxis: {
      categories: [],
    },
    fill: {
      opacity: 1
    },
    colors:['#4A3AFF','#95CBFF'],
    yaxis: {
      labels: {
        formatter: (val) => {
          return val
        }
      }
    },
    grid: {
      // show: true,
        borderColor: '#E5E5EF',
        strokeDashArray: 4,
        
      // padding: {
      //   bottom: 30
      // }
    },
    legend: {
      position: 'bottom',
      offsetY: 10,
      horizontalAlign:'center',
    },
  }
  });
  useEffect(() => {
    dispatch(getgraphLostAmountScanDelay({
      filter,
      end_date: formValues.end_date,
       start_date: formValues.start_date }))
  }, [filter, formValues])
// console.log(graphClaimAccept.data);
// console.log(graphLostAmountScanDelay);
useEffect(() => {
  setChartData(prevData => ({
    ...prevData,
    series: graphLostAmountScanDelay.data.series,
    options: {
      ...prevData.options,
      xaxis: {
        ...prevData.options.xaxis,
        categories: graphLostAmountScanDelay.data.xaxis.categories,
      },
    },
  }));
}, [graphLostAmountScanDelay]);



const onChange = (name, date) => {
  console.log("Name",name);
  setrangePickerDate(date);
  var currentDate = new Date(date[0]);
  // Get the year, month, and day from the date
  var year = currentDate.getFullYear();
  var month = String(currentDate.getMonth() + 1).padStart(2, '0'); // Month is 0-based
  var day = String(currentDate.getDate()).padStart(2, '0');
  // Format the date in "Y-m-d" format
  var toDate = `${year}-${month}-${day}`;

  var currentDate = new Date(date[1]);
  // Get the year, month, and day from the date
  var year = currentDate.getFullYear();
  var month = String(currentDate.getMonth() + 1).padStart(2, '0'); // Month is 0-based
  var day = String(currentDate.getDate()).padStart(2, '0');

  // Format the date in "Y-m-d" format
  var fromDate = `${year}-${month}-${day}`;
  let formdata = { ...formValues };
  formdata["start_date"] = toDate; // Update this line
formdata["end_date"] = fromDate; // Update this line
  // formdata[name]=date
  setFormValue(formdata);
};
  // const handleChange = (event) => {
  //   setSelectedDuration(event.target.value);
  // };
  const handleFilterChange = (e) => {
    setFilter(e.target.value);
}
  return (
    <div className='white_box'>
        <div className='heading_select'>
                <h2>Total Loss Amount - Delayed Scanning</h2>
                <div className='select_box_area'>
          <div className="custom-select">
            <select className='selectArea'
            value={filter}
            onChange={handleFilterChange}
            >
              {
                optionsSelect && optionsSelect.map((item) => (
                  <option value={item.value}>{item.label}</option>
                ))
              }
            </select>
          </div>
          <div className="custom-select" style={{marginRight:"10px"}}>
          <DateRangePicker
                      // placeholder="Current Planned Date"
                      onChange={(e) => onChange("start_date_", e)}
                      value={rangePickerDate}
                      yearAriaLabel="Year"
                      clearIcon={false}
                    />
          </div>
        </div>
                </div>
       <div className="border-wordload"></div>
       <div>
      <ReactApexChart options={chartData.options} series={chartData.series} type="bar" height={450} />
      </div>
    </div>
  )
}

export default TotalLossAmounChart

