import React, { useEffect, useState } from 'react'
import Dropdown from '../../../component/Dropdown/DropDownControlled'
import { useDispatch, useSelector } from 'react-redux';
import { getclaimStatusDropdown, getorderReturnDropdown, getpartyOption } from '../../../store/user/actions';
import { DatePicker } from 'antd';
import { Link } from 'react-router-dom';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';


dayjs.extend(customParseFormat);
var utc = require('dayjs/plugin/utc')
var timezone = require('dayjs/plugin/timezone')
dayjs.extend(utc)
dayjs.extend(timezone)
const ReturnFilter = ({ filterValue }) => {
  const initialValues = {
    status: "",
    party_id: "",
    claim_reason: "",
    date: "",
    not_responding_by_portal: "",
    not_responding_by_us: ""
  };
  const dateStr = "2024-03-05T15:54:54.000Z";
  const date = new Date(dateStr);
  const day = String(date.getUTCDate()).padStart(2, '0');
  const month = String(date.getUTCMonth() + 1).padStart(2, '0');
  const year = date.getUTCFullYear();
  const formattedDate = `${day}/${month}/${year}`;
  const [formValues, setFormValue] = useState(initialValues);
  const [changeDate, setChangeDate] = useState(null);
  const partyOption = useSelector(state => state.partyOption)
  const claimStatusDropdown = useSelector(state => state.claimStatusDropdown)
  const orderReturnDropdown = useSelector(state => state.orderReturnDropdown)
  const dispatch = useDispatch()
  useEffect(() => {
    filterValue(cleanedFormValues()); // Send only the selected filter values
  }, [formValues]);

  const handleChange = (name, value) => {
    let data = { ...formValues };
    data[name] = value;
    setFormValue(data);
  };
  useEffect(() => {
    // dispatch(getcourierOption({}))
    // dispatch(getgateEntryPassOption({}))
    dispatch(getpartyOption({}))
    dispatch(getorderReturnDropdown({}))
  }, [])
  const onChange = (date, dateString) => {
    setChangeDate(dateString); // Store the selected date string in the state
    handleChange("date", dateString); // Update the breach_date field in the formValues state
  }
  const cleanedFormValues = () => {
    const cleanedValues = {};
    for (const key in formValues) {
      if (formValues[key] !== "") {
        cleanedValues[key] = formValues[key];
      }
    }
    return cleanedValues;
  };
  const respondingbyPortalOptions = [
    {
      // value: true,
      label: "Yes",
      id: true
    },
    {
      // value: false,
      label: "No",
      id: false
    },
  ];
  const respondingbyUsOptions = [
    {
      // value: true,
      label: "Yes",
      id: true
    },
    {
      // value: false,
      label: "No",
      id: false
    },
  ];
  useEffect(() => {
    dispatch(getclaimStatusDropdown({}))
  }, [])
  // console.log(orderReturnDropdown.data.claim_reasons);
  return (
    <div className='mt-4'>
      <div className='row'>
        <div className='col-md-3 col-lg-3 col-12 mb-4'>
          <Dropdown
            allowSearch
            variant="outlinedGreen"
            defaultText="Select Reason"
            color="#1C1C1C"
            fontWeight="700"
            width="100%"
            height="48px"
            selectedId={formValues?.claim_reason}
            onOptionClick={(item) => handleChange("claim_reason", item?.id)}
            options={orderReturnDropdown.data.claim_reasons.map(item => ({ id: item.id, label: item.name }))}
          />
        </div>
        <div className='col-md-3 col-lg-3 col-12 mb-4'>
          <Dropdown
            allowSearch
            variant="outlinedGreen"
            defaultText="Claim Status"
            color="#1C1C1C"
            fontWeight="700"
            width="100%"
            height="48px"
            selectedId={formValues?.status}
            onOptionClick={(item) => handleChange("status", item?.id)}
            options={claimStatusDropdown.data.map(item => ({ id: item.id, label: item.name }))}
          />
        </div>
        <div className='col-md-3 col-lg-3 col-12 mb-4'>
          <Dropdown
            defaultText="Select portal"
            allowSearch
            // border="1px solid #F8B552"
            backgroundColor="white"
            boxShadow="0px 2px 6px #13124212"
            color="#1C1C1C"
            fontWeight="700"
            width="100%"
            height="48px"
            selectedId={formValues?.party_id}
            onOptionClick={(item) => handleChange("party_id", item?.id)}
            options={partyOption.data}
          />
        </div>
        <div className='col-md-3 col-lg-3 col-12 mb-4'>
          <DatePicker
            className='input-custom'
            placeholder="Claim from to date"
            onChange={onChange}
            format="YYYY-MM-DD"
          />
        </div>
        <div className='col-md-3 col-lg-3 col-12 mb-4'>
          <Dropdown
            allowSearch
            variant="outlinedGreen"
            defaultText="Not Responding by Portal"
            color="#1C1C1C"
            fontWeight="700"
            width="100%"
            height="48px"
            selectedId={formValues?.not_responding_by_portal}
            onOptionClick={(item) => handleChange("not_responding_by_portal", item?.id)}
            options={respondingbyPortalOptions}
          />
        </div>
        <div className='col-md-3 col-lg-3 col-12 mb-4'>
          <Dropdown
            allowSearch
            variant="outlinedGreen"
            defaultText="Not Responding by Us"
            color="#1C1C1C"
            fontWeight="700"
            width="100%"
            height="48px"
            selectedId={formValues?.not_responding_by_us}
            onOptionClick={(item) => handleChange("not_responding_by_us", item?.id)}
            options={respondingbyUsOptions}
          />
        </div>
        <div className='col-md-3 col-lg-3 col-12 mt-2'>
          <div className="primary_but">
            <Link onClick={() => setFormValue(initialValues)} className="clear_filter"><img src="/Images/trash.svg" /> Clear Filter</Link>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ReturnFilter
