import React, { useEffect, useState } from 'react'
import Dropdown from '../../../component/Dropdown/DropDownControlled'
import { useDispatch, useSelector } from 'react-redux';
import { getmrpCreationCreateBatchBundle, getmrpCreationDropdowns } from '../../../store/user/actions';

const MrpBatchBundle = ({mClose}) => {
  const initialValues = {
    // loose_return_id:props.loose_return_id
    product_id:"",
    batch:"",
    bundle:"",

  };
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [formErrors, setFormErrors] = useState({});
  const [formValues, setFormValue] = useState(initialValues);
  const mrpCreationCreateBatchBundle = useSelector(state => state.mrpCreationCreateBatchBundle)
  const mrpCreationDropdowns = useSelector(state => state.mrpCreationDropdowns)
  const dispatch = useDispatch()
  const handleChange = (name, value) => {
    let data = { ...formValues };
    data[name] = value;
    setFormValue(data);
  };
  // console.log(props);
  useEffect(() => {
    dispatch(getmrpCreationDropdowns({}))
  }, [])

  const handleUpdate = () => {
    setError(null);
    setLoading(true);
    let check = validate(formValues);
    setFormErrors(check.errors);
    if (check.isValid) {
      setLoading(false);
      dispatch(getmrpCreationCreateBatchBundle({
        batch: formValues.batch,
      bundle: formValues.bundle,
      product_id: formValues.product_id
      }))
    }
  }
  useEffect(()=>{
    if(mrpCreationCreateBatchBundle.isSuccess){
      mClose(false)
    }
  },[mrpCreationCreateBatchBundle])
  useEffect(() => {
    if (Object.keys(formErrors).length > 0) {
    }
  }, [formErrors]);

  const validate = (values) => {
    let isValid = true;
    const errors = {};
    if (!values.product_id) {
      isValid = false;
      errors.product_id = "product id is required";
    }
    if (!values.batch) {
      isValid = false;
      errors.batch = "batch is required";
    }
    if (!values.bundle) {
      isValid = false;
      errors.bundle = "bundle is required";
    }
    return {
      isValid,
      errors,
    };
  };
  return (
    <div className='create_bud'> 
      <div className='alert_modal'>
    <div className='col-md-12 col-lg-12 col-12 mb-4'>
            <label className='select-label'>Batch</label>
            <input
              className='input-custom-primary'
              type='text'
              onChange={(e) => handleChange("batch", e.target.value)}
              // onChange={handleSerialCodeChange}
              value={formValues.batch}
            />
             <span className='error-msg'>{formErrors.batch}</span>
          </div>
          <div className='col-md-12 col-lg-12 col-12 mb-4'>
            <label className='select-label'>Bundle</label>
            <input
              className='input-custom-primary'
              type='text'
              onChange={(e) => handleChange("bundle", e.target.value)}
              // onChange={handleSerialCodeChange}
              value={formValues.bundle}
            />
             <span className='error-msg'>{formErrors.bundle}</span>
          </div>
          <div className='col-md-12 col-lg-12 col-12 mb-4'>
          <label className='select-label'>SKU</label>
          <Dropdown
            allowSearch
            defaultText="Select SKU"
            border="1px solid #F8B552"
            color="#1C1C1C"
            fontWeight="700"
            width="100%"
            height="48px"
            selectedId={formValues?.product_id}
            onOptionClick={(item) => handleChange("product_id", item?.id)}
            options={mrpCreationDropdowns?.data?.sku_list.map(item => ({ id: item?.id, label: item?.name }))}
          />
          <span className='error-msg'>{formErrors.product_id}</span>
        </div>
        <div className='package_butn mt-2'>
          <button
            className='suffix_bag'
            type='button'
            onClick={() => {handleUpdate()}}
          >
          Submit
          </button>
        </div>
   </div></div>
  )
}

export default MrpBatchBundle