import React, { useEffect, useState } from 'react'
import ReactApexChart from 'react-apexcharts';
import DateRangePicker from '@wojtekmaj/react-daterange-picker'
import '@wojtekmaj/react-daterange-picker/dist/DateRangePicker.css';
import { getontimeSecondaryPacage } from '../../../store/user/actions';
import { useDispatch, useSelector } from 'react-redux';

const optionsSelect = [
  { value: "today", label: "Today" },
  { value: "yesterday", label: "Yesterday" },
  { value: "last_seven_days", label: "Last Seven Days" },
  { value: "current_month", label: "Current Month" },
  { value: "last_three_months", label: "Last Month" },
  { value: "last_six_months", label: "Last Six Months" },
];
const OntimePackageScanChart = () => {
  const initialValues = {
    // start_date: "",
    // end_date: "",
  };
  const rangeDate = [new Date(), new Date]
  const [formValues, setFormValue] = useState(initialValues);
  const [filter, setFilter] = useState("last_six_months");
  const [rangePickerDate, setrangePickerDate] = useState(rangeDate);
        const ontimeSecondaryPacage = useSelector(state => state.ontimeSecondaryPacage)
        const dispatch = useDispatch()
        const [selectedDuration, setSelectedDuration] = useState(
          optionsSelect[0].value // Set default value to "duration"
        );
        const [chartData, setChartData] = useState({
          series: [],
        options: {
          chart: {
            type: 'bar',
            height: 450,
            stacked: false,
            borderRadius:10,
            toolbar: {
              show: false,
            },
          },
          stroke: {
            width: 3,
            colors: ['#fff']
          },
          dataLabels: {
            // rotate: -90,
            // rotateAlways:true,
            // orientation: 'vertical',
            enabled: false,
            rotate: -45,
            style: {
                fontSize: '12px',
                fontWeight: 500,
                fontFamily:'Poppins',
              },
            formatter: (val) => {
              return val / 1000 + 'K';
            }
          },
          
          plotOptions: {
            bar: {
              horizontal: false,
              dataLabels: { orientation: "vertical" },
               columnWidth: '26%',
               endingShape: "rounded",
                  borderRadius: 7,
                  borderRadiusApplication: "end", // "around" / "end" 
                  borderRadiusWhenStacked: "last" // "all"/"last"  
      
            }
          },
          xaxis: {
            categories: [],
          },
          fill: {
            opacity: 1
          },
          colors:['#95CBFF','#4A3AFF'],
          yaxis: [
            {
              seriesName: "On Time Scanning Percentage",
              title: {
                text: 'On Time Scanning %',
              },
              labels: {
                formatter: function (value) {
                  return value.toFixed(2);
                },
              },
            },
            {
              seriesName: "Total Packages To Be Scan",
              opposite: true,
              title: {
                text: 'Total Packages To Be Scan',
              },
              labels: {
                formatter: function (value) {
                  return value.toFixed(2);
                },
              },
            },
          ],
          grid: {
            // show: true,
              borderColor: '#E5E5EF',
              strokeDashArray: 4,
              
            // padding: {
            //   bottom: 30
            // }
          },
          legend: {
            position: 'bottom',
            offsetY: 10,
            horizontalAlign:'center',
            show: false,
          },
        }
        });
      
        // console.log();
        useEffect(() => {
          dispatch(getontimeSecondaryPacage({ filter, 
            }))
        }, [filter])
        // console.log(ontimeSecondaryPacage);
        useEffect(() => {
          const formattedSeries = [
            {
              name: 'On Time Scanning Percentage',
              data: ontimeSecondaryPacage.data.map(item => item.on_time_scanning_percentage),
            },
            {
              name: 'Total Packages To Be Scan',
              data: ontimeSecondaryPacage.data.map(item => item.total_packages_to_be_scan),
            },
            // Add more series as needed
          ];
        
          const categories = ontimeSecondaryPacage.data.map(item => item.label);
        
          setChartData(prevData => ({
            ...prevData,
            series: formattedSeries,
            options: {
              ...prevData.options,
              xaxis: {
                ...prevData.options.xaxis,
                categories: categories,
              },
            },
          }));
        }, [ontimeSecondaryPacage]);
   
        const handleFilterChange = (e) => {
          setFilter(e.target.value);
      }
      // console.log(formValues.start_date);
  return (
    <div className='white_box'>
    <div className='heading_select'>
            <h2>Ontime secondary package scan </h2>
            <div className="custom-select">
                      <select className='selectArea'
                       value={filter}
                       onChange={handleFilterChange}
                      >
                        {
                          optionsSelect && optionsSelect.map((item) => (
                            <option value={item.value}>{item.label}</option>
                          ))
                        }
                      </select>
                    </div>
            </div>
   <div className="border-wordload"></div>
   <div>
  <ReactApexChart options={chartData.options} series={chartData.series} type="bar" height={450} />
  </div>
</div>
  )
}

export default OntimePackageScanChart
