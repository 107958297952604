import React from 'react'
import SecondaryTable from './component/SecondaryTable'

const SecondaryScan = () => {
  return (
    <div>
      <SecondaryTable />
    </div>
  )
}

export default SecondaryScan
