
import React, { useState, useEffect } from "react";

import Table from '../../../component/Table/Table'

import { Pagination } from 'antd';
import { Link, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getlooseReturnPackagesList, } from "../../../store/user/actions";
import Loader from "../../../component/Loader";
import DefaultModal from "../../../component/DefaultModal/DefaultModal";


const PackageDetailsTable = () => {

  
    const [formValues, setFormValue] = useState([]);
    const [current, setCurrent] = useState(1);
    const [tableData, setTableData] = useState([]);
    const [limitData, setLimitData] = useState(10);
    const [selectedImage, setSelectedImage] = useState([]);
    const [showFullImage, setShowFullImage] = useState(false);
    const [totalData, setTotalData] = useState(0);
    const looseReturnPackagesList = useSelector(state => state.looseReturnPackagesList)
    const dispatch = useDispatch()
    const navigate = useNavigate();
    const { id } = useParams();
  
    useEffect(() => {
        let data = { ...formValues };
        data["loose_return_bag_id"] = id;
        data["loose_return"] = 1
        data["page"] = current
        data["limit"] = 10
      dispatch(getlooseReturnPackagesList(data))
    }, [])

    
        useEffect(()=>{
            setTotalData(looseReturnPackagesList.data.total)
            // let sno = (current-1)*10+1;
        const FakeTableData = looseReturnPackagesList && looseReturnPackagesList.data?.data?.data.map((item, i) => {
            return [
            <>
               <h6>{i+1}</h6>
            </>,
           
                    // <h6>{item.courier_no}</h6>
           ,
            <div>
                <h6>{item.tracking_no}</h6>
            </div>,
            <>

                <h6>{item.order_no}</h6>
            </>,
            <>
                <h6>{item.no_of_items}</h6>
            </>,
            <>
                <h6>{item.suffix_string}</h6>
            </>,
            <>
                <h6>{item.dispatch_date}
                {/* {item.dispatch_date} */}
                </h6>
            </>,
             <>
             <h6>{item.is_secondary_scan}</h6>
            </>,
             <div className="image_pic">
             {item.images.slice(0, 1).map((picture, index) => (
               <img key={index} src={picture.thumbnail} alt={`Item ${index}`} 
               // onClick={() => { setShowFullImage(true); }}
               onClick={() => handleImageClick(item.images)}
               />
             ))}
             {item.images.length > 3 && (
               <div className="image-overlay" onClick={() => handleImageClick(item.images)}>{`${item.images.length - 3}+`}</div>
             )}
           </div>
           
             
          
]
})
setTableData(FakeTableData);
},[looseReturnPackagesList])

    const TemplateTableData = [

        {
            heading: "Sr.No.",
        },
        // {
        //     heading: "Courier Number",
        // },
        {
            heading: "Tracking Number",
        },
        {
            heading: "Order Number",
        },
        {
            heading: "Number of Items",
        },
        {
            heading: "String Matched",
        },
        {
            heading: "Dispatch Date",
        },
        {
            heading: "Secondary Scan",
        },
        {
            heading: "Item Pictures",
        },
       

    ];
    const handleGoBack = () => {
        // Navigate back to the previous item
        navigate(-1);
      };
      const handlePageChange = (page,limit) => {
        setCurrent(page);
        setLimitData(limit)
        dispatch(getlooseReturnPackagesList({page,limit:limit}))
    };
    const handleImageClick = (images) => {
        setSelectedImage(images);
        setShowFullImage(true); // Show modal
      };
    return (
        <div>
           <div className='heading_select'>
                    
                    <div className="d-flex align-items-baseline" onClick={handleGoBack}>
                    <img style={{ cursor: "pointer" }} src="/Images/arrow-left.svg" />
                        <h1>Package Summary</h1>
                        </div>
                   <div className="primary_but">
                    <button><img src="/Images/exportFile.svg"/>Export File</button>
                   </div>
                </div>
             
            <div className='white_box'>
                <div className="position-relative">
                    {
              looseReturnPackagesList.isLoading && <div className="loader_bg"><Loader /></div>
                 } 
                    <div className='table-bg'>
                        <Table
                            gridTemplateColumnsHeader="10% 10% 15% 15% 10% 15% 10% 15%"
                            gridTemplateColumnsBody="10% 10% 15% 15% 10% 15% 10% 15%"
                            columns={TemplateTableData}
                            data={tableData}
                            Loader={true}
                        />
                        <Pagination
                            current={current}
                            onChange={handlePageChange}
                            total={looseReturnPackagesList.pagination ? looseReturnPackagesList.pagination.total : 0}
                            pageSize={limitData}
                             />
                    </div>

                </div>
            </div>
            <DefaultModal
                show={showFullImage}
                onHide={() => setShowFullImage(false)}
                className="image-doc-modal image_big_modal"
                style={{ paddingRight: "0px" }}
                centered={false}
                showHeader={false}
                size=""
                showFooter={false}
            >
                <div className="modal-heading">
                    <h4></h4>
                    <div className="close-icon">
                        <img src="/Images/closeBlack.svg" onClick={() => setShowFullImage(false)} />
                    </div>
                </div>
                {/* Render the selected image in the modal */}
                <div className="row">
                {selectedImage.map((image, index) => (
                    <div className="col-lg-6 image_gallery mt-2">
                    <img
                        key={index}
                        src={image.thumbnail}
                        alt={`Image ${index}`}
                        style={{ width: '100%', height: '250px',objectFit:"cover" }}
                    />
                    </div>
                ))}
                </div>
                </DefaultModal>
        </div>
    )
}

export default PackageDetailsTable

