import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import styled from "styled-components";
import Header  from "../Header";
import LeftSideBar  from "../LeftSideBar";



const RightSide = styled.div`
padding:2.5rem 2rem 2rem 2rem;
 
  background: #F3F5F6;
  // overflow-y: scroll;

  // &::-webkit-scrollbar {
  //   width: 8px;
  // }
  // &::-webkit-scrollbar-thumb {
  //   background: #9197b3;
  //   border-radius: 5px;
  // }
`;
const RightSideSection = styled.div`
   width: ${(props) => (props.open ? "100%" : "100%")};
`;

const NavbarSidebarAdmin = ({ children }) => {

  // const [open, setOpen] = useState(true);
    
    return (
      <div className="container-fluid p-0">
        <div className="d-flex">
          <LeftSideBar />
          <RightSideSection>
            <Header />
            <RightSide>{children}</RightSide>
          </RightSideSection>
        </div>
      </div>
    );
  };

NavbarSidebarAdmin.propTypes = {
  children: PropTypes.element.isRequired,
};

export default NavbarSidebarAdmin;
