import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getcourierReturnEmailSettingCreate, getcourierReturnEmailSettingRead, gettatSettingsStoreAll } from '../../../store/user/actions';
import Dropdown from '../../../component/Dropdown/DropDownControlled';


const EmailSettings = () => {
  const initialValues = {
    email_setting_id: '',
    party_id: '',
    username: '',
    email: '',
    // server_ip: '',
    sender_host:'',
    sender_port:'',
    sender_encryption:'',
    receiver_host:'',
    password: '',
  };
  const [formValues, setFormValues] = useState(initialValues);
  const [validationError, setValidationError] = useState('');
  const courierReturnEmailSettingCreate = useSelector((state) => state.courierReturnEmailSettingCreate);
  const courierReturnEmailSettingRead = useSelector((state) => state.courierReturnEmailSettingRead);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getcourierReturnEmailSettingRead(formValues));
  }, []);

  useEffect(() => {
    if(courierReturnEmailSettingRead.isSuccess){
      const readdata = {}
      courierReturnEmailSettingRead.data.map((item)=>{
        readdata[`username_${item.party_id}`] = item.username
        readdata[`email_${item.party_id}`] = item.email
        // readdata[`server_ip_${item.party_id}`] = item.server_ip
        readdata[`sender_host_${item.party_id}`] = item.sender_host
        readdata[`sender_port_${item.party_id}`] = item.sender_port
        readdata[`sender_encryption_${item.party_id}`] = item.sender_encryption
        readdata[`receiver_host_${item.party_id}`] = item.receiver_host
        readdata[`password_${item.party_id}`] = item.password
        readdata[`email_setting_id_${item.party_id}`] = item.email_setting_id
       
      })
      setFormValues(readdata)
    }
  }, [courierReturnEmailSettingRead]);
// console.log(courierReturnEmailSettingRead);

  const handleChange = (name, value) => {
    setValidationError(''); // Clear validation error
    setFormValues((prevFormValues) => ({
      ...prevFormValues,
      [name]: value,
    }));
  };
  console.log("Form Values:", formValues);
  const handleSubmit = () => {
    const dataToSend = [];
    console.log("formValues.email_setting_id:", formValues.email_setting_id);
    const emailSettingId = formValues.email_setting_id || '';
    console.log("emailSettingId:", emailSettingId);
    // Loop through the form values and create objects for each party
    for (const [partyId, partyValues] of Object.entries(formValues)) {
      if (partyId.startsWith('username_')) {
        const partyIdNum = partyId.replace('username_', '');
        dataToSend.push({
          email_setting_id: formValues[`email_setting_id_${partyIdNum}`],
          party_id: partyIdNum,
          username: partyValues,
          email: formValues[`email_${partyIdNum}`],
          // server_ip: formValues[`server_ip_${partyIdNum}`],
          password: formValues[`password_${partyIdNum}`],
          sender_host: formValues[`sender_host_${partyIdNum}`],
          sender_port: formValues[`sender_port_${partyIdNum}`],
          receiver_host: formValues[`receiver_host_${partyIdNum}`],
          sender_encryption: formValues[`sender_encryption_${partyIdNum}`],
        });
      }
    }
    console.log("Data to Send:", dataToSend);
    // Send the data to the API
    dispatch(getcourierReturnEmailSettingCreate({data:dataToSend}));
  };
console.log(courierReturnEmailSettingRead);

const senderOptions = [
  {
    // value: true,
    label: "tls",
    id: "tls"
  },
  {
    // value: false,
    label: "ssl",
    id: "ssl"
  },
];

  return (
    <div className='suffix_title'>
      <h4>Email Settings</h4>
      {courierReturnEmailSettingRead?.data?.map((party) => (
        <div className='row' key={party.party_id}>
          <label>{party.party_name}</label>
          <div className='col-md-3 col-lg-3 col-12 mb-4 mt-2'>
            <input
              placeholder='Enter Username'
              type="text"
              className='input-custom-common'
              onChange={(e) => handleChange(`username_${party.party_id}`, e.target.value)}
              value={formValues[`username_${party.party_id}`]}
            />
          </div>
          <div className='col-md-3 col-lg-3 col-12 mb-4 mt-2'>
            <input
              placeholder='Enter Email'
              type="email"
              className='input-custom-common'
              onChange={(e) => handleChange(`email_${party.party_id}`, e.target.value)}
              value={formValues[`email_${party.party_id}`]}
            />
          </div>
          {/* <div className='col-md-3 col-lg-3 col-12 mb-4 mt-2'>
            <input
              placeholder='Enter Server IP'
              type="text"
              className='input-custom-common'
              onChange={(e) => handleChange(`server_ip_${party.party_id}`, e.target.value)}
              value={formValues[`server_ip_${party.party_id}`]}
              autocomplete="new-password"
            />
          </div> */}
          <div className='col-md-3 col-lg-3 col-12 mb-4 mt-2'>
            <input
              placeholder='Enter sender host'
              type="text"
              className='input-custom-common'
              onChange={(e) => handleChange(`sender_host_${party.party_id}`, e.target.value)}
              value={formValues[`sender_host_${party.party_id}`]}
              
            />
          </div>
          <div className='col-md-3 col-lg-3 col-12 mb-4 mt-2'>
            <input
              placeholder='Enter sender port'
              type="text"
              className='input-custom-common'
              onChange={(e) => handleChange(`sender_port_${party.party_id}`, e.target.value)}
              value={formValues[`sender_port_${party.party_id}`]}
              
            />
          </div>
          <div className='col-md-3 col-lg-3 col-12 mb-4 mt-2'>
            <input
              placeholder='Enter receiver host'
              type="text"
              className='input-custom-common'
              onChange={(e) => handleChange(`receiver_host_${party.party_id}`, e.target.value)}
              value={formValues[`receiver_host_${party.party_id}`]}
              
            />
          </div>
          <div className='col-md-3 col-lg-3 col-12 mt-3'>
            {/* <input
              placeholder='Enter sender encryption'
              type="text"
              className='input-custom-common'
              onChange={(e) => handleChange(`sender_encryption_${party.party_id}`, e.target.value)}
              value={formValues[`sender_encryption_${party.party_id}`]}
              
            /> */}
            <Dropdown
            allowSearch
            variant="outlinedGreen"
            defaultText="Sender Encryption"
            color="#1C1C1C"
            fontWeight="700"
            width="100%"
            border="1px solid #B9BCBF"
            height="48px"
            borderRadius="7px"
            selectedId={formValues[`sender_encryption_${party.party_id}`]}
            onOptionClick={(item) => handleChange(`sender_encryption_${party.party_id}`, item?.id)}
            options={senderOptions}
          />
          </div>
          <div className='col-md-3 col-lg-3 col-12 mb-4 mt-2'>
            <input
              placeholder='Enter Password'
              type='password'
              className='input-custom-common'
              onChange={(e) => handleChange(`password_${party.party_id}`, e.target.value)}
              value={formValues[`password_${party.party_id}`]}
              autocomplete="new-password"
            />
          </div>
        </div>
      ))}
      <div className='mx-auto text-center'>
        <button className='common_but' type='submit' onClick={handleSubmit}>
          Submit
        </button>
      </div>
    </div>
  );
};

export default EmailSettings;
