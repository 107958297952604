import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import { getmeasurementUnitRead, getorderItemCreate } from '../../../store/user/actions';
import { useDispatch, useSelector } from 'react-redux';
import Dropdown from '../../../component/Dropdown/DropDownControlled';

const CreateAddItem = () => {
    const initialValues = {
        name: "",
        uom_id: "",
        image_file: "",
    };
    const [formValues, setFormValue] = useState(initialValues);
    const [files, setFiles] = useState([]);
    const [index, setIndex] = useState(0);
    const measurementUnitRead = useSelector(state => state.measurementUnitRead)
    const dispatch = useDispatch()
  
    useEffect(() => {
      dispatch(getmeasurementUnitRead({}))
   
    }, [])
    
    const handleChange = (name, value) => {
        let data = { ...formValues };
        data[name] = value;
        setFormValue(data);
    };
    //image_upload_fuction
    const handleFileChange = (e, index) => {
        const selectedFiles = e.target.files;
        const oldFiles = [...files];
        const newFiles = [];
        for (let i = 0; i < selectedFiles.length; i++) {
            newFiles.push(selectedFiles[i]);
        }
        oldFiles[index] = newFiles;
        setFiles(oldFiles);
    };
    const handleRemoveFile = (index, fileindex) => {
        const newFiles = [...files];
        newFiles[index].splice(fileindex, 1);

        setFiles(newFiles);
    };
    const handleSubmit = () => {
        const formData = new FormData();
        formData.append('received_packages', formValues.received_packages);
        if (files.length > 0) {
            for (let i = 0; i < files.length; i++) {
                formData.append(`images[${i}]`, files[i][0]);
            }
        }
        // Construct boxes data
        formData.append('id');
        dispatch(getorderItemCreate(formData));
    };
    return (
        <div>
            <div className="primary_input_section">
                <div className='col-lg-12'>
                    <label className='select-label'>Items</label>
                    <input
                        placeholder='Forward Order Id'
                        className='input-custom-primary'
                        type='text'
                        onChange={(e) => handleChange("name", e.target.value)}
                        value={formValues.name}
                    />
                </div>
                <div className='col-lg-12 mt-3'>
                    <label className='select-label'>Uom</label>
                    <Dropdown
                    allowSearch
                    border="1px solid #F8B552"
                    color="#1C1C1C"
                    fontWeight="500"
                    width="100%"
                    height="48px"
                    fontSize="13px"
                    selectedId={formValues?.uom_id}
                    onOptionClick={(item) => handleChange("uom_id", item?.id)}
                    options={measurementUnitRead.data.map(item => ({ id: item.id, label: item.name }))}
                    />

                </div>
                <div className="file-preview-container position-relative upload-pic-box mt-4 mb-4">
                    <input
                        type="file"
                        multiple
                        className="hidden-file-input"
                        onChange={(e) => handleFileChange(e, index)}
                        accept='image/*'
                    />
                    <div className='mb-2'>
                        <img src="/Images/camera.svg" />
                        <div>
                            <h5>Upload Image</h5>
                            <small>You can upload JPG, PNG</small>
                        </div>
                    </div>
                    <div className='d-flex'>
                        {files[index] && files[index].map((file, fileIndex) => (
                            <div className="file-preview">
                                <img
                                    src={URL.createObjectURL(file)}
                                    alt={`Preview ${index}`}
                                />
                                <button onClick={() => handleRemoveFile(index, fileIndex)} className='close_red'><img src='/Images/redClose.svg' /></button>
                            </div>
                        ))}
                    </div>
                </div>
                <div className='package_butn mt-4 mb-2'>
                    <Link className='suffix_bag w-100 d-block' onClick={handleSubmit}>Submit</Link>
                </div>
            </div>
        </div>
    )
}

export default CreateAddItem
