import React, { useEffect, useState } from 'react'
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { useDropzone } from 'react-dropzone'
import { getreturnedContainerBoxCreate, getreturnedContainerBoxRead } from '../../store/user/actions';
import { useDispatch, useSelector } from 'react-redux';
import DefaultModal from '../../component/DefaultModal/DefaultModal';

const BoxScanThree = () => {
  const initialValues = {
    file: "",
    document: "",
    no_of_boxes_received: 0,
    returned_container_id:0,
    total_pieces:0,
    box_no:0
  };
  const navigate = useNavigate();
  const [formValues, setFormValue] = useState(initialValues);
  const [selectedImage, setSelectedImage] = useState([]); 
  const [showFullImage, setShowFullImage] = useState(false);
  const returnedContainerBoxRead = useSelector(state => state.returnedContainerBoxRead)
  const dispatch = useDispatch()
  const params = useParams()
  // Image upload state

  const handleGoBack = () => {
    navigate(-1);
  };
 
  // returned_container_create
//  useEffect(()=>{
//   dispatch(getreturnedContainerBoxRead({}))
//  },[])


// console.log(returnedContainerBoxRead);

  useEffect(() => {
    const storedFormData = JSON.parse(localStorage.getItem('formData'));
   dispatch(getreturnedContainerBoxRead({ returned_container_id: params.id }));

    console.log(storedFormData);
  }, []);
  const openImageModal = (images) => {
    setSelectedImage(images);
    setShowFullImage(true);
  };
  return (
    <div>
      <div className='heading_select'>
        <div className="d-flex align-items-baseline" onClick={handleGoBack}>
          <img style={{ cursor: "pointer" }} src="/Images/arrow-left.svg" />
          <h1>Box Scan Form</h1>
        </div>
      </div>
      <div className='row'>
      {/* {Array.from({ length: formValues.no_of_boxes_received }).map((item, index) => ( */}
      {returnedContainerBoxRead.data.map((item,index)=>(
          <div className='col-lg-4' key={index}>
            <div className='scan_box'>
              <div className='gray_box'>
                BOX-{index + 1}
              </div>
              <div className="file-preview-container position-relative">
                <h6 className='sort_title'>Pictures</h6>
              {item?.returned_container_box_pictures.map((picture, index) => (
                  <img key={index} src={picture.file_url} alt={`Picture ${index}`} className='upload_pic' onClick={() => openImageModal(item.returned_container_box_pictures)}/>
                ))}
              </div>
              <div className="file-preview-container position-relative">
              <h6 className='sort_title'>Documents</h6>
              {item.returned_container_box_documents?.map((document, index) => (
                //  <img key={index} src={document.file_url} alt={`Document ${index}`} className='upload_pic' />
                <a key={index} href={document.file_url} target="blank"download={`Doc_${index+1}`} className='upload_pic'>
                {/* <img src={document.file_url} alt={`Doc ${index}`} className='upload_pic doc_text' /> */}
                <img src="/Images/download.svg" alt={`Doc ${index}`} className='upload_pic doc_text' />
                {/* <img src={document.file_url==""?"/Images/download.svg":document.file_url}className='upload_pic doc_text'alt={`Doc ${index}`} /> */}
            </a>
               ))}
               
              </div>
              <div className='mt-4 mb-2 position-relative'>
                <div className='box-data'>
                  <h6>Total Pieces </h6>
                  <p>{item.total_pieces}</p>
                </div>
                <div className='box-data'>
                  <h6>Box Number</h6>
                  <p>{item.box_no}</p>
                </div>
                <div className='box_butn mt-2'>
                  <Link to={"/primary-upload/"+item.returned_container_id+"/"+item.returned_container_box_id} className='add_bag'>Open Box and Count Pieces</Link>
                </div>
              </div>
            </div>
          </div>
        ))}

      </div>
      <div className='package_butn mt-4'>
        <Link to="/primary-scan?tab=Screen%20details" className='suffix_bag'>Back to home</Link>
      </div>
      <DefaultModal
                show={showFullImage}
                onHide={() => setShowFullImage(false)}
                className="image-doc-modal"
                style={{ paddingRight: "0px" }}
                centered={false}
                showHeader={false}
                size=""
                showFooter={false}
            >
                <div className="modal-heading">
                    <h4></h4>
                    <div className="close-icon">
                        <img src="/Images/closeBlack.svg" onClick={() => setShowFullImage(false)} />
                    </div>
                </div>
                {/* Render the selected image in the modal */}
                <div className="row">
                {selectedImage.map((image, index) => (
                    <div className="col-lg-4 image_gallery mt-2">
                    <img
                        key={index}
                        src={image.file_url}
                        alt={`Image ${index}`}
                        style={{ width: '100%', height: '300px', objectFit:"cover"}}
                    />
                    </div>
                ))}
                </div>
                </DefaultModal>
    </div>
  )
}

export default BoxScanThree
