
import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import Dropdown from '../../component/Dropdown/DropDownControlled';
import CustomVideoRecorder from './component/CustomVideoRecorder';
import { useDispatch, useSelector } from 'react-redux';
import { getserialCodebyOrderNo, getorderItemRead, getorderReturnCreate, getorderReturnDropdown, getorderDetailsBySerialCode, getserialCodesTrackingNo } from '../../store/user/actions';
import DefaultModal from '../../component/DefaultModal/DefaultModal';
import CreateAddItem from './component/CreateAddItem';
import DispositionTable from './component/DispositionTable';
import AddMoreItem from './component/AddMoreItem';
import { resetorderDetailsBySerialCode, resetorderReturnCreateState } from '../../store/user/userSlice';
import Table from '../../component/Table/Table';
import Loader from '../../component/Loader';

const SecondaryScanAdd = () => {
  const navigate = useNavigate();
  const initialValues = {
    return_type: "",
    party_id: "",
    carier_id: "",
    order_no: "",
    gate_incoming_pass_id: "",
    forward_order_no: "",
    forward_tracking_no: "",
    backward_order_no: "",
    backward_tracking_no: "",
    backward_other_id_1: "",
    backward_other_id_2: "",
    is_problem: "",
    is_any_other_item_found: "",
    claim_reason_id: "",
    video_file: "",
    disposition_id: "",
    parcel_id: "",
    serial_code: ""
  };
  const [formValues, setFormValue] = useState(initialValues);
  const [itemsValues, setItemsValue] = useState({
    items: [""], // Array to store item IDs
    qty: [], 
  });
  const [showSecondBox, setShowSecondBox] = useState(false);
  const [addItemsModal, setAddItemsModal] = useState(false);
  // const [dispositionData, setDispositionData] = useState([]);
  const [videoBox, setVideoBox] = useState(true);
  const [formErrors, setFormErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [current, setCurrent] = useState(1);
  const [apiCall, setApiCall] = useState(1);
  const [arrayOfObjects, setArrayOfObjects] = useState([]);
  const [dropdownSelectedValues, setDropdownSelectedValues] = useState({});
  const orderItemRead = useSelector(state => state.orderItemRead);
  const [totalData, setTotalData] = useState(0);
  const [invalidInputMsg, setInvalidInputMsg] = useState('');
  const orderDetailsBySerialCode = useSelector(state => state.orderDetailsBySerialCode)
  const orderReturnDropdown = useSelector(state => state.orderReturnDropdown)
  const orderReturnCreate = useSelector(state => state.orderReturnCreate)
  const serialCodebyOrderNo = useSelector(state => state.serialCodebyOrderNo)
  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(getorderReturnDropdown({}))
  }, [])
  // console.log(orderReturnDropdown.data.return_open_sub_location_list);
  const handleGoBack = () => {
    // Navigate back to the previous item
    navigate(-1);
  };
  const parcelOptions = [

    {
      // value: 'Yes',
      label: "Yes",
      id: "1"
    },
    {
      // value: 'No',
      label: "No",
      id: "0"
    },
  ];
  const otherItemFoundOptions = [

    {
      value: 'Yes',
      label: "Yes",
      id: "Yes"
    },
    {
      value: 'No',
      label: "No",
      id: "No"
    },
  ];
  const handleChange = (name, value) => {
    let data = { ...formValues };
    data[name] = value;
    setFormValue(data);
  };

  const handleDrodownChange =( value, index)=>{
   const dispotionData = [...arrayOfObjects]
   dispotionData[index].disposition_id=value
   setArrayOfObjects(dispotionData)
  }
  const callOnBlurOrder = () => {
    const requestBody = {
      order_no: formValues.forward_order_no,
    };
    dispatch(getserialCodebyOrderNo(requestBody))
  };
  const callOnBlurTrack = (e) => {
    const requestBody = {
      tracking_no: formValues.forward_tracking_no,
    };
    dispatch(getserialCodesTrackingNo(requestBody))

  }
  // console.log(formValues);
  const handleStartRecording = () => {
    // When start recording is clicked, show the second box
    setShowSecondBox(true);
  };
  // Validation function for backward inputs
  const isBackwardInputRequired = () => {
    return formValues.return_type === 'Customer Return';
  };
  // validation-start
  useEffect(() => {
    if (Object.keys(formErrors).length > 0) {
    }
  }, [formErrors]);

  const validate = (values) => {
    let isValid = true;
    const errors = {};
    if (!values.return_type) {
      isValid = false;
      errors.return_type = "Return type is required";
    }
    if (!values.party_id) {
      isValid = false;
      errors.party_id = "Party Id is required ";
    }
    if (!values.carier_id) {
      isValid = false;
      errors.carier_id = "Carier Id is required ";
    }
    if (!videoBox || !videoBox) { // Check if videoBox is undefined or has no length
      isValid = false;
      errors.video_file = "Video is required";
    }
    if (!values.gate_incoming_pass_id) {
      isValid = false;
      errors.gate_incoming_pass_id = "Gate pass id required";
    }
    if (!values.forward_order_no && values.return_type !== '2') { // Validation for Forward Order No only if Return Type is not Customer
      isValid = false;
      errors.forward_order_no = "Forward order no required";
    }
    if (!values.forward_tracking_no && values.return_type !== '2') { // Validation for Forward Tracking No only if Return Type is not Customer
      isValid = false;
      errors.forward_tracking_no = "Forward Ttracking no required";
    }
    
    if (!values.backward_tracking_no && values.return_type !== '1') {
      isValid = false;
      errors.backward_tracking_no = "Backward tracking no required";
    }
    if (!values.backward_order_no && values.return_type !== '1') {
      isValid = false;
      errors.backward_order_no = "Backward order no required";
    }
    // if (!values.backward_other_id_1 && values.return_type !== '1') {
    //   isValid = false;
    //   errors.backward_other_id_1 = "Backward tracking no required";
    // }
    // if (!values.backward_other_id_2 && values.return_type !== '1') {
    //   isValid = false;
    //   errors.backward_other_id_2 = "Backward order no required";
    // }
    return {
      isValid,
      errors,
    };
  };
  console.log(formValues);
  const validateSub = (values) => {
    let isValid = true;
    const errors = {};
    if (!values.sub_location_id) {
      isValid = false;
      errors.sub_location_id = "Sub Location is required";
    }
    return {
      isValid,
      errors,
    };
  }

  const handleSubmit = (e, name, value) => {
    setError(null);
    if (videoBox) {
      let data = { ...formValues };
      data["video_file"] = videoBox;
      setFormValue(data);
    }
    // console.log(formValues);
    // setLoading(true);
    let check = validate(formValues);
    let data = { ...initialValues };
    data[name] = value;
    setFormErrors(check.errors);
     console.log(check.errors);
    if (check.isValid) {
      console.log(check.isValid);
      setLoading(true);
      const formData = new FormData();
      console.log(formData, itemsValues);
      if (videoBox) {
        formData.append("video_file", videoBox)
      }
      formData.append("return_type", formValues.return_type)
      formData.append("party_id", formValues.party_id)
      formData.append("carier_id", formValues.carier_id)
      formData.append("gate_incoming_pass_id", formValues.gate_incoming_pass_id)
      formData.append("forward_order_no", formValues.forward_order_no)
      formData.append("forward_tracking_no", formValues.forward_tracking_no)
      formData.append("backward_tracking_no", formValues.backward_tracking_no)
      formData.append("backward_order_no", formValues.backward_order_no)
      formData.append("backward_other_id_1", formValues.backward_other_id_1)
      formData.append("backward_other_id_2", formValues.backward_other_id_2)
      formData.append("is_problem", formValues.is_problem)
      formData.append("claim_reason_id", formValues.claim_reason_id)

      formData.append("bbsc",JSON.stringify(arrayOfObjects))
      // console.log(tableData);
      const orderReturnItems = itemsValues.items.map((itemId, index) => ({
        item_id: itemId,
        qty: itemsValues.qty[index]
      }));
      // Append other form data
      if(formValues.is_problem==1){
      formData.append("order_return_item", JSON.stringify(orderReturnItems));
      }else{
        formData.append("order_return_item", JSON.stringify([]));
      }
      
      dispatch(getorderReturnCreate(formData))
      console.log(formData, itemsValues);
    }
  }
  
  useEffect(() => {
    if (orderReturnCreate.isSuccess) {
      // reset-pending
      setLoading(false);
      navigate('/secondary-scan');
      dispatch(resetorderReturnCreateState())
      dispatch(resetorderDetailsBySerialCode())
    }
  }, [orderReturnCreate]);
  const videoValue = (selectValue) => {
    setVideoBox(selectValue)
  }
  //table-data-start

  useEffect(() => {
    setTotalData(orderDetailsBySerialCode.data.total)
    let sno = (current - 1) * 10 + 1;
    const FakeTableData = arrayOfObjects.map((item, i) => {
       const uniqueId = i
      return [
        <h6>{sno + i}</h6>,
        // Your table row content (headings, `<h6>` elements, and `Dropdown`)

        <div> <h6>{item.sub_location_barcode}</h6> </div>,
        <div><h6>{item.serial_code}</h6> </div>,
        <div> <h6>{item.sku}</h6></div>,
        <><h6>{item.order_no}</h6></>,
        <> <h6>{item.tracking_no}</h6> </>,
        <>
          <Dropdown
            allowSearch
            color="#1C1C1C"
            fontWeight="500"
            width="100%"
            height="48px"
            fontSize="13px"
            border="1px solid #F8B552"
            // selectedId={formValues?.disposition_id[uniqueId]} // Assuming formValues.disposition_id is an object with uniqueId keys
            // onOptionClick={(item) => handleChange("disposition_id", { ...formValues.disposition_id, [uniqueId]: item?.id })}
            // options={parcelGradeOptions}
            selectedId={arrayOfObjects[i].disposition_id}
            onOptionClick={(item) => handleDrodownChange(  item?.id,i)}
            options={orderReturnDropdown.data.disposition_list.map(item => ({ id: item.id, label: item.name }))}
          />
        </>
      ]
    })
    setTableData(FakeTableData);
  }, [arrayOfObjects, formValues.disposition_id])
console.log(arrayOfObjects);

  const TemplateTableData = [

    {
      heading: "Sr.No.",
    },
    {
      heading: "Sub Location",
    },
    {
      heading: "Serial Code",
    },
    {
      heading: "SKU",
    },
    {
      heading: "Order ID",
    },
    {
      heading: "Tracking ID",
    },
    {
      heading: "Disposition",
    },
  ];
  useEffect(() => {
    // Default values for disposition_id
  const defaultDispoForCarrier = 0;
  const defaultDispoForCustomer = 0;
    if (orderDetailsBySerialCode.isSuccess) {
      const isNameUnique = arrayOfObjects.every(obj => obj.serial_code !== orderDetailsBySerialCode.data.serial_code);
      if (isNameUnique) {
        const orderTrackingCode = {...orderDetailsBySerialCode.data}
        orderTrackingCode.disposition_id = formValues.disposition_id || defaultDispoForCarrier;
        orderTrackingCode.sub_location_id = formValues.sub_location_id
        // orderTrackingCode.disposition_id = formValues.disposition_id
        orderTrackingCode.sub_location_barcode = getLocationLable(formValues.sub_location_id)
      if(orderTrackingCode.order_no==formValues.forward_order_no){
        orderTrackingCode.is_extra=0
      }else{
        orderTrackingCode.is_extra=1
      } if(orderTrackingCode.order_no==formValues.forward_order_no){
        orderTrackingCode.found_status=true
      }else{
        orderTrackingCode.found_status=false
      }
      console.log(orderTrackingCode);
        setArrayOfObjects((prevArray) => [...prevArray, orderTrackingCode]);
      }
    }
  }, [orderDetailsBySerialCode])
  const getLocationLable = (id) => {
   const returnLocation = orderReturnDropdown.data.return_open_sub_location_list.filter(item => item.id==id)
    if(returnLocation.length){
    return returnLocation[0].name
    }
    return ""
  }
  // console.log(arrayOfObjects);

  const handleKeyPressEnter = (e) => {
    if (e.key === 'Enter') {
      setError(null);
      // setLoading(true);
      let check = validateSub(formValues);
      setFormErrors(check.errors);
      if (check.isValid) {
      dispatch(getorderDetailsBySerialCode({ serial_code: formValues.serial_code }))
        .catch(error => {
          console.error('Error:', error);
        });
    }
  }else {
    setLoading(false);
  }
  };
  const nextSubmit = (e) => {
    // if (apiCall!=1){
    //   alert("Please add another code")
    //   return
    // }setApiCall(2)
    setError(null);
    setLoading(true);
    let check = validateSub(formValues);
    setFormErrors(check.errors);
    if (check.isValid) {
      setLoading(false);
    dispatch(getorderDetailsBySerialCode({ serial_code: formValues.serial_code }))
    }
    console.log(orderDetailsBySerialCode);
  }
  // console.log(videoBox);
  useEffect(() => {
    dispatch(getorderItemRead({}));
    // handleAddMore()
    console.log("hghjgg");
}, []); 
const handleItemChange = (index, type, value) => { // Handle item or qty changes
  setItemsValue(prevValues => ({
      ...prevValues,
      [type]: [ // Update specific array based on type
          ...prevValues[type].slice(0, index), // Maintain existing elements before index
          value,                                // Update value at specific index
          ...prevValues[type].slice(index + 1), // Maintain existing elements after index
      ],
  }));
};
const handleAddMore = () => {
  setItemsValue(prevValues => ({
      ...prevValues,
      items: [...prevValues.items, ''], // Add empty item ID using spread operator
      qty: [...prevValues.qty, ''],     // Add empty quantity using spread operator
  }));
};
// console.log(orderReturnDropdown?.data);
  return (
    <div className='primary_height_sec'>
      <div className='heading_select'>
        <div className="d-flex align-items-baseline" onClick={handleGoBack}>
          <img style={{ cursor: "pointer" }} src="/Images/arrow-left.svg" />
          <h1>Secondary Scan Add</h1>
        </div>

      </div>
      <div className="primary_bg">
        <div className="row primary_input_section">
          <div className='col-md-4 col-lg-4 col-12 mb-4 position-relative'>
            <label className='select-label'>Type of Return</label>
            <Dropdown
              allowSearch
              border="1px solid #F8B552"
              color="#1C1C1C"
              fontWeight="500"
              width="100%"
              height="48px"
              fontSize="13px"
              selectedId={formValues?.return_type}
              onOptionClick={(item) => handleChange("return_type", item?.id)}
              options={Object.entries(orderReturnDropdown.data.return_types).map(([id, label]) => ({ id, label }))}
            />
            <span className='error-msg'>{formErrors.return_type}</span>
          </div>
          <div className='col-md-4 col-lg-4 col-12 mb-4 position-relative'>
            <label className='select-label'>Portal</label>
            <Dropdown
              allowSearch
              border="1px solid #F8B552"
              color="#1C1C1C"
              fontWeight="500"
              width="100%"
              height="48px"
              fontSize="13px"
              selectedId={formValues?.party_id}
              onOptionClick={(item) => handleChange("party_id", item?.id)}
              options={orderReturnDropdown.data.portal_names.map(item => ({ id: item.id, label: item.name }))}
            />
            <span className='error-msg'>{formErrors.party_id}</span>
          </div>
          <div className='col-md-4 col-lg-4 col-12 mb-4'>
            <label className='select-label'>Courier</label>
            <Dropdown
              allowSearch
              border="1px solid #F8B552"
              color="#1C1C1C"
              fontWeight="500"
              width="100%"
              height="48px"
              fontSize="13px"
              selectedId={formValues?.carier_id}
              onOptionClick={(item) => handleChange("carier_id", item?.id)}
              options={orderReturnDropdown.data.courier_names.map(item => ({ id: item.id, label: item.name }))}
            />
            <span className='error-msg'>{formErrors.carier_id}</span>
          </div>
          <div className='col-md-4 col-lg-4 col-12 mb-4'>
            <label className='select-label'>Gate Pass No.</label>
            <Dropdown
              allowSearch
              border="1px solid #F8B552"
              color="#1C1C1C"
              fontWeight="500"
              width="100%"
              height="48px"
              fontSize="13px"
              selectedId={formValues?.gate_incoming_pass_id}
              onOptionClick={(item) => handleChange("gate_incoming_pass_id", item?.id)}
              options={orderReturnDropdown.data.gate_entry_numbers.map(item => ({ id: item.id, label: item.name }))}
            />
            <span className='error-msg'>{formErrors.gate_incoming_pass_id}</span>
          </div>
          <div className='col-md-4 col-lg-4 col-12 mb-4'>
            <label className='select-label'>Forward Order Id</label>
            <input
              placeholder='Forward Order Id'
              className={`input-custom-primary ${formValues.return_type === '2' ? 'disabled-input' : ''}`}
              type='text'
              // onKeyDown={handleKeyPress}
              onBlur={(e) => {
                callOnBlurOrder(); // Call the API function on blur
              }}
              onChange={(e) => handleChange("forward_order_no", e.target.value)}
              value={formValues.forward_order_no}
              disabled={formValues.return_type === '2'}
            />
            {invalidInputMsg && <div className="error-message">{invalidInputMsg}</div>}
            {formValues.return_type !== '2' && <div className="error-message">{formErrors.forward_order_no}</div>}
          </div>
          <div className='col-md-4 col-lg-4 col-12 mb-4'>
            <label className='select-label'>Forward Tracking Id</label>
            <input
              placeholder='Forward Tracking Id'
              className={`input-custom-primary ${formValues.return_type === '2' ? 'disabled-input' : ''}`}
              type='text'
              // onKeyDown={handleKeyPress}
              onBlur={(e) => {
                callOnBlurTrack(); // Call the API function on blur
              }}
              disabled={formValues.return_type === '2'}
              onChange={(e) => handleChange("forward_tracking_no", e.target.value)}
              value={formValues.forward_tracking_no}
            />
            {invalidInputMsg && <div className="error-message">{invalidInputMsg}</div>}
            {formValues.return_type !== '2' && <div className="error-message">{formErrors.forward_order_no}</div>}
          </div>

          <div className='col-md-4 col-lg-4 col-12 mb-4'>
            <label className='select-label'>Backward Order Id</label>
            <input
              placeholder='Backward order id'
              className={`input-custom-primary ${formValues.return_type === '1' ? 'disabled-input' : ''}`}
              type='text'
              onChange={(e) => handleChange("backward_order_no", e.target.value)}
              value={formValues.backward_order_no}
              required={isBackwardInputRequired()} // Apply required attribute based on validation condition
            />
            {isBackwardInputRequired() && !formValues.backward_order_no && (
              <span className="error-message">Backward Order Id is required for Customer Return</span>
            )}
          </div>
          <div className='col-md-4 col-lg-4 col-12 mb-4'>
            <label className='select-label'>Backward Tracking Id</label>
            <input
              placeholder='Backward Tracking Id'
              className={`input-custom-primary ${formValues.return_type === '1' ? 'disabled-input' : ''}`}
              type='text'
              onChange={(e) => handleChange("backward_tracking_no", e.target.value)}
              value={formValues.backward_tracking_no}
              required={isBackwardInputRequired()}
            />
            {isBackwardInputRequired() && !formValues.backward_tracking_no && (
              <span className="error-message">Backward Tracking Id is required for Customer Return</span>
            )}
          </div>
          <div className='col-md-4 col-lg-4 col-12 mb-4'>
            <label className='select-label'>Backward Other ID 01</label>
            <input
              placeholder='Backward Other ID 01'
              className={`input-custom-primary ${formValues.return_type === '1' ? 'disabled-input' : ''}`}
              type='text'
              onChange={(e) => handleChange("backward_other_id_1", e.target.value)}
              value={formValues.backward_other_id_1}
            />
          </div>
          <div className='col-md-4 col-lg-4 col-12 mb-4'>
            <label className='select-label'>Backward Other ID 02</label>
            <input
              placeholder='Backward Other ID 02'
              className={`input-custom-primary ${formValues.return_type === '1' ? 'disabled-input' : ''}`}
              type='text'
              onChange={(e) => handleChange("backward_other_id_2", e.target.value)}
              value={formValues.backward_other_id_2}
            />
          </div>
        </div>
        <div>
          <CustomVideoRecorder
            onStartRecording={handleStartRecording}
            videoValue={videoValue}

          />
          <span className='error-msg'>{formErrors.video_file}</span>
        </div>
      </div>
      {showSecondBox && (
        <div className="primary_bg second-box mt-2">
          <div className="row primary_input_section">
            <div className='col-md-4 col-lg-4 col-12 mb-4'>
              <label className='select-label'>If any problem with Parcel?</label>
              <Dropdown
                allowSearch
                color="#1C1C1C"
                fontWeight="500"
                width="100%"
                height="48px"
                fontSize="13px"
                border="1px solid #F8B552"
                selectedId={formValues?.is_problem}
                onOptionClick={(item) => handleChange("is_problem", item?.id)}
                options={parcelOptions}
              />
              <span className='error-msg'>{formErrors.is_problem}</span>
            </div>
            {formValues?.is_problem == '1' && (
              <div className='col-md-4 col-lg-4 col-12 mb-4'>
                <label className='select-label'>Select Reason</label>
                <Dropdown
                  allowSearch
                  defaultText="Select Reason"
                  color="#1C1C1C"
                  fontWeight="500"
                  width="100%"
                  height="48px"
                  fontSize="13px"
                  border="1px solid #F8B552"
                  selectedId={formValues?.claim_reason_id}
                  onOptionClick={(item) => handleChange("claim_reason_id", item?.id)}
                  options={orderReturnDropdown.data.claim_reasons.map(item => ({ id: item.id, label: item.name }))}
                />
              </div>
            )}

            <div className='col-md-4 col-lg-4 col-12 mb-4'>
              <label className='select-label'>Other Items Found</label>
              <Dropdown
                allowSearch
                defaultText="Other Items Found"
                color="#1C1C1C"
                fontWeight="500"
                width="100%"
                height="48px"
                fontSize="13px"
                border="1px solid #F8B552"
                selectedId={formValues?.is_any_other_item_found}
                onOptionClick={(item) => handleChange("is_any_other_item_found", item?.id)}
                options={otherItemFoundOptions}
              />
            </div>

          </div>
        </div>
      )}
      {formValues?.is_any_other_item_found == 'Yes' && (
        <>
        <div className="primary_bg mt-2">
           <div className="row primary_input_section">
                    {itemsValues.items.map((itemId, index) => (
                        <React.Fragment key={index}>
                            <div className='col-md-4 col-lg-4 col-12 mb-4'>
                                <label className='select-label'>Items</label>
                                <Dropdown
                                    allowSearch
                                    color="#1C1C1C"
                                    fontWeight="500"
                                    width="100%"
                                    height="48px"
                                    fontSize="13px"
                                    border="1px solid #F8B552"
                                    selectedId={itemId} // Use current item ID
                                    onOptionClick={(item) => handleItemChange(index, "items", item?.id)}
                                    options={orderItemRead.data.map(item => ({ id: item.id, label: item.name }))}
                                />
                            </div>
                            <div className='col-md-4 col-lg-4 col-12 mb-4'>
                                <label className='select-label'>Qty.</label>
                                <input
                                    placeholder='Enter Qty.'
                                    className='input-custom-primary'
                                    type='text'
                                    onChange={(e) => handleItemChange(index, "qty", e.target.value)}
                                    value={itemsValues.qty[index]}
                                />
                            </div>
                            <div className='col-md-4 col-lg-4 col-12 mb-4'></div>
                        </React.Fragment>
                    ))}
                    
             
             <div className='buttons_scan'>
                 <button className='blu_bt' onClick={()=> handleAddMore()}>Add More</button>
                 <button className='red_bt' onClick={() => setAddItemsModal(true)}>Create Item</button>
             </div>
         </div>
                </div>
                {/* <AddMoreItem /> */}
        </>
       
      )}
      {/* //table-data */}
      <div className='position-relative'>
        <div className="primary_bg mt-2">
          <div className="row primary_input_section">
            <div className='col-md-4 col-lg-4 col-12 mb-4'>
              <label className='select-label'>Scan Sub Location Barcode</label>
              <Dropdown
                allowSearch
                defaultText="Select Reason"
                color="#1C1C1C"
                fontWeight="500"
                width="100%"
                height="48px"
                fontSize="13px"
                border="1px solid #F8B552"
                selectedId={formValues?.sub_location_id}
                onOptionClick={(item) => handleChange("sub_location_id", item?.id)}
                options={orderReturnDropdown.data.return_open_sub_location_list.map(item => ({ id: item.id, label: item.name }))}
                // options={Object.entries(orderReturnDropdown.data.return_open_sub_location_list).map(([id, label]) => ({ id, label }))}
              />
              <span className='error-msg'>{formErrors.sub_location_id}</span>
            </div>
            <div className='col-md-4 col-lg-4 col-12 mb-4'>
              <label className='select-label'>Scan Serial Code</label>
              <input
                placeholder=''
                className='input-custom-primary'
                type='text'
                onKeyDown={handleKeyPressEnter}
                onChange={(e) => handleChange("serial_code", e.target.value)}
                value={formValues.serial_code}
              />
            </div>

          </div>
          <div className='buttons_scan_left'>
            <button className='black_bt' onClick={() => nextSubmit()}>Next</button>
          </div>
        </div>
        <div className='white_box'>
          <div className="position-relative">
            <div className='table-bg'>
              <Table
                gridTemplateColumnsHeader="10% 10% 15% 15% 15% 15% 20%"
                gridTemplateColumnsBody="10% 10% 15% 15% 15% 15% 20%"
                columns={TemplateTableData}
                data={tableData}
                Loader={true}
              />

            </div>
          </div>
        </div>
        {/* {
           orderDetailsBySerialCode.isLoading && <div className="loader_bg"><Loader /></div>
        } */}
      </div>
      {/* <DispositionTable onDispositionDataChange={handleDispositionDataChange} /> */}
      
      <div className='buttons_scan'>
        {/* <button className='black_bt' type='submit' onClick={() => handleSubmit()}>Submit</button> */}
        <input
          className='black_bt'
          type="button"
          value={loading ? 'Loading...' : 'Submit'}
          onClick={() => handleSubmit()}
          disabled={loading} />
      </div>
      <DefaultModal
                show={addItemsModal}
                onHide={() => setAddItemsModal(false)}
                className="return-modal"
                style={{ paddingRight: "0px" }}
                centered={false}
                showHeader={false}
                size=""
                showFooter={false}
            >
                <div className="modal-heading">
                    <h4>Add Items</h4>
                    <div className="close-icon">
                        <img src="/Images/closeBlack.svg" alt="Close" onClick={() => setAddItemsModal(false)} />
                    </div>
                </div>

                <CreateAddItem 
                 mClose={setAddItemsModal}
                
                />
            </DefaultModal>
    </div>
  )
}

export default SecondaryScanAdd
