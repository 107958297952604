import React from 'react'
import { useNavigate } from 'react-router-dom';
import SummaryTab from './component/SummaryTab';

const BoxSummaryDetails = () => {
    const navigate = useNavigate();
    const handleGoBack = () => {
        // Navigate back to the previous item
        navigate(-1);
      };
      
  return (
    <div>
         <div className='heading_select'>
         <div className="d-flex align-items-baseline" onClick={handleGoBack}>
                  <img style={{ cursor: "pointer" }} src="/Images/arrow-left.svg" />
                  <h1>Box Summary details</h1>
              </div>
                   {/* <div className="primary_but">
                    <button><img src="/Images/exportFile.svg"/>Export File</button>
                   </div> */}
                   
                </div>
                <SummaryTab/>
    </div>
  )
}

export default BoxSummaryDetails
