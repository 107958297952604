import React, { useEffect, useState } from 'react';
import Table from '../../../component/Table/Table';
import { useDispatch, useSelector } from 'react-redux';
import { getorderReturnDetails } from '../../../store/user/actions';
import Loader from '../../../component/Loader';

const ViewDetailsModal = ({itemId}) => {
  const [current, setCurrent] = useState(1);
  const [tableData, setTableData] = useState([]);
  const [tableDataSerialCodes, setTableDataSerialCodes] = useState([]);
  const [totalData, setTotalData] = useState(0);
  const orderReturnDetails = useSelector(state => state.orderReturnDetails);
  const dispatch = useDispatch();

  useEffect(() => {
      dispatch(getorderReturnDetails({ order_return_id: itemId }));
  }, [itemId]);

console.log(orderReturnDetails);
    useEffect(() => {
        setTotalData(orderReturnDetails.data.total)
        const FakeTableData = orderReturnDetails && orderReturnDetails?.data?.order_return_items?.map((item, i) => {
            return [
                  
                      <h6>{item.id}</h6>,
                      
                          <h6>{item.item}</h6>,
                      
                      
                          <h6>{item.qty}</h6>,
                
                      
                          <h6>{item.uom}</h6>,
                    
                
            ]
        })
        setTableData(FakeTableData);
    }, [orderReturnDetails])

        useEffect(() => {
            setTotalData(orderReturnDetails.data.total)
            const FakeTableData = orderReturnDetails && orderReturnDetails?.data?.order_return_serial_codes.map((serialCode, i) => {
                return [
                    
                        <h6>{i + 1}</h6>,
                        <div>
                            <h6>{serialCode.serial_code}</h6>
                        </div>,
                        <div>
                            <h6>{serialCode.found}</h6>
                        </div>,
                        <>
                            <h6>{serialCode.disposition}</h6>
                        </>
                
    ]
    })
    setTableDataSerialCodes(FakeTableData);
    }, [orderReturnDetails])

  const TemplateTableData = [
      { heading: "Sr.No." },
      { heading: "Item" },
      { heading: "Qty" },
      { heading: "UOM" },
  ];
  const TemplateTableDataSerialCodes = [
    { heading: "Sr.No." },
    { heading: "Serial Code" },
    { heading: "Found" },
    { heading: "Disposition" },
];
  return (
      <div>
          <div className="position-relative">
              {orderReturnDetails.isLoading && <div className="loader_bg"><Loader /></div>}
              <div className='table-bg mt-4'>
                  <Table
                      gridTemplateColumnsHeader="25% 25% 25% 25%"
                      gridTemplateColumnsBody="25% 25% 25% 25%"
                      columns={TemplateTableData}
                      data={tableData}
                      Loader={true}
                  />
                  <Table
                       gridTemplateColumnsHeader="25% 25% 25% 25%"
                       gridTemplateColumnsBody="25% 25% 25% 25%"
                        columns={TemplateTableDataSerialCodes}
                        data={tableDataSerialCodes}
                        Loader={true}
                    />
                  <div className='video_se'>
                      {/* <video src={orderReturnDetails.data?.video_url} /> */}
                  
                  <video controls>
                    <source src={orderReturnDetails.data.video_url} type="video/mp4" />
                   
                </video>
                </div>
              </div>
          </div>
      </div>
  );
}
export default ViewDetailsModal;
