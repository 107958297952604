import React, { useEffect, useState } from 'react'
import { getclaimEmailTemplateDropdown, getclaimEmailTemplateEdit, getclaimEmailTemplateUpdate } from '../../../store/user/actions';
import Dropdown from '../../../component/Dropdown/DropDownControlled';
import { useDispatch, useSelector } from 'react-redux';

const EditMail = ({ itemId,mClose, onRefreshTable }) => {
    const initialValues = {
        claim_reason_id: "",
        party_id: "",
        email_address: "",
        subject: "",
        body:""
      };
    
      const [formValues, setFormValue] = useState(initialValues);
      const claimEmailTemplateDropdown = useSelector(state => state.claimEmailTemplateDropdown)
      const claimEmailTemplateEdit = useSelector(state => state.claimEmailTemplateEdit)
      const claimEmailTemplateUpdate = useSelector(state => state.claimEmailTemplateUpdate)
      const dispatch = useDispatch()
      const handleChange = (name, value) => {
        let data = { ...formValues };
        data[name] = value;
        setFormValue(data);
      };
        //edit-api
  // const handleEdit = (id) => {
  //   console.log(itemId);
  //   dispatch(getclaimEmailTemplateEdit({id}));
   
  //   // onHide();
  // }
    // Fetch and pre-fill form data on component mount
    useEffect(() => {
      dispatch(getclaimEmailTemplateEdit({ id: itemId })).then(() => {
        setFormValue(claimEmailTemplateEdit.data); // Set form values with fetched data
      });
    }, [dispatch, itemId]);
  
    // Handle form submission (update email template)
    const handleEdit = () => {
      const updatedData = {
        ...formValues,
        email_template_id: itemId, // Include the email template ID for the update API
      };
  
      dispatch(getclaimEmailTemplateUpdate(updatedData)).then(()=>{
        onRefreshTable();
      })
    };
  useEffect(() => {
    if (claimEmailTemplateUpdate.isLoading) {
      mClose(false);
    }
   
  }, [claimEmailTemplateUpdate]);
  useEffect(() => {
    dispatch(getclaimEmailTemplateDropdown({}))
  }, [])
  return (
    <div>
         <div>
        <div className='primary_input_section'>
          <div className='row'>
            <div className='col-md-4 col-lg-4 col-12 mb-4'>
              <label className='select-label'>Select Portal</label>
              <Dropdown
                allowSearch
                border="1px solid #F8B552"
                color="#1C1C1C"
                fontWeight="700"
                width="100%"
                height="48px"
                selectedId={formValues?.party_id}
                onOptionClick={(item) => handleChange("party_id", item?.id)}
                options={claimEmailTemplateDropdown.data.parties.map(item => ({ id: item.id, label: item.name }))}
              />
            </div>
            <div className='col-md-4 col-lg-4 col-12 mb-4'>
              <label className='select-label'>Claim Type</label>
              <Dropdown
                allowSearch
                border="1px solid #F8B552"
                color="#1C1C1C"
                fontWeight="700"
                width="100%"
                height="48px"
                selectedId={formValues?.claim_reason_id}
                onOptionClick={(item) => handleChange("claim_reason_id", item?.id)}
                options={claimEmailTemplateDropdown.data.claim_reason.map(item => ({ id: item.id, label: item.name }))}
              />
            </div>
            <div className='col-md-4 col-lg-4 col-12 mb-4'>
              <label className='select-label'>mail from</label>
              <input
                placeholder=''
                className='input-custom-primary'
                type='text'
                onChange={(e) => handleChange("email_address", e.target.value)}
                value={formValues.email_address}
              />
            </div>

          </div>
          <div className='col-md-12 col-lg-12 col-12 mb-4'>
            <label className='select-label'>Mail Subject</label>
            <textarea
              className='input-custom-primary'
              type='text'
              onChange={(e) => handleChange("subject", e.target.value)}
              value={formValues.subject}
            />
          </div>
          <div className='col-md-12 col-lg-12 col-12 mb-4'>
            <label className='select-label'>Mail Body</label>
            <textarea
              className='input-custom-primary'
              type='text'
              onChange={(e) => handleChange("body", e.target.value)}
              value={formValues.body}
            />
          </div>
          <div className='col-lg-4 col-md-4 mx-auto'>

            <div className='package_butn sub_margin'>
              <button className='suffix_bag' onClick={() => handleEdit((itemId))}>Update</button>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default EditMail
