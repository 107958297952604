

import React, { useState, useEffect } from "react";

import Table from '../../../component/Table/Table'

import { Pagination } from 'antd';
import { Link, useParams } from "react-router-dom";
import ReturnOptionModal from "./ReturnOptionModal";
import DefaultModal from '../../../component/DefaultModal/DefaultModal'
import { useDispatch, useSelector } from "react-redux";
import { getlooseReturnBagsExport, getreturnContainerExport, getreturnContainerRead, getreturnedContainerUpdate } from "../../../store/user/actions";
import Loader from "../../../component/Loader";
import { resetBagDataState, resetreturnContainerExportState } from "../../../store/user/userSlice";


const PrimaryBoxTable = () => {

    //   const [currentRequest, setCurrentRequest] = useState(0);
    const [formValues, setFormValue] = useState([]);
    const [current, setCurrent] = useState(1);
    const [tableData, setTableData] = useState([]);
    const [totalData, setTotalData] = useState(0);
    const [limitData, setLimitData] = useState(10);
    const [showBagClose, setShowBagClose] = useState(false);
    const [selectedItemId, setSelectedItemId] = useState(null);
    const [show, setShow] = useState(false);
    const returnContainerExport = useSelector(state => state.returnContainerExport)
    const returnContainerRead = useSelector(state => state.returnContainerRead)
    const dispatch = useDispatch()
    const params = useParams()
    useEffect(() => {
        let data = { ...formValues };
        data["page"] = current
        data["limit"] = 10
        dispatch(getreturnContainerRead(data))
    }, [])
    // console.log(returnContainerRead);
    const handlePageChange = (page, pageSize) => {
        setCurrent(page);
        setLimitData(pageSize)
        dispatch(getreturnContainerRead({ page, limit: pageSize,filter: "all" }))
    };

    useEffect(() => {
        setTotalData(returnContainerRead.data.total)
        let sno = (current - 1) * 10 + 1;
        const FakeTableData = returnContainerRead && returnContainerRead?.data?.data.map((item, i) => {

            // const editButton = item.rtn_status === "0" ? (
            //     console.log("Edit Button Rendering: Item ID:", item.id, "RTN Status:", item.rtn_status),
            //     <Link to={`/box-form-edit/${item.returned_container_id}/${item.no_of_box_received}`} className="edit_green">Edit</Link>
            // ) : null;
            const editButton = item.rtn_status === 0 ? (
                <Link to={`/box-form-edit/${item.returned_container_id}/${item.no_of_box_received}`} className="edit_green">Edit</Link>
            ) : null;
            return [
                <>
                    <h6>{sno + i}</h6>
                </>,
                <div className="samplePic">
                    <h6>{item.rtn_no}</h6>
                </div>,
                <>

                    <h6>{item.courier_name}</h6>
                </>,
                <>
                    <h6>{item.portal_name}</h6>
                </>,
                <>
                    <h6>{item.guard_name}</h6>
                </>,
                <>

                    <h6>{item.no_of_box_received}</h6>
                </>,
                <>
                    <h6>{item.no_of_packages_expected}</h6>
                </>,
                <>
                    <h6>{item.no_of_packages_received}</h6>
                </>,
                <>
                    <h6>{item.opening_due_date}</h6>
                </>,
                <>
                    <h6>{item.breached_date}</h6>
                </>,
                <div className="edit_but">
                    {editButton}
                    {/* {item.rtn_status !== 1 && <Link className="pod_close" 
                    onClick={item.rtn_status === "1" ? null : () => { setSelectedItemId(item.id); 
                    if(item.rtn_status === "1"){setShowBagClose(true);}
                     }}>POD Close</Link>} */}
                    {/* <Link className="pod_close" 
                    onClick={item.rtn_status === "1" ? null : () => { setSelectedItemId(item.id); 
                    {setShowBagClose(true);}
                     }}>POD Close</Link> */}
                    <Link className="pod_close" onClick={() => handlePODClose(item)}>RTN Close</Link>
                </div>,

            ]
        })
        setTableData(FakeTableData);
    }, [returnContainerRead])

    const handlePODClose = (item) => {
        if (item.rtn_status !== 1) {
            // Open modal for confirmation
            setShowBagClose(true);
            // Store the selected item ID or other necessary data
            setSelectedItemId(item.returned_container_id);
        }
    };

    const handlePODCloseConfirm = () => {
        // Make API call to close the POD using the selected item ID
        dispatch(getreturnedContainerUpdate({
            returned_container_id: selectedItemId,
            rtn_status: 1
        })).then(() => {
            // Fetch updated table data after closing the POD
            dispatch(getreturnContainerRead({}));
        });
        // Close the modal
        setShowBagClose(false);
    };

    const TemplateTableData = [
        { heading: "Sr.No." },
        { heading: "RTN No." },
        { heading: "Courier Name" },
        { heading: "Portal Name" },
        { heading: "Guard Name" },
        { heading: "No. of Box Received" },
        { heading: "No. of Packages Expected" },
        { heading: "No. of Packages Received" },
        { heading: "Opening due Date" },
        { heading: "Reached Date" },
        { heading: "Action" },
    ];

    const downloadUrl = (page) => {
        setCurrent(page);
        // setLimitData(pageSize)
        dispatch(getreturnContainerExport({ page: current, limit: limitData, filter: 'all' }))
    }
    useEffect (()=>{
        if(returnContainerExport.isSuccess){
            window.open(returnContainerExport.data.download_url)
            dispatch(resetreturnContainerExportState())
        }
        },[returnContainerExport])

    return (
        <div>
            <div className='heading_select'>
                <h1></h1>
                <div className="primary_but">
                    <button onClick={() => downloadUrl()} rel="noopener noreferrer" ><img src="/Images/exportFile.svg" />Export File</button>
                </div>
            </div>

            <div className="position-relative">
                {
                    returnContainerRead.isLoading && <div className="loader_bg"><Loader /></div>
                }
                <div className='table-bg'>
                    <Table
                        gridTemplateColumnsHeader="5% 10% 10% 8% 10% 10% 7% 10% 10% 10% 10%"
                        gridTemplateColumnsBody="5% 10% 10% 8% 10% 10% 7% 10% 10% 10% 10% "
                        columns={TemplateTableData}
                        data={tableData}
                        Loader={true}
                    />
                    <Pagination
                        current={current}
                        onChange={handlePageChange}
                        total={returnContainerRead.data.pagination ? returnContainerRead.data.pagination.total : 0}
                        pageSize={limitData}
                        showSizeChanger
                    />
                </div>

            </div>
            <DefaultModal
                show={showBagClose}
                onHide={() => setShowBagClose(false)}
                className="add-modal"
                style={{ paddingRight: "0px" }}
                centered={false}
                showHeader={false}
                size=""
                showFooter={false}

            >
                <div className="modal-heading">
                    <h4></h4>
                    <div className="close-icon">
                        <img src="/Images/closeBlack.svg" onClick={() => setShowBagClose(false)} />
                    </div>
                </div>

                <div className='alert_modal'>
                    <img src="/Images/BOX.svg" />
                    <h2>Are You Sure to Close the RTN?</h2>
                    <div className='alert_bag'>
                        <Link onClick={handlePODCloseConfirm}>Yes</Link>

                    </div>

                </div>
            </DefaultModal>
            <DefaultModal
                show={show}
                onHide={() => setShow(false)}
                className="return-modal"
                style={{ paddingRight: "0px" }}
                centered={false}
                showHeader={false}
                size=""
                showFooter={false}
            >
                <div className="modal-heading">
                    <h4>Select
                        Return Option</h4>
                    <div className="close-icon">
                        <img src="/Images/closeBlack.svg" onClick={() => setShow(false)} />
                    </div>
                </div>
                <ReturnOptionModal />

            </DefaultModal>
        </div>
    )
}

export default PrimaryBoxTable


