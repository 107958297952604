import React, { useState, useEffect } from "react";
import SideBarLinks from "../constants/SideBarLinksAdmin.json";
import { Link, NavLink, useLocation } from "react-router-dom";
import Icon from "./Icon/Icon";

const LeftSideBar = () => {
  const location = useLocation();
  const [linkRefs, setLinkRefs] = useState(SideBarLinks);

  const active = (i) => {
    let newLinkRefs = linkRefs.map((link) => {
      link.active = false;
      return link;
    });
    linkRefs[i].active = true;
    setLinkRefs(newLinkRefs);
  };

  const [submenuVisibility, setSubmenuVisibility] = useState({});

  const toggleSubmenu = (i) => {
    setSubmenuVisibility((prevVisibility) => ({
      ...prevVisibility,
      [i]: !prevVisibility[i],
    }));
  };

  useEffect(() => {
    const link = location?.pathname;
    const index = SideBarLinks.findIndex((item) => item.link === link);
    if (location?.pathname === `/`) {
      active(0);
    }
    if (index >= 0) {
      active(index);
    }
  }, [location]);

  const handleSubmenuClick = (i) => {
    active(i); // Set the active class for the submenu
    toggleSubmenu(i); // Toggle the visibility of the submenu
  };

  return (
    <div className="sidebar_bg">
      <div className="sidbaar-scroll">
        <div className="logo-im-sidebbar">
          <Link to="/">
            {" "}
            <img src="/Images/sidebaar-logo.svg" />
          </Link>
        </div>
        <div className="left-side-scroll">
          {SideBarLinks &&
            SideBarLinks.map((item, i) => (
              <div key={i} className="li-content">
                {item.submenus ? (
                  // Render dropdown with submenus
                  <>
                    <NavLink
                      to={item.link}
                      onClick={() => active(i)}
                      className={item.active ? "activeClass" : null}
                    >
                      <Icon
                        iconName={item.icon}
                        width="30px"
                        height="30px"
                        color={item.active ? "#fff" : "#fff"}
                      />
                      <h5>{item.name}</h5>
                     <div className="drop_angle_up" onClick={() => handleSubmenuClick(i)}> 
                     <i className="fa fa-angle-up" aria-hidden="true"></i>
                     </div>
                    </NavLink>
                    {submenuVisibility[i] && (
                      <ul className="submenu-list">
                        {item.submenus.map((submenu, j) => (
                          <li key={j}>
                            <NavLink
                              to={submenu.link}
                              onClick={() => active(i)}
                              className={
                                submenu.active ? "activeClass" : null
                              }
                            >
                              <h5>{submenu.name}</h5> 
                            </NavLink>
                          </li>
                        ))}
                      </ul>
                    )}
                  </>
                ) : (
                  // Render regular menu item
                  <>
                    <NavLink
                      to={item.link}
                      onClick={() => active(i)}
                      className={item.active ? "activeClass" : null}
                    >
                      <Icon
                        iconName={item.icon}
                        width="30px"
                        height="30px"
                        color={item.active ? "#fff" : "#fff"}
                      />
                      <h5>{item.name}</h5>
                    </NavLink>
                  </>
                )}
                <div className="border_bottom"></div>
              </div>
            ))}
        </div>
      </div>
    </div>
  );
};

export default LeftSideBar;
