import React, { useState, useEffect } from 'react';
import DashboardCard from './component/DashboardCard'
import DashboardTable from './component/DashboardTable'
import { Helmet } from 'react-helmet';
import PortalWiseChart from './component/PortalWiseChart';
import ClaimAcceptedChart from './component/ClaimAcceptedChart';
import ClaimAcceptedAmountChart from './component/ClaimAcceptedAmountChart';
import ClaimAmountChart from './component/ClaimAmountChart';
import TotalLossAmounChart from './component/TotalLossAmounChart';
import NotRespondingByUs from './component/NotRespondingByUs';
import OntimePackageScanChart from './component/OntimePackageScanChart';
import ManPowerTable from './component/ManPowerTable';
import ReturnInventory from './component/ReturnInventory';
import ProcessNodeWiseChart from './component/ProcessNodeWiseChart';
import NotResPondingByPortal from './component/NotResPondingByPortal';

const Dashboard = () => {

  return (
    <div className='dashboard-bg'>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Dashboard | Courier Return Flow</title>
      </Helmet>

      <div>
        <DashboardCard />
        <PortalWiseChart />
        <ClaimAcceptedChart/>
        <ClaimAmountChart/>
       <ClaimAcceptedAmountChart/>
       <TotalLossAmounChart/>
       <NotRespondingByUs/>
       <NotResPondingByPortal/>
        <DashboardTable
        />
        <OntimePackageScanChart/>
        {/* <ManPowerTable /> */}
        <ReturnInventory/>
        {/* <ProcessNodeWiseChart/> */}
      </div>

    </div>
  )
}

export default Dashboard
