import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from 'axios';
import {getToken, getUser} from '../../util/Common'
import { showNotification } from "../../util/utility";



const handleApiRequest = async (url,method, data = {},notification = false,datamessage = false,pagination=false) => {
  try {
    // console.log(data);
    const token = await getToken()
    const headers = {
      'Authorization': 'Bearer ' + token,
    };
    var response = null
    if(method=='post'){
       response = await axios.post(url, data, { headers });
    }
    else {
      response = await axios.get(url, { params:data,headers });
    }
    if (response.data.status === 1) {
      notification && showNotification("success", response.data.message);
      if(pagination){
        return {data:response.data.data,pagination:response.data.pagination};
      }else{
        return response.data.data;
      }
      return response.data.data;
    } else {
      if(notification && datamessage){
         showNotification("error", response.data.data.error);
      }else if(notification){
         showNotification("error", response.data.message);
      }else {showNotification("error", response.data.data.error);}
      
      throw new Error(response.data.message);
    }
  } catch (error) {
    throw new Error(error.message);
  }
};
const handleLooseApiRequest = async (url,method, data = {},notification = false,datamessage = false) => {
  try {
    const token = await getToken()
    const headers = {
      'Authorization': 'Bearer ' + token,
    };
    var response = null
    if(method=='post'){
       response = await axios.post(url, data, { headers });
    }
    else {
      response = await axios.get(url, { params:data,headers });
    }
      notification && showNotification("success", response.data.message);
      return response.data;
     
  } catch (error) {
    throw new Error(error.message);
  }
};
export const getdashboardCard = createAsyncThunk('dashboardCard/getdashboardCard', async (formValues) => {
  return handleApiRequest(process.env.REACT_APP_API_URL + `/dashboard`, formValues);
});
//setting-api-sachin
export const getbaglimit = createAsyncThunk('baglimit/getbaglimit', async (formValues) => {
  return handleApiRequest(process.env.REACT_APP_API_URL + `/setting/get`,'post', formValues);
});
export const getsetbaglimit = createAsyncThunk('setbaglimit/getsetbaglimit', async (formValues) => {
  return handleApiRequest(process.env.REACT_APP_API_URL + `/setting/set`,'post', formValues,true);
});
export const getsuffixstrings = createAsyncThunk('suffixstrings/getsuffixstrings', async (formValues) => {
  return handleApiRequest(process.env.REACT_APP_API_URL + `/suffixstrings`, 'get',formValues);
});
export const getsuffixstringsStoreAll = createAsyncThunk('suffixstringsStoreAll/getsuffixstringsStoreAll', async (formValues) => {
  return handleApiRequest(process.env.REACT_APP_API_URL + `/suffixstrings/store-all`,'post', formValues,true);
});
export const gettatSettings = createAsyncThunk('tatSettings/gettatSettings', async (formValues) => {
  return handleApiRequest(process.env.REACT_APP_API_URL + `/tatsettings`,'get', formValues);
});
export const gettatSettingsStoreAll = createAsyncThunk('tatSettingsStoreAll/gettatSettingsStoreAll', async (formValues) => {
  return handleApiRequest(process.env.REACT_APP_API_URL + `/tatsettings/store-all`,'post', formValues,true);
});
export const getcourierReturnEmailSettingCreate = createAsyncThunk('courierReturnEmailSettingCreate/getcourierReturnEmailSettingCreate', async (formValues) => {
  return handleApiRequest(process.env.REACT_APP_API_URL + `/courier_return_email_setting_create`,'post', formValues,true);
});
export const getcourierReturnEmailSettingRead = createAsyncThunk('courierReturnEmailSettingRead/getcourierReturnEmailSettingRead', async (formValues) => {
  return handleApiRequest(process.env.REACT_APP_API_URL + `/courier_return_email_setting_read`,'post', formValues);
});
export const getcloseBagDropdown = createAsyncThunk('closeBagDropdown/getcloseBagDropdown', async (formValues) => {
  return handleApiRequest(process.env.REACT_APP_API_URL + `/pod-bag/options`,'post', formValues);
});
export const getcloseBagUpdate = createAsyncThunk('closeBagUpdate/getcloseBagUpdate', async (formValues) => {
  return handleApiRequest(process.env.REACT_APP_API_URL + `/loosereturnbags/update`,'post', formValues,true);
});
export const getturnAroundTimePortalWise = createAsyncThunk('turnAroundTimePortalWise/getturnAroundTimePortalWise', async (formValues) => {
  return handleApiRequest(process.env.REACT_APP_API_URL + `/dashboard/turn-around-time-portal-wise`,'post', formValues,false,false,true);
});
export const getturnAroundTimeWise = createAsyncThunk('turnAroundTimeWise/getturnAroundTimeWise', async (formValues) => {
  return handleApiRequest(process.env.REACT_APP_API_URL + `/dashboard/turn-around-time`,'post', formValues);
});
//end
export const getprimaryScanList = createAsyncThunk('primaryScanList/getprimaryScanList', async (formValues) => {
  return handleApiRequest(process.env.REACT_APP_API_URL + `/loosereturns/get`,'post', formValues,false, false, true);
});
export const getprimaryScanBagList = createAsyncThunk('primaryScanBagList/getprimaryScanBagList', async (formValues) => {
  return handleApiRequest(process.env.REACT_APP_API_URL + `/loosereturnbags/get`,'post', formValues,false, false, true);
});
export const getprimaryScanForm = createAsyncThunk('primaryScanForm/getprimaryScanForm', async (formValues) => {
  return handleApiRequest(process.env.REACT_APP_API_URL + `/loosereturns`,'post', formValues,true);
});
export const getcloseStatusUpdate = createAsyncThunk('closeStatusUpdate/getcloseStatusUpdate', async (formValues) => {
  return handleApiRequest(process.env.REACT_APP_API_URL + `/loosereturns/close-status/update`,'post', formValues,true);
});
export const getuploadCount = createAsyncThunk('uploadCount/getuploadCount', async (formValues) => {
  return handleApiRequest(process.env.REACT_APP_API_URL + `/loosereturns/update`,'post', formValues,true);
});
export const getlooseReturnBagsStoreAll = createAsyncThunk('looseReturnBagsStoreAll/getlooseReturnBagsStoreAll', async (formValues) => {
  return handleApiRequest(process.env.REACT_APP_API_URL + `/loosereturnbags/store-all`,'post', formValues,true);
});
export const getlooseReturnPackages = createAsyncThunk('looseReturnPackages/getlooseReturnPackages', async (formValues) => {
  return handleLooseApiRequest(process.env.REACT_APP_API_URL + `/loosereturnpackages`,'post', formValues);
});
export const getlooseReturnPackagesList = createAsyncThunk('looseReturnPackagesList/getlooseReturnPackagesList', async (formValues) => {
  return handleApiRequest(process.env.REACT_APP_API_URL + `/loosereturnpackages/get`,'post', formValues,false,false,true);
});
export const getlooseReturnExport = createAsyncThunk('looseReturnExport/getlooseReturnEport', async (formValues) => {
  return handleApiRequest(process.env.REACT_APP_API_URL + `/loosereturns/export`,'post', formValues);
});
export const getlooseReturnPackagesExport = createAsyncThunk('looseReturnPackagesExport/getlooseReturnPackagesEport', async (formValues) => {
  return handleApiRequest(process.env.REACT_APP_API_URL + `/loosereturnpackages/export`,'post', formValues);
});
export const getlooseReturnBagsExport = createAsyncThunk('looseReturnBagsExport/getlooseReturnBagsEport', async (formValues) => {
  return handleApiRequest(process.env.REACT_APP_API_URL + `/loosereturnbags/export`,'post', formValues);
});
export const getreturnContainerExport = createAsyncThunk('returnContainerExport/getreturnContainerExport', async (formValues) => {
  return handleApiRequest(process.env.REACT_APP_API_URL + `/returned_container_export`,'post', formValues);
});

//kamlesh ji primary-box-list
export const getreturnContainerRead = createAsyncThunk('returnContainerRead/getreturnContainerRead', async (formValues) => {
  return handleApiRequest(process.env.REACT_APP_API_URL + `/returned_container_read`,'post', formValues,false, false, true);
});
// dropdown-api
export const getcourierOption = createAsyncThunk('courierOption/getcourierOption', async (formValues) => {
  return handleApiRequest(process.env.REACT_APP_API_URL + `/courier/options`,'get', formValues);
});
export const getpartyOption = createAsyncThunk('partyOption/getpartyOption', async (formValues) => {
  return handleApiRequest(process.env.REACT_APP_API_URL + `/party/options`,'get', formValues);
});
export const getgatekeeperNameOption = createAsyncThunk('gatekeeperNameOption/getgatekeeperNameOption', async (formValues) => {
  return handleApiRequest(process.env.REACT_APP_API_URL + `/gatekeeper-name/options`,'get', formValues);
});
export const gettokenNumberOption = createAsyncThunk('tokenNumberOption/gettokenNumberOption', async (formValues) => {
  return handleApiRequest(process.env.REACT_APP_API_URL + `/token-number/options`,'get', formValues);
});
export const getgateEntryPassOption = createAsyncThunk('gateEntryPassOption/getgateEntryPassOption', async (formValues) => {
  return handleApiRequest(process.env.REACT_APP_API_URL + `/gate-incoming-pass/options`,'get', formValues);
});
export const getreturnedContainerBoxSummaryDropdown = createAsyncThunk('returnedContainerBoxSummaryDropdown/getreturnedContainerBoxSummaryDropdown', async (formValues) => {
  return handleApiRequest(process.env.REACT_APP_API_URL + `/returned_container_box_summary_dropdown`,'post', formValues);
});
// return-primary-screen-box-api
export const getreturnedContainerDropdowns = createAsyncThunk('returnedContainerDropdowns/getreturnedContainerDropdowns', async (formValues) => {
  return handleApiRequest(process.env.REACT_APP_API_URL + `/returned_container_dropdowns`,'post', formValues);
});
export const getreturnedContainerCreate = createAsyncThunk('returnedContainerCreate/getreturnedContainerCreate', async (formValues) => {
  return handleApiRequest(process.env.REACT_APP_API_URL + `/returned_container_create`,'post', formValues,true);
});
export const getreturnedContainerUpdate = createAsyncThunk('returnedContainerUpdate/getreturnedContainerUpdate', async (formValues) => {
  return handleApiRequest(process.env.REACT_APP_API_URL + `/returned_container_update`,'post', formValues,true);
});
export const getreturnedContainerBoxCreate = createAsyncThunk('returnedContainerBoxCreate/getreturnedContainerBoxCreate', async (formValues) => {
  return handleApiRequest(process.env.REACT_APP_API_URL + `/returned_container_box_create`,'post', formValues,true);
});
export const getreturnedContainerBoxRead = createAsyncThunk('returnedContainerBoxRead/getreturnedContainerBoxRead', async (formValues) => {
  return handleApiRequest(process.env.REACT_APP_API_URL + `/returned_container_box_read`,'post', formValues);
});
export const getreturnedContainerBoxUpdate = createAsyncThunk('returnedContainerBoxUpdate/getreturnedContainerBoxUpdate', async (formValues) => {
  return handleApiRequest(process.env.REACT_APP_API_URL + `/returned_container_box_update`,'post', formValues,true);
});
export const getreturnedContainerBoxDeletePicturesDocuments = createAsyncThunk('returnedContainerBoxDeletePicturesDocuments/getreturnedContainerBoxDeletePicturesDocuments', async (formValues) => {
  return handleApiRequest(process.env.REACT_APP_API_URL + `/returned_container_box_delete_pictures_or_documents`,'post', formValues,true);
});
// repecking-api
export const getrepackingDropdowns = createAsyncThunk('repackingDropdowns/getrepackingDropdowns', async (formValues) => {
  return handleApiRequest(process.env.REACT_APP_API_URL + `/repacking_dropdowns`,'post', formValues);
});
export const getrepackingRead = createAsyncThunk('repackingRead/getrepackingRead', async (formValues) => {
  return handleApiRequest(process.env.REACT_APP_API_URL + `/repacking_read`,'post', formValues,false,false,true);
});
export const getrepackingCreate = createAsyncThunk('repackingCreate/getrepackingCreate', async (formValues) => {
  return handleApiRequest(process.env.REACT_APP_API_URL + `/repacking_create`,'post', formValues,true);
});
export const getrepackingExport = createAsyncThunk('repackingExport/getrepackingExport', async (formValues) => {
  return handleApiRequest(process.env.REACT_APP_API_URL + `/repacking_export`,'post', formValues);
});
// mrp-creation
export const getmrpCreationDropdowns = createAsyncThunk('mrpCreationDropdowns/getmrpCreationDropdowns', async (formValues) => {
  return handleApiRequest(process.env.REACT_APP_API_URL + `/mrp_creation_dropdowns`,'post', formValues);
});
export const getmrpCreationUpdateLog = createAsyncThunk('mrpCreationUpdateLog/getmrpCreationUpdateLog', async (formValues) => {
  return handleApiRequest(process.env.REACT_APP_API_URL + `/mrp_creation_update_log`,'post', formValues,true);
});
export const getmrpCreationCreate = createAsyncThunk('mrpCreationCreate/getmrpCreationCreate', async (formValues) => {
  return handleApiRequest(process.env.REACT_APP_API_URL + `/mrp_creation_create`,'post', formValues);
});
export const getmrpCreationCreateBatchBundle = createAsyncThunk('mrpCreationCreateBatchBundle/getmrpCreationCreateBatchBundle', async (formValues) => {
  return handleApiRequest(process.env.REACT_APP_API_URL + `/mrp_creation_create_batch_bundle`,'post', formValues,true);
});
//kamlesh ji Secondary-screen
export const getorderReturnRead = createAsyncThunk('orderReturnRead/getorderReturnRead', async (formValues) => {
  return handleApiRequest(process.env.REACT_APP_API_URL + `/order_return_read`,'post', formValues,false, false, true);
});

export const getorderReturnDropdown = createAsyncThunk('orderReturnDropdown/getorderReturnDropdown', async (formValues) => {
  return handleApiRequest(process.env.REACT_APP_API_URL + `/order_return_dropdowns`,'post', formValues,true);
});
export const getorderReturnDetails = createAsyncThunk('orderReturnDetails/getorderReturnDetails', async (formValues) => {
  return handleApiRequest(process.env.REACT_APP_API_URL + `/order_return_detail`,'post', formValues);
});
export const getorderReturnCreate = createAsyncThunk('orderReturnCreate/getorderReturnCreate', async (formValues) => {
  return handleApiRequest(process.env.REACT_APP_API_URL + `/order_return_loose_create`,'post', formValues,true);
});
export const getorderReturnBoxCreate = createAsyncThunk('orderReturnBoxCreate/getorderReturnBoxCreate', async (formValues) => {
  return handleApiRequest(process.env.REACT_APP_API_URL + `/order_return_box_create`,'post', formValues,true);
});
export const getorderReturnExport = createAsyncThunk('orderReturnExport/getorderReturnExport', async (formValues) => {
  return handleApiRequest(process.env.REACT_APP_API_URL + `/order_return_export`,'post', formValues);
});
export const getorderItemRead = createAsyncThunk('orderItemRead/getorderItemRead', async (formValues) => {
  return handleApiRequest(process.env.REACT_APP_API_URL + `/item_read`,'post', formValues);
});
export const getorderItemCreate = createAsyncThunk('orderItemCreate/getorderItemCreate', async (formValues) => {
  return handleApiRequest(process.env.REACT_APP_API_URL + `/item_create`,'post', formValues,true);
});
export const getmeasurementUnitRead = createAsyncThunk('measurementUnitRead/getmeasurementUnitRead', async (formValues) => {
  return handleApiRequest(process.env.REACT_APP_API_URL + `/measurement_unit_read`,'post', formValues);
});
export const getserialCodesTrackingNo = createAsyncThunk('serialCodesTrackingNo/getserialCodesTrackingNo', async (formValues) => {
  return handleApiRequest(process.env.REACT_APP_API_URL + `/get_serial_codes_by_tracking_no`,'post', formValues,true);
});

// export const getajaxGetOrderDetailBySerialCode = createAsyncThunk('ajaxGetOrderDetailBySerialCode/getajaxGetOrderDetailBySerialCode', async (code) => {
//   return handleApiRequest(process.env.REACT_APP_CACK_URL + `/InventoryPickLists/ajaxGetOrderDetailBySerialCode/`+code,'get');
// });
export const getserialCodebyOrderNo = createAsyncThunk('serialCodebyOrderNo/getserialCodebyOrderNo', async (formValues) => {
  return handleApiRequest(process.env.REACT_APP_API_URL + `/get_serial_codes_by_order_no`,'post',formValues,true);
});

// export const getajaxGetSerialCodesByOrderNo = createAsyncThunk('ajaxGetSerialCodesByOrderNo/getajaxGetSerialCodesByOrderNo', async (code) => {
//   return handleApiRequest(process.env.REACT_APP_CACK_URL + `/InventoryPickLists/ajaxGetSerialCodesByOrderNo/`+code,'get');
// });
export const getorderDetailsBySerialCode = createAsyncThunk('orderDetailsBySerialCode/getorderDetailsBySerialCode', async (formValues) => {
  return handleApiRequest(process.env.REACT_APP_API_URL + `/get_order_detail_by_serial_code`,'post',formValues,true);
});
//Returned Container Box Summary
export const getreturnContainerBoxSummary = createAsyncThunk('returnContainerBoxSummary/getreturnContainerBoxSummary', async (formValues) => {
  return handleApiRequest(process.env.REACT_APP_API_URL + `/returned_container_box_summary`,'post', formValues,false,false,true);
});
export const getontimeSecondaryPacage = createAsyncThunk('ontimeSecondaryPacage/getontimeSecondaryPacage', async (formValues) => {
  return handleApiRequest(process.env.REACT_APP_API_URL + `/dashboard/ontime-secondary-package-scan`,'post', formValues);
});
export const getreturnInventory = createAsyncThunk('returnInventory/getreturnInventory', async (formValues) => {
  return handleApiRequest(process.env.REACT_APP_API_URL + `/dashboard/return-inventory`,'post', formValues);
});
export const getreturnInventoryProcessWise = createAsyncThunk('returnInventoryProcessWise/getreturnInventoryProcessWise', async (formValues) => {
  return handleApiRequest(process.env.REACT_APP_API_URL + `/dashboard/return-inventory-process-wise`,'post', formValues);
});
export const getreturnedContainerBoxSummaryPictures = createAsyncThunk('returnedContainerBoxSummaryPictures/getreturnedContainerBoxSummaryPictures', async (formValues) => {
  return handleApiRequest(process.env.REACT_APP_API_URL + `/returned_container_box_summary_pictures_and_documents`,'post', formValues);
});
export const getreturnedContainerBoxSummaryExport = createAsyncThunk('returnedContainerBoxSummaryExport/getreturnedContainerBoxSummaryExport', async (formValues) => {
  return handleApiRequest(process.env.REACT_APP_API_URL + `/returned_container_box_summary_export`,'post', formValues);
});
export const getreturnedContainerBoxSummaryBbscDetail = createAsyncThunk('returnedContainerBoxSummaryBbscDetail/getreturnedContainerBoxSummaryBbscDetail', async (formValues) => {
  return handleApiRequest(process.env.REACT_APP_API_URL + `/returned_container_box_summary_bbsc_detail`,'post', formValues,false,false,true);
});
export const getreturnedContainerBoxSummaryBbscDetailExport = createAsyncThunk('returnedContainerBoxSummaryBbscDetailExport/getreturnedContainerBoxSummaryBbscDetailExport', async (formValues) => {
  return handleApiRequest(process.env.REACT_APP_API_URL + `/returned_container_box_summary_bbsc_detail_export`,'post', formValues);
});
export const getreturnedContainerBoxSummaryItemDetail = createAsyncThunk('returnedContainerBoxSummaryItemDetail/getreturnedContainerBoxSummaryItemDetail', async (formValues) => {
  return handleApiRequest(process.env.REACT_APP_API_URL + `/returned_container_box_summary_item_detail`,'post', formValues,false,false,true);
});
export const getreturnedContainerBoxSummaryItemDetailExport = createAsyncThunk('returnedContainerBoxSummaryItemDetailExport/getreturnedContainerBoxSummaryItemDetailExport', async (formValues) => {
  return handleApiRequest(process.env.REACT_APP_API_URL + `/returned_container_box_summary_item_detail_export`,'post', formValues);
});

//Forward and Backward Import kamlesh ji
export const getclaimReasonCreate = createAsyncThunk('claimReasonCreate/getclaimReasonCreate', async (formValues) => {
  return handleApiRequest(process.env.REACT_APP_API_URL + `/claim_reason_create`,'post', formValues,true);
});
//claim-resion
export const getclaimReasonRead = createAsyncThunk('claimReasonRead/getclaimReasonRead', async (formValues) => {
  return handleApiRequest(process.env.REACT_APP_API_URL + `/claim_reason_read`,'post', formValues,false,false,true);
});
export const getclaimReasonDelete = createAsyncThunk('claimCreate/getclaimCreate', async (formValues) => {
  return handleApiRequest(process.env.REACT_APP_API_URL + `/claim_reason_delete`,'post', formValues,true);
});

//Claim Sachin ji

export const getclaimListing = createAsyncThunk('claimListing/getclaimListing', async (formValues) => {
  return handleApiRequest(process.env.REACT_APP_API_URL + `/claim/get`,'post', formValues,false,false,true);
});
export const getcounterMetrics = createAsyncThunk('counterMetrics/getcounterMetrics', async (formValues) => {
  return handleApiRequest(process.env.REACT_APP_API_URL + `/claim/counter-metrics`,'post', formValues);
});
export const getclaimExport = createAsyncThunk('claimExport/getclaimExport', async (formValues) => {
  return handleApiRequest(process.env.REACT_APP_API_URL + `/claim/export`,'post', formValues);
});
export const getsendClaimMessage = createAsyncThunk('sendClaimMessage/getsendClaimMessage', async (formValues) => {
  return handleApiRequest(process.env.REACT_APP_API_URL + `/claim/send-message`,'post', formValues,true);
});
export const getclaimChatMessage = createAsyncThunk('claimChatMessage/getclaimChatMessage', async (formValues) => {
  return handleApiRequest(process.env.REACT_APP_API_URL + `/claim/get-chat-message`,'post', formValues);
});
export const getclaimCloseTicket = createAsyncThunk('claimCloseTicket/getclaimCloseTicket', async (formValues) => {
  return handleApiRequest(process.env.REACT_APP_API_URL + `/claim/close-ticket`,'post', formValues, true);
});
export const getclaimRemark = createAsyncThunk('claimRemark/getclaimRemark', async (formValues) => {
  return handleApiRequest(process.env.REACT_APP_API_URL + `/claim/get-remark`,'post', formValues,false,false,true);
});
export const getsetClaimRemark = createAsyncThunk('setClaimRemark/getsetClaimRemark', async (formValues) => {
  return handleApiRequest(process.env.REACT_APP_API_URL + `/claim/set-remark`,'post', formValues,true);
});
export const getclaimMedia = createAsyncThunk('claimMedia/getclaimMedia', async (formValues) => {
  return handleApiRequest(process.env.REACT_APP_API_URL + `/claim/get-media`,'post', formValues);
});
export const getclaimStatusChange = createAsyncThunk('claimStatusChange/getclaimStatusChange', async (formValues) => {
  return handleApiRequest(process.env.REACT_APP_API_URL + `/claim/change-status`,'post', formValues,true);
});
export const getticketCloseDropdown = createAsyncThunk('ticketCloseDropdown/getticketCloseDropdown', async (formValues) => {
  return handleApiRequest(process.env.REACT_APP_API_URL + `/ticket-close-reason/options`,'get', formValues);
});
export const getticketOpenDropdown = createAsyncThunk('ticketOpenDropdown/getticketOpenDropdown', async (formValues) => {
  return handleApiRequest(process.env.REACT_APP_API_URL + `/ticket-open-reason/options`,'get', formValues);
});
export const getclaimStatusDropdown = createAsyncThunk('claimStatusDropdown/getclaimStatusDropdown', async (formValues) => {
  return handleApiRequest(process.env.REACT_APP_API_URL + `/claim-status/options`,'get', formValues);
});
//kamlesh ji Forward and Backward Import
export const getforwardBackward = createAsyncThunk('forwardBackward/getforwardBackward', async (formValues) => {
  return handleApiRequest(process.env.REACT_APP_API_URL + `/forward_backward_number_import`,'post', formValues,true);
});
//sachin ji Dashboard api
export const getpodCounter = createAsyncThunk('podCounter/getpodCounter', async (formValues) => {
  return handleApiRequest(process.env.REACT_APP_API_URL + `/dashboard/pod-counter`,'post', formValues);
});
export const getsecondaryCounterMatric = createAsyncThunk('secondaryCounterMatric/getsecondaryCounterMatric', async (formValues) => {
  return handleApiRequest(process.env.REACT_APP_API_URL + `/dashboard/secondary-counter`,'post', formValues);
});
export const getclaimCounterMatric = createAsyncThunk('claimCounterMatric/getclaimCounterMatric', async (formValues) => {
  return handleApiRequest(process.env.REACT_APP_API_URL + `/dashboard/claim-counter`,'post', formValues);
});
export const getgraphPortalWise = createAsyncThunk('graphPortalWise/getgraphPortalWise', async (formValues) => {
  return handleApiRequest(process.env.REACT_APP_API_URL + `/dashboard/graph-portal-wise`,'post', formValues);
});
export const getgraphClaimAccept = createAsyncThunk('graphClaimAccept/getgraphClaimAccept', async (formValues) => {
  return handleApiRequest(process.env.REACT_APP_API_URL + `/dashboard/graph-claim-accept`,'post', formValues);
});
export const getgraphPortalwiseAmount = createAsyncThunk('graphPortalwiseAmount/getgraphPortalwiseAmount', async (formValues) => {
  return handleApiRequest(process.env.REACT_APP_API_URL + `/dashboard/graph-portal-wise-amount`,'post', formValues);
});
export const getgraphClaimAcceptAmount = createAsyncThunk('graphClaimAcceptAmount/getgraphClaimAcceptAmount', async (formValues) => {
  return handleApiRequest(process.env.REACT_APP_API_URL + `/dashboard/graph-claim-accept-amount`,'post', formValues);
});
export const getgraphLostAmountScanDelay = createAsyncThunk('graphLostAmountScanDelay/getgraphLostAmountScanDelay', async (formValues) => {
  return handleApiRequest(process.env.REACT_APP_API_URL + `/dashboard/graph-lost-amount-scan-delay`,'post', formValues);
});
export const getnotRespondingByUs = createAsyncThunk('notRespondingByUs/getnotRespondingByUs', async (formValues) => {
  return handleApiRequest(process.env.REACT_APP_API_URL + `/dashboard/not-responding-by-us`,'post', formValues);
});
export const getnotRespondingByPortal = createAsyncThunk('notRespondingByPortal/getnotRespondingByPortal', async (formValues) => {
  return handleApiRequest(process.env.REACT_APP_API_URL + `/dashboard/not-responding-by-portal`,'post', formValues);
});
//Web Dashboard Box Returned Container
export const getboxDashboardWebReturnCounts = createAsyncThunk('boxDashboardWebReturnCounts/getboxDashboardWebReturnCounts', async (formValues) => {
  return handleApiRequest(process.env.REACT_APP_API_URL + `/box_dashboard_web_return_counts`,'post', formValues);
});
export const getboxDashboardWebReturnInventory = createAsyncThunk('boxDashboardWebReturnInventory/getboxDashboardWebReturnInventory', async (formValues) => {
  return handleApiRequest(process.env.REACT_APP_API_URL + `/box_dashboard_web_return_inventory`,'post', formValues);
});
export const getboxDashboardWebReturnInventoryProcessWise = createAsyncThunk('boxDashboardWebReturnInventoryProcessWise/getboxDashboardWebReturnInventoryProcessWise', async (formValues) => {
  return handleApiRequest(process.env.REACT_APP_API_URL + `/box_dashboard_web_return_inventory_process_wise`,'post', formValues);
});
//claim email template 
export const getclaimEmailTemplateDropdown = createAsyncThunk('claimEmailTemplateDropdown/getclaimEmailTemplateDropdown', async (formValues) => {
  return handleApiRequest(process.env.REACT_APP_API_URL + `/claim_email_template_dropdowns`,'post', formValues);
});
export const getclaimEmailTemplateCreate = createAsyncThunk('claimEmailTemplateCreate/getclaimEmailTemplateCreate', async (formValues) => {
  return handleApiRequest(process.env.REACT_APP_API_URL + `/claim_email_template_create`,'post', formValues,true);
});
export const getclaimEmailTemplateRead = createAsyncThunk('claimEmailTemplateRead/getclaimEmailTemplateRead', async (formValues) => {
  return handleApiRequest(process.env.REACT_APP_API_URL + `/claim_email_template_read`,'post', formValues,false,false,true);
});
export const getclaimEmailTemplateEdit = createAsyncThunk('claimEmailTemplateEdit/getclaimEmailTemplateEdit', async (formValues) => {
  return handleApiRequest(process.env.REACT_APP_API_URL + `/claim_email_template_edit`,'post', formValues);
});
export const getclaimEmailTemplateUpdate = createAsyncThunk('claimEmailTemplateUpdate/getclaimEmailTemplateUpdate', async (formValues) => {
  return handleApiRequest(process.env.REACT_APP_API_URL + `/claim_email_template_update`,'post', formValues);
});
export const getclaimCreate = createAsyncThunk('claimCreate/getclaimCreate', async (formValues) => {
  return handleApiRequest(process.env.REACT_APP_API_URL + `/claim_create`,'post', formValues,true);
});

