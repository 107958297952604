import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import {
  getsuffixstrings,
  getsuffixstringsStoreAll,
} from "../../../store/user/actions";

const SuffixString = () => {
  const initialValues = {
    id: "",
    carier_id: "",
    party_id: "",
    name: "",
  };
  const [formValues, setFormValue] = useState(initialValues);
  const [inputFields, setInputFields] = useState([]);
  const [suffixInputValue, setsuffixInputValue] = useState(null);
  const [defaultValue, setDefaultValue] = useState();
  const [suffixValue, setSuffixValue] = useState(null);
  const [inputFieldValue, setInputFieldValue] = useState([]);
  const { id } = useParams();
  const suffixstrings = useSelector((state) => state.suffixstrings);
  const dispatch = useDispatch();

  useEffect(() => {
    setSuffixValue(suffixstrings.data);
  }, [suffixstrings]);

  useEffect(() => {
    dispatch(getsuffixstrings(id));
  }, []);

  useEffect(() => {
    var oldData = {};
    suffixValue?.Couriers?.map(async (courier, courierIndex) => {
      await courier.parties?.map(async (party, partyIndex) => {
        await party.suffix_strings?.map(async (suffix) => {
          oldData[`${courierIndex}-${partyIndex}-${suffix.id}`] = {
            id: suffix.id,
            carier_id: courier.id,
            party_id: party.id,
            name: suffix.name,
          };
          //  console.log( id,carier_id,party_id,index,name);
          // await setsuffixInputValue(oldData)
          return true;
        });
      });
    });
    setsuffixInputValue(oldData);
  }, [suffixValue]);

  const handleAddField = (courierIndex, partyIndex) => {
    var data = { ...inputFields };
    if (data[courierIndex] == undefined) {
      data[courierIndex] = {};
      data[courierIndex][partyIndex] = [
        { id: "", party_id: "", carier_id: "", name: "" },
      ];
    } else if (data[courierIndex][partyIndex] == undefined) {
      data[courierIndex][partyIndex] = [
        { id: "", party_id: "", carier_id: "", name: "" },
      ];
    } else {
      data[courierIndex][partyIndex].push({
        id: "",
        party_id: "",
        carier_id: "",
        name: "",
      });
    }
    setInputFields(data);
  };

  const getValue = (index) => {
    // console.log(index);
    if (suffixInputValue[index]) {
      return suffixInputValue[index].name;
    }
    return "";
  };

  const handleChangeNew = async (index, id, carier_id, party_id, name) => {
    // console.log(index);
    var oldData = { ...suffixInputValue };
    oldData[index] = {
      id,
      carier_id,
      party_id,
      name,
    };
    console.log(id, carier_id, party_id, index, name);
    await setsuffixInputValue(oldData);
    return true;
  };

  console.log(inputFields, suffixInputValue);

  const removeInput = (courierIndex, partyIndex, suffixId, datai) => {
    const extractINumber = (input) => {
      const match = input.match(/-i(\d+)$/);
      if (match) {
        return parseInt(match[1], 10);
      }
      return null;
    };
    var suffixIndex = extractINumber(datai) - 1;
    let newInputFields = JSON.parse(JSON.stringify(inputFields));

    // Remove the suffixObj from the copied state
    if (
      newInputFields[courierIndex] &&
      newInputFields[courierIndex][partyIndex] &&
      newInputFields[courierIndex][partyIndex][suffixIndex] !== undefined
    ) {
      // Remove the suffixObj from the copied state
      newInputFields[courierIndex][partyIndex].splice(suffixIndex, 1);

      // Remove the partyIndex if it becomes empty
      if (newInputFields[courierIndex][partyIndex].length === 0) {
        delete newInputFields[courierIndex][partyIndex];
      }

      // Remove the courierIndex if it becomes an empty object
      if (Object.keys(newInputFields[courierIndex]).length === 0) {
        delete newInputFields[courierIndex];
      }
    }
    // Update the state with the new array
    setInputFields(newInputFields);

    function removeKeyAndUpdateIndices(obj, keyToRemove) {
      let keyParts = keyToRemove.split("-");
      let prefix = keyParts.slice(0, -1).join("-");
      let indexToRemove = parseInt(keyParts.slice(-1)[0].slice(1));

      delete obj[keyToRemove];

      let newObj = {};

      Object.keys(obj).forEach((key) => {
        let parts = key.split("-");
        let currentPrefix = parts.slice(0, -1).join("-");
        let lastPart = parts[parts.length - 1];
        let isIndex = lastPart.startsWith("i");
        let currentIndex = isIndex ? parseInt(lastPart.slice(1)) : null;

        if (currentPrefix === prefix && isIndex) {
          // Decrease the index if it is greater than the removed index
          if (currentIndex > indexToRemove) {
            let newIndex = currentIndex - 1;
            let newKey = `${currentPrefix}-i${newIndex}`;
            newObj[newKey] = obj[key];
          } else {
            // Keep the key unchanged if index is less than or equal to removed index
            newObj[key] = obj[key];
          }
        } else {
          // Keep the key unchanged if it doesn't match the prefix or is not an indexed key
          newObj[key] = obj[key];
        }
      });

      return newObj;
    }

    let newSuffixInputValue = JSON.parse(JSON.stringify(suffixInputValue));
    if (
      newSuffixInputValue[datai] &&
      newSuffixInputValue[datai] !== undefined
    ) {
      newSuffixInputValue = removeKeyAndUpdateIndices(
        newSuffixInputValue,
        datai
      );
    }
    console.log(newSuffixInputValue);
    setsuffixInputValue(newSuffixInputValue);
  };

  const removeExisted = (courierIndex, partyIndex, suffixId) => {
    let newSuffixValue = { ...suffixValue };
    newSuffixValue.Couriers = newSuffixValue.Couriers.map((courier, cIndex) => {
      if (cIndex === courierIndex) {
        return {
          ...courier,
          parties: courier.parties.map((party, pIndex) => {
            if (pIndex === partyIndex) {
              return {
                ...party,
                suffix_strings: party.suffix_strings.filter(
                  (suffix) => suffix.id !== suffixId
                ),
              };
            }
            return party;
          }),
        };
      }
      return courier;
    });

    setSuffixValue(newSuffixValue);
  };

  const handleSubmit = () => {
    const userOption = Object.entries(suffixInputValue).map(
      ([i, item]) => item
    );
    dispatch(getsuffixstringsStoreAll({ data: userOption }));
  };
  var inputIndex = -1;

  return (
    <div className="suffix_title">
      {suffixValue?.Couriers?.map((courier, courierIndex) => (
        <div key={courier.id}>
          <h4>{courier.name}</h4>

          <div>{courier.data?.value}</div>
          <div className="row">
            {courier.parties?.map((party, partyIndex) => {
              inputIndex = 0;
              return (
                <div key={party.id} className="col-md-4 mt-2">
                  <div className="d-flex justify-content-between">
                    <label>{party.name}</label>
                    <div
                      onClick={() => handleAddField(courierIndex, partyIndex)}
                    >
                      <img src="/Images/addCircle.svg" />
                    </div>
                  </div>

                  <div>
                    {party.suffix_strings?.map((suffix, index) => {
                      // handleChangeNew(`${courierIndex}-${partyIndex}-${suffix.id}`,suffix.id,courier.id,party.id, getValue(`${courierIndex}-${partyIndex}-${suffix.id}`))
                      return (
                        <div
                          key={suffix.id}
                          className="d-flex align-items-center"
                        >
                          <input
                            // placeholder={suffix.name}
                            value={getValue(
                              `${courierIndex}-${partyIndex}-${suffix.id}`
                            )}
                            type={suffix.suffix_strings}
                            data-id={`${courierIndex}-${partyIndex}-${suffix.id}`}
                            className="input-custom-common"
                            onChange={(e) =>
                              handleChangeNew(
                                `${courierIndex}-${partyIndex}-${suffix.id}`,
                                suffix.id,
                                courier.id,
                                party.id,
                                e.target.value
                              )
                            }
                            // onLoad={(e) => handleChangeNew(`${courierIndex}-${partyIndex}-${suffix.id}`,suffix.id,courier.id,party.id, suffix.name)}
                          />
                          <div
                            onClick={() =>
                              removeExisted(courierIndex, partyIndex, suffix.id)
                            }
                          >
                            <img src="/Images/minus-cirlce.svg" />
                          </div>
                        </div>
                      );
                    })}
                    {inputFields[courierIndex] &&
                      inputFields[courierIndex][partyIndex] &&
                      inputFields[courierIndex][partyIndex].map((suffix) => {
                        inputIndex++;
                        const suffixdataInput = `${courierIndex}-${partyIndex}-i${inputIndex}`;
                        return (
                          <div
                            key={inputIndex}
                            className="d-flex align-items-center"
                          >
                            <input
                              //  placeholder={suffix.name}
                              value={getValue(suffixdataInput)}
                              type={suffix.suffix_strings}
                              data-id={suffixdataInput}
                              className="input-custom-common"
                              onChange={(e) =>
                                handleChangeNew(
                                  suffixdataInput,
                                  null,
                                  courier.id,
                                  party.id,
                                  e.target.value
                                )
                              }
                            />
                            <div
                              onClick={() =>
                                removeInput(
                                  courierIndex,
                                  partyIndex,
                                  suffix.id,
                                  suffixdataInput
                                )
                              }
                            >
                              <img src="/Images/minus-cirlce.svg" />
                            </div>
                          </div>
                        );
                      })}
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      ))}

      <div className="mx-auto text-center mt-4">
        <button className="common_but" type="submit" onClick={handleSubmit}>
          Submit
        </button>
      </div>
    </div>
  );
};

export default SuffixString;