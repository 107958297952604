import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getreturnInventory, getreturnInventoryProcessWise } from '../../../store/user/actions'



const ReturnInventory = () => {
    const [filter, setFilter] = useState("today");
    const returnInventory = useSelector(state => state.returnInventory.data)
    const returnInventoryProcessWise = useSelector(state => state.returnInventoryProcessWise)
    const dispatch = useDispatch()
    useEffect(() => {
        dispatch(getreturnInventory({filter:"all"}))
        dispatch(getreturnInventoryProcessWise({ "filter": "all",
        "start_date":"2024-03-26",
        "end_date":"2024-03-26"
    }))
        
    }, [])
    const optionsSelect = [

        { value: "today", label: "Today" },
        { value: "this_week", label: "This Week" },
        { value: "last_week", label: "Last Week" },
        { value: "this_month", label: "This Month" },
        { value: "last_month", label: "Last Month" },
        { value: "last_three_month", label: "Last 3 Month" },
        { value: "this_year", label: "This Year" },
        { value: "last_year", label: "Last Year" },
        { value: "all", label: "All" },
      ];
    const handleFilterChange = (e) => {
        setFilter(e.target.value);
    }
    console.log(returnInventoryProcessWise);
    return (
        <div>
           <div className='heading_select'>
                <h2>Return Inventory</h2>
                {/* <div className="custom-select">
                    <select className='selectArea' 
                    value={filter}
                    onChange={handleFilterChange}
                            // onChange={(e)=> persionValue(e.target.value)} 
                        >
                            {
                                optionsSelect && optionsSelect.map((item)=>(
                                <option value={item.value}>{item.lable}</option>
                                ))
                            } 
                            </select>
                </div> */}
                </div>
            <div className='row padding_left'>
                <div className='col-lg-3 col-md-3 col-12 card-common'>
                    <div className="pale-red">
                        {/* <h2>{dashboardCard.totalCollection}</h2> */}
                        <h2>{returnInventory.primary_scan_inventory}</h2>
                        <p>Primary scan inventory</p>
                    </div>
                </div>
                <div className='col-lg-3 col-md-3 col-12 card-common'>
                    <div className="alice-blue">
                        {/* <h2>{dashboardCard.totalCompletedCollection}</h2> */}
                        <h2>{returnInventory.secondory_scan_inventory}</h2>
                        <p>Secondary scan inventory</p>
                    </div>
                </div>
                <div className='col-lg-3 col-md-3 col-12 card-common'>
                    <div className="light-green">
                        <h2>{returnInventory.repacking_scan_inventory}</h2>
                        {/* <h2>{dashboardCard.totalPandingCollection}</h2> */}

                        <p>Repacking scan inventory</p>
                    </div>
                </div>
                <div className='col-lg-3 col-md-3 col-12 card-common'>
                   
                    <div className="light-orange">
                    <h2>{returnInventory.mrp_inventory}</h2>
                        {/* <h2>{dashboardCard.totalPartiallyPandingCollection}</h2> */}
                        <p>MRP inventory </p>
                    </div>
                </div>
                <div className='col-lg-3 col-md-3 col-12 card-common'>
                    <div className="light-pink">
                        {/* <h2>{dashboardCard.totalSampleRequestTime} Hours</h2> */}
                        <h2>{returnInventory.sorting_inventory}</h2>
                        <p>Sorting  inventory</p>
                    </div>
                </div>

            </div>
            <div className='white_box'>
                <div className='heading_select pt-4 pb-4'>
                    <h2>Process Wise</h2>
                </div>
                <table className="table table-bordered table-sm table_box">
                    <thead>
                        <tr>
                            <th>Portal Name</th>
                            <th>0 - 3 Days</th>
                            <th>3 - 7 Days</th>
                            <th>7 - 15 Days</th>
                            <th>15+ Days</th>
                        </tr>
                    </thead>
                    <tbody>
                        {returnInventoryProcessWise.data.map(item => (
                            <tr key={item.process}>
                                <td>{item.process}</td>
                                <td>{item.today_to_last_seventh_day}</td>
                                <td>{item.last_seventh_day_to_last_fifteenth_day}</td>
                                <td>{item.last_fifteenth_day_to_last_thirtieth_day}</td>
                                <td>{item.more_than_thirty_days}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        </div>
    )
}

export default ReturnInventory
