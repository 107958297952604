import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { getreturnedContainerBoxUpdate } from '../../store/user/actions';
import { useDispatch, useSelector } from 'react-redux';
import { resetreturnedContainerBoxUpdateState } from '../../store/user/userSlice';
import Loader from '../../component/Loader';

const PrimaryUploadPicture = () => {
  const initialValues = {
    actual_count: "",
    manual_count: "",
  };

  const navigate = useNavigate();
  const [formValues, setFormValues] = useState(initialValues);
  const [formErrors, setFormErrors] = useState({});
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [files, setFiles] = useState([]);
  const [videoFile, setVideoFile] = useState(null);
  const returnedContainerBoxUpdate = useSelector(state => state.returnedContainerBoxUpdate);
  const dispatch = useDispatch();
  const params = useParams()

  // const handleChange = (name, value) => {
  //   setFormValues(prevState => ({ ...prevState, [name]: value }));
  // };
  const handleChange = (name, value) => {
    // Ensure the value is not negative
    const numericValue = Math.max(parseInt(value), 0);
    setFormValues(prevState => ({ ...prevState, [name]: numericValue }));
};

  const handleGoBack = () => {
    navigate(-1);
  };

  const handleVideoFileChange = e => {
    const file = e.target.files[0];
    setVideoFile(file);
    console.log("Video file selected:", file);
  };

  const handleSubmit = (e, name, value) => {
    setError(null);
    setLoading(true);
    // console.log("Before validation check");
    let check = validate(formValues);
    // console.log("Validation result:", check);
    let data = { ...initialValues };
    data[name] = value;
    e.preventDefault();
    setFormErrors(check.errors);
    
    if (check.isValid) {
      setLoading(false);
    const formData = new FormData();
    formData.append('video_file', videoFile);
    formData.append('actual_count', formValues.actual_count);
    formData.append('manual_count', formValues.manual_count);
    formData.append("returned_container_id", params.returned_container_id);
    formData.append("returned_container_box_id", params.returned_container_box_id);
    // const storedFormData = JSON.parse(localStorage.getItem('formData'));
    dispatch(getreturnedContainerBoxUpdate(formData))
    }

  };
  useEffect(() => {
    if (returnedContainerBoxUpdate.isSuccess) {
      navigate(`/box-scan-image/${params.returned_container_id}`);
      dispatch(resetreturnedContainerBoxUpdateState())
    }
    console.log(returnedContainerBoxUpdate);
  }, [returnedContainerBoxUpdate]);
    // validation-start
    useEffect(() => {
      if (Object.keys(formErrors).length > 0) {
      }
    }, [formErrors]);
  
    const validate = (values) => {
      let isValid = true;
      const errors = {};
      if (!videoFile) {
        isValid = false;
        errors.video_file = "Video is required";
      }
      if (!values.actual_count) {
        isValid = false;
        errors.actual_count = "Actual Count required";
    }
      if (!values.manual_count) {
        isValid = false;
        errors.manual_count = "Received Packages required";
    }
      return {
        isValid,
        errors,
      };
    };
  return (
    <div>
      <div className='heading_select'>
        <div className="d-flex align-items-baseline" onClick={handleGoBack}>
          <img style={{ cursor: "pointer" }} src="/Images/arrow-left.svg" />
          <h1>Open Box and Count Pieces</h1>
        </div>
      </div>
      <div className='upload_box position-relative'>
        <div className='primary_input_section'>
          <p className='mb-0'><b style={{ color: "#FF0000" }}>Note*</b></p>
          <p>Start by recording the box opening video, making sure to count everything accurately. Only submit the video; don't submit without it.</p>
          <div className="file-preview-container position-relative video-upload-box">
            <input
              className="hidden-doc-input"
              type="file"
              onChange={handleVideoFileChange}
            />
            <div>
              <img src="/Images/cloud.svg" />
              <div>
                <h5>Upload Video</h5>
                <small>You can upload video files</small>
              </div>
            </div>
            <div className='d-flex'>
              {videoFile && (
                <div className="file-preview">
                  <label>{videoFile.name}</label>
                </div>
              )}
            </div>
          </div>
          <span className='error-msg'>{formErrors.files}</span>
          <div className='col-12 mb-4 mt-2 position-relative'>
            <label className='select-label'>Actual Counting</label>
            <input
              placeholder='Actual counting'
              className='input-custom-primary'
              type='number'
              min="0"
              onChange={(e) => handleChange("actual_count", e.target.value)}
              value={formValues.actual_count}
            />
             <span className='error-msg'>{formErrors.actual_count}</span>
          </div>
         
          <div className='col-12 mb-4 position-relative'>
            <label className='select-label'>Manual Counting</label>
            <input
              placeholder='Manual Counting'
              className='input-custom-primary'
              type='number'
              min="0"
              onChange={(e) => handleChange("manual_count", e.target.value)}
              value={formValues.manual_count}
            />
            <span className='error-msg'>{formErrors.manual_count}</span>
          </div>
          
        </div>
        {
         returnedContainerBoxUpdate.isLoading && <div className="loader_bg"><Loader /></div>
               }
        <div className='package_butn sub_margin'>
          <Link className='suffix_bag' onClick={handleSubmit}>Submit</Link>
        </div>
      </div>
    </div>
  )
}

export default PrimaryUploadPicture;
