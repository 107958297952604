import React from 'react'
import NumberOfBagTable from './component/NumberOfBagTable'

const NumberOfBags = () => {
  return (
    <div>
      <NumberOfBagTable/>
    </div>
  )
}

export default NumberOfBags
