import React, { useEffect, useState } from 'react'
import { getsetClaimRemark } from '../../../store/user/actions';
import { useDispatch, useSelector } from 'react-redux';
import { Link } from 'react-router-dom';

const ClaimRemark = ({claimId,mClose,onRefreshTable}) => {
    const initialValues = {
        remark: "",
    };
    const setClaimRemark = useSelector(state => state.setClaimRemark)
    const [formValues, setFormValue] = useState(initialValues)
    const dispatch = useDispatch()
    
   
    const handleChange = (name, value) => {
        let data = { ...formValues };
        data[name] = value;
        setFormValue(data);
      };
    const remarkUpdate = () => {
        // Dispatch an action to update the remarks
        dispatch(getsetClaimRemark({
            claim_id: claimId, // Include the claim ID in the payload
            remark: formValues.remark // Assuming newRemark is the state that holds the updated remark
        })).then(()=>{
            onRefreshTable();
        });
    };
    useEffect(() => {
        if (setClaimRemark.isLoading) {
          mClose(false);
        }
       
      }, [setClaimRemark]);
    return (
        <div>
            <div className='col-md-12 col-lg-12 col-12 mb-4 pt-4'>
                <label className='select-label'>Remarks</label>
                
                <textarea 
                value={formValues.remark} 
                onChange={e => handleChange('remark', e.target.value)} // Ensure correct parameters are passed
               />
            </div>
            <div className='package_butn sub_margin'>
                <Link to={`/history/${claimId}`} className='suffix_bag_history'>History</Link>
                <button className='suffix_bag' onClick={remarkUpdate}>SAVE</button>
            </div>
        </div>
    )
}

export default ClaimRemark
