import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { getcloseBagDropdown, getcloseBagUpdate } from '../../../store/user/actions';
import Dropdown from '../../../component/Dropdown/DropDownControlled';
import { Link } from 'react-router-dom';
import { resetcloseBagUpdateState } from '../../../store/user/userSlice';
import { showNotification } from '../../../util/utility';


const CloseBagModal = (props) => {
  const initialValues = {
    // loose_return_id:props.loose_return_id
    bag_number:""
  };
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [formErrors, setFormErrors] = useState({});
  const closeBagDropdown = useSelector(state => state.closeBagDropdown)
  const closeBagUpdate = useSelector(state => state.closeBagUpdate)
  const [formValues, setFormValue] = useState(initialValues);
  const dispatch = useDispatch()
  const handleChange = (name, value) => {
    let data = { ...formValues };
    data[name] = value;
    setFormValue(data);
  };
  // console.log(props);
  useEffect (()=>{
    dispatch(getcloseBagDropdown({loose_return_id:props.loose_return_id}))
    },[]);

    const handleUpdate = () => {
      setError(null);
      setLoading(true);
      let check = validate(formValues);
      setFormErrors(check.errors);
      if (check.isValid) {
        setLoading(false);
      if (formValues.bag_number) {
        // Construct the desired payload
        const payload = {
          id: formValues.bag_number, // Use the `id` prop from props
          is_close: 1, // Set is_close to 1 (assuming this indicates closing)
        };
    
        dispatch(getcloseBagUpdate(payload));
    
        if (!closeBagUpdate.isLoading) {
          props.mClose(false);
        } else {
          // Handle loading state as needed (optional)
        }
      }
    }else {
      setLoading(false);
      // showNotification("error", "Form is not valid");
    }
    };
    useEffect(()=>{
      if(closeBagUpdate.isSuccess){
        dispatch(resetcloseBagUpdateState())
      }
    },[closeBagUpdate])
  // console.log(closeBagDropdown);
    // validation-start
    useEffect(() => {
      if (Object.keys(formErrors).length > 0) {
      }
    }, [formErrors]);
  
    const validate = (values) => {
      let isValid = true;
      const errors = {};
      if (!values.bag_number) {
        isValid = false;
        errors.bag_number = "bag number is required";
      }
      return {
        isValid,
        errors,
      };
    };
  return (
    <div>
        <div className='alert_modal'>
                <img src="/Images/BOX.svg"/>
                <h2>Close Bag</h2>
                <p>Are you sure you want to cloose the bag?</p>
                <div className='col-md-12 col-lg-12 col-12 mb-4'>
            
              <Dropdown
                allowSearch
                defaultText="select bag"
                border="1px solid #F8B552"
                color="#1C1C1C"
                fontWeight="700"
                width="100%"
                height="48px"
                selectedId={formValues?.bag_number}
                onOptionClick={(item) => handleChange("bag_number", item?.id)}
                options={closeBagDropdown?.data?.map(item => ({ id: item?.id, label: item?.name }))}
              />
               <span className='error-msg'>{formErrors.bag_number}</span>
            </div>
                <div className='alert_bag'>
                   <Link onClick={() => handleUpdate()}>YES</Link>
                </div>
               </div>
    </div>
  )
}

export default CloseBagModal
