import React, { useEffect, useState } from 'react'
import Dropdown from '../../../component/Dropdown/DropDownControlled';
import { useDispatch, useSelector } from 'react-redux';
import { getcourierOption, getgateEntryPassOption, getpartyOption, getreturnedContainerBoxSummaryDropdown } from '../../../store/user/actions';
import { DatePicker } from 'antd';
import { Link } from 'react-router-dom';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';


dayjs.extend(customParseFormat);
var utc = require('dayjs/plugin/utc')
var timezone = require('dayjs/plugin/timezone')
dayjs.extend(utc)
dayjs.extend(timezone)

const BoxFilter = ({ filterValue }) => {
  const initialValues = {
    rtn_no:"",
    // pod_number: "",
    portal_name: "",
    gate_pass_no: "",
    carier_name: "",
    delayed_time: "",
    breached_date: "",
    opening_due_date:"",
    // rtn_no:"",
  };
  const dateStr = "2024-03-05T15:54:54.000Z";

  // Create a new Date object from the ISO date string
  const date = new Date(dateStr);
  
  // Extract day, month, and year
  const day = String(date.getUTCDate()).padStart(2, '0');
  const month = String(date.getUTCMonth() + 1).padStart(2, '0');
  const year = date.getUTCFullYear();
  
  // Format the date as dd/mm/yyyy
  const formattedDate = `${day}/${month}/${year}`;
    const [formValues, setFormValue] = useState(initialValues);
    const [changeDate, setChangeDate] = useState(null);
  const courierOption = useSelector(state => state.courierOption)
  const partyOption = useSelector(state => state.partyOption)
  const gateEntryPassOption = useSelector(state => state.gateEntryPassOption)
  const returnedContainerBoxSummaryDropdown = useSelector(state => state.returnedContainerBoxSummaryDropdown)
  const dispatch = useDispatch()

  useEffect(() => {
    filterValue(cleanedFormValues()); // Send only the selected filter values
  }, [formValues]);
  useEffect(() => {
    dispatch(getcourierOption({}))
    dispatch(getreturnedContainerBoxSummaryDropdown({}))
    dispatch(getgateEntryPassOption({}))
    dispatch(getpartyOption({}))
  }, [])
  // console.log(returnedContainerBoxSummaryDropdown);
  const handleChange = (name, value) => {
    let data = { ...formValues };
    data[name] = value;
    setFormValue(data);
  };
  const onChange = (date, dateString) => {
    setChangeDate(dateString); // Store the selected date string in the state
    handleChange("breached_date", dateString); // Update the breach_date field in the formValues state
    handleChange("opening_due_date", dateString); // Update the opening_due_date field in the formValues state
  }
  const cleanedFormValues = () => {
    const cleanedValues = {};
    for (const key in formValues) {
      if (formValues[key] !== "") {
        cleanedValues[key] = formValues[key];
      }
    }
    return cleanedValues;
  };
  return (
    <div className='bag_filter'>
      <div className="row">
        {/* <div className='col-md-3 col-lg-3 col-12 mb-4'>
          <input
            placeholder='Reverse Transaction'
            className='input-custom'
            type='text'
            onChange={(e) => handleChange("pod_number", e.target.value)}
            value={formValues.pod_number}
          />

        </div> */}
             <div className='col-md-3 col-lg-3 col-12 mb-4'>
             {/* <input
            placeholder='Reverse Transaction'
            className='input-custom'
            type='text'
            onChange={(e) => handleChange("rtn_no", e.target.value)}
            value={formValues.rtn_no}
          /> */}
          <Dropdown
            defaultText="Reverse Transaction"
            allowSearch
            // border="1px solid #F8B552"
            backgroundColor="white"
            boxShadow="0px 2px 6px #13124212"
            color="#1C1C1C"
            fontWeight="700"
            width="100%"
            height="48px"
            selectedId={formValues?.rtn_no}
                onOptionClick={(item) => handleChange("rtn_no", item?.id)}
                options={returnedContainerBoxSummaryDropdown.data.returned_container_numbers.map(item => ({ id: item.id, label: item.name }))}
          />
        </div>

        <div className='col-md-3 col-lg-3 col-12 mb-4'>
          <Dropdown
            defaultText="Select portal"
            allowSearch
            // border="1px solid #F8B552"
            backgroundColor="white"
            boxShadow="0px 2px 6px #13124212"
            color="#1C1C1C"
            fontWeight="700"
            width="100%"
            height="48px"
            selectedId={formValues?.portal_name}
            onOptionClick={(item) => handleChange("portal_name", item?.id)}
            options={partyOption.data}
          />
        </div>
        <div className='col-md-3 col-lg-3 col-12 mb-4'>
          <Dropdown
            allowSearch
            defaultText="Select Courier Name"
            // variant="outlinedGreen"
            // border="1px solid #F8B552"
            backgroundColor="white"
            boxShadow="0px 2px 6px #13124212"
            color="#1C1C1C"
            fontWeight="700"
            width="100%"
            height="48px"
            selectedId={formValues?.carier_name}
            onOptionClick={(item) => handleChange("carier_name", item?.id)}
            options={courierOption.data}
          />
        </div>
        <div className='col-md-3 col-lg-3 col-12 mb-4'>
          <Dropdown
            allowSearch
            defaultText="Gate Entry Number"
            // variant="outlinedGreen"
            // border="1px solid #F8B552"
            backgroundColor="white"
            boxShadow="0px 2px 6px #13124212"
            color="#1C1C1C"
            fontWeight="700"
            width="100%"
            height="48px"
            selectedId={formValues?.gate_pass_no}
            onOptionClick={(item) => handleChange("gate_pass_no", item?.id)}
            options={gateEntryPassOption.data}
          />
        </div>
        <div className='col-md-3 col-lg-3 col-12 mb-4'>
          {/* <label className='select-label'>Delay Duration</label> */}
          <DatePicker
            className='input-custom'
            placeholder="Breach Date"
            format="YYYY-MM-DD"
            onChange={onChange}
          />
        </div>



        <div className='col-md-3 col-lg-3 col-12 mb-4'>
          <input
            placeholder='Delay Time'
            className='input-custom'
            type='text'
            onChange={(e) => handleChange("delayed_time", e.target.value)}
            value={formValues.delayed_time}
          />
        </div>
        <div className='col-md-3 col-lg-3 col-12 mb-4'>
          {/* <label className='select-label'>Delay Duration</label> */}
          <DatePicker
            className='input-custom'
            placeholder="Actual Box opening Date"
            format="YYYY-MM-DD"
            onChange={onChange}
          />
        </div>
        <div className='col-md-3 col-lg-3 col-12 mt-2'>
        <div className="primary_but">
          <Link onClick={() => setFormValue(initialValues)} className="clear_filter"><img src="/Images/trash.svg" /> Clear Filter</Link>
        </div>
        </div>
      </div>
    </div>
  )
}

export default BoxFilter

