





import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import EmailFilter from "./component/EmailFilter";
import EmailTable from "./component/EmailTable";




const EmailTemplates = () => {


  return (
    <div>
      <div className='heading_select align-items-baseline'>
        <h1>Email Templates</h1>
        <div className="primary_but">
          <Link to="/add-email-template"><img src="/Images/addPlus.svg" />Add Email Template</Link>
        </div>

      </div>


      <EmailTable
      />


    </div>
  )
}

export default EmailTemplates

