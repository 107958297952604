import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { getbaglimit, getsetbaglimit } from '../../../store/user/actions';

const SetBagLimit = () => {
  const [formValues, setFormValue] = useState({
    name: 'bag_limit',
    value: localStorage.getItem('bagLimit') || '', // Get initial value from local storage
  });
  const [validationError, setValidationError] = useState('');
  const baglimit = useSelector(state => state.baglimit)
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getbaglimit({name:"bag_limit"}));
  }, []);

  useEffect(() => {
   if (baglimit.isSuccess){
    setFormValue({
      name: 'bag_limit',
      value: baglimit.data.Setting.value, // Get initial value from local storage
    })
   }
  }, [baglimit]);
  const handleChange = (name, value) => {
    const regex = /^[0-9]*$/; // Matches 0 or more digits
    if (!regex.test(value)) {
      setValidationError('Bag limit must be a positive integer');
      // Prevent the character from being displayed in the input field
      setFormValue((prevValues) => ({
        ...prevValues,
        [name]: prevValues.value.slice(0, -1), // Remove the last character
      }));
    } else {
      setValidationError(''); // Clear any existing error
      setFormValue((prevValues) => ({
        ...prevValues,
        [name]: value,
      }));
    }
  };

  const handleSubmit = () => {
    // Validate bag_limit
    if (formValues.value <= 0 || formValues.value.includes('.') || formValues.value === '.') {
      setValidationError('Bag limit must be a positive integer');
      return;
    }

    // Check if the value is a valid integer
    if (!Number.isInteger(Number(formValues.value))) {
      setValidationError('Bag limit must be a positive integer');
      return;
    }

    // If validation passes, dispatch action to set bag limit
    dispatch(getsetbaglimit(formValues));

    // Save the bag limit value to local storage
    localStorage.setItem('bagLimit', formValues.value);
    
    // Optionally, you can reset the form after successful submission
    setFormValue({
      name: 'bag_limit',
      value: formValues.value,
    });
  };

  return (
    <div>
      <div className='row'>
        <div className='col-md-3 col-lg-3 col-12 mb-4 mt-2'>
          <label className='select-label'>Enter Bag Limit</label>
          <input
            placeholder='Enter Bag Limit'
            type='number'
            className='input-custom-common'
            onChange={(e) => handleChange('value', e.target.value)}
            value={formValues.value}
            inputMode="numeric"
          />
          {validationError && (
            <p style={{ color: 'red', marginTop: '5px' }}>{validationError}</p>
          )}
        </div>
        <div>
          <button className='common_but' onClick={handleSubmit}>
            Submit
          </button>
        </div>
      </div>
    </div>
  );
};

export default SetBagLimit;
