
import React, { useState } from 'react'
import Box from '../../../component/Box';
import BatchBundle from './BatchBundle';
import OtherProduct from './OtherProduct';

const SummaryTab = () => {
  const [selectedSegment, setselectedSegment] = useState("Information");
//   const [showComponent, setShowComponent] = useState("initial");

  const setSegment = (segment) => {
    setselectedSegment(segment);
  };
  return (
    <div className='mt-4'>
       <Box display="flex" alignItems="center" cursor="pointer" justifyContent="left">
        <Box fontSize="22px" fontWeight={700}>{selectedSegment === "Information"}</Box>
        <ul className="d-flex tab_setting">
          <li
            className="information"
            style={{
              fontWeight: selectedSegment === "Information" ? "600" : "600",
              backgroundColor:
                selectedSegment === "Information" ? "#F8B552" : "#EBEEF2",
              color: selectedSegment === "Information" ? "#1C1C1C" : "#1C1C1C",
              marginRight:selectedSegment === "Information" ? "7px" : "7px",
              padding:selectedSegment === "Information" ? "7px" : "7px",
              borderRadius:
                selectedSegment === "Information"
                  ? "5px 5px 0px 0px"
                  : "5px 5px 0px 0px",
            }}
            onClick={() => setSegment("Information")}
          >
                 Batch Bundle{" "}
          </li>
          <li
            className="screen-details"
            style={{
              fontWeight: selectedSegment === "Screen details" ? "600" : "600",
              backgroundColor:
                selectedSegment === "Screen details" ? "#F8B552" : "#EBEEF2",
              color: selectedSegment === "Screen details" ? "#1C1C1C" : "#1C1C1C",
              borderRadius:
                selectedSegment === "Screen details"
                  ? "5px 5px 0px 0px"
                  : "5px 5px 0px 0px",
            }}
            onClick={() => setSegment("Screen details")}
          >
           
           Other Product{" "}
          </li>
         
       
        </ul>
      </Box>
      <Box className='tab_body'>
        {selectedSegment === "Information" ? (
            <BatchBundle 
            setSegment={setSegment}
            />
        ) : null}
        {selectedSegment === "Screen details" ? (
            <OtherProduct
            setSegment={setSegment}
            />
        ) : null}
        
    
      </Box>
    </div>
  )
}

export default SummaryTab


