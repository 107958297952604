


import React, { useState, useEffect } from "react";

import Table from '../../../component/Table/Table'

import { Pagination } from 'antd';
import { Link } from "react-router-dom";
import ReturnOptionModal from "./ReturnOptionModal";
import DefaultModal from '../../../component/DefaultModal/DefaultModal'
import { useDispatch, useSelector } from "react-redux";
import { getcloseStatusUpdate, getlooseReturnExport, getprimaryScanList } from "../../../store/user/actions";
import Loader from "../../../component/Loader";
import { resetReturnDataState } from "../../../store/user/userSlice";


const PrimaryTable = () => {

    //   const [currentRequest, setCurrentRequest] = useState(0);
    const [formValues, setFormValue] = useState({});
    const [current, setCurrent] = useState(1);
    const [tableData, setTableData] = useState([]);
    const [limitData, setLimitData] = useState(10);
    const [showBagClose, setShowBagClose] = useState(false);
    const [showFull, setShowFull] = useState(false); // Changed initial state to false
    const [selectedImage, setSelectedImage] = useState([]); // Added state to store the selected image URL
    const [isEditMode, setisEditMode] = useState(false)
    const [totalData, setTotalData] = useState(0);
    const looseReturnExport = useSelector(state => state.looseReturnExport)
    const [selectedItemId, setSelectedItemId] = useState(null);
    const primaryScanList = useSelector(state => state.primaryScanList)
    const dispatch = useDispatch()

    useEffect(() => {
        let data = { ...formValues };
        data["page"] = current
        data["limit"] = 10
        data["filter"] = "all"
        dispatch(getprimaryScanList(data))
    }, [])
    // console.log(primaryScanList);
    const handlePageChange = (page, limit) => {
        console.log(`Page change: ${page}, Limit: ${limit}`); // Debugging

        setCurrent(page);
        setLimitData(limit);
        dispatch(getprimaryScanList({ page, limit:limit, filter: "all" }));
      };

    useEffect(() => {
        setTotalData(primaryScanList.data.total)
        let sno = (current - 1) * 10 + 1;
        const FakeTableData = primaryScanList && primaryScanList?.data?.data?.data?.map((item, i) => {
            // console.log('is_close value for row', i, ':', item.is_close); // L
            const editButton = item.is_close === "0" ? (
                <Link to={`/package-scan-two/${item.id}/${item.total_packages}`} className="edit_green">Edit</Link>
            ) : null;
            // Render images
            //  const mainImage = item.images.length > 0 ? (
            //     <div className="image-container">
            //         <img src={item.images[0].media_url} alt={`Main Image`} />
            //         {/* Render additional image count as overlay */}
            //         {item.images.length > 1 && (
            //             <div className="additional-image-count-overlay">{`+${item.images.length - 1} more`}</div>
            //         )}
            //     </div>
            // ) : null;

            return [
                <>
                    <h6>{sno + i}</h6>
                </>,
                <div className="samplePic">
                    <h6>{item.pod_number}</h6>
                </div>,
                <div className="pod_pic">
                    {item.images.length > 0 && (
                        <img
                            src={item.images[0].media_url}
                            alt={`Main Image`}
                            onClick={() => { setSelectedImage(item.images); setShowFull(true); }}
                        />
                    )}
                </div>,
                <>

                    <h6>{item.courier_name}</h6>
                </>,
                <>
                    <h6>{item.handover_date}</h6>
                </>,
                <>
                    <h6>{item.guard_name}</h6>
                </>,
                <>
                    <h6>{item.gate_incoming_pass_id}</h6>
                </>,
                <div className="numberBags">
                    <h6><Link to={`/number-of-bags/${item.id}`}>{item.number_of_bags}</Link></h6>
                </div>,
                <>
                    <h6>{item.total_packages}</h6>
                </>,
                <>
                    <h6>{item.scan_packages}</h6>
                </>,
                <>
                    <h6>{item.short_packages}</h6>
                </>,
                <div className="edit_but">
                    {editButton}
                    {item.is_close !== 1 && <Link className="pod_close"
                        onClick={item.is_close === "1" ? null : () => { setSelectedItemId(item.id); setShowBagClose(true); }}>POD Close</Link>}

                </div>,

            ]
        })
        setTableData(FakeTableData);
    }, [primaryScanList])

    //   console.log(primaryScanList);
    const handlePODClose = () => {
        if (selectedItemId) {
            // Make API call to close POD using the selected item id
            dispatch(getcloseStatusUpdate({ id: selectedItemId, is_close: 1 })).then(() => {
                // Fetch updated table data after closing the POD
                dispatch(getprimaryScanList({ page: current, limit: limitData, filter: "all" }));
            });
            setShowBagClose(false);
        }
    };
    const TemplateTableData = [

        { heading: "Sr.No.", },
        { heading: "POD ID" },
        { heading: "POD Pictures" },
        { heading: "Courier Name" },
        { heading: "Handover Date" },
        { heading: "Guard Name" },
        { heading: "Gate Entry Number" },
        { heading: "Numbers of Bags" },
        { heading: "Total Number of Packages" },
        { heading: "Scanned Packages" },
        { heading: "Short Packages" },
        { heading: "Action" },

    ];
    const downloadUrl = () => {
    //   setCurrent(page);
    //   setLimitData(limit)
      dispatch(getlooseReturnExport({ page: current, limit:limitData, filter: 'all' }))
    }
    useEffect(() => {
        if (looseReturnExport.isSuccess) {
          window.open(looseReturnExport.data.download_url)
          dispatch(resetReturnDataState())
        }
      }, [looseReturnExport])
    //   const itemRender = (_, type, originalElement) => {
    //     if (type === 'prev') {
    //       return <a>Previous</a>;
    //     }
    //     if (type === 'next') {
    //       return <a>Next</a>;
    //     }
    //     return originalElement;
    //   };
    return (
        <div>
    <div className='heading_select'>
        <h1></h1>
        <div className="primary_but">
          <button onClick={() => downloadUrl()} rel="noopener noreferrer" ><img src="/Images/exportFile.svg" />Export File</button>
        </div>
      </div>

            <div className="position-relative">
                {
                    primaryScanList.isLoading && <div className="loader_bg"><Loader /></div>
                }
                <div className='table-bg'>
                    <Table
                        gridTemplateColumnsHeader="5% 7% 8% 10% 10% 10% 8% 7% 10% 7% 7% 10%"
                        gridTemplateColumnsBody="5% 7% 8% 10% 10% 10% 8% 7% 10% 7% 7% 10%"
                        columns={TemplateTableData}
                        data={tableData}
                        Loader={true}
                    />
                    <Pagination
                        current={current}
                        onChange={handlePageChange}
                        total={primaryScanList?.data?.data ? primaryScanList?.data?.data?.total : 0}
                        pageSize={limitData}
                        // itemRender={itemRender}
                        showSizeChanger
                    />
                </div>

            </div>
            <DefaultModal
                show={showBagClose}
                onHide={() => setShowBagClose(false)}
                className="add-modal"
                style={{ paddingRight: "0px" }}
                centered={false}
                showHeader={false}
                size=""
                showFooter={false}

            >
                <div className="modal-heading">
                    <h4></h4>
                    <div className="close-icon">
                        <img src="/Images/closeBlack.svg" onClick={() => setShowBagClose(false)} />
                    </div>
                </div>

                <div className='alert_modal'>
                    <img src="/Images/BOX.svg" />
                    <h2>Are You Sure to Close the POD?</h2>
                    <div className='alert_bag'>
                        <Link onClick={() => handlePODClose()}>Yes</Link>
                        {/* <Link onClick={() => setShowBagClose(false)}>Cancel</Link> */}
                    </div>

                </div>
            </DefaultModal>
            <DefaultModal
                show={showFull}
                onHide={() => setShowFull(false)}
                className="image-doc-modal image_big_modal"
                style={{ paddingRight: "0px" }}
                centered={false}
                showHeader={false}
                size=""
                showFooter={false}
            >
                <div className="modal-heading">
                    <h4></h4>
                    <div className="close-icon">
                        <img src="/Images/closeBlack.svg" onClick={() => setShowFull(false)} />
                    </div>
                </div>
                {/* Render the selected image in the modal */}
                <div className="row">
                    {selectedImage.map((image, index) => (
                        <div className="col-lg-6 image_gallery mt-2">
                            <img
                                key={index}
                                src={image.media_url}
                                alt={`Image ${index}`}
                                style={{ width: '100%', height: 'auto' }}
                            />
                        </div>
                    ))}
                </div>
            </DefaultModal>
        </div>


    )
}

export default PrimaryTable

