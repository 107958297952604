// import React, { useRef, useState, useEffect } from 'react';
// import { ReactMediaRecorder } from 'react-media-recorder';

// const VideoPreview = ({ stream }) => {
//   const videoRef = useRef(null);

//   useEffect(() => {
//     if (videoRef.current && stream) {
//       videoRef.current.srcObject = stream;
//     }
//   }, [stream]);

//   if (!stream) {
//     return null;
//   }

//   return <video ref={videoRef} autoPlay controls />;
// };

// export default function VideoRecorder({onStartRecording,videoValue}) {
//   const [recording, setRecording] = useState(false);
//   const [videos, setVideos] = useState();
//   const [mediaBlobUrl, setMediaBlobUrl] = useState(null); // Track media URL
//   const [isCameraAvailable, setIsCameraAvailable] = useState(true); // Track camera availability
//    const [initiatedFromCurrentPage, setInitiatedFromCurrentPage] = useState(false); // Track if recording initiated from this page

//   useEffect(() => {
//     // Check if mediaDevices and getUserMedia are supported
//     const isMediaDevicesSupported = navigator.mediaDevices && navigator.mediaDevices.getUserMedia;

//     // Check if getUserMedia is supported and if camera is available
//     if (isMediaDevicesSupported) {
//       navigator.mediaDevices.getUserMedia({ video: true })
//         .then(() => setIsCameraAvailable(true))
//         .catch(() => setIsCameraAvailable(false));
//     } else {
//       setIsCameraAvailable(false);
//     }
//   }, []);
//   useEffect(() => {
//     if (!initiatedFromCurrentPage) {
//       // Reset recording if initiated from another page
//       setRecording(false);
//     }
//   }, [initiatedFromCurrentPage]);
//   useEffect(() =>{
//     const old = videos;
//     // old.name =  "abc.mp4"
//     videoValue(old)
//   },[videos])

//   const handleStop = blobUrl => {
//     // Convert the blob URL to a File object
//     fetch(blobUrl)
//       .then(res => res.blob())
//       .then(blob => {
//         const file = new File([blob], 'recorded-video.webm', { type: 'video/webm' });
//        setVideos(file)
//       })
//       .catch(error => {
//         console.error('Error converting blob to file:', error);
//         // Handle error or set state accordingly
//       });
//   };

//   // console.log(mediaBlobUrl);
//   return (
//     <div>
//        {isCameraAvailable ? (
//         <div className='video_sec'>
//           <ReactMediaRecorder
//             video  
//             blobPropertyBag={{ type: 'video/webm' }}
//             askPermissionOnMount={true}
//             onStop={handleStop}
//             render={({ previewStream, startRecording, stopRecording, mediaBlobUrl,blob
//           }) => {
//               setMediaBlobUrl(mediaBlobUrl); // Update state for conditional rendering
//               // console.log(blob);
//               return (
//                 <div className='row'>
//                  <div className='col-md-4 col-lg-4'> 
//                  {isCameraAvailable ? (
//                     recording ? (
//                       <button className='red_bt' onClick={() => { stopRecording(); setRecording(false); }}>Stop Recording</button>
//                     ) : (
//                       <button className="black_bt" onClick={() => { startRecording(); setRecording(true); onStartRecording();setInitiatedFromCurrentPage(true);}}>Start Recording</button>
//                     )
//                   ) : (
//                     <p>Camera access is not available. Please check your camera settings.</p>
//                   )}
//                   </div>
//                   <div className='col-md-4 col-lg-4'>
//                   {recording && <VideoPreview stream={previewStream} />}
//                   {(recording === false && mediaBlobUrl !== null) && <video src={mediaBlobUrl} controls />}
//                   </div>
//                 </div>
//               );
//             }}
//           />
//         </div>
//       ) : (
//         <p>Camera access is not available. Please check your camera settings.</p>
//       )}
//     </div>
//   );
// }


import React, { useRef, useState, useEffect } from 'react';
import { ReactMediaRecorder } from 'react-media-recorder';

const VideoPreview = ({ stream }) => {
  const videoRef = useRef(null);

  useEffect(() => {
    if (videoRef.current && stream) {
      videoRef.current.srcObject = stream;
    }
  }, [stream]);

  if (!stream) {
    return null;
  }

  return <video ref={videoRef} autoPlay controls />;
};

export default function VideoRecorder({ onStartRecording, videoValue }) {
  const [recording, setRecording] = useState(false);
  const [videos, setVideos] = useState();
  const [mediaBlobUrl, setMediaBlobUrl] = useState(null); // Track media URL
  const [isCameraAvailable, setIsCameraAvailable] = useState(true); // Track camera availability
  const [initiatedFromCurrentPage, setInitiatedFromCurrentPage] = useState(false); // Track if recording initiated from this page

  useEffect(() => {
    // Check if mediaDevices and getUserMedia are supported
    const isMediaDevicesSupported = navigator.mediaDevices && navigator.mediaDevices.getUserMedia;

    if (!isMediaDevicesSupported) {
      setIsCameraAvailable(false);
    }
  }, []);

  useEffect(() => {
    if (!initiatedFromCurrentPage) {
      // Reset recording if initiated from another page
      setRecording(false);
      setMediaBlobUrl(null); // Reset recording state and mediaBlobUrl on unmount
    }
  }, [initiatedFromCurrentPage]);

  useEffect(() => {
    const old = videos;
    videoValue(old);
  }, [videos]);

  const handleStop = (blobUrl) => {
    // Convert the blob URL to a File object
    fetch(blobUrl)
      .then(res => res.blob())
      .then(blob => {
        const file = new File([blob], 'recorded-video.webm', { type: 'video/webm' });
        setVideos(file);
      })
      .catch(error => {
        console.error('Error converting blob to file:', error);
        // Handle error or set state accordingly
      });
  };

  useEffect(() => {
    return () => {
      setRecording(false);
      setMediaBlobUrl(null); // Reset recording state and mediaBlobUrl on unmount
    };
  }, []);

  return (
    <div>
      {isCameraAvailable ? (
        <div className='video_sec'>
          <ReactMediaRecorder
            video
            blobPropertyBag={{ type: 'video/webm' }}
            onStop={handleStop}
            render={({ previewStream, startRecording, stopRecording, mediaBlobUrl, clearBlobUrl }) => {
              return (
                <div className='row'>
                  <div className='col-md-4 col-lg-4'>
                    {recording ? (
                      <button className='red_bt' onClick={() => { stopRecording(); setRecording(false); }}>
                        Stop Recording
                      </button>
                    ) : (
                      <button className="black_bt" onClick={() => { clearBlobUrl(); startRecording(); setRecording(true); onStartRecording(); setInitiatedFromCurrentPage(true); }}>
                        Start Recording
                      </button>
                    )}
                  </div>
                  <div className='col-md-4 col-lg-4'>
                    {recording && <VideoPreview stream={previewStream} />}
                    {(!recording && mediaBlobUrl !== null) && <video src={mediaBlobUrl} controls />}
                  </div>
                </div>
              );
            }}
          />
        </div>
      ) : (
        <p>Camera access is not available. Please check your camera settings.</p>
      )}
    </div>
  );
}








