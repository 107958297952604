
import React, { useEffect, useState } from 'react'
import Table from '../../../component/Table/Table';
import { Pagination } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { getclaimReasonCreate, getclaimReasonDelete, getclaimReasonRead } from '../../../store/user/actions';
import Loader from '../../../component/Loader';
import { useParams } from 'react-router-dom';

const ReasonTable = () => {
  const initialValues = {
    title: "",
    type: "2"

  };
  const [current, setCurrent] = useState(1);
  const [formValues, setFormValue] = useState(initialValues);
  const [tableData, setTableData] = useState([]);
  const [limitData, setLimitData] = useState(10);
  const [totalData, setTotalData] = useState(0);
  const claimReasonRead = useSelector(state => state.claimReasonRead)
  const dispatch = useDispatch()
  const id = useParams()
  const handleChange = (name, value) => {
    let data = { ...formValues };
    data[name] = value;
    setFormValue(data);
  };
  useEffect(() => {
    let data = { ...formValues };
    data["page"] = current
    data["limit"] = limitData
    // data["title"] = formValues.title
    dispatch(getclaimReasonRead(data))
  }, [])
  const handleSubmit = () => {
    // Extract the title from the form values
    const { title } = formValues;
  
    // Dispatch the action with the dynamic title and type
    dispatch(getclaimReasonCreate({
      title: title,
      type: "2"
    })).then(() => {
      setFormValue(initialValues);
      // After submitting the form, refresh the table data
      dispatch(getclaimReasonRead({type: "2"}));
    });
  };
  
  const deleteReason = (id) => {
    dispatch(getclaimReasonDelete({ id })).then(() => {
      // After submitting the form, refresh the table data
      dispatch(getclaimReasonRead({type: "2"}));
    });;
  };
  useEffect(() => {
    setTotalData(claimReasonRead.data.total)
    let sno = (current - 1) * 10 + 1;
    const FakeTableData = claimReasonRead && claimReasonRead.data.data.map((item, i) => {
      return [
        <>
          <h6>{sno + i}</h6>
        </>,
        <div>
          <h6>{item.title}</h6>
        </div>,
        <>
          <div className='delete_but'><button onClick={()=>deleteReason(item.id)}>Delete</button></div>
        </>,

      ]
    })
    setTableData(FakeTableData);
  }, [claimReasonRead])

  const TemplateTableData = [

    {heading: "Sr.No."},
    {heading: "Claims Type" },
    {heading: "Action"},


  ];
  const handlePageChange = (page,limit) => {
    setCurrent(page);
    setLimitData(limit)
    dispatch(getclaimReasonRead ({ page,limit }))
};
console.log(claimReasonRead);
  return (
    <div className='Ticket_reson_bg'>
      <div className='white_box'>
        <h6>Ticket Closure Reasons</h6>
        {/* <div className='d-flex'>
         <label className="container_radio">Open
        <input type="radio" checked="checked" name="radio" />
        <span className="checkmark"></span>
        </label>
        <label className="container_radio">Close
        <input type="radio" name="radio" />
        <span className="checkmark"></span>
        </label>
        </div> */}
        <div className='col-lg-4 mt-4 mb-4'>
          <input
            className='input-custom-primary'
            type='text'
            onChange={(e) => handleChange("title", e.target.value)}
            value={formValues.title}
          />
        </div>
        <div className='package_butn mt-2'>
          <button className='suffix_bag' onClick={handleSubmit}>Submit</button>
        </div>
      </div>
      <div className='white_box'>
        <div className="position-relative">
          {
            claimReasonRead.isLoading && <div className="loader_bg"><Loader /></div>
          }
          <div className='table-bg'>
            <Table
              gridTemplateColumnsHeader="20% 45% 35%"
              gridTemplateColumnsBody="20% 45% 35%"
              columns={TemplateTableData}
              data={tableData}
              Loader={true}
            />
            <Pagination
              current={current}
              onChange={handlePageChange}
              total={claimReasonRead.data.pagination ? claimReasonRead.data.pagination.total : 0}
              pageSize={limitData}

              />
          </div>
        </div>
      </div>
    </div>
  )
}

export default ReasonTable
