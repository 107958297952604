


import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom';

import { useDispatch, useSelector } from 'react-redux';
import Dropdown from '../../component/Dropdown/DropDownControlled';
import { getclaimEmailTemplateCreate, getclaimEmailTemplateDropdown, getclaimEmailTemplateRead } from '../../store/user/actions';
import Table from '../../component/Table/Table';
import { Pagination } from 'antd';
import { claimEmailTemplateCreateSlice, claimEmailTemplateReadSlice, resetclaimEmailTemplateCreateState } from '../../store/user/userSlice';
import Loader from '../../component/Loader';
import DefaultModal from '../../component/DefaultModal/DefaultModal';
import EditMail from './components/EditMail';

const AddEmailTemplate = () => {
  const initialValues = {
    claim_reason_id: "",
    party_id: "",
    email_address: "",
    subject: "",
    body: ""
  };

  const [formValues, setFormValue] = useState(initialValues);
  const [current, setCurrent] = useState(1);
  const [show, setShow] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [limitData, setLimitData] = useState(10);
  const [selectedItemId, setSelectedItemId] = useState(null);
  const [totalData, setTotalData] = useState(0);
  const claimEmailTemplateDropdown = useSelector(state => state.claimEmailTemplateDropdown)
  const claimEmailTemplateRead = useSelector(state => state.claimEmailTemplateRead)
  const claimEmailTemplateCreate = useSelector(state => state.claimEmailTemplateCreate)
  // const [rowSpanData, setRowSpanData] = useState("Regarding your ticket communication for 52933MJ974, we are actively processing the provided information. To proceed, we kindly request the corresponding order number, which is ORN.1234567890.");
  const dispatch = useDispatch()
  const navigate = useNavigate();
  const handleGoBack = () => {
    // Navigate back to the previous item
    navigate(-1);
  };

  const handleChange = (name, value) => {
    let data = { ...formValues };
    data[name] = value;
    setFormValue(data);
  };


  const handleSubmit = () => {
    // Gather form data
    const formData = {
      claim_reason_id: formValues.claim_reason_id,
      party_id: formValues.party_id,
      email_address: formValues.email_address,
      subject: formValues.subject,
      body: formValues.body
    };

    // Dispatch an action with the form data
    dispatch(getclaimEmailTemplateCreate(formData)).then(() => {
      // After submitting the form, refresh the table data
      dispatch(getclaimEmailTemplateRead({
        page: current, // Pass the current page
        limit: limitData // Pass the current limit
      }));
    });
  };
 
  useEffect(() => {
    if (claimEmailTemplateCreate.isSuccess) {
      // reset-pending
      navigate('/email-templates');
      dispatch(resetclaimEmailTemplateCreateState())
    }
  }, [claimEmailTemplateCreate]);

  const handleEdit = (id) => {
    console.log(id);
    setSelectedItemId(id); // Set the selected item ID when Edit button is clicked
    setShow(true); // Open the modal
  };
  const onRefreshTable = () => {
    // Dispatch the `getclaimEmailTemplateRead` action with the current page and limit
    dispatch(getclaimEmailTemplateRead({
      page: current,
      limit: limitData,
    }));
  };
  // const handleEdit = (id) => {
  //   console.log(id);
  //   setSelectedItemId(id); // Set the selected item ID when Edit button is clicked
  //   setShow(true); // Open the modal

  //   // Fetch the specific email template data for the selected ID
  //   dispatch(getclaimEmailTemplateRead({ emailTemplateId: id })).then(() => {
  //     // Update form values with the fetched data
  //     setFormValue({
  //       ...claimEmailTemplateRead.data,
  //     });
  //   });
  // };
  useEffect(() => {
    dispatch(getclaimEmailTemplateDropdown({}))
  }, [])
  // useEffect(() => {
  //   let data = { ...formValues };
  //   data["page"] = current
  //   data["limit"] = 10
  //   dispatch(getclaimEmailTemplateRead(data))
  // }, [])
  useEffect(() => {
    // Check if both party_id and claim_reason_id are selected
    if (formValues.party_id && formValues.claim_reason_id) {
        let data = { ...formValues };
        data["page"] = current;
        data["limit"] = 10;
        dispatch(getclaimEmailTemplateRead(data));
    }
}, [formValues.party_id, formValues.claim_reason_id, current]);
 
 
  useEffect(() => {
    setTotalData(claimEmailTemplateRead.data.total)
    let sno = (current - 1) * 10 + 1;
    const FakeTableData = []
    claimEmailTemplateRead && claimEmailTemplateRead.data.data.map((item, i) => {
      // console.log(item);
      FakeTableData.push(
        [
          <>
            <h6>{sno + i}</h6>
          </>,
          <>
            <h6>{item.email_template_id}</h6>
          </>,

          <>
            <h6>{item.email_subject}</h6>
          </>,
          <>
            <h6>{item.claim_reason}</h6>
          </>,
          <>
            <div className='green_but'><button onClick={() => handleEdit(item.email_template_id)}>Edit</button></div>
          </>,

        ])
      FakeTableData.push(
        [<>{item.email_body}</>])
    })
    setTableData(FakeTableData);
  }, [claimEmailTemplateRead])

  const TemplateTableData = [

    {
      heading: "Sr.No.",
    },
    {
      heading: "Email Template Id",
    },
    {
      heading: "Email Subject",
    },
    {
      heading: "Claim Type",
    },
    {
      heading: "Action",
    },


  ];
  const handlePageChange = (page,limit) => {
    setCurrent(page);
    setLimitData(limit)
    dispatch(getclaimEmailTemplateRead({ page, limit: limit }))
};
  // console.log(claimEmailTemplateRead);
  return (
    <div>
      <div className='heading_select'>
        <div className='heading_select'>
          <div className="d-flex align-items-baseline" onClick={handleGoBack}>
            <img style={{ cursor: "pointer" }} src="/Images/arrow-left.svg" />
            <h1>Add Email Template</h1>
          </div>
        </div>

      </div>
      <div className='background_bg'>
        <div className='primary_input_section'>
          <div className='row'>
            <div className='col-md-4 col-lg-4 col-12 mb-4'>
              <label className='select-label'>Select Portal</label>
              <Dropdown
                allowSearch
                border="1px solid #F8B552"
                color="#1C1C1C"
                fontWeight="700"
                width="100%"
                height="48px"
                selectedId={formValues?.party_id}
                onOptionClick={(item) => handleChange("party_id", item?.id)}
                options={claimEmailTemplateDropdown.data.parties.map(item => ({ id: item.id, label: item.name }))}
              />
            </div>
            <div className='col-md-4 col-lg-4 col-12 mb-4'>
              <label className='select-label'>Claim Type</label>
              <Dropdown
                allowSearch
                border="1px solid #F8B552"
                color="#1C1C1C"
                fontWeight="700"
                width="100%"
                height="48px"
                selectedId={formValues?.claim_reason_id}
                onOptionClick={(item) => handleChange("claim_reason_id", item?.id)}
                options={claimEmailTemplateDropdown.data.claim_reason.map(item => ({ id: item.id, label: item.name }))}
              />
            </div>
            <div className='col-md-4 col-lg-4 col-12 mb-4'>
              <label className='select-label'>mail from</label>
              <input
                placeholder=''
                className='input-custom-primary'
                type='text'
                onChange={(e) => handleChange("email_address", e.target.value)}
                value={formValues.email_address}
              />
            </div>

          </div>
          <div className='col-md-12 col-lg-12 col-12 mb-4'>
            <label className='select-label'>Mail Subject</label>
            <textarea
              className='input-custom-primary'
              type='text'
              onChange={(e) => handleChange("subject", e.target.value)}
              value={formValues.subject}
            />
          </div>
          <div className='col-md-12 col-lg-12 col-12 mb-4'>
            <label className='select-label'>Mail Body</label>
            <textarea
              className='input-custom-primary'
              type='text'
              onChange={(e) => handleChange("body", e.target.value)}
              value={formValues.body}
            />
          </div>
          <div className='col-lg-4 col-md-4 mx-auto'>

            <div className='package_butn sub_margin'>
              <Link className='suffix_bag' onClick={handleSubmit}>Submit</Link>
            </div>
          </div>
        </div>
      </div>
     
      <div className='white_box'>
        <div className="position-relative">
          {
            claimEmailTemplateRead.isLoading && <div className="loader_bg"><Loader /></div>
          }
           {formValues.party_id && formValues.claim_reason_id && (
          <div className='table-bg'>
            <Table
              gridTemplateColumnsHeader="15% 25% 20% 20% 20%"
              gridTemplateColumnsBody="15% 25% 20% 20% 20%"
              // rowSpanData={rowSpanData}
              columns={TemplateTableData}
              data={tableData}
              Loader={true}
            />
            <Pagination
              current={current}
              onChange={handlePageChange}
              total={claimEmailTemplateRead.pagination ? claimEmailTemplateRead.pagination.total : 0}
              pageSize={limitData}
              
              />
          </div>
         )}
        </div>
      </div>
      <DefaultModal
        show={show}
        onHide={() => setShow(false)}
        className="edit_modal"
        style={{ paddingRight: "0px" }}
        centered={false}
        showHeader={false}
        size=""
        showFooter={false}
      >
        <div className="modal-heading">
          <h4></h4>
          <div className="close-icon">
            <img src="/Images/closeBlack.svg" onClick={() => setShow(false)} />
          </div>
        </div>
        <EditMail itemId={selectedItemId} 
        // onHide={() => setShow(false)}
        mClose={setShow}
        onRefreshTable={onRefreshTable}
        />

      </DefaultModal>
    </div>
  )
}

export default AddEmailTemplate

