


import React, { useState, useEffect } from "react";

import Table from '../../../component/Table/Table'

import { Pagination } from 'antd';
import { Link } from "react-router-dom";
import { getrepackingCreate, getrepackingExport, getrepackingRead } from "../../../store/user/actions";
import { useDispatch, useSelector } from "react-redux";
import { resetrepackingExportDataState } from "../../../store/user/userSlice";
import Loader from "../../../component/Loader";


const RepackagingTable = () => {
  const [formValues, setFormValue] = useState([]);
    //   const [currentRequest, setCurrentRequest] = useState(0);
    const [current, setCurrent] = useState();
    const [tableData, setTableData] = useState([]);
    const [totalData, setTotalData] = useState(0);
    const [limitData, setLimitData] = useState(10);
    const repackingRead = useSelector(state => state.repackingRead)
    const repackingExport = useSelector(state => state.repackingExport)
    const dispatch = useDispatch()
  
    useEffect(() => {
      let data = { ...formValues };
      data["page"] = current
      data["limit"] = 10
  
      dispatch(getrepackingRead(data))
    }, [])
    const handlePageChange = (page,limit) => {
      setCurrent(page);
      setLimitData(limit)
      dispatch(getrepackingRead({ page, limit: limit }))
  };
   console.log(repackingRead);
   useEffect(() => {
    setTotalData(repackingRead.data.total)
    let sno = (current - 1) * 10 + 1;
    const FakeTableData = repackingRead && repackingRead?.data.data.map((item, i) => {
        return [
        <h6>{item.id}</h6>,
        <div>
          <h6>{item.serial_code}</h6>
        </div>,
        <div>
        <h6>{item.disposition}</h6>
      </div>,
        <h6>{item.reporting_user_id}</h6>,
      ]
    })
    setTableData(FakeTableData);
}, [repackingRead])

    const TemplateTableData = [

        {
            heading: "Sr.No.",
        },
        {
            heading: "Serial Code",
        },
        {
          heading: "Desposition Id",
      },
        {
            heading: "Repacking User Id",
        },
       

    ];
  
   const downloadUrl = () => {
      dispatch(getrepackingExport({page:current,limit:10,loose_return_bag_id:''}))
  }
  useEffect (()=>{
  if(repackingExport.isSuccess){
      window.open(repackingExport.data.download_url)
      dispatch(resetrepackingExportDataState())
  }
  },[repackingExport])
    return (
        <div>
           <div className='heading_select'>
                    <h1>Repacking </h1>
                   <div className="primary_but">
                    <button onClick={() => downloadUrl()} rel="noopener noreferrer"><img src="/Images/exportFile.svg"/>Export File</button>
                    <Link to="/serial-code-scan"><img src="/Images/scan.svg"/>Scan Serial Code</Link>
                   </div>
                </div>
                <div className='white_box'>
                <div className="position-relative">
                    {
                     repackingRead.isLoading && <div className="loader_bg"><Loader /></div>
                   }
                    <div className='table-bg'>
                        <Table
                            gridTemplateColumnsHeader="25% 25% 25% 25%"
                            gridTemplateColumnsBody="25% 25% 25% 25%"
                            columns={TemplateTableData}
                            data={tableData}
                            Loader={true}
                        />
                        <Pagination
                        current={current}
                        onChange={handlePageChange}
                        total={repackingRead.pagination ? repackingRead.pagination.total : 0}
                        pageSize={limitData}
                            />
                    </div>
                   </div>
                </div>
        </div>
    )
}

export default RepackagingTable

