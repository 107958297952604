
import React, { useState, useEffect } from "react";

import Table from '../../../component/Table/Table'

import { Pagination } from 'antd';
import { Link, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getlooseReturnBagsExport, getprimaryScanBagList } from "../../../store/user/actions";
import { resetBagDataState } from "../../../store/user/userSlice";


const NumberOfBagTable = () => {

    //   const [currentRequest, setCurrentRequest] = useState(0);
    const [formValues, setFormValue] = useState([]);
    const [current, setCurrent] = useState(1);
    const navigate = useNavigate();
    const [tableData, setTableData] = useState([]);
    const [totalData, setTotalData] = useState(0);
    const [limitData, setLimitData] = useState(10);
    const primaryScanBagList = useSelector(state => state.primaryScanBagList)
    const looseReturnBagsExport = useSelector(state => state.looseReturnBagsExport)
    const dispatch = useDispatch()
    const { id } = useParams();
  
    // useEffect(() => {
       
    //   dispatch(getprimaryScanBagList({loose_return_id: id}))
    // }, [])
    useEffect(() => {
        const requestData = {
            ...formValues,
            // filter: "all",
            page: current,
            limit: 10,
            loose_return_id:id
        };
        dispatch(getprimaryScanBagList(requestData));
    }, [formValues]);

    // console.log(primaryScanBagList);
    useEffect(()=>{
        // setTotalData(primaryScanBagList.data.total)
        let sno = (current-1)*10+1;
    const FakeTableData = primaryScanBagList && primaryScanBagList.data.data.data.map((item, i) => {
        return [
            <>
                <h6>{sno+i} </h6>
            </>,
           
            <h6><Link>{item.bag_number}</Link></h6>
           ,
            <div>
                <h6>{item.total_packages}</h6>
            </div>,
            <>

                <h6>{item.party_name}</h6>
            </>,
            <>
                <h6>{item.claim_expire_date}</h6>
            </>,
            <>
                <h6>{item.bag_breach_date}</h6>
            </>,
            

        ]

    })
    setTableData(FakeTableData);
},[primaryScanBagList])

    const TemplateTableData = [

        {
            heading: "Sr.No.",
        },
        {
            heading: "Bag Number",
        },
        {
            heading: "Total Number of Packages",
        },
        {
            heading: "Portal",
        },
        {
            heading: "Claim Expire Date",
        },
        {
            heading: "To be opened before",
        },
        
        

    ];
    const handleGoBack = () => {
        // Navigate back to the previous item
        navigate(-1);
      };
  
    const handlePageChange = (page, limit) => {
        setCurrent(page);
        setLimitData(limit)
        dispatch(getprimaryScanBagList({ page, limit: limit,loose_return_id: id }))
    };
    const downloadUrl = (limit) => {
        dispatch(getlooseReturnBagsExport({page:current,limit:limit,filter:"all",loose_return_id:id}))
    }
    useEffect (()=>{
    if(looseReturnBagsExport.isSuccess){
        window.open(looseReturnBagsExport.data.download_url)
        dispatch(resetBagDataState())
    }
    },[looseReturnBagsExport])
    return (
        <div>
           <div className='heading_select'>
                 <div className="d-flex align-items-baseline"onClick={handleGoBack}>
                    <img style={{cursor:"pointer"}} src="/Images/arrow-left.svg" />
                    <h1>Number of Bags</h1>
                    </div>
                    
                    <div className="primary_but">
                    {/* <Link onClick={()=> setFormValue(initialValues)} className="clear_filter"><img src="/Images/trash.svg" /> Clear Filter</Link> */}
                    <Link onClick={() => downloadUrl()} rel="noopener noreferrer" className='but_blue'>
                        <img src="/Images/exportFile.svg" />Export File
                    </Link>
                </div>
                </div>
            
            <div className='white_box'>
                <div className="position-relative">
                    {/* {
        collectionData.isLoading && <div className="loader_bg"><Loader /></div>
      } */}
                    <div className='table-bg'>
                        <Table
                            gridTemplateColumnsHeader="15% 15% 15% 15% 20% 20%"
                            gridTemplateColumnsBody="15% 15% 15% 15% 20% 20%"
                            columns={TemplateTableData}
                            data={tableData}
                            Loader={true}
                        />
                        <Pagination
                            current={current}
                            onChange={handlePageChange}
                            total={primaryScanBagList?.data?.data ? primaryScanBagList?.data?.data?.total : 0}
                            pageSize={limitData}
                            />
                    </div>

                </div>
            </div>
        </div>
    )
}

export default NumberOfBagTable

