import React from 'react'
import PackageDetailsTable from './component/PackageDetailsTable'

const PackageDetails = () => {
  return (
    <div>
      <PackageDetailsTable />
    </div>
  )
}

export default PackageDetails
