import React, { useEffect, useState } from 'react'
import Table from '../../../component/Table/Table';
import { Pagination } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { getreturnedContainerBoxSummaryBbscDetail, getreturnedContainerBoxSummaryBbscDetailExport } from '../../../store/user/actions';
import Loader from '../../../component/Loader';
import { resetExportState } from '../../../store/user/userSlice';
import { Link, useParams } from 'react-router-dom';
import DefaultModal from '../../../component/DefaultModal/DefaultModal';

const BatchBundle = () => {
    const [current, setCurrent] = useState(1);
    const [formValues, setFormValue] = useState([]);
    const [tableData, setTableData] = useState([]);
    const [limitData, setLimitData] = useState(10);
    const [showFull, setShowFull] = useState(false); // Changed initial state to false
    const [selectedImage, setSelectedImage] = useState(''); // Added state to store the selected image URL
    const [totalData, setTotalData] = useState(0);
    const returnedContainerBoxSummaryBbscDetail = useSelector(state => state.returnedContainerBoxSummaryBbscDetail)
    const returnedContainerBoxSummaryBbscDetailExport = useSelector(state => state.returnedContainerBoxSummaryBbscDetailExport)
    const dispatch = useDispatch()
    const { id } = useParams();

    useEffect(() => {
        let data = { ...formValues };
        data["returned_container_box_id"] = id;
        data["page"] = current
        data["limit"] = 10
        dispatch(getreturnedContainerBoxSummaryBbscDetail(data))
    }, [])

    const handlePageChange = (page,limit) => {
        setCurrent(page);
        setLimitData(limit)
        dispatch(getreturnedContainerBoxSummaryBbscDetail({page,limit:limit}))
    };
    console.log(returnedContainerBoxSummaryBbscDetail);
    useEffect(() => {
        // setTotalData(returnedContainerBoxSummaryBbscDetail.data.total)
        let sno = (current - 1) * 10 + 1;
        const FakeTableData = returnedContainerBoxSummaryBbscDetail && returnedContainerBoxSummaryBbscDetail.data.data.map((item, i) => {
            return [
                <>
                    <h6>{sno + i} </h6>
                </>,

                <h6>{item.serial_code}</h6>
                ,
                <div>
                    <h6>{item.sku_number}</h6>
                </div>,
                <>

                    <img src={item.product_picture_image} alt='image' style={{height:"48px"}} />

                </>,
                <div className='video_pop'>
                    <video width="100%" height="200" controls>
                        <source src={item.box_opening_video} type="video/mp4" onClick={() => { setSelectedImage(item.box_opening_video); setShowFull(true); }} />
                    </video>
                </div>,

            ]
        })
        setTableData(FakeTableData);
    }, [returnedContainerBoxSummaryBbscDetail])
    const TemplateTableData = [

        {
            heading: "Sr.No.",
        },
        {
            heading: "Serial Code",
        },
        {
            heading: "SKU Number",
        },
        {
            heading: "Product Picture",
        },
        {
            heading: "Box Opening Video",
        },

    ];
    const downloadUrl = (limit) => {
        dispatch(getreturnedContainerBoxSummaryBbscDetailExport({ page: current, limit: limit, returned_container_box_id: id }))
    }
    useEffect(() => {
        if (returnedContainerBoxSummaryBbscDetailExport.isSuccess) {
            window.open(returnedContainerBoxSummaryBbscDetailExport.data.download_url)
            dispatch(resetExportState())
        }
    }, [returnedContainerBoxSummaryBbscDetailExport])
    return (
        <div>
            <div className='d-flex justify-content-end mb-4'>
                <div className="primary_but">
                    <Link onClick={() => downloadUrl()} rel="noopener noreferrer" className='but_blue'>
                        <img src="/Images/exportFile.svg" />Export File
                    </Link>
                </div>
            </div>
            {
                returnedContainerBoxSummaryBbscDetail.isLoading && <div className="loader_bg pd-4"><Loader /></div>
            }
            <div className='table-bg'>
                <Table
                    gridTemplateColumnsHeader="20% 20% 20% 20% 20%"
                    gridTemplateColumnsBody="20% 20% 20% 20% 20%"
                    columns={TemplateTableData}
                    data={tableData}
                    Loader={true}
                />
                <Pagination
                    current={current}
                    onChange={handlePageChange}
                    total={returnedContainerBoxSummaryBbscDetail.pagination ? returnedContainerBoxSummaryBbscDetail.pagination.total : 0}
                    pageSize={limitData}
                    
                    />
            </div>
            <DefaultModal
                show={showFull}
                onHide={() => setShowFull(false)}
                className="video_modal"
                style={{ paddingRight: "0px" }}
                centered={false}
                showHeader={false}
                size=""
                showFooter={false}
            >
                <div className="modal-heading">
                    <h4></h4>
                    <div className="close-icon">
                        <img src="/Images/closeBlack.svg" onClick={() => setShowFull(false)} />
                    </div>
                </div>
                {/* Render the selected image in the modal */}
                <video width="100%" height="512" controls>
                        <source src={selectedImage} type="video/mp4" />
                    </video>
                </DefaultModal>
        </div>
    )
}

export default BatchBundle
