
import React, { useState, useEffect } from "react";

import Table from '../../../component/Table/Table'

import { Pagination } from 'antd';
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getmrpCreationCreate, getmrpCreationDropdowns, getmrpCreationUpdateLog } from "../../../store/user/actions";
import Loader from "../../../component/Loader";
import Dropdown from "../../../component/Dropdown/DropDownControlled";
import { addMrpCreationData, resetmrpCreationCreateState } from "../../../store/user/userSlice";
import { usePDF, Resolution, Margin } from 'react-to-pdf';
import QRCode from "qrcode.react";
import Barcode from 'react-barcode';
import MrpBatchBundle from "./MrpBatchBundle";
import DefaultModal from "../../../component/DefaultModal/DefaultModal";
import { showNotification } from "../../../util/utility";
// import QRCode from 'react-qr-code';



const MrpTable = () => {
  const initialValues = {
    party_id: "",
    serial_code: "",
  };
  const [formValues, setFormValue] = useState(initialValues);
  const [loading, setLoading] = useState(false);
  const [bundleQty, setBundleQty] = useState('');
  const [error, setError] = useState(false);
  const [limitData, setLimitData] = useState(10);
  const [formErrors, setFormErrors] = useState({});
  const [showCreate, setShowCreate] = useState(false);
  const [showView, setShowView] = useState(false);
  const [isContinuePrintChecked, setIsContinuePrintChecked] = useState(false);
  const mrpCreationDropdowns = useSelector(state => state.mrpCreationDropdowns)
  const [arrayOfObjects, setArrayOfObjects] = useState([]);
  const [tableData, setTableData] = useState([]);
  const [enterData, setEnterData] = useState(false);
  const [current, setCurrent] = useState(1);
  const [serialCodes, setSerialCodes] = useState([]);
  // const [cardCount, setCardCount] = useState(5);
  const [cardCount, setCardCount] = useState(arrayOfObjects.length || 1);
  // const { toPDF, targetRef } = usePDF({ filename: 'page.pdf' })
  const mrpCreationCreate = useSelector(state => state.mrpCreationCreate)
  const dispatch = useDispatch()

  // const bundleQtyy = mrpCreationCreate.data.data.bundle_qty;
  // console.log(tableData)
  useEffect(() => {
    dispatch(getmrpCreationDropdowns({}))
  }, [])
  const handleChange = (name, value) => {
    let data = { ...formValues };
    data[name] = value;
    setFormValue(data);
  };

  const options = {
    filename: "advanced-example.pdf",
    method: "save",
    resolution: Resolution.EXTREME,

    page: {
      format: [140, 70], // Width, Height in inches
      orientation: "landscape",
      // margin: Margin.MEDIUM
      margin: {
        top: 0, // Specify the top margin in mm
        right: 0, // Specify the right margin in mm
        bottom: 5, // Specify the bottom margin in mm
        left: 0, // Specify the left margin in mm
      }
    },
    canvas: {
      mimeType: "image/jpeg",
      qualityRatio: 1
    },
    overrides: {
      pdf: {
        compress: true
      },
      canvas: {
        useCORS: true
      }
    }
  };

  const { toPDF, targetRef } = usePDF(options);
  // const downloadPdf = () => {
  //   setShowView(true)
  //   setTimeout(()=>{
  //     toPDF(); 
  //     setShowView(false)
  //   },200)
  //   dispatch(getmrpCreationUpdateLog({}));
  // }
  ;



  // validation-start

  useEffect(() => {
    if (Object.keys(formErrors).length > 0) {
    }
  }, [formErrors]);

  const validate = (values) => {
    let isValid = true;
    const errors = {};
    if (!values.serial_code) {
      isValid = false;
      errors.serial_code = "Serial code is required";
    }
    if (!values.party_id) {
      isValid = false;
      errors.party_id = "Party id is required";
    }

    return {
      isValid,
      errors,
    };
  };


  // const handleSerialCodeChange = (e) => {
  //   const v = e.target.value.trim().toUpperCase();
  //   handleChange("serial_code", v);
  //   // dispatch(getmrpCreationUpdateLog(formValues));
  // }

  useEffect(() => {
    let sno = (current - 1) * 10 + 1;
    const FakeTableData = arrayOfObjects.map((item, i) => ([
      <h6>{sno + i}</h6>,
      <h6>{item.party}</h6>,
      <div>
        <h6>{item.serial_code}</h6>
      </div>,
      <h6>{item.sku}</h6>,
      <div className="sku_img">
        <img src={item.Image?.image} alt="img" />
      </div>
    ]));
    // const arrayOfArrays = FakeTableData.map(obj => Object.values(obj));
    setTableData(FakeTableData);
  }, [arrayOfObjects])

  const TemplateTableData = [
    { heading: "Sr.No." },
    { heading: "Portal / Party" },
    { heading: "Serial Code" },
    { heading: "SKU" },
    { heading: "Photo" },
  ];

  useEffect(() => {
    if (mrpCreationCreate.isSuccess) {
      //batch bundle modal pending
      
      const isNameUnique = arrayOfObjects.every(obj => obj.party !== mrpCreationCreate.data.data.party || 
        obj.serial_code !== mrpCreationCreate.data.data.serial_code);
        if(mrpCreationCreate.data.data.color_code == "" ||  mrpCreationCreate.data.data.color == ""|| mrpCreationCreate.data.data.style == ""){
         alert("color or color code or style no available")
         return
        }
        if(enterData){
          downloadPdf();
        }
      if (isNameUnique) {
        setArrayOfObjects((prevArray) => [...prevArray, mrpCreationCreate.data.data]);
        setBundleQty(mrpCreationCreate.data.data.bundle_qty);

      } else {
        console.log("dfsd", isNameUnique)
        //Download pdf
      }
      if (isContinuePrintChecked) {
        // If "Continue Print" is checked, set cardCount to 1
        setCardCount(1);
      } else {
        // If not checked, set cardCount dynamically based on the length of arrayOfObjects
        setCardCount(prevCount => prevCount + 1);
      }
    }
    // dispatch(resetmrpCreationCreateState());
  }, [mrpCreationCreate, isContinuePrintChecked])
  

  // const handleKeyPress = (e) => {
  //   if (e.key === 'Enter') {
  //     dispatch(getmrpCreationCreate({ serial_code: formValues.serial_code, party_id: formValues.party_id }))
  //   }
  // };
  const handleKeyPress = (e) => {
    setError(null);
    setLoading(true);
    let check = validate(formValues);
    setFormErrors(check.errors);
    if (check.isValid) {
        setLoading(false);
        setEnterData(false)
        // console.log(isContinuePrintChecked);
        if (e.key === 'Enter' && !isContinuePrintChecked) {
          // console.log(isContinuePrintChecked);
            dispatch(getmrpCreationCreate({ serial_code: formValues.serial_code, party_id: formValues.party_id }))
        } else if (e.key === 'Enter') { 
          setEnterData(true)
          dispatch(getmrpCreationCreate({ serial_code: formValues.serial_code, party_id: formValues.party_id })) 
          .then(() => {
            // if(mrpCreationCreate.data.data.color_code == "" ||  mrpCreationCreate.data.data.color == ""|| mrpCreationCreate.data.data.style == ""){
            //   showNotification("color or color code or style no available")
            //   return
            //  }else{
            //   console.log(mrpCreationCreate.data);
            //   downloadPdf();
             
            //  }
            
           
        })
        .catch((error) => {
            showNotification("Please enter a valid code ")
        });
        } 
    }
};

  const downloadPdf = async () => {
    console.log("mmmm");
    setShowView(true);
    setError(null);
    setLoading(true);
    let check = validate(formValues);
    setFormErrors(check.errors);
    if (check.isValid) {
      if(isContinuePrintChecked && mrpCreationCreate.data.data.color == null){
        showNotification("color code not available")
        return false
      }
    
      setLoading(false);
      await dispatch(getmrpCreationUpdateLog({ serial_code: formValues.serial_code }));
      await new Promise(resolve => setTimeout(resolve, 10));
      await toPDF();
     
  }
  // setShowView(false);
  };

  const handleCheckboxChange = (e) => {
    setIsContinuePrintChecked(e.target.checked);
  };
  // const handleDownload = useDownload('./mrpLableComponent.pdf', 'mrpLableComponent.pdf');
  // console.log(bundleQty);
  const handlePageChange = (page, limit) => {
    setCurrent(page);
    setLimitData(limit)
    dispatch(getmrpCreationCreate({ page, limit: limit }))
  };

  useEffect(() => {
    if (mrpCreationCreate.isSuccess && mrpCreationCreate.data && mrpCreationCreate.data.data) {
      setBundleQty(mrpCreationCreate.data.data.bundle_qty);
    }
  }, [mrpCreationCreate]);

console.log(mrpCreationCreate.data.data.serial_code);
  return (
    <div>
      <div className="primary_bg">
        <div className="row primary_input_section">
          <div className='col-md-4 col-lg-4 col-12 mb-4'>
            <label className='select-label'>Select Portal / Party</label>
            <Dropdown
              allowSearch
              // variant="outlinedGreen"
              border="1px solid #F8B552"
              defaultText="Courier Name"
              color="#1C1C1C"
              fontWeight="700"
              width="100%"
              height="48px"
              selectedId={formValues?.party_id}
              onOptionClick={(item) => handleChange("party_id", item?.id)}
              // options={mrpCreationDropdowns.data.party_list}
              options={mrpCreationDropdowns.data.party_list.map(item => ({ id: item.id, label: item.name }))}
            />
              <span className='error-msg'>{formErrors.party_id}</span>
          </div>
          <div className='col-md-4 col-lg-4 col-12 mb-4'>
            <label className='select-label'>Serial Code</label>
            <input
              className='input-custom-primary'
              type='text'
              onKeyDown={handleKeyPress}
              onChange={(e) => handleChange("serial_code", e.target.value)}
              // onChange={handleSerialCodeChange}
              value={formValues.serial_code}
            />
             <span className='error-msg'>{formErrors.serial_code}</span>
          </div>
          <div className='col-md-4 col-lg-4 col-12 mb-4'>
            <label className='select-label'>Bundle Qty</label>
            <div
              className='input-custom-primary'
              style={{ background: "#F2F2F2", border: "0px" }}
            >
              {bundleQty}
            </div>
          </div>
        </div>
        <div className='package_butn mt-2'>
          <div className='print-flex'>
            {/* <img src="/Images/green_check.svg" /> */}
            <input
              type="checkbox"
              checked={isContinuePrintChecked}
              onChange={handleCheckboxChange}
            />
            <label for="isContinuePrintChecked"><h6>Continue Print</h6></label>
          </div>
          <button
            className='suffix_bag'
            type='button'
            onClick={() => { downloadPdf() }}
          >
            Download PDF
          </button>
        </div>
        <div className='white_box'>
          <div className="position-relative">
            {/* {
              mrpCreationCreate.isLoading && <div className="loader_bg"><Loader /></div>
                      }  */}
            <div className='table-bg'>
              <Table
                gridTemplateColumnsHeader="20% 20% 20% 20% 20% "
                gridTemplateColumnsBody="20% 20% 20% 20% 20% "
                columns={TemplateTableData}
                data={tableData}
                Loader={true}
              />
              <Pagination
                current={current}
                onChange={handlePageChange}
                total={mrpCreationCreate.pagination ? mrpCreationCreate.pagination.total : 0}
                pageSize={limitData}
              />
            </div>

          </div>
        </div>
      </div>
      <DefaultModal
        show={showView}
        onHide={() => setShowView(false)}
        className="secondory-modal pdf-Modal"
        style={{ paddingRight: "0px" }}
        centered={false}
        showHeader={false}
        size=""
        showFooter={false}
      >
        <div className="modal-heading">
          <h4></h4>
          <div className="close-icon">
            <img src="/Images/closeBlack.svg" onClick={() => setShowView(false)} />
          </div>
        </div>
        {/* <button
            className='suffix_bag'
            type='button'
            onClick={() => {downloadPdf()}}
          >Download PDF</button> */}
        <div ref={targetRef} className="pdf_sec">
          {isContinuePrintChecked ? (
            // Render single content
            <div className="row">
              <div className="col-lg-4 col-md-4">
                <div className="scanner_im">
                  <QRCode
                    id={`qrCodeEl_0`}
                    value={mrpCreationCreate.data.data.serial_code}
                    size={200}
                    level={"L"}
                    bgColor={"#ffffff"}
                    fgColor={"#000000"}
                  />
                  <h1>{mrpCreationCreate.data.data.serial_code}</h1>
                  <h2>{mrpCreationCreate.data.data.product_group}</h2>
                </div>
              </div>
              <div className="col-lg-8 col-md-8" style={{ paddingLeft: "0px" }}>
                <div className="pdf_custom">
                  <ul>
                    <li>BRAND : {mrpCreationCreate.data.data.brand}</li>
                    <li>COLOR : {mrpCreationCreate.data.data.color} </li>
                    <li>COLOR CODE : {mrpCreationCreate.data.data.color_code}</li>
                    <li>STYLE CODE : {mrpCreationCreate.data.data.style}</li>
                    <li>SIZE : {mrpCreationCreate.data.data.size}</li>
                    <li>GENERIC NAME : {mrpCreationCreate.data.data.art_name}</li>
                    <li>DIMENSIONS (in cms) : {mrpCreationCreate.data.data.dimension}</li>
                  </ul>
                  <h1>MFD. BY / MARKETED BY / CUSTOMER
                  CARE: Seven Rocks International, 207-A
                  Industrial Area, Ldh-141003</h1>
                  <h6>Contact : 0161-4617621, mailto:sevenrocksint@gmail.com</h6>
                  <Barcode
                  height={50}
                    value={
                      mrpCreationCreate.data.data.amazon_sku
                        ? mrpCreationCreate.data.data.amazon_sku
                        : mrpCreationCreate.data.data.flipkart_sku
                          ? mrpCreationCreate.data.data.flipkart_sku
                          : mrpCreationCreate.data.data.sku
                    } />
                </div>
              </div>
            </div>
          ) : (
            // Render multiple content
            // Array(cardCount).fill(0).map((_, index) => (
            arrayOfObjects.map((data, index) => (
              <div className="row" key={index}>
                <div className="col-lg-4">
                  <div className="scanner_im">
                    <QRCode
                      id={`qrCodeEl_${index}`}
                      value={data.serial_code}
                      size={200}
                      level={"L"}
                      bgColor={"#ffffff"}
                      fgColor={"#000000"}
                    />
                    <h2>{data.product_group}</h2>
                  </div>
                </div>
                <div className="col-lg-8" style={{ paddingLeft: "0px" }}>
                  <div className="pdf_custom">
                    <ul>
                      <li>BRAND : {data.brand}</li>
                      <li>COLOR : {data.color} </li>
                      <li>COLOR CODE : {data.color_code}</li>
                      <li>STYLE CODE : {data.style}</li>
                      <li>SIZE : {data.size}</li>
                      <li>GENERIC NAME : {data.art_name}</li>
                      <li>DIMENSIONS (in cms) : {data.dimension}</li>
                    </ul>
                    <h6>Contact : 0161-4617621, mailto:sevenrocksint@gmail.com</h6>
                    <Barcode
                    height={50}
                      value={
                        data.amazon_sku
                          ? data.amazon_sku
                          : data.flipkart_sku
                            ? data.flipkart_sku
                            : data.sku
                      } />
                  </div>
                </div>
              </div>
            ))
          )}
        </div>

      </DefaultModal>
      <DefaultModal
        show={showCreate}
        onHide={() => setShowCreate(false)}
        className="add-modal"
        style={{ paddingRight: "0px" }}
        centered={false}
        showHeader={false}
        size=""
        showFooter={false}
      >
        <div className="modal-heading mb-4">
          <h4 style={{color:"red"}}>Create Batch Bundle</h4>
          <div className="close-icon">
            <img src="/Images/closeBlack.svg" onClick={() => setShowCreate(false)} />
          </div>
        </div>

        <div className='alert_modal'>
        <MrpBatchBundle 
         mClose={setShowCreate}
         />
         
        </div>
      </DefaultModal>
    </div>
  )
}

export default MrpTable
