
import './App.css';
import {BrowserRouter, Routes, Route, Outlet, Navigate, useNavigate } from "react-router-dom";
import LoginPage from './pages/LoginPage/LoginPage';
import { ThemeProvider } from "styled-components";
import theme from "./theme";
import NavbarSidebarAdmin from './component/NavbarSidebarMosque/NavbarSidebarAdmin';
import Dashboard from './pages/Dashboard/Dashboard';
import "./css/header.css";
import "./css/dashboard.css";
import "./css/primary.css";
import "./css/settings.css";
import "./css/secondary.css";
import "./css/returnclaim.css";
import "./css/ticketReason.css";
import "./css/pdf.css";
import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import PublicRoute from './util/PublicRoute';
import PrivateRoute from './util/PrivateRoute';
import BoxDashboard from './pages/BoxDashboard/BoxDashboard';
import PrimaryScan from './pages/PrimaryScan/PrimaryScan';
import SecondaryScan from './pages/SecondaryScan/SecondaryScan';
import Repackaging from './pages/Repackaging/Repackaging';
import MRPCreation from './pages/MRPCreation/MRPCreation';
import BagSummary from './pages/BagSummary/BagSummary';
import BoxSummary from './pages/BoxSummary/BoxSummary';
import ReturnClaims from './pages/ReturnClaims/ReturnClaims';
import FBImport from './pages/FBImport/FBImport';
import Settings from './pages/Settings/Settings';
import NumberOfBags from './pages/NumberOfBags/NumberOfBags';
import PackageScanOne from './pages/PackageScanOne/PackageScanOne';
import PackageScanTwo from './pages/PackageScanTwo/PackageScanTwo';
import BoxScanOne from './pages/BoxScanOne/BoxScanOne';
import BoxScanTwo from './pages/BoxScanTwo/BoxScanTwo';
import PrimaryUploadPicture from './pages/PrimaryUploadPicture/PrimaryUploadPicture';
import SecondaryScanAdd from './pages/SecondaryScanAdd/SecondaryScanAdd';
import Communication from './pages/Communications/Communication';
import ClaimMedia from './pages/ClaimMedia/ClaimMedia';
import BoxSummaryDetails from './pages/BoxSummaryDetails/BoxSummaryDetails';
import UploadCountPieces from './pages/UploadCountPieces/UploadCountPieces';
import PackageDetails from './pages/PackageDetails/PackageDetails';
import SerialCodeScan from './pages/SerialCodeScan/SerialCodeScan';
import TicketReason from './pages/TicketReason/TicketReason';
import BoxScanThree from './pages/BoxScanThree/BoxScanThree';
import ClaimsSummary from './pages/ClaimsSummary/ClaimsSummary';
import AddManualClaims from './pages/AddManualClaims/AddManualClaims';
import AddClaimsType from './pages/AddClaimsType/AddClaimsType';
import EmailTemplates from './pages/EmailTemplates/EmailTemplates';
import AddEmailTemplate from './pages/AddEmailTemplate/AddEmailTemplate';
import SecondaryBoxScanAdd from './pages/SecondaryBoxScanAdd/SecondaryBoxScanAdd';
import BoxEditScanTwo from './pages/BoxEditScanTwo/BoxEditScanTwo';
import PackageScanTwoAdd from './pages/PackageScanTwoAdd/PackageScanTwoAdd';
import UploadCountWithout from './pages/UploadCountWithout/UploadCountWithout';
import History from './pages/History/History';


function App() {
  // const isLoading = useSelector((state) => state.loader.isLoading);
  return (
   <BrowserRouter>
    <ThemeProvider theme={theme}>
    <Routes>
    <Route element={<PublicRoute />}>
          <Route path="/login" element={<LoginPage />} />
    </Route>
        <Route element={<PrivateRoute />}>
        <Route  
       element={
         <NavbarSidebarAdmin>
            <Outlet />
           </NavbarSidebarAdmin>
        }>
          <Route path="/" element={<Dashboard />} />
          <Route path="/box-dashboard" element={<BoxDashboard />} />
           {/* primary-scan-url */}
          <Route path="/primary-scan" element={<PrimaryScan />} />
          <Route path="/package-scan-one" element={<PackageScanOne />} />
          <Route path="/upload-count/:id?" element={<UploadCountPieces />} />
          <Route path="/upload-count-without/:id" element={<UploadCountWithout />} />
          <Route path="/package-scan-two/:id?/:received_packages?" element={<PackageScanTwo />} />
          <Route path="/package-scan-two-add/:id?" element={<PackageScanTwoAdd />} />
          <Route path="/primary-upload/:returned_container_id/:returned_container_box_id" element={<PrimaryUploadPicture />} />
          <Route path="/box-scan-one" element={<BoxScanOne />} />
          <Route path="/box-form/:id" element={<BoxScanTwo />} />
          <Route path="/box-form-edit/:id/:box" element={<BoxEditScanTwo />} />
          <Route path="/box-scan-image/:id" element={<BoxScanThree />} />
          {/* primary-scan-url-end*/}
           {/* secondary-scan-url */}
          <Route path="/secondary-scan" element={<SecondaryScan/>} />
          <Route path="/secondary-scan-add" element={<SecondaryScanAdd/>} />
          <Route path="/secondary-box-scan-add" element={<SecondaryBoxScanAdd />} />
          <Route path="/repackaging" element={<Repackaging />} />
          <Route path="/serial-code-scan" element={<SerialCodeScan />} />
          <Route path="/mrp-creation" element={<MRPCreation />} />
            {/* bag-summary url */}
          <Route path="/bag-summary" element={<BagSummary />} />
          <Route path="/package-summary/:id" element={<PackageDetails />} />
          {/* box-summary url */}
          <Route path="/box-summary" element={<BoxSummary />} />
          <Route path="/box-summary-details/:id" element={<BoxSummaryDetails />} />
             {/* box-summary end */}
           {/* return url */}
          <Route path="/return-claims" element={<ReturnClaims />} />
          <Route path="/communication/:id/:claimTicketId" element={<Communication />} />
          <Route path="/claim-media/:id" element={<ClaimMedia />} />
          <Route path="/claim-summary" element={<ClaimsSummary />} />
          <Route path="/add-manual-claims" element={<AddManualClaims />} />
          <Route path="/add-claims-type" element={<AddClaimsType />} />
          {/* return url end */}
          <Route path="/fbImport" element={<FBImport />} />
          <Route path="/settings" element={<Settings />} />
          <Route path="/number-of-bags/:id" element={<NumberOfBags />} />
            {/* ticket url end */}
          <Route path="/ticket-reason" element={<TicketReason/>} />
          <Route path="/email-templates" element={<EmailTemplates />} />
          <Route path="/add-email-template" element={<AddEmailTemplate/>} />
          <Route path="/history/:id" element={<History/>} />
        </Route>
      </Route>
    </Routes>
    </ThemeProvider>
    <ToastContainer/>
   
   </BrowserRouter>
  );
}

export default App;
