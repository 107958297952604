import React, { useEffect, useState } from 'react'
import PrimaryTable from './component/PrimaryTable'
import PrimaryTab from './component/PrimaryTab'
import { Link } from 'react-router-dom'
import DefaultModal from '../../component/DefaultModal/DefaultModal'
import ReturnOptionModal from './component/ReturnOptionModal'
import { getlooseReturnExport } from '../../store/user/actions'
import { useDispatch, useSelector } from 'react-redux'
import { resetReturnDataState } from '../../store/user/userSlice'

const PrimaryScan = () => {
  const [show, setShow] = useState(false);
  const [current, setCurrent] = useState(1);
  const [limitData, setLimitData] = useState(500);
  // const looseReturnExport = useSelector(state => state.looseReturnExport)
  // const dispatch = useDispatch()
  // const downloadUrl = (page,limit) => {
  //   setCurrent(page);
  //   setLimitData(limit)
  //   dispatch(getlooseReturnExport({ page: current, limit:limitData, filter: 'all' }))
  // }
  // useEffect(() => {
  //   if (looseReturnExport.isSuccess) {
  //     window.open(looseReturnExport.data.download_url)
  //     dispatch(resetReturnDataState())
  //   }
  // }, [looseReturnExport])
  return (
    <div>
      <div className='heading_select'>
        <h1>Primary Scan </h1>
        <div className="primary_but mb-2">
          {/* <button onClick={() => downloadUrl()} rel="noopener noreferrer" ><img src="/Images/exportFile.svg" />Export File</button> */}
          <Link onClick={() => setShow(true)}><img src="/Images/scan.svg" />Scan Primary Packages</Link>
        </div>
      </div>

      <PrimaryTab />
      <DefaultModal
        show={show}
        onHide={() => setShow(false)}
        className="return-modal"
        style={{ paddingRight: "0px" }}
        centered={false}
        showHeader={false}
        size=""
        showFooter={false}
      >
        <div className="modal-heading">
          <h4>Select
            Return Option</h4>
          <div className="close-icon">
            <img src="/Images/closeBlack.svg" onClick={() => setShow(false)} />
          </div>
        </div>
        <ReturnOptionModal

        />

      </DefaultModal>
    </div>
  )
}

export default PrimaryScan
