import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { getclaimStatusChange, getclaimStatusDropdown } from '../../../store/user/actions';
import Dropdown from '../../../component/Dropdown/DropDownControlled';

const ClaimStatus = ({claimId,mClose,onRefreshTable}) => {
    const initialValues = {
        claim_id: "",
        amount: "",
        status: "",
        rejected_remark: "",
    };
    const [formValues, setFormValue] = useState(initialValues)
    const claimStatusDropdown = useSelector(state => state.claimStatusDropdown)
    const claimStatusChange = useSelector(state => state.claimStatusChange)
    const dispatch = useDispatch()
    const statusChange = () => {
       
        console.log(claimId);
        const requestData = {
            claim_id:claimId,
            status: formValues.status
        };
        // Include additional fields based on status
        if (formValues.status === 'Filed' || formValues.status === 'Approved') {
            requestData.amount = formValues.amount;
            requestData.rejected_remark = formValues.rejected_remark;
        } else if (formValues.status === 'Rejected') {
            requestData.rejected_remark = formValues.rejected_remark;
        }
        dispatch(getclaimStatusChange(requestData)).then(()=>{
            onRefreshTable();
        })
    };
  console.log(claimId);
    useEffect(() => {
        dispatch(getclaimStatusDropdown({}))
    }, [])
    const handleChange = (name, value) => {
        let data = { ...formValues };
        data[name] = value;
        setFormValue(data);
    };
    //remark status

    const renderAmountInputAndRemark = () => {
        if (formValues.status === 'Rejected') {
            return (
                <>
                    <div className='col-md-12 col-lg-12 col-12 mb-4 pt-4'>
                        <label className='select-label'>Remarks</label>
                        <textarea
                            value={formValues.rejected_remark}
                            onChange={(e) => handleChange('rejected_remark', e.target.value)}
                        />
                    </div>
                </>
            );
        }

        if (formValues.status === 'Filed' || formValues.status === 'Approved') {
            return (
                <>
                    <div className='col-md-12 col-lg-12 col-12 mb-4 pt-4'>
                        <label className='select-label'>Enter Amount</label>
                        <input
                            className='input-custom-primary'
                            type='number'
                            onChange={(e) => handleChange('amount', e.target.value)}
                            value={formValues.amount}
                        />
                    </div>
                    <div className='col-md-12 col-lg-12 col-12 mb-4 pt-4'>
                        <label className='select-label'>Remarks</label>
                        <textarea
                            value={formValues.rejected_remark}
                            onChange={(e) => handleChange('rejected_remark', e.target.value)}
                        />
                    </div>
                </>
            );
        }

        return null; // Render nothing if status is neither Rejected nor Filed/Approved
    };
    useEffect(() => {
        if (claimStatusChange.isLoading) {
          mClose(false);
        }
       
      }, [claimStatusChange]);
    return (
        <div>
            <div>
                <div className="col-md-12 col-lg-12 col-12 mb-4 pt-4">
                    <label className='select-label'>Status </label>
                    <Dropdown
                        allowSearch
                        // variant="outlinedGreen"
                        border="1px solid #F8B552"
                        defaultText="status"
                        color="#1C1C1C"
                        fontWeight="700"
                        width="100%"
                        height="48px"
                        selectedId={formValues?.status}
                        onOptionClick={(item) => handleChange("status", item?.id)}
                        options={claimStatusDropdown.data}
                    />
                </div>
                {renderAmountInputAndRemark()}
                <div className='package_butn sub_margin'>
                    <button className='suffix_bag' onClick={statusChange}>SAVE</button>
                </div>
            </div>
        </div>
    )
}

export default ClaimStatus
