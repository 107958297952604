import React, { useEffect } from 'react'
import { getnotRespondingByPortal } from '../../../store/user/actions'
import { useDispatch, useSelector } from 'react-redux'

const NotResPondingByPortal = () => {
    const notRespondingByPortal = useSelector(state => state.notRespondingByPortal)
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(getnotRespondingByPortal({}))
    }, [])
    return (
        <div>
            <div className='white_box'>
                <div className='heading_select pt-4 pb-4'>
                    <h2>Not Responding by Portal  </h2>
                </div>
                <div className='scroll_sec_table'>
                    <table className="table table-bordered table-sm table_box">

                        <thead className='sticky-top'>
                            <tr>
                                <th>Portal Name</th>
                                <th>0 - 3 Days</th>
                                <th>3 -7 Days</th>
                                <th>7 - 15 Days</th>
                                <th>15+ Days</th>
                            </tr>
                        </thead>
                        <tbody>

                            {
                                notRespondingByPortal.data.map((item) => (
                                    <tr>
                                        <td>{item.name}</td>
                                        <td>{item.zero_to_three_days}</td>
                                        <td>{item.three_to_seven_days}</td>
                                        <td>{item.seven_to_fifteen_days}</td>
                                        <td>{item.more_than_fifteen_days}</td>

                                    </tr>
                                ))
                            }

                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
}

export default NotResPondingByPortal
