


import React, { useState, useEffect } from "react";

import Table from '../../../component/Table/Table'

import { Pagination } from 'antd';
import { Link } from "react-router-dom";
import Dropdown from "../../../component/Dropdown/DropDownControlled";
import BagFilter from "./BagFilter";
import { useDispatch, useSelector } from "react-redux";
import { getlooseReturnBagsExport, getprimaryScanBagList } from "../../../store/user/actions";
import { resetBagDataState } from "../../../store/user/userSlice";
import Loader from "../../../component/Loader";


const BagTable = () => {

  
    const [formValues, setFormValue] = useState([])
    const [current, setCurrent] = useState(1);
    const [tableData, setTableData] = useState([]);
    const [totalData, setTotalData] = useState(0);
    const [limitData, setLimitData] = useState(10);
    const [selectBox, setSelectBox] = useState(true);
    const filterValue = (selectValue) => {
        setSelectBox(selectValue)
    }
    // useEffect(() => {
    // dispatch(getprimaryScanBagList(selectBox))
    // },[selectBox])

    const primaryScanBagList = useSelector(state => state.primaryScanBagList)
    const looseReturnBagsExport = useSelector(state => state.looseReturnBagsExport)
    const dispatch = useDispatch()
  
    // useEffect(() => {
    //   dispatch(getprimaryScanBagList({page: current }))
    // }, [])
    useEffect(() => {
        setCurrent(1);
        const requestData = {
            ...formValues,
            ...selectBox,
             filter: "all",
            page: current,
            limit: limitData,
        };
        dispatch(getprimaryScanBagList(requestData));
    }, [formValues,selectBox]);
    // console.log(primaryScanBagList.data.data);
    const handlePageChange = (page,limit) => {
        setCurrent(page);
        setLimitData(limit)
        const requestData = {
            ...formValues,
            ...selectBox,
             filter: "all",
            page: page,
            limit: limit,
        };
        dispatch(getprimaryScanBagList(requestData));
    };
   
    //  console.log(primaryScanBagList.bag_breach_date);
        useEffect(()=>{
            // let sno = (current - 1) * 10 + 1;
        const FakeTableData = primaryScanBagList && primaryScanBagList.data.data.data.map((item, i) => {
            return [
            <>
               <h6>{item.id}</h6>
            </>,
           
                    <h6>{item.bag_number}</h6>
           ,
            <div>
                <h6>{item.pod_number}</h6>
            </div>,
            <>

                <h6>{item.total_packages}</h6>
            </>,
            <>
                <h6>{item.party_name}</h6>
            </>,
            <>
                <h6>{item.courier_name}</h6>
            </>,
            <>
                <h6>{item.created}</h6>
            </>,
             <>
             <h6>{item.bag_breach_date}</h6>
            </>,
             <>
             <h6>{item.open_date}</h6>
            </>,
             <div>
              <h6>{item.delay_days} Days</h6>
            </div>,
           <div className="view_but">
           <Link to={`/package-summary/${item.id}`}>View Pkg</Link>
           </div>,
]
})
setTableData(FakeTableData);
// }
},[primaryScanBagList])

    const TemplateTableData = [

        {
            heading: "Sr.No.",
        },
        {
            heading: "Bag Number",
        },
        {
            heading: "POD Number",
        },
        {
            heading: "Total Bag Counter",
        },
        {
            heading: "Portal Name",
        },
        {
            heading: "Courier Name",
        },
        {
            heading: "Bag Created Date",
        },
        {
            heading: "Bag Opening Breach Date",
        },
        {
            heading: "Actual Bag Opening Date",
        },
        {
            heading: "Delay Time",
        },
        {
            heading: "Action",
        },

    ];
    const downloadUrl = () => {
        dispatch(getlooseReturnBagsExport({page:current,limit:limitData,filter:"all"}))
    }
    useEffect (()=>{
    if(looseReturnBagsExport.isSuccess){
        window.open(looseReturnBagsExport.data.download_url)
        dispatch(resetBagDataState())
    }
    },[looseReturnBagsExport])
    
    return (
        <div>
           <div className='heading_select'>
                    <h1>Bag Summary</h1>
                    <div className="primary_but">
                    {/* <Link onClick={()=> setFormValue(initialValues)} className="clear_filter"><img src="/Images/trash.svg" /> Clear Filter</Link> */}
                    <Link onClick={() => downloadUrl()} rel="noopener noreferrer" className='but_blue'>
                        <img src="/Images/exportFile.svg" />Export File
                    </Link>
                </div>
                </div>
             <BagFilter
            //  primaryScanBagList={primaryScanBagList}
             filterValue={filterValue}
             />
            <div className='white_box'>
                <div className="position-relative">
                    {
                    primaryScanBagList.isLoading && <div className="loader_bg"><Loader /></div>
                    }
                    <div className='table-bg'>
                        <Table
                            gridTemplateColumnsHeader="5% 9% 9% 8% 10% 10% 10% 10% 9% 10% 10%"
                            gridTemplateColumnsBody="5% 9% 9% 8% 10% 10% 10% 10% 9% 10% 10%"
                            columns={TemplateTableData}
                            data={tableData}
                            Loader={true}
                        />
                        <Pagination
                            current={current}
                            onChange={handlePageChange}
                            total={primaryScanBagList?.data?.data ? primaryScanBagList?.data?.data?.total : 0}
                            pageSize={limitData}
                            showSizeChanger
                            />
                    </div>

                </div>
            </div>
        </div>
    )
}

export default BagTable

