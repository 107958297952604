import React from 'react'
import RepackagingTable from './component/RepackagingTable'

const Repackaging = () => {
  return (
    <div>
      <RepackagingTable />
    </div>
  )
}

export default Repackaging
