import React, { useEffect } from 'react'
import { getclaimListing, getcounterMetrics } from '../../../store/user/actions'
import { useDispatch, useSelector } from 'react-redux'

const ReturnCard = () => {
    const counterMetrics = useSelector(state => state.counterMetrics.data)
    const dispatch = useDispatch()
  
    useEffect(() => {
      dispatch(getcounterMetrics({filter:"all"}))
    }, [])
  
  return (
    <div>
          <div className='row padding_left'>
                <div className='col-lg-3 col-md-3 col-12 card-common'>
                    <div className="pale-red">
                        <h2>{counterMetrics.totalNotRespondingByPortal}</h2>
                        {/* <h2>{counterMetrics}</h2> */}
                        <p>Not Responding by Portal</p>
                    </div>
                </div>
                <div className='col-lg-3 col-md-3 col-12 card-common'>
                    <div className="alice-blue">
                    <h2>{counterMetrics.totalNotRespondingByUs}</h2>
                        {/* <h2>{dashboardCard.totalCompletedCollection}</h2> */}
                        <p>Not Responding by Us</p>
                    </div>
                </div>
                <div className='col-lg-3 col-md-3 col-12 card-common'>
                    <div className="light-green">
                    <h2>{counterMetrics.totalPendingClaim}</h2>
                        {/* <h2>{dashboardCard.totalPandingCollection}</h2> */}
                        <p>Claims Pending</p>
                    </div>
                </div>
                <div className='col-lg-3 col-md-3 col-12 card-common'>
                    <div className="light-orange">
                    <h2>{counterMetrics.totalFiledClaim}</h2>
                        {/* <h2>{dashboardCard.totalPartiallyPandingCollection}</h2> */}
                        <p>Claims Filed</p>
                    </div>
                </div>
                <div className='col-lg-3 col-md-3 col-12 card-common'>
                    <div className="light-pink">
                    <h2>{counterMetrics.totalApprovedClaim}</h2>
                        {/* <h2>{dashboardCard.totalSampleRequestTime} Hours</h2> */}
                        <p>Claims Approved</p>
                    </div>
                </div>
                <div className='col-lg-3 col-md-3 col-12 card-common'>
                    <div className="dark-green">
                    <h2>{counterMetrics.totalRejectedClaim}</h2>
                        {/* <h2>{dashboardCard.totalSampleRequest}</h2> */}
                        <p>Claims Rejected</p>
                    </div>
                </div>
                <div className='col-lg-3 col-md-3 col-12 card-common'>
                    <div className="dark-gray">
                    <h2>{counterMetrics.totalClaims}</h2>
                        {/* <h2>{dashboardCard.totalCompleteSampleRequest}</h2> */}
                        <p>Total Claims</p>
                    </div>
                </div>
            </div>
    </div>
  )
}

export default ReturnCard
