import React from 'react'
import ReasonTable from './component/ReasonTable'

const TicketReason = () => {
  return (
    <div>
         <div className='heading_select'>
                    <h1>Ticket Reason</h1>
                  
                </div>
      <ReasonTable/>
    </div>
  )
}

export default TicketReason
