import React, { useState, useEffect } from "react";

import Table from '../../../component/Table/Table'

import { Pagination } from 'antd';
import TATCard from "./TATCard";
import { useDispatch, useSelector } from "react-redux";
import { getclaimEmailTemplateDropdown, getturnAroundTimePortalWise } from "../../../store/user/actions";

import DateRangePicker from '@wojtekmaj/react-daterange-picker'
import '@wojtekmaj/react-daterange-picker/dist/DateRangePicker.css';
import Dropdown from "../../../component/Dropdown/DropDownControlled";
const optionsSelect = [

  { value: "today", label: "Today" },
  { value: "this_week", label: "This Week" },
  { value: "last_week", label: "Last Week" },
  { value: "this_month", label: "This Month" },
  { value: "last_month", label: "Last Month" },
  { value: "last_three_month", label: "Last 3 Month" },
  { value: "this_year", label: "This Year" },
  { value: "last_year", label: "Last Year" },
  { value: "all", label: "All" },
];
const DashboardTable = () => {
  const initialValues = {
    start_date: "",
    end_date: "",
    party_id:"",
  };
  const rangeDate = [new Date(), new Date]
  const [formValues, setFormValue] = useState(initialValues);
  const [filter, setFilter] = useState("select_date");
  const [rangePickerDate, setrangePickerDate] = useState(rangeDate);
  const [limitData, setLimitData] = useState(10);
  const [selectedDuration, setSelectedDuration] = useState(
    optionsSelect[0].value // Set default value to "duration"
  );
  //   const [currentRequest, setCurrentRequest] = useState(0);
  const [current, setCurrent] = useState(1);
  const [tableData, setTableData] = useState([]);
  const [totalData, setTotalData] = useState(0);
  const claimEmailTemplateDropdown = useSelector(state => state.claimEmailTemplateDropdown)
  const turnAroundTimePortalWise = useSelector(state => state.turnAroundTimePortalWise)
  const dispatch = useDispatch()

  // const onChange = (page) => {
  //   setCurrent(page);
  // };
  useEffect(() => {
    // let data = { ...formValues };
    // data["page"] = current
    // data["limit"] = 10
    dispatch(getturnAroundTimePortalWise({
      filter, 
      start_date: formValues.start_date,
       end_date: formValues.end_date,
       party_id:formValues.party_id,
       page: current,
       limit: limitData,
      }))
  }, [filter, formValues])
  useEffect(() => {
    dispatch(getclaimEmailTemplateDropdown({}))
  }, [])

  useEffect(() => {
    setTotalData(turnAroundTimePortalWise.data.total)
    let sno = (current - 1) * 10 + 1;
    const FakeTableData = turnAroundTimePortalWise && turnAroundTimePortalWise?.data?.data.data.map((item, i) => {
      return [
        <>
          <h6>{sno+i}</h6>
        </>,
        <div>
          <h6>{item.portal_name}</h6>
        </div>,
        <>
          <h6>{item.courier_name}</h6>
        </>,
        <>

          <h6>{item.total_packages_pending_to_be_open}</h6>
        </>,
        <>
          <h6>{item.total_bags_pending_to_be_open}</h6><br />

        </>,
      ]
    })
    setTableData(FakeTableData);
  }, [turnAroundTimePortalWise])

  const TemplateTableData = [

    { heading: "Sr.No." },
    { heading: "Portal Name" },
    { heading: "Courier Name" },
    { heading: "Total Packages Pending to be Open" },
    { heading: "Total Bags Pending to be Open" },

  ];

  const onChange = (name, date) => {
    console.log("Name",name);
    setrangePickerDate(date);
    var currentDate = new Date(date[0]);
    // Get the year, month, and day from the date
    var year = currentDate.getFullYear();
    var month = String(currentDate.getMonth() + 1).padStart(2, '0'); // Month is 0-based
    var day = String(currentDate.getDate()).padStart(2, '0');
    // Format the date in "Y-m-d" format
    var toDate = `${year}-${month}-${day}`;
  
    var currentDate = new Date(date[1]);
    // Get the year, month, and day from the date
    var year = currentDate.getFullYear();
    var month = String(currentDate.getMonth() + 1).padStart(2, '0'); // Month is 0-based
    var day = String(currentDate.getDate()).padStart(2, '0');
  
    // Format the date in "Y-m-d" format
    var fromDate = `${year}-${month}-${day}`;
    let formdata = { ...formValues };
    formdata["end_date"] = toDate; // Update this line
  formdata["start_date"] = fromDate; // Update this line
    // formdata[name]=date
    setFormValue(formdata);
  };
  // const handleChange = (event) => {
  //   setSelectedDuration(event.target.value);

  //   // If updating form state:
  // };
  const handleChange = (name, value) => {
    let data = { ...formValues };
    data[name] = value;
    setFormValue(data);
  };
  const handleFilterChange = (e) => {
    setFilter(e.target.value);
}
const handlePageChange = (page, limit) => {
  setCurrent(page);
  setLimitData(limit)
  dispatch(getturnAroundTimePortalWise({ page, limit:limitData }))
};
console.log(turnAroundTimePortalWise);
  return (
    <div>
      <TATCard />
      <div className='white_box'>
        <div className='heading_select'>
          <h2>Portal wise </h2>
          <div className='select_box_area select_box_area_two'>
            <div>
              <Dropdown
                allowSearch
                border="1px solid #E0E0E0"
                color="#1C1C1C"
                fontWeight="700"
                width="150px"
                height="48px"
                borderRadius="40px"
                backgroundColor= "tranparent"
                selectedId={formValues?.party_id}
                onOptionClick={(item) => handleChange("party_id", item?.id)}
                options={claimEmailTemplateDropdown.data.parties.map(item => ({ id: item.id, label: item.name }))}
              />
            </div>
            <div className="custom-select">
              <select className='selectArea'
                // value={selectedDuration} // Set the selected value from state
                // onChange={handleChange}
                value={filter}
                onChange={handleFilterChange}
              >
                {
                  optionsSelect && optionsSelect.map((item) => (
                    <option value={item.value}>{item.label}</option>
                  ))
                }
              </select>
            </div>
            <div className="custom-select" style={{ marginRight: "10px" }}>
            <DateRangePicker
                      // placeholder="Current Planned Date"
                      onChange={(e) => onChange("start_date_", e)}
                      value={rangePickerDate}
                      yearAriaLabel="Year"
                      clearIcon={false}
                    />
            </div>
          </div>
        </div>
        <div className="border-wordload"></div>
        <div className="position-relative">
          {/* {
        collectionData.isLoading && <div className="loader_bg"><Loader /></div>
      } */}
          <div className='table-bg'>
            <Table
              gridTemplateColumnsHeader="20% 20% 20% 20% 20%"
              gridTemplateColumnsBody="20% 20% 20% 20% 20%"
              columns={TemplateTableData}
              data={tableData}
              Loader={true}
            />
            <Pagination
              current={current}
              onChange={handlePageChange}
              total={turnAroundTimePortalWise?.data.data ? turnAroundTimePortalWise?.data?.data.total : 0}
              pageSize={limitData}
              showSizeChanger
              />
          </div>

        </div>
      </div>
    </div>
  )
}

export default DashboardTable
