import React from 'react'
import SettingTab from './component/SettingTab'

function Settings() {
  return (
    <div>
      <SettingTab />
    </div>
  )
}

export default Settings
