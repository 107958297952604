


import React, { useState, useEffect } from "react";

import Table from '../../../component/Table/Table'

import { Pagination } from 'antd';
import { Link, useParams } from "react-router-dom";
import DefaultModal from "../../../component/DefaultModal/DefaultModal";
import CountTableModal from "./CountTableModal";
import ViewDetailsModal from "./ViewDetailsModal";
import { useDispatch, useSelector } from "react-redux";
import { getlooseReturnPackagesExport, getorderReturnExport, getorderReturnRead } from "../../../store/user/actions";
import { orderReturnExportState } from "../../../store/user/userSlice";
import ReturnOptionModal from "./ReturnOptionModal";
import Loader from "../../../component/Loader";


const SecondaryTable = () => {

    const [formValues, setFormValue] = useState([]);
    const [current, setCurrent] = useState(1);
    const [showCount, setShowCount] = useState(false);
    const [currentRequest, setCurrentRequest] = useState(0);
    const [currentId, setCurrentId] = useState(null); // State to hold the current item's ID
    const [showView, setShowView] = useState(false);
    const [showBox, setShowBox] = useState(false);
    const [tableData, setTableData] = useState([]);
    const [limitData, setLimitData] = useState(10);
    const [totalData, setTotalData] = useState(0);
    const orderReturnRead = useSelector(state => state.orderReturnRead)
    const orderReturnExport = useSelector(state => state.orderReturnExport)
    const dispatch = useDispatch()
    const { id } = useParams();

    useEffect(() => {
        let data = { ...formValues };
        data["page"] = current
        data["limit"] = 10
        dispatch(getorderReturnRead(data))
    }, [])
    console.log(orderReturnRead);
  
    //   console.log(orderReturnRead.data);
    // useEffect(() => {
    //     if (orderReturnRead && orderReturnRead.pagination && orderReturnRead.pagination.current_page 
    //         && orderReturnRead.pagination.per_page && orderReturnRead.pagination.total) {
    //         // setTotalData(orderReturnRead.pagination.total);
    //         const sno = (orderReturnRead.pagination.current_page - 1) * orderReturnRead.pagination.per_page + 1;
    //         const FakeTableData = orderReturnRead.data.map((item, i) => {
        useEffect(() => {
            setTotalData(orderReturnRead.data.total)
            let sno = (current - 1) * 10 + 1;
            const FakeTableData = orderReturnRead && orderReturnRead?.data.data.map((item, i) => {
                return [
                    <h6>{item.id}</h6>,
                    <div><h6>{item.secondary_scan_type}</h6></div>,
                    <div><h6>{item.return_type}</h6></div>,
                    <div><h6>{item.portal}</h6></div>,
                    <h6>{item.courier}</h6>,
                    <h6>{item.gate_pass_no}</h6>,
                    <h6 className="text_wrap">{item.forward_order_no}</h6>,
                    <h6 className="text_wrap">{item.forward_tracking_no}</h6>,
                    <h6 className="text_wrap">{item.backward_order_no}</h6>,
                    <h6 className="text_wrap">{item.backward_tracking_no}</h6>,
                    <div className="status_msg_t"><h6><b>{item.dispute_raised}</b></h6></div>,
                    <div className="numberCount"><Link onClick={() => { setShowCount(true); setCurrentRequest(item.disputes) }}>{item.unsettle_dispute_count}</Link></div>,
                    <div className="view_but"><Link onClick={() => { setShowView(true);setCurrentId(item.id);}}>View</Link></div>,
                ]
            })
            setTableData(FakeTableData);
        }, [orderReturnRead])
    
    const TemplateTableData = [
        { heading: "Sr.No." },
        { heading: "Secndory Scan Type" },
        { heading: "Return Type" },
        { heading: "Portal" },
        { heading: "Courier" },
        { heading: "Gate Pass No." },
        { heading: "Forward Order No." },
        { heading: "Forward Tracking No." },
        { heading: "Backward Order No." },
        { heading: "Backward Tracking No." },
        { heading: "Dispute Raised" },
        { heading: "Unsettle Dispute Count" },
        { heading: "Action" },
    ];
    const handlePageChange = (page,limit) => {
        setCurrent(page);
        setLimitData(limit)
        dispatch(getorderReturnRead({ page, limit: limit }))
    };
    const downloadUrl = () => {
        dispatch(getorderReturnExport({ page: current, limit: limitData, loose_return_bag_id: id, filter:"all"}))
    }
    useEffect(() => {
        if (orderReturnExport.isSuccess) {
            window.open(orderReturnExport.data.download_url)
            dispatch(orderReturnExportState())
        }
    }, [orderReturnExport])
    
console.log("currentId:", currentId);
console.log(orderReturnRead);
    return (
        <div>
            <div className='heading_select'>
                <h1>Secondary Scan </h1>
                <div className="primary_but">
                    <button onClick={() => downloadUrl()} rel="noopener noreferrer"><img src="/Images/exportFile.svg" />Export File</button>
                    <Link onClick={() => setShowBox(true)}><img src="/Images/scan.svg" />Add Secondary Scan</Link>
                </div>
            </div>
            <div className='white_box'>
                <div className="position-relative">
                    {
                   orderReturnRead.isLoading && <div className="loader_bg"><Loader /></div>
                    }
                    <div className='table-bg'>
                        <Table
                            gridTemplateColumnsHeader="5% 6% 9% 6% 6% 10% 10% 10% 7% 7% 7% 8% 9%"
                            gridTemplateColumnsBody="5% 6% 9% 6% 6% 10% 10% 10% 7% 7% 7% 8% 9%"
                            columns={TemplateTableData}
                            data={tableData}
                            Loader={true}
                        />
                        <Pagination
                        current={current}
                        onChange={handlePageChange}
                        total={orderReturnRead.data.pagination ? orderReturnRead.data.pagination.total : 0}
                        pageSize={limitData}
                        showSizeChanger
                            />
                    </div>

                </div>
            </div>
            <DefaultModal
                show={showCount}
                onHide={() => setShowCount(false)}
                className="secondory-modal"
                style={{ paddingRight: "0px" }}
                centered={false}
                showHeader={false}
                size=""
                showFooter={false}
            >
                <div className="modal-heading">
                    <h4></h4>
                    <div className="close-icon">
                        <img src="/Images/closeBlack.svg" onClick={() => setShowCount(false)} />
                    </div>
                </div>
                <CountTableModal
                    requestId={currentRequest}
                />

            </DefaultModal>
            <DefaultModal
                show={showView}
                onHide={() => setShowView(false)}
                className="secondory-modal"
                style={{ paddingRight: "0px" }}
                centered={false}
                showHeader={false}
                size=""
                showFooter={false}
            >
                <div className="modal-heading">
                    <h4></h4>
                    <div className="close-icon">
                        <img src="/Images/closeBlack.svg" onClick={() => setShowView(false)} />
                    </div>
                </div>
                <ViewDetailsModal 
                itemId={currentId}
                />

            </DefaultModal>
            <DefaultModal
                show={showBox}
                onHide={() => setShowBox(false)}
                className="return-modal"
                style={{ paddingRight: "0px" }}
                centered={false}
                showHeader={false}
                size=""
                showFooter={false}
            >
                <div className="modal-heading">
                    <h4>Select
                        Return Option</h4>
                    <div className="close-icon">
                        <img src="/Images/closeBlack.svg" onClick={() => setShowBox(false)} />
                    </div>
                </div>
                <ReturnOptionModal

                />

            </DefaultModal>
        </div>
    )
}

export default SecondaryTable

