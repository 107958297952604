
import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { getclaimMedia } from '../../store/user/actions';

const ClaimMedia = () => {

    const claimMedia = useSelector(state => state.claimMedia)
    const dispatch = useDispatch()
    const { id} = useParams();
    const navigate = useNavigate();
  
    useEffect(() => {
      dispatch(getclaimMedia({claim_id:id}))
    }, [])
    const handleGoBack = () => {
        // Navigate back to the previous item
        navigate(-1);
      };
    
  return (
    <div>
      <div className='heading_select'>
              <div className="d-flex align-items-baseline" onClick={handleGoBack}>
                  <img style={{ cursor: "pointer" }} src="/Images/arrow-left.svg" />
                  <h1>Media</h1>
              </div>
          </div>
          <div className='communication-box claim_media'>
            <h1>POD Media</h1>
            {claimMedia.isLoading && <p>Loading...</p>}
          {claimMedia.data.POD_media?.images && claimMedia.data.POD_media.images.map((item) => (
            <img key={item.id} src={item.media_url} alt={`img-${item.id}`} />
          ))}
            
          </div>
          <div className='communication-box claim_media'>
            <h1>POD Box-wise Media</h1>
            {claimMedia.isLoading && <p>Loading...</p>}
          {claimMedia.data.POD_media?.images && claimMedia.data.POD_media.images.map((item) => (
            <img key={item.id} src={item.media_url} alt={`img-${item.id}`} />
          ))}
            
          </div>
          <div className='communication-box claim_media'>
            <h1>Secondary Scan Media</h1>
            {claimMedia.isLoading && <p>Loading...</p>}
            {claimMedia.data?.secondary_media?.video_url && (
                        <video controls>
                           <source src={claimMedia.data.secondary_media.video_url} type="video/webm"/>
            <source src={claimMedia.data.secondary_media.video_url} type="video/mp4"/>
                        </video>
                    )}
          </div>
    </div>
  )
}

export default ClaimMedia
