import React from 'react'
import BoxTable from './component/BoxTable'

const BoxSummary = () => {
  return (
    <div>
      <BoxTable/>
    </div>
  )
}

export default BoxSummary
