import React, { useEffect, useState } from 'react'
import { getclaimEmailTemplateDropdown, getclaimEmailTemplateRead } from '../../../store/user/actions'
import { useDispatch, useSelector } from 'react-redux';
import Table from '../../../component/Table/Table';
import { Pagination } from 'antd';
import { Link, useParams } from 'react-router-dom';
import Loader from '../../../component/Loader';
import EmailFilter from './EmailFilter';
import EditMail from './EditMail';
import DefaultModal from '../../../component/DefaultModal/DefaultModal';

const EmailTable = () => {
    const initialValues = {
      //  "claim_reason_id": "",
      //  "party_id": ""

    }
    const [formValues, setFormValue] = useState(initialValues);
    const [selectedItemId, setSelectedItemId] = useState(null);
    const [current, setCurrent] = useState(1);
    const [tableData, setTableData] = useState([]);
    const [show, setShow] = useState(false);
    const [limitData, setLimitData] = useState(10);
    const [totalData, setTotalData] = useState(0);
    const claimEmailTemplateRead = useSelector(state => state.claimEmailTemplateRead)
    const dispatch = useDispatch()
     const { id } = useParams();
     const [selectBox, setSelectBox] = useState(true);
     const filterValue = (selectValue) => {
         setSelectBox({...selectValue})
     }
    //  useEffect(() => {
    //     let data = { ...formValues };
    //     data["claim_reason_id"] = id;
    //     data["party_id"] = id;
    //     data["page"] = current
    //     data["limit"] = 10
    //     dispatch(getclaimEmailTemplateRead(data,selectBox))
    //   }, [id,selectBox])
  
 
      useEffect(() => {
        setCurrent(1);
        let data = {
          ...formValues,
          claim_reason_id: id,
          party_id: id,
          page: current,
          limit: 10,
          ...selectBox, // Spread the selectBox object to preserve filters
        };
        dispatch(getclaimEmailTemplateRead(data));
      }, [id, current, selectBox, formValues]);
    
   
      const handleEdit = (id) => {
        console.log(id);
        setSelectedItemId(id); // Set the selected item ID when Edit button is clicked
        setShow(true); // Open the modal
      };
      console.log(id);
    useEffect(() => {
        setTotalData(claimEmailTemplateRead.data.total)
        let sno = (current - 1) * 10 + 1;
        const FakeTableData = claimEmailTemplateRead && claimEmailTemplateRead.data.data.map((item, i) => {
          return [
            <>
              <h6>{sno + i}</h6>
            </>,
    
            <h6>{item.email_template_id}</h6>
            ,
            <div>
              <h6>{item.email_subject}</h6>
            </div>,
            <>
    
              <h6>{item.portal_name}</h6>
            </>,
            <>
              <h6>{item.claim_reason}</h6>
            </>,
            //  <div className="view_but_blue">
            //  <Link to="" onClick={() => {setShow(true); viewPicture(item.id);}}>View</Link>
            //  </div>,
            <div className="view_edit">
              <Link onClick={() => { handleEdit(item.email_template_id)}}className="edit_bt">Edit</Link>
            </div>,
    
          ]
        })
        setTableData(FakeTableData);
      }, [claimEmailTemplateRead])
    
      const TemplateTableData = [
    
        {
          heading: "Sr.No.",
        },
        {
          heading: "Email Template Id",
        },
        {
          heading: "Email Subject",
        },
        {
          heading: "Portal Name",
        },
        {
          heading: "Claim Type",
        },
        {
          heading: "Actions",
        },
    
      ];
    
      const handlePageChange = (page,limit) => {
        setCurrent(page);
        setLimitData(limit)
        let data = {
          ...formValues,
          claim_reason_id: id,
          party_id: id,
          page: current,
          limit: 10,
          ...selectBox, // Spread the selectBox object to preserve filters
        };
        dispatch(getclaimEmailTemplateRead(data));
    };
    const onRefreshTable = () => {
      // Dispatch the `getclaimEmailTemplateRead` action with the current page and limit
      dispatch(getclaimEmailTemplateRead({
        page: current,
        limit: limitData,
      }));
    };
  return (
    <div className='white_box'>
        <EmailFilter
         filterValue={filterValue}
        />
       <div className="position-relative">
          {
            claimEmailTemplateRead.isLoading && <div className="loader_bg"><Loader /></div>
          }
          <div className='table-bg'>
            <Table
              gridTemplateColumnsHeader="10% 20% 20% 10% 20% 20%"
              gridTemplateColumnsBody="10% 20% 20% 10% 20% 20%"
              columns={TemplateTableData}
              data={tableData}
              Loader={true}
            />
            <Pagination
              current={current}
              onChange={handlePageChange}
              total={claimEmailTemplateRead.data.pagination ? claimEmailTemplateRead.data.pagination.total : 0}
              pageSize={limitData}
              />
          </div>

        </div>
        <DefaultModal
        show={show}
        onHide={() => setShow(false)}
        className="edit_modal"
        style={{ paddingRight: "0px" }}
        centered={false}
        showHeader={false}
        size=""
        showFooter={false}
      >
        <div className="modal-heading">
          <h4></h4>
          <div className="close-icon">
            <img src="/Images/closeBlack.svg" onClick={() => setShow(false)} />
          </div>
        </div>
        <EditMail 
        itemId={selectedItemId} 
        // onHide={() => setShow(false)}
        mClose={setShow}
        onRefreshTable={onRefreshTable}
        />

      </DefaultModal>
    </div>
  )
}

export default EmailTable
