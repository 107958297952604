

import React, { useState } from 'react';
import { QrReader } from 'react-qr-reader';


const Scanner = () => {
  const [result, setResult] = useState('');

  const handleScan = (data) => {
    if (data) {
      setResult(data);
    }
  }

  const handleError = (err) => {
    console.error(err);
  }

  return (
    <div className='position-relative'>
      <QrReader 
        delay={300}
        onError={handleError}
        onScan={handleScan}
        style={{ width: '100%' }}
      />
    </div>
  );
}

export default Scanner;
