import { createSlice } from "@reduxjs/toolkit";
// import { getUserList } from "./actions"
import {
    getajaxGetOrderDetailBySerialCode,
    getajaxGetSerialCodesByOrderNo,
    getbaglimit,
    getboxDashboardWebReturnCounts,
    getboxDashboardWebReturnInventory,
    getboxDashboardWebReturnInventoryProcessWise,
    getclaimChatMessage,
    getclaimCloseTicket,
    getclaimCounterMatric,
    getclaimCreate,
    getclaimEmailTemplateCreate,
    getclaimEmailTemplateDropdown,
    getclaimEmailTemplateEdit,
    getclaimEmailTemplateRead,
    getclaimEmailTemplateUpdate,
    getclaimExport,
    getclaimListing,
    getclaimMedia,
    getclaimReasonCreate,
    getclaimReasonDelete,
    getclaimReasonRead,
    getclaimRemark,
    getclaimStatusChange,
    getclaimStatusDropdown,
    getcloseBagDropdown,
    getcloseBagUpdate,
    getcloseStatusUpdate,
    getcounterMetrics,
    getcourierOption,
    getcourierReturnEmailSettingCreate,
    getcourierReturnEmailSettingRead,
    getdashboardCard,
    getforwardBackward,
    getgateEntryPassOption,
    getgatekeeperNameOption,
    getgraphClaimAccept,
    getgraphClaimAcceptAmount,
    getgraphLostAmountScanDelay,
    getgraphPortalWise,
    getgraphPortalwiseAmount,
    getlooseReturnBagsExport,
    getlooseReturnBagsStoreAll,
    getlooseReturnExport,
    getlooseReturnPackages,
    getlooseReturnPackagesExport,
    getlooseReturnPackagesList,
    getmeasurementUnitRead,
    getmrpCreationCreate,
    getmrpCreationCreateBatchBundle,
    getmrpCreationDropdowns,
    getmrpCreationUpdateLog,
    getnotRespondingByPortal,
    getnotRespondingByUs,
    getontimeSecondaryPacage,
    getorderDetailsBySerialCode,
    getorderItemCreate,
    getorderItemRead,
    getorderReturnBoxCreate,
    getorderReturnCreate,
    getorderReturnDetails,
    getorderReturnDropdown,
    getorderReturnExport,
    getorderReturnRead,
    getpartyOption,
    getpodCounter,
    getprimaryScanBagList,
    getprimaryScanForm,
    getprimaryScanList,
    getrepackingCreate,
    getrepackingDropdowns,
    getrepackingExport,
    getrepackingRead,
    getreturnContainerBoxSummary,
    getreturnContainerExport,
    getreturnContainerRead,
    getreturnInventory,
    getreturnInventoryProcessWise,
    getreturnedContainerBoxCreate,
    getreturnedContainerBoxDeletePicturesDocuments,
    getreturnedContainerBoxRead,
    getreturnedContainerBoxSummaryBbscDetail,
    getreturnedContainerBoxSummaryBbscDetailExport,
    getreturnedContainerBoxSummaryDropdown,
    getreturnedContainerBoxSummaryExport,
    getreturnedContainerBoxSummaryItemDetail,
    getreturnedContainerBoxSummaryItemDetailExport,
    getreturnedContainerBoxSummaryPictures,
    getreturnedContainerBoxUpdate,
    getreturnedContainerCreate,
    getreturnedContainerDropdowns,
    getreturnedContainerUpdate,
    getsecondaryCounterMatric,
    getsendClaimMessage,
    getserialCodebyOrderNo,
    getserialCodesTrackingNo,
    getsetClaimRemark,
    getsetbaglimit,
    getsuffixstrings,
    getsuffixstringsStoreAll,
    gettatSettings,
    gettatSettingsStoreAll,
    getticketCloseDropdown,
    getticketOpenDropdown,
    gettokenNumberOption,
    getturnAroundTimePortalWise,
    getturnAroundTimeWise,
    getuploadCount,
} from "../user/actions"

const initialState = {
    data: [],
    isLoading: false,
    isSuccess: false,
    errorMessage: ''
}

const loaders = createSlice({
    name: 'loader',
    initialState: {
        isLoading: true,
    },
    reducers: {
        setLoading: (state, action) => {
            state.isLoading = action.payload;
        },
    },
})
// .reducer
export const { setLoading } = loaders.actions;
export const { loaderSlice } = loaders.reducer;

export const cardSlice = createSlice({
    name: 'dashboardCard',
    initialState: {
        isLoading: false,
        isSuccess: false,
        errorMessage: '',
        data: {
            "totalCompletedCollection": 0,
            "totalPandingCollection": 0,
            "totalSampleRequestTime": 0,
            "totalPartiallyPandingCollection": 0,
            "totalPandingTask": 0,
            "totalDelayTask": 0,
            "totalCompletedTask": 0,
            "totalTask": 0,
            "totalReward": 0,
            "totalStrike": 0,
            "totalIncentive": 0,
            "totalRejections": 0,
            "totalCollection": 0,
            "totalSampleRequest": 0,
            "totalCompleteSampleRequest": 0,
            "totalPandingSampleRequest": 0,
            "totalDetalySampleRequest": 0
        }
    },
    extraReducers: {
        [getdashboardCard.pending]: (state) => {
            state.isLoading = true;
        },
        [getdashboardCard.fulfilled]: (state, { payload }) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.data = payload;
        },
        [getdashboardCard.rejected]: (state, { payload }) => {
            state.isLoading = false;
            state.isSuccess = false;
            state.errorMessage = payload
        }
    }
})
    .reducer;
//setting
export const baglimitSlice = createSlice({
    name: 'baglimit',
    initialState: {
        isLoading: false,
        isSuccess: false,
        errorMessage: '',
        data: {
            "Setting": {
                "id": 0,
                "name": "",
                "value": ""
            }

        }
    },
    extraReducers: {
        [getbaglimit.pending]: (state) => {
            state.isLoading = true;
        },
        [getbaglimit.fulfilled]: (state, { payload }) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.data = payload;
        },
        [getbaglimit.rejected]: (state, { payload }) => {
            state.isLoading = false;
            state.isSuccess = false;
            state.errorMessage = payload
        }
    }
})
    .reducer;
export const setbaglimitSlice = createSlice({
    name: 'setbaglimit',
    initialState: {
        isLoading: false,
        isSuccess: false,
        errorMessage: '',
        data: {
            "Setting": {
                "id": 0,
                "name": "",
                "value": ""
            }

        }
    },
    extraReducers: {
        [getsetbaglimit.pending]: (state) => {
            state.isLoading = true;
        },
        [getsetbaglimit.fulfilled]: (state, { payload }) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.data = payload;
        },
        [getsetbaglimit.rejected]: (state, { payload }) => {
            state.isLoading = false;
            state.isSuccess = false;
            state.errorMessage = payload
        }
    }
})
    .reducer;
export const suffixstringsSlice = createSlice({
    name: 'suffixstrings',
    initialState: {
        isLoading: false,
        isSuccess: false,
        errorMessage: '',
        "data": {
            "Couriers": [
                // {
                //     "name": "",
                //     "id": 0,
                //     "parties": [
                //         {
                //             "name": "",
                //             "id": 0,
                //             "suffix_strings": [
                //                 {
                //                     "id": 0,
                //                     "carrier_id": "",
                //                     "party_id": "",
                //                     "name": ""
                //                 },
                //             ]
                //         },



                //     ]
                // },

            ]
        }

    },
    extraReducers: {
        [getsuffixstrings.pending]: (state) => {
            state.isLoading = true;
        },
        [getsuffixstrings.fulfilled]: (state, { payload }) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.data = payload;
        },
        [getsuffixstrings.rejected]: (state, { payload }) => {
            state.isLoading = false;
            state.isSuccess = false;
            state.errorMessage = payload
        }
    }
})
    .reducer;

export const suffixstringsStoreAllSlice = createSlice({
    name: 'suffixstringsStoreAll',
    initialState: {
        isLoading: false,
        isSuccess: false,
        errorMessage: '',
        data: {
            data:[]
        }
    },
    extraReducers: {
        [getsuffixstringsStoreAll.pending]: (state) => {
            state.isLoading = true;
        },
        [getsuffixstringsStoreAll.fulfilled]: (state, { payload }) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.data = payload;
        },
        [getsuffixstringsStoreAll.rejected]: (state, { payload }) => {
            state.isLoading = false;
            state.isSuccess = false;
            state.errorMessage = payload
        }
    }
})
    .reducer;
export const tatSettingsSlice = createSlice({
    name: 'tatSettings',
    initialState: {
        isLoading: false,
        isSuccess: false,
        errorMessage: '',
        data: {
            LooseParties: [],
            boxParties: [],

        }
    },
    extraReducers: {
        [gettatSettings.pending]: (state) => {
            state.isLoading = true;
        },
        [gettatSettings.fulfilled]: (state, { payload }) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.data = payload;
        },
        [gettatSettings.rejected]: (state, { payload }) => {
            state.isLoading = false;
            state.isSuccess = false;
            state.errorMessage = payload
        }
    }
})
    .reducer;
export const tatSettingsStoreAllSlice = createSlice({
    name: 'tatSettingsStoreAll',
    initialState: {
        isLoading: false,
        isSuccess: false,
        errorMessage: '',
        data: {
        }
    },
    extraReducers: {
        [gettatSettingsStoreAll.pending]: (state) => {
            state.isLoading = true;
        },
        [gettatSettingsStoreAll.fulfilled]: (state, { payload }) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.data = payload;
        },
        [gettatSettingsStoreAll.rejected]: (state, { payload }) => {
            state.isLoading = false;
            state.isSuccess = false;
            state.errorMessage = payload
        }
    }
})
    .reducer;
    const closeBagDropdownSlices = createSlice({
        name: 'closeBagDropdown',
        initialState: {
            isLoading: false,
            isSuccess: false,
            errorMessage: '',
            data: []
        },
        reducers: {
            resetClodeDataState: (state) => {
                return {
                    ...state,
                    isLoading: false,
                    isSuccess: false,
                    errorMessage: '',
                    data: {
                    }
                };
            },
        },
        extraReducers: {
            [getcloseBagDropdown.pending]: (state) => {
                state.isLoading = true;
            },
            [getcloseBagDropdown.fulfilled]: (state, { payload }) => {
                state.isLoading = false;
                state.isSuccess = true;
                state.data = payload;
            },
            [getcloseBagDropdown.rejected]: (state, { payload }) => {
                state.isLoading = false;
                state.isSuccess = false;
                state.errorMessage = payload
            }
        }
    })
    export const { resetClodeDataState } = closeBagDropdownSlices.actions;
    export const closeBagDropdownSlice = closeBagDropdownSlices.reducer;
    const closeBagUpdateSlices = createSlice({
        name: 'closeBagUpdate',
        initialState: {
            isLoading: false,
            isSuccess: false,
            errorMessage: '',
            data: {}
        },
        reducers: {
            resetcloseBagUpdateState: (state) => {
                return {
                    ...state,
                    isLoading: false,
                    isSuccess: false,
                    errorMessage: '',
                    data: {
                    },
                    
                };
            },
        },
        extraReducers: {
            [getcloseBagUpdate.pending]: (state) => {
                state.isLoading = true;
            },
            [getcloseBagUpdate.fulfilled]: (state, { payload }) => {
                state.isLoading = false;
                state.isSuccess = true;
                state.data = payload;
            },
            [getcloseBagUpdate.rejected]: (state, { payload }) => {
                state.isLoading = false;
                state.isSuccess = false;
                state.errorMessage = payload
            }
        }
    })
    export const { resetcloseBagUpdateState } = closeBagUpdateSlices.actions;
export const closeBagUpdateSlice = closeBagUpdateSlices.reducer;

    export const turnAroundTimePortalWiseSlice = createSlice({
        name: 'turnAroundTimePortalWise',
        initialState: {
            isLoading: false,
            isSuccess: false,
            errorMessage: '',
            // data: []
            data: {
                data: {
                    data:[]
                },
                total: 0,
                list: [], total_request: 0
            },    
        },
        extraReducers: {
            [getturnAroundTimePortalWise.pending]: (state) => {
                state.isLoading = true;
            },
            [getturnAroundTimePortalWise.fulfilled]: (state, { payload }) => {
                state.isLoading = false;
                state.isSuccess = true;
                state.data = payload;
            },
            [getturnAroundTimePortalWise.rejected]: (state, { payload }) => {
                state.isLoading = false;
                state.isSuccess = false;
                state.errorMessage = payload
            }
        }
    })
    .reducer;
    export const turnAroundTimeWiseSlice = createSlice({
        name: 'turnAroundTimeWise',
        initialState: {
            isLoading: false,
            isSuccess: false,
            errorMessage: '',
            data: {}
        },
        extraReducers: {
            [getturnAroundTimeWise.pending]: (state) => {
                state.isLoading = true;
            },
            [getturnAroundTimeWise.fulfilled]: (state, { payload }) => {
                state.isLoading = false;
                state.isSuccess = true;
                state.data = payload;
            },
            [getturnAroundTimeWise.rejected]: (state, { payload }) => {
                state.isLoading = false;
                state.isSuccess = false;
                state.errorMessage = payload
            }
        }
    })
    .reducer;

export const courierReturnEmailSettingCreateSlice = createSlice({
    name: 'courierReturnEmailSettingCreate',
    initialState: {
        isLoading: false,
        isSuccess: false,
        errorMessage: '',
        data: [
            {
                "email_setting_id": null,
                "party_id": "",
                "username": "",
                "email": "",
                "server_ip": "",
                "password": ""

            }
        ]
    },
    extraReducers: {
        [getcourierReturnEmailSettingCreate.pending]: (state) => {
            state.isLoading = true;
        },
        [getcourierReturnEmailSettingCreate.fulfilled]: (state, { payload }) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.data = payload;
        },
        [getcourierReturnEmailSettingCreate.rejected]: (state, { payload }) => {
            state.isLoading = false;
            state.isSuccess = false;
            state.errorMessage = payload
        }
    }
})
    .reducer;
export const courierReturnEmailSettingReadSlice = createSlice({
    name: 'courierReturnEmailSettingRead',
    initialState: {
        isLoading: false,
        isSuccess: false,
        errorMessage: '',
        data: []
    },
    extraReducers: {
        [getcourierReturnEmailSettingRead.pending]: (state) => {
            state.isLoading = true;
        },
        [getcourierReturnEmailSettingRead.fulfilled]: (state, { payload }) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.data = payload;
        },
        [getcourierReturnEmailSettingRead.rejected]: (state, { payload }) => {
            state.isLoading = false;
            state.isSuccess = false;
            state.errorMessage = payload
        }
    }
})
    .reducer;
//loose-return-start
export const primaryScanListSlice = createSlice({
    name: 'primaryScanList',
    initialState: {
        isLoading: false,
        isSuccess: false,
        errorMessage: '',
        data: {
            data: {
                data:[]
            },
            total: 0,
            list: [], total_request: 0
        },    
    },
    extraReducers: {
        [getprimaryScanList.pending]: (state) => {
            state.isLoading = true;
        },
        [getprimaryScanList.fulfilled]: (state, { payload }) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.data = payload;
        },
        [getprimaryScanList.rejected]: (state, { payload }) => {
            state.isLoading = false;
            state.isSuccess = false;
            state.errorMessage = payload
        }
    }
})
    .reducer;
export const primaryScanBagListSlice = createSlice({
    name: 'primaryScanBagList',
    initialState: {
        isLoading: false,
        isSuccess: false,
        errorMessage: '',
        data: {
            data: {
                data:[]
            },
            total: 0,
            list: [], total_request: 0
        },    
        // pagination:{
        //     current_page: 0, per_page: 0, total: 0, last_page: 0
        // }
        
    },
    extraReducers: {
        [getprimaryScanBagList.pending]: (state) => {
            state.isLoading = true;
        },
        [getprimaryScanBagList.fulfilled]: (state, { payload }) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.data = payload;
        },
        [getprimaryScanBagList.rejected]: (state, { payload }) => {
            state.isLoading = false;
            state.isSuccess = false;
            state.errorMessage = payload
        }
    }
})
    .reducer;
const primaryScanFormSlices = createSlice({
    name: 'primaryScanForm',
    initialState: {
        isLoading: false,
        isSuccess: false,
        errorMessage: '',
        data: {}
    },
    reducers: {
        resetFormDataState: (state) => {
            return {
                ...state,
                isLoading: false,
                isSuccess: false,
                errorMessage: '',
                data: {
                }
            };
        },
    },
    extraReducers: {
        [getprimaryScanForm.pending]: (state) => {
            state.isLoading = true;
        },
        [getprimaryScanForm.fulfilled]: (state, { payload }) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.data = payload;
        },
        [getprimaryScanForm.rejected]: (state, { payload }) => {
            state.isLoading = false;
            state.isSuccess = false;
            state.errorMessage = payload
        }
    }
})
export const { resetFormDataState } = primaryScanFormSlices.actions;
export const primaryScanFormSlice = primaryScanFormSlices.reducer;
export const closeStatusUpdateSlice = createSlice({
    name: 'closeStatusUpdate',
    initialState: {
        isLoading: false,
        isSuccess: false,
        errorMessage: '',
        data: {}
    },
    extraReducers: {
        [getcloseStatusUpdate.pending]: (state) => {
            state.isLoading = true;
        },
        [getcloseStatusUpdate.fulfilled]: (state, { payload }) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.data = payload;
        },
        [getcloseStatusUpdate.rejected]: (state, { payload }) => {
            state.isLoading = false;
            state.isSuccess = false;
            state.errorMessage = payload
        }
    }
})
    .reducer;
export const looseReturnBagsStoreAllSlice = createSlice({
    name: 'looseReturnBagsStoreAll',
    initialState: {
        isLoading: false,
        isSuccess: false,
        errorMessage: '',
        data: []
    },
    extraReducers: {
        [getlooseReturnBagsStoreAll.pending]: (state) => {
            state.isLoading = true;
        },
        [getlooseReturnBagsStoreAll.fulfilled]: (state, { payload }) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.data = payload;
        },
        [getlooseReturnBagsStoreAll.rejected]: (state, { payload }) => {
            state.isLoading = false;
            state.isSuccess = false;
            state.errorMessage = payload
        }
    }
})
    .reducer;
const looseReturnPackagesSlices = createSlice({
    name: 'looseReturnPackages',
    initialState: {
        isLoading: false,
        isSuccess: false,
        errorMessage: '',
        data: {
            data:{
                error_code: null,
                total_packages: null,
                total_scan: null,
                loose_return_package: {}
            }
           
        }
    },
    reducers: {
        resetlooseReturnPackagesState: (state) => {
            return {
                ...state,
                isLoading: false,
                isSuccess: false,
                errorMessage: '',
                data: {
                },
                
            };
        },
    },
    extraReducers: {
        [getlooseReturnPackages.pending]: (state) => {
            state.isLoading = true;
        },
        [getlooseReturnPackages.fulfilled]: (state, { payload }) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.data = payload;
        },
        [getlooseReturnPackages.rejected]: (state, { payload }) => {
            state.isLoading = false;
            state.isSuccess = false;
            state.errorMessage = payload
        }
    }
})
export const { resetlooseReturnPackagesState } = looseReturnPackagesSlices.actions;
export const looseReturnPackagesSlice = looseReturnPackagesSlices.reducer;
// end//
 export const looseReturnPackagesListSlice = createSlice({
    name: 'looseReturnPackagesList',
    initialState: {
        isLoading: false,
        isSuccess: false,
        errorMessage: '',   
        data: {
            data: {
                data:[]
            },
            total: 0,
            list: [], total_request: 0
        },    
        pagination:{
            current_page: 0, per_page: 0, total: 0, last_page: 0
        }
    },
    extraReducers: {
        [getlooseReturnPackagesList.pending]: (state) => {
            state.isLoading = true;
        },
        [getlooseReturnPackagesList.fulfilled]: (state, { payload }) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.data = payload;
        },
        [getlooseReturnPackagesList.rejected]: (state, { payload }) => {
            state.isLoading = false;
            state.isSuccess = false;
            state.errorMessage = payload
        }
    }
})
.reducer;
const looseReturnPackagesExportSlices = createSlice({
    name: 'looseReturnPackagesExport',
    initialState: {
        isLoading: false,
        isSuccess: false,
        errorMessage: '',
        data: {}
    },
    reducers: {
        resetPackagesDataState: (state) => {
            return {
                ...state,
                isLoading: false,
                isSuccess: false,
                errorMessage: '',
                data: {
                }
            };
        },
    },
    extraReducers: {
        [getlooseReturnPackagesExport.pending]: (state) => {
            state.isLoading = true;
        },
        [getlooseReturnPackagesExport.fulfilled]: (state, { payload }) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.data = payload;
        },
        [getlooseReturnPackagesExport.rejected]: (state, { payload }) => {
            state.isLoading = false;
            state.isSuccess = false;
            state.errorMessage = payload
        }
    }
})
export const { resetPackagesDataState } = looseReturnPackagesExportSlices.actions;
export const looseReturnPackagesExportSlice = looseReturnPackagesExportSlices.reducer;


const looseReturnExportSlices = createSlice({
    name: 'looseReturnExport',
    initialState: {
        isLoading: false,
        isSuccess: false,
        errorMessage: '',
        data: {}
    },
    reducers: {
        resetReturnDataState: (state) => {
            return {
                ...state,
                isLoading: false,
                isSuccess: false,
                errorMessage: '',
                data: {
                }
            };
        },
    },
    extraReducers: {
        [getlooseReturnExport.pending]: (state) => {
            state.isLoading = true;
        },
        [getlooseReturnExport.fulfilled]: (state, { payload }) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.data = payload;
        },
        [getlooseReturnExport.rejected]: (state, { payload }) => {
            state.isLoading = false;
            state.isSuccess = false;
            state.errorMessage = payload
        }
    }
})
export const { resetReturnDataState } = looseReturnExportSlices.actions;
export const looseReturnExportSlice = looseReturnExportSlices.reducer;
const looseReturnBagsExportSlices = createSlice({
    name: 'looseReturnBagsExport',
    initialState: {
        isLoading: false,
        isSuccess: false,
        errorMessage: '',
        data: {
            data: []
        }
    },
    reducers: {
        resetBagDataState: (state) => {
            return {
                ...state,
                isLoading: false,
                isSuccess: false,
                errorMessage: '',
                data: {
                }
            };
        },
    },
    extraReducers: {
        [getlooseReturnBagsExport.pending]: (state) => {
            state.isLoading = true;
        },
        [getlooseReturnBagsExport.fulfilled]: (state, { payload }) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.data = payload;
        },
        [getlooseReturnBagsExport.rejected]: (state, { payload }) => {
            state.isLoading = false;
            state.isSuccess = false;
            state.errorMessage = payload
        }
    }
})
export const { resetBagDataState } = looseReturnBagsExportSlices.actions;
export const looseReturnBagsExportSlice = looseReturnBagsExportSlices.reducer;

const returnContainerExportSlices = createSlice({
    name: 'returnContainerExport',
    initialState: {
        isLoading: false,
        isSuccess: false,
        errorMessage: '',
        data: {
            data: []
        }
    },
    reducers: {
        resetreturnContainerExportState: (state) => {
            return {
                ...state,
                isLoading: false,
                isSuccess: false,
                errorMessage: '',
                data: {
                }
            };
        },
    },
    extraReducers: {
        [getreturnContainerExport.pending]: (state) => {
            state.isLoading = true;
        },
        [getreturnContainerExport.fulfilled]: (state, { payload }) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.data = payload;
        },
        [getreturnContainerExport.rejected]: (state, { payload }) => {
            state.isLoading = false;
            state.isSuccess = false;
            state.errorMessage = payload
        }
    }
})
export const { resetreturnContainerExportState } = returnContainerExportSlices.actions;
export const returnContainerExportSlice = returnContainerExportSlices.reducer;


 const uploadCountSlices = createSlice({
    name: 'uploadCount',
    initialState: {
        isLoading: false,
        isSuccess: false,
        errorMessage: '',
        data: {}
    },
    reducers: {
        resetUpdateCountState: (state) => {
            return {
                ...state,
                isLoading: false,
                isSuccess: false,
                errorMessage: '',
                data: {
                    data: {}
                }
            };
        },
    },
    extraReducers: {
        [getuploadCount.pending]: (state) => {
            state.isLoading = true;
        },
        [getuploadCount.fulfilled]: (state, { payload }) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.data = payload;
        },
        [getuploadCount.rejected]: (state, { payload }) => {
            state.isLoading = false;
            state.isSuccess = false;
            state.errorMessage = payload
        }
    }
})
  export const { resetUpdateCountState } = uploadCountSlices.actions;
export const uploadCountSlice = uploadCountSlices.reducer;
//end

export const courierOptionSlice = createSlice({
    name: 'courierOption',
    initialState: {
        isLoading: false,
        isSuccess: false,
        errorMessage: '',
        data: {}
    },
    extraReducers: {
        [getcourierOption.pending]: (state) => {
            state.isLoading = true;
        },
        [getcourierOption.fulfilled]: (state, { payload }) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.data = payload;
        },
        [getcourierOption.rejected]: (state, { payload }) => {
            state.isLoading = false;
            state.isSuccess = false;
            state.errorMessage = payload
        }
    }
})
    .reducer;

export const partyOptionSlice = createSlice({
    name: 'partyOption',
    initialState: {
        isLoading: false,
        isSuccess: false,
        errorMessage: '',
        data: []
    },
    extraReducers: {
        [getpartyOption.pending]: (state) => {
            state.isLoading = true;
        },
        [getpartyOption.fulfilled]: (state, { payload }) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.data = payload;
        },
        [getpartyOption.rejected]: (state, { payload }) => {
            state.isLoading = false;
            state.isSuccess = false;
            state.errorMessage = payload
        }
    }
})
    .reducer;
export const gatekeeperNameOptionSlice = createSlice({
    name: 'gatekeeperNameOption',
    initialState: {
        isLoading: false,
        isSuccess: false,
        errorMessage: '',
        data: []
    },
    extraReducers: {
        [getgatekeeperNameOption.pending]: (state) => {
            state.isLoading = true;
        },
        [getgatekeeperNameOption.fulfilled]: (state, { payload }) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.data = payload;
        },
        [getgatekeeperNameOption.rejected]: (state, { payload }) => {
            state.isLoading = false;
            state.isSuccess = false;
            state.errorMessage = payload
        }
    }
})
    .reducer;
export const tokenNumberOptionSlice = createSlice({
    name: 'tokenNumberOption',
    initialState: {
        isLoading: false,
        isSuccess: false,
        errorMessage: '',
        data: []
    },
    extraReducers: {
        [gettokenNumberOption.pending]: (state) => {
            state.isLoading = true;
        },
        [gettokenNumberOption.fulfilled]: (state, { payload }) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.data = payload;
        },
        [gettokenNumberOption.rejected]: (state, { payload }) => {
            state.isLoading = false;
            state.isSuccess = false;
            state.errorMessage = payload
        }
    }
})
    .reducer;
export const gateEntryPassOptionSlice = createSlice({
    name: 'gateEntryPassOption',
    initialState: {
        isLoading: false,
        isSuccess: false,
        errorMessage: '',
        data: {}
    },
    extraReducers: {
        [getgateEntryPassOption.pending]: (state) => {
            state.isLoading = true;
        },
        [getgateEntryPassOption.fulfilled]: (state, { payload }) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.data = payload;
        },
        [getgateEntryPassOption.rejected]: (state, { payload }) => {
            state.isLoading = false;
            state.isSuccess = false;
            state.errorMessage = payload
        }
    }
})
    .reducer;

export const returnedContainerDropdownsSlice = createSlice({
    name: 'returnedContainerDropdowns',
    initialState: {
        isLoading: false,
        isSuccess: false,
        errorMessage: '',
        data: {
            gate_entry_numbers: [],
            courier_names: [],
            portal_names: [],
            gate_keeper_names: [],
            token_numbers: [],
        }
    },
    extraReducers: {
        [getreturnedContainerDropdowns.pending]: (state) => {
            state.isLoading = true;
        },
        [getreturnedContainerDropdowns.fulfilled]: (state, { payload }) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.data = payload;
        },
        [getreturnedContainerDropdowns.rejected]: (state, { payload }) => {
            state.isLoading = false;
            state.isSuccess = false;
            state.errorMessage = payload
        }
    }
})
    .reducer;
export const returnContainerReadSlice = createSlice({
    name: 'returnContainerRead',
    initialState: {
        isLoading: false,
        isSuccess: false,
        errorMessage: '',
        data:{
            data: [],    
            pagination:{
                current_page: 0, per_page: 0, total: 0, last_page: 0
            }
        }
    },
    extraReducers: {
        [getreturnContainerRead.pending]: (state) => {
            state.isLoading = true;
        },
        [getreturnContainerRead.fulfilled]: (state, { payload }) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.data = payload;
        },
        [getreturnContainerRead.rejected]: (state, { payload }) => {
            state.isLoading = false;
            state.isSuccess = false;
            state.errorMessage = payload
        }
    }
})
    .reducer;
    export const ontimeSecondaryPacageSlice = createSlice({
        name: 'ontimeSecondaryPacage',
        initialState: {
            isLoading: false,
            isSuccess: false,
            errorMessage: '',
            data:[]
        },
        extraReducers: {
            [getontimeSecondaryPacage.pending]: (state) => {
                state.isLoading = true;
            },
            [getontimeSecondaryPacage.fulfilled]: (state, { payload }) => {
                state.isLoading = false;
                state.isSuccess = true;
                state.data = payload;
            },
            [getontimeSecondaryPacage.rejected]: (state, { payload }) => {
                state.isLoading = false;
                state.isSuccess = false;
                state.errorMessage = payload
            }
        }
    })
        .reducer;
        export const returnInventorySlice = createSlice({
            name: 'returnInventory',
            initialState: {
                isLoading: false,
                isSuccess: false,
                errorMessage: '',
                data:{}
            },
            extraReducers: {
                [getreturnInventory.pending]: (state) => {
                    state.isLoading = true;
                },
                [getreturnInventory.fulfilled]: (state, { payload }) => {
                    state.isLoading = false;
                    state.isSuccess = true;
                    state.data = payload;
                },
                [getreturnInventory.rejected]: (state, { payload }) => {
                    state.isLoading = false;
                    state.isSuccess = false;
                    state.errorMessage = payload
                }
            }
        })
            .reducer;
            export const returnInventoryProcessWiseSlice = createSlice({
                name: 'returnInventoryProcessWise',
                initialState: {
                    isLoading: false,
                    isSuccess: false,
                    errorMessage: '',
                    data:[]
                },
                extraReducers: {
                    [getreturnInventoryProcessWise.pending]: (state) => {
                        state.isLoading = true;
                    },
                    [getreturnInventoryProcessWise.fulfilled]: (state, { payload }) => {
                        state.isLoading = false;
                        state.isSuccess = true;
                        state.data = payload;
                    },
                    [getreturnInventoryProcessWise.rejected]: (state, { payload }) => {
                        state.isLoading = false;
                        state.isSuccess = false;
                        state.errorMessage = payload
                    }
                }
            })
                .reducer;

export const returnedContainerCreateSlice = createSlice({
    name: 'returnedContainerCreate',
    initialState: {
        isLoading: false,
        isSuccess: false,
        errorMessage: '',
        data: {}
    },
    extraReducers: {
        [getreturnedContainerCreate.pending]: (state) => {
            state.isLoading = true;
        },
        [getreturnedContainerCreate.fulfilled]: (state, { payload }) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.data = payload;
        },
        [getreturnedContainerCreate.rejected]: (state, { payload }) => {
            state.isLoading = false;
            state.isSuccess = false;
            state.errorMessage = payload
        }
    }
})
    .reducer;
export const returnedContainerUpdateSlice = createSlice({
    name: 'returnedContainerUpdate',
    initialState: {
        isLoading: false,
        isSuccess: false,
        errorMessage: '',
        data: {}
    },
    extraReducers: {
        [getreturnedContainerUpdate.pending]: (state) => {
            state.isLoading = true;
        },
        [getreturnedContainerUpdate.fulfilled]: (state, { payload }) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.data = payload;
        },
        [getreturnedContainerUpdate.rejected]: (state, { payload }) => {
            state.isLoading = false;
            state.isSuccess = false;
            state.errorMessage = payload
        }
    }
})
    .reducer;
const returnedContainerBoxCreateSlices = createSlice({
    name: 'returnedContainerBoxCreate',
    initialState: {
        isLoading: false,
        isSuccess: false,
        errorMessage: '',
        data: []
    },
    reducers: {
        resetreturnedContainerBoxCreateState: (state) => {
            return {
                ...state,
                isLoading: false,
                isSuccess: false,
                errorMessage: '',
                data: {
                    data: {}
                }
            };
        },
    },
    extraReducers: {
        [getreturnedContainerBoxCreate.pending]: (state) => {
            state.isLoading = true;
        },
        [getreturnedContainerBoxCreate.fulfilled]: (state, { payload }) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.data = payload;
        },
        [getreturnedContainerBoxCreate.rejected]: (state, { payload }) => {
            state.isLoading = false;
            state.isSuccess = false;
            state.errorMessage = payload
        }
    }
})
export const { resetreturnedContainerBoxCreateState } = returnedContainerBoxCreateSlices.actions;
export const returnedContainerBoxCreateSlice = returnedContainerBoxCreateSlices.reducer;

export const returnedContainerBoxReadSlice = createSlice({
    name: 'returnedContainerBoxRead',
    initialState: {
        isLoading: false,
        isSuccess: false,
        errorMessage: '',
        data: []
    },
    extraReducers: {
        [getreturnedContainerBoxRead.pending]: (state) => {
            state.isLoading = true;
        },
        [getreturnedContainerBoxRead.fulfilled]: (state, { payload }) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.data = payload;
        },
        [getreturnedContainerBoxRead.rejected]: (state, { payload }) => {
            state.isLoading = false;
            state.isSuccess = false;
            state.errorMessage = payload
        }
    }
})
    .reducer;
const returnedContainerBoxUpdateSlices = createSlice({
    name: 'returnedContainerBoxUpdate',
    initialState: {
        isLoading: false,
        isSuccess: false,
        errorMessage: '',
        data: []
    },
    reducers: {
        resetreturnedContainerBoxUpdateState: (state) => {
            return {
                ...state,
                isLoading: false,
                isSuccess: false,
                errorMessage: '',
                data: {
                    data: {}
                }
            };
        },
    },
    extraReducers: {
        [getreturnedContainerBoxUpdate.pending]: (state) => {
            state.isLoading = true;
        },
        [getreturnedContainerBoxUpdate.fulfilled]: (state, { payload }) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.data = payload;
        },
        [getreturnedContainerBoxUpdate.rejected]: (state, { payload }) => {
            state.isLoading = false;
            state.isSuccess = false;
            state.errorMessage = payload
        }
    }
})
export const { resetreturnedContainerBoxUpdateState } = returnedContainerBoxUpdateSlices.actions;
export const returnedContainerBoxUpdateSlice = returnedContainerBoxUpdateSlices.reducer;

    export const returnedContainerBoxDeletePicturesDocumentsSlice = createSlice({
        name: 'returnedContainerBoxDeletePicturesDocuments',
        initialState: {
            isLoading: false,
            isSuccess: false,
            errorMessage: '',
            data: []
        },
        extraReducers: {
            [getreturnedContainerBoxDeletePicturesDocuments.pending]: (state) => {
                state.isLoading = true;
            },
            [getreturnedContainerBoxDeletePicturesDocuments.fulfilled]: (state, { payload }) => {
                state.isLoading = false;
                state.isSuccess = true;
                state.data = payload;
            },
            [getreturnedContainerBoxDeletePicturesDocuments.rejected]: (state, { payload }) => {
                state.isLoading = false;
                state.isSuccess = false;
                state.errorMessage = payload
            }
        }
    })
        .reducer;
// repecking-slice
export const repackingDropdownsSlice = createSlice({
    name: 'repackingDropdowns',
    initialState: {
        isLoading: false,
        isSuccess: false,
        errorMessage: '',
        data: {
            disposition_grades: []
        }
    },
    extraReducers: {
        [getrepackingDropdowns.pending]: (state) => {
            state.isLoading = true;
        },
        [getrepackingDropdowns.fulfilled]: (state, { payload }) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.data = payload;
        },
        [getrepackingDropdowns.rejected]: (state, { payload }) => {
            state.isLoading = false;
            state.isSuccess = false;
            state.errorMessage = payload
        }
    }
})
    .reducer;
export const repackingReadSlice = createSlice({
    name: 'repackingRead',
    initialState: {
        isLoading: false,
        isSuccess: false,
        errorMessage: '',
        // data: []
        data:{
            data: [],    
            pagination:{
                current_page: 0, per_page: 0, total: 0, last_page: 0
            }
        }
    },
    extraReducers: {
        [getrepackingRead.pending]: (state) => {
            state.isLoading = true;
        },
        [getrepackingRead.fulfilled]: (state, { payload }) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.data = payload;
        },
        [getrepackingRead.rejected]: (state, { payload }) => {
            state.isLoading = false;
            state.isSuccess = false;
            state.errorMessage = payload
        }
    }
})
    .reducer;
 const repackingCreateSlices = createSlice({
    name: 'repackingCreate',
    initialState: {
        isLoading: false,
        isSuccess: false,
        errorMessage: '',
        data: {
            images: []

        }
    },
    reducers: {
        resetrrepackingState: (state) => {
            return {
                ...state,
                isLoading: false,
                isSuccess: false,
                errorMessage: '',
                data: {
                }
            };
        },
    },
    extraReducers: {
        [getrepackingCreate.pending]: (state) => {
            state.isLoading = true;
        },
        [getrepackingCreate.fulfilled]: (state, { payload }) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.data = payload;
        },
        [getrepackingCreate.rejected]: (state, { payload }) => {
            state.isLoading = false;
            state.isSuccess = false;
            state.errorMessage = payload
        }
    }
})
export const { resetrrepackingState } = repackingCreateSlices.actions;
export const repackingCreateSlice = repackingCreateSlices.reducer;

const repackingExportSlices = createSlice({
    name: 'repackingExport',
    initialState: {
        isLoading: false,
        isSuccess: false,
        errorMessage: '',
        data: {}
    },
    reducers: {
        resetrepackingExportDataState: (state) => {
            return {
                ...state,
                isLoading: false,
                isSuccess: false,
                errorMessage: '',
                data: {
                }
            };
        },
    },
    extraReducers: {
        [getrepackingExport.pending]: (state) => {
            state.isLoading = true;
        },
        [getrepackingExport.fulfilled]: (state, { payload }) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.data = payload;
        },
        [getrepackingExport.rejected]: (state, { payload }) => {
            state.isLoading = false;
            state.isSuccess = false;
            state.errorMessage = payload
        }
    }
})
export const { resetrepackingExportDataState } = repackingExportSlices.actions;
export const repackingExportSlice = repackingExportSlices.reducer;

// mrp-slice
export const mrpCreationDropdownsSlice = createSlice({
    name: 'mrpCreationDropdowns',
    initialState: {
        isLoading: false,
        isSuccess: false,
        errorMessage: '',
        data: {
            party_list: []
        }
    },
    extraReducers: {
        [getmrpCreationDropdowns.pending]: (state) => {
            state.isLoading = true;
        },
        [getmrpCreationDropdowns.fulfilled]: (state, { payload }) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.data = payload;
        },
        [getmrpCreationDropdowns.rejected]: (state, { payload }) => {
            state.isLoading = false;
            state.isSuccess = false;
            state.errorMessage = payload
        }
    }
})
    .reducer;
export const mrpCreationUpdateLogSlice = createSlice({
    name: 'mrpCreationUpdateLog',
    initialState: {
        isLoading: false,
        isSuccess: false,
        errorMessage: '',
        data: {}
    },
    extraReducers: {
        [getmrpCreationUpdateLog.pending]: (state) => {
            state.isLoading = true;
        },
        [getmrpCreationUpdateLog.fulfilled]: (state, { payload }) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.data = payload;
        },
        [getmrpCreationUpdateLog.rejected]: (state, { payload }) => {
            state.isLoading = false;
            state.isSuccess = false;
            state.errorMessage = payload
        }
    }
})
    .reducer;
const mrpCreationCreatesSlice = createSlice({
    name: 'mrpCreationCreate',
    initialState: {
        isLoading: false,
        isSuccess: false,
        errorMessage: '',
        data: {
            data: {}
        }
    },
    reducers: {
        resetmrpCreationCreateState: (state) => {
            return {
                ...state,
                isLoading: false,
                isSuccess: false,
                errorMessage: '',
                data: {
                    data: {}
                }
            };
        },
    },
    extraReducers: {
        [getmrpCreationCreate.pending]: (state) => {
            state.isLoading = true;
        },
        [getmrpCreationCreate.fulfilled]: (state, { payload }) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.data = payload;
        },
        [getmrpCreationCreate.rejected]: (state, { payload }) => {
            state.isLoading = false;
            state.isSuccess = false;
            state.errorMessage = payload
        }
    }
});

export const { resetmrpCreationCreateState } = mrpCreationCreatesSlice.actions;
export const mrpCreationCreateSlice = mrpCreationCreatesSlice.reducer;

export const mrpCreationCreateBatchBundleSlice = createSlice({
    name: 'mrpCreationCreateBatchBundle',
    initialState: {
        isLoading: false,
        isSuccess: false,
        errorMessage: '',
        data: {}
    },
    extraReducers: {
        [getmrpCreationCreateBatchBundle.pending]: (state) => {
            state.isLoading = true;
        },
        [getmrpCreationCreateBatchBundle.fulfilled]: (state, { payload }) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.data = payload;
        },
        [getmrpCreationCreateBatchBundle.rejected]: (state, { payload }) => {
            state.isLoading = false;
            state.isSuccess = false;
            state.errorMessage = payload
        }
    }
})
    .reducer;


//secondary-scan
export const orderReturnReadSlice = createSlice({
    name: 'orderReturnRead',
    initialState: {
        isLoading: false,
        isSuccess: false,
        errorMessage: '',
        // data: { list: [], total_request: 0 },
        // pagination:{
        //     current_page: 0, per_page: 0, total: 0, last_page: 0
        // }
        data:{
            data: [],    
            pagination:{
                current_page: 0, per_page: 0, total: 0, last_page: 0
            }
        }

    },
    extraReducers: {
        [getorderReturnRead.pending]: (state) => {
            state.isLoading = true;
        },
        [getorderReturnRead.fulfilled]: (state, { payload }) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.data = payload;
        },
        [getorderReturnRead.rejected]: (state, { payload }) => {
            state.isLoading = false;
            state.isSuccess = false;
            state.errorMessage = payload
        }
    }
})
    .reducer;

export const orderReturnDropdownSlice = createSlice({
    name: 'orderReturnDropdown',
    initialState: {
        isLoading: false,
        isSuccess: false,
        errorMessage: '',
        data: {
            return_types:{},
            gate_entry_numbers:[],
            courier_names:[],
            portal_names:[],
            claim_reasons:[],
            disposition_list:[],
            return_open_sub_location_list:[],
        }

    },
    extraReducers: {
        [getorderReturnDropdown.pending]: (state) => {
            state.isLoading = true;
        },
        [getorderReturnDropdown.fulfilled]: (state, { payload }) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.data = payload;
        },
        [getorderReturnDropdown.rejected]: (state, { payload }) => {
            state.isLoading = false;
            state.isSuccess = false;
            state.errorMessage = payload
        }
    }
})
    .reducer;
export const orderReturnDetailsSlice = createSlice({
    name: 'orderReturnDetails',
    initialState: {
        isLoading: false,
        isSuccess: false,
        errorMessage: '',
        data: {
            order_return_items:[],
            order_return_serial_codes:[]
        }

    },
    extraReducers: {
        [getorderReturnDetails.pending]: (state) => {
            state.isLoading = true;
        },
        [getorderReturnDetails.fulfilled]: (state, { payload }) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.data = payload;
        },
        [getorderReturnDetails.rejected]: (state, { payload }) => {
            state.isLoading = false;
            state.isSuccess = false;
            state.errorMessage = payload
        }
    }
})
    .reducer;
const orderReturnCreateSlices = createSlice({
    name: 'orderReturnCreate',
    initialState: {
        isLoading: false,
        isSuccess: false,
        errorMessage: '',
        data: {}

    },
    reducers: {
        resetorderReturnCreateState: (state) => {
            return {
                ...state,
                isLoading: false,
                isSuccess: false,
                errorMessage: '',
                data: {
                    data: {}
                }
            };
        },
    },
    extraReducers: {
        [getorderReturnCreate.pending]: (state) => {
            state.isLoading = true;
        },
        [getorderReturnCreate.fulfilled]: (state, { payload }) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.data = payload;
        },
        [getorderReturnCreate.rejected]: (state, { payload }) => {
            state.isLoading = false;
            state.isSuccess = false;
            state.errorMessage = payload
        }
    }
})
export const { resetorderReturnCreateState } = orderReturnCreateSlices.actions;
export const orderReturnCreateSlice = orderReturnCreateSlices.reducer;

const orderReturnBoxCreateSlices = createSlice({
    name: 'orderReturnBoxCreate',
    initialState: {
        isLoading: false,
        isSuccess: false,
        errorMessage: '',
        data: []

    },
    reducers: {
        restorderBoxCreateState: (state) => {
            return {
                ...state,
                isLoading: false,
                isSuccess: false,
                errorMessage: '',
                data: {
                    data: {}
                }
            };
        },
    },
    extraReducers: {
        [getorderReturnBoxCreate.pending]: (state) => {
            state.isLoading = true;
        },
        [getorderReturnBoxCreate.fulfilled]: (state, { payload }) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.data = payload;
        },
        [getorderReturnBoxCreate.rejected]: (state, { payload }) => {
            state.isLoading = false;
            state.isSuccess = false;
            state.errorMessage = payload
        }
    }
})
export const { restorderBoxCreateState } = orderReturnBoxCreateSlices.actions;
export const orderReturnBoxCreateSlice = orderReturnBoxCreateSlices.reducer;
const orderReturnExportSlices = createSlice({
    name: 'orderReturnExport',
    initialState: {
        isLoading: false,
        isSuccess: false,
        errorMessage: '',
        data: []

    },
    reducers: {
        orderReturnExportState: (state) => {
            return {
                ...state,
                isLoading: false,
                isSuccess: false,
                errorMessage: '',
                data: {
                    data: {}
                }
            };
        },
    },
    extraReducers: {
        [getorderReturnExport.pending]: (state) => {
            state.isLoading = true;
        },
        [getorderReturnExport.fulfilled]: (state, { payload }) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.data = payload;
        },
        [getorderReturnExport.rejected]: (state, { payload }) => {
            state.isLoading = false;
            state.isSuccess = false;
            state.errorMessage = payload
        }
    }
})
export const { orderReturnExportState } = orderReturnExportSlices.actions;
export const orderReturnExportSlice = orderReturnExportSlices.reducer;

export const orderItemReadSlice = createSlice({
    name: 'orderItemRead',
    initialState: {
        isLoading: false,
        isSuccess: false,
        errorMessage: '',
        data: []

    },
    extraReducers: {
        [getorderItemRead.pending]: (state) => {
            state.isLoading = true;
        },
        [getorderItemRead.fulfilled]: (state, { payload }) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.data = payload;
        },
        [getorderItemRead.rejected]: (state, { payload }) => {
            state.isLoading = false;
            state.isSuccess = false;
            state.errorMessage = payload
        }
    }
})
    .reducer;
 const orderItemCreateSlices = createSlice({
        name: 'orderItemCreate',
        initialState: {
            isLoading: false,
            isSuccess: false,
            errorMessage: '',
            data: []
    
        },
        reducers: {
            resetorderItemCreateState: (state) => {
                return {
                    ...state,
                    isLoading: false,
                    isSuccess: false,
                    errorMessage: '',
                    data: {
                        data: {}
                    }
                };
            },
        },
        extraReducers: {
            [getorderItemCreate.pending]: (state) => {
                state.isLoading = true;
            },
            [getorderItemCreate.fulfilled]: (state, { payload }) => {
                state.isLoading = false;
                state.isSuccess = true;
                state.data = payload;
            },
            [getorderItemCreate.rejected]: (state, { payload }) => {
                state.isLoading = false;
                state.isSuccess = false;
                state.errorMessage = payload
            }
        }
    })
    export const { resetorderItemCreateState } = orderItemCreateSlices.actions;
   export const orderItemCreateSlice = orderItemCreateSlices.reducer;
        export const measurementUnitReadSlice = createSlice({
            name: 'measurementUnitRead',
            initialState: {
                isLoading: false,
                isSuccess: false,
                errorMessage: '',
                data: []
        
            },
            extraReducers: {
                [getmeasurementUnitRead.pending]: (state) => {
                    state.isLoading = true;
                },
                [getmeasurementUnitRead.fulfilled]: (state, { payload }) => {
                    state.isLoading = false;
                    state.isSuccess = true;
                    state.data = payload;
                },
                [getmeasurementUnitRead.rejected]: (state, { payload }) => {
                    state.isLoading = false;
                    state.isSuccess = false;
                    state.errorMessage = payload
                }
            }
        })
            .reducer;
            export const serialCodebyOrderNoSlice = createSlice({
                name: 'serialCodebyOrderNo',
                initialState: {
                    isLoading: false,
                    isSuccess: false,
                    errorMessage: '',
                    data: []
            
                },
                extraReducers: {
                    [getserialCodebyOrderNo.pending]: (state) => {
                        state.isLoading = true;
                    },
                    [getserialCodebyOrderNo.fulfilled]: (state, { payload }) => {
                        state.isLoading = false;
                        state.isSuccess = true;
                        state.data = payload;
                    },
                    [getserialCodebyOrderNo.rejected]: (state, { payload }) => {
                        state.isLoading = false;
                        state.isSuccess = false;
                        state.errorMessage = payload
                    }
                }
            })
                .reducer;
            const orderDetailsBySerialCodeSlices = createSlice({
                    name: 'orderDetailsBySerialCode',
                    initialState: {
                        isLoading: false,
                        isSuccess: false,
                        errorMessage: '',
                        data: {}
                
                    },
                    reducers: {
                        resetorderDetailsBySerialCode: (state) => {
                            return {
                                ...state,
                                isLoading: false,
                                isSuccess: false,
                                errorMessage: '',
                                data: {
                                }
                            };
                        },
                    },
                    extraReducers: {
                        [getorderDetailsBySerialCode.pending]: (state) => {
                            state.isLoading = true;
                        },
                        [getorderDetailsBySerialCode.fulfilled]: (state, { payload }) => {
                            state.isLoading = false;
                            state.isSuccess = true;
                            state.data = payload;
                        },
                        [getorderDetailsBySerialCode.rejected]: (state, { payload }) => {
                            state.isLoading = false;
                            state.isSuccess = false;
                            state.errorMessage = payload
                        }
                    }
                })
                export const { resetorderDetailsBySerialCode } = orderDetailsBySerialCodeSlices.actions;
                export const orderDetailsBySerialCodeSlice = orderDetailsBySerialCodeSlices.reducer;
                    export const tserialCodesTrackingNoSlice = createSlice({
                        name: 'tserialCodesTrackingNo',
                        initialState: {
                            isLoading: false,
                            isSuccess: false,
                            errorMessage: '',
                            data: {}
                    
                        },
                        extraReducers: {
                            [getserialCodesTrackingNo.pending]: (state) => {
                                state.isLoading = true;
                            },
                            [getserialCodesTrackingNo.fulfilled]: (state, { payload }) => {
                                state.isLoading = false;
                                state.isSuccess = true;
                                state.data = payload;
                            },
                            [getserialCodesTrackingNo.rejected]: (state, { payload }) => {
                                state.isLoading = false;
                                state.isSuccess = false;
                                state.errorMessage = payload
                            }
                        }
                    })
                        .reducer;
//Returned Container Box Summary

export const returnContainerBoxSummarySlice = createSlice({
    name: 'returnContainerBoxSummary',
    initialState: {
        isLoading: false,
        isSuccess: false,
        errorMessage: '',
        data:{
        data: [],    
        pagination:{
            current_page: 0, per_page: 0, total: 0, last_page: 0
        }
    }

    },
    extraReducers: {
        [getreturnContainerBoxSummary.pending]: (state) => {
            state.isLoading = true;
        },
        [getreturnContainerBoxSummary.fulfilled]: (state, { payload }) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.data = payload;
        },
        [getreturnContainerBoxSummary.rejected]: (state, { payload }) => {
            state.isLoading = false;
            state.isSuccess = false;
            state.errorMessage = payload
        }
    }
})
    .reducer;
export const returnedContainerBoxSummaryPicturesSlice = createSlice({
    name: 'returnedContainerBoxSummaryPictures',
    initialState: {
        isLoading: false,
        isSuccess: false,
        errorMessage: '',
        data: {}

    },
    extraReducers: {
        [getreturnedContainerBoxSummaryPictures.pending]: (state) => {
            state.isLoading = true;
        },
        [getreturnedContainerBoxSummaryPictures.fulfilled]: (state, { payload }) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.data = payload;
        },
        [getreturnedContainerBoxSummaryPictures.rejected]: (state, { payload }) => {
            state.isLoading = false;
            state.isSuccess = false;
            state.errorMessage = payload
        }
    }
})
    .reducer;
const returnedContainerBoxSummaryExportSlices = createSlice({
    name: 'returnedContainerBoxSummaryExport',
    initialState: {
        isLoading: false,
        isSuccess: false,
        errorMessage: '',
        data: []
    },
    reducers: {
        resetExportBox: (state) => {
            return {
                ...state,
                isLoading: false,
                isSuccess: false,
                errorMessage: '',
                data: {
                }
            };
        },
    },
    extraReducers: {
        [getreturnedContainerBoxSummaryExport.pending]: (state) => {
            state.isLoading = true;
        },
        [getreturnedContainerBoxSummaryExport.fulfilled]: (state, { payload }) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.data = payload;
        },
        [getreturnedContainerBoxSummaryExport.rejected]: (state, { payload }) => {
            state.isLoading = false;
            state.isSuccess = false;
            state.errorMessage = payload
        }
    }
})
export const { resetExportBox } = returnedContainerBoxSummaryExportSlices.actions;
export const returnedContainerBoxSummaryExportSlice = returnedContainerBoxSummaryExportSlices.reducer;

export const returnedContainerBoxSummaryBbscDetailSlice = createSlice({
    name: 'returnedContainerBoxSummaryBbscDetail',
    initialState: {
        isLoading: false,
        isSuccess: false,
        errorMessage: '',
        data:{
            data: [],    
            pagination:{
                current_page: 0, per_page: 0, total: 0, last_page: 0
            }
        }
    },
    extraReducers: {
        [getreturnedContainerBoxSummaryBbscDetail.pending]: (state) => {
            state.isLoading = true;
        },
        [getreturnedContainerBoxSummaryBbscDetail.fulfilled]: (state, { payload }) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.data = payload;
        },
        [getreturnedContainerBoxSummaryBbscDetail.rejected]: (state, { payload }) => {
            state.isLoading = false;
            state.isSuccess = false;
            state.errorMessage = payload
        }
    }
})
    .reducer;
const returnedContainerBoxSummaryBbscDetailExportSlices = createSlice({
    name: 'returnedContainerBoxSummaryBbscDetailExport',
    initialState: {
        isLoading: false,
        isSuccess: false,
        errorMessage: '',
        data: {}
    },
    reducers: {
        resetExportState: (state) => {
            return {
                ...state,
                isLoading: false,
                isSuccess: false,
                errorMessage: '',
                data: {
                }
            };
        },
    },
    extraReducers: {
        [getreturnedContainerBoxSummaryBbscDetailExport.pending]: (state) => {
            state.isLoading = true;
        },
        [getreturnedContainerBoxSummaryBbscDetailExport.fulfilled]: (state, { payload }) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.data = payload;
        },
        [getreturnedContainerBoxSummaryBbscDetailExport.rejected]: (state, { payload }) => {
            state.isLoading = false;
            state.isSuccess = false;
            state.errorMessage = payload
        }
    }
})
export const { resetExportState } = returnedContainerBoxSummaryBbscDetailExportSlices.actions;
export const returnedContainerBoxSummaryBbscDetailExportSlice = returnedContainerBoxSummaryBbscDetailExportSlices.reducer;
export const returnedContainerBoxSummaryItemDetailSlice = createSlice({
    name: 'returnedContainerBoxSummaryItemDetail',
    initialState: {
        isLoading: false,
        isSuccess: false,
        errorMessage: '',
        data:{
            data: [],    
            pagination:{
                current_page: 0, per_page: 0, total: 0, last_page: 0
            }
        }

    },
    extraReducers: {
        [getreturnedContainerBoxSummaryItemDetail.pending]: (state) => {
            state.isLoading = true;
        },
        [getreturnedContainerBoxSummaryItemDetail.fulfilled]: (state, { payload }) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.data = payload;
        },
        [getreturnedContainerBoxSummaryItemDetail.rejected]: (state, { payload }) => {
            state.isLoading = false;
            state.isSuccess = false;
            state.errorMessage = payload
        }
    }
})
    .reducer;

const returnedContainerBoxSummaryItemDetailExportSlices = createSlice({
    name: 'returnedContainerBoxSummaryItemDetailExport',
    initialState: {
        isLoading: false,
        isSuccess: false,
        errorMessage: '',
        data: {}
    },
    reducers: {
        resettrailDataState: (state) => {
            return {
                ...state,
                isLoading: false,
                isSuccess: false,
                errorMessage: '',
                data: {
                }
            };
        },
    },
    extraReducers: {
        [getreturnedContainerBoxSummaryItemDetailExport.pending]: (state) => {
            state.isLoading = true;
        },
        [getreturnedContainerBoxSummaryItemDetailExport.fulfilled]: (state, { payload }) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.data = payload;
        },
        [getreturnedContainerBoxSummaryItemDetailExport.rejected]: (state, { payload }) => {
            state.isLoading = false;
            state.isSuccess = false;
            state.errorMessage = payload
        }
    }
})
export const { resettrailDataState } = returnedContainerBoxSummaryItemDetailExportSlices.actions;
export const returnedContainerBoxSummaryItemDetailExportSlice = returnedContainerBoxSummaryItemDetailExportSlices.reducer;
//end

//Forward and Backward Import kamlesh ji
export const claimReasonCreateSlice = createSlice({
    name: 'claimReasonCreate',
    initialState: {
        isLoading: false,
        isSuccess: false,
        errorMessage: '',
        data: []

    },
    extraReducers: {
        [getclaimReasonCreate.pending]: (state) => {
            state.isLoading = true;
        },
        [getclaimReasonCreate.fulfilled]: (state, { payload }) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.data = payload;
        },
        [getclaimReasonCreate.rejected]: (state, { payload }) => {
            state.isLoading = false;
            state.isSuccess = false;
            state.errorMessage = payload
        }
    }
})
    .reducer;
export const claimReasonReadSlice = createSlice({
    name: 'claimReasonRead',
    initialState: {
        isLoading: false,
        isSuccess: false,
        errorMessage: '',
        // data: []
        data: {
            data:[],
            
            total: 0,
            list: [], total_request: 0
        },

    },
    extraReducers: {
        [getclaimReasonRead.pending]: (state) => {
            state.isLoading = true;
        },
        [getclaimReasonRead.fulfilled]: (state, { payload }) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.data = payload;
        },
        [getclaimReasonRead.rejected]: (state, { payload }) => {
            state.isLoading = false;
            state.isSuccess = false;
            state.errorMessage = payload
        }
    }
})
    .reducer;
export const claimReasonDeleteSlice = createSlice({
    name: 'claimReasonDelete',
    initialState: {
        isLoading: false,
        isSuccess: false,
        errorMessage: '',
        data: []

    },
    extraReducers: {
        [getclaimReasonDelete.pending]: (state) => {
            state.isLoading = true;
        },
        [getclaimReasonDelete.fulfilled]: (state, { payload }) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.data = payload;
        },
        [getclaimReasonDelete.rejected]: (state, { payload }) => {
            state.isLoading = false;
            state.isSuccess = false;
            state.errorMessage = payload
        }
    }
})
    .reducer;

//end

//Claim Sachin ji
export const claimListingSlice = createSlice({
    name: 'claimListing',
    initialState: {
        isLoading: false,
        isSuccess: false,
        errorMessage: '',
      
        // data:{
        //     data: [],    
        //     pagination:{
        //         current_page: 0, per_page: 0, total: 0, last_page: 0
        //     }
        // }
        data: {
            data: {
                data:[]
            },
            total: 0,
            list: [], total_request: 0
        },

    },
    extraReducers: {
        [getclaimListing.pending]: (state) => {
            state.isLoading = true;
        },
        [getclaimListing.fulfilled]: (state, { payload }) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.data = payload;
        },
        [getclaimListing.rejected]: (state, { payload }) => {
            state.isLoading = false;
            state.isSuccess = false;
            state.errorMessage = payload
        }
    }
})
    .reducer;
export const counterMetricsSlice = createSlice({
    name: 'counterMetrics',
    initialState: {
        isLoading: false,
        isSuccess: false,
        errorMessage: '',
        data: {}

    },
    extraReducers: {
        [getcounterMetrics.pending]: (state) => {
            state.isLoading = true;
        },
        [getcounterMetrics.fulfilled]: (state, { payload }) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.data = payload;
        },
        [getcounterMetrics.rejected]: (state, { payload }) => {
            state.isLoading = false;
            state.isSuccess = false;
            state.errorMessage = payload
        }
    }
})
    .reducer;
const claimExportSlices = createSlice({
    name: 'claimExport',
    initialState: {
        isLoading: false,
        isSuccess: false,
        errorMessage: '',
        data: []

    },
    reducers: {
        resetclaimExportState: (state) => {
            return {
                ...state,
                isLoading: false,
                isSuccess: false,
                errorMessage: '',
                data: {
                }
            };
        },
    },
    extraReducers: {
        [getclaimExport.pending]: (state) => {
            state.isLoading = true;
        },
        [getclaimExport.fulfilled]: (state, { payload }) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.data = payload;
        },
        [getclaimExport.rejected]: (state, { payload }) => {
            state.isLoading = false;
            state.isSuccess = false;
            state.errorMessage = payload
        }
    }
})
export const { resetclaimExportState } = claimExportSlices.actions;
export const claimExportSlice = claimExportSlices.reducer;

export const sendClaimMessageSlice = createSlice({
    name: 'sendClaimMessage',
    initialState: {
        isLoading: false,
        isSuccess: false,
        errorMessage: '',
        data: []

    },
    extraReducers: {
        [getsendClaimMessage.pending]: (state) => {
            state.isLoading = true;
        },
        [getsendClaimMessage.fulfilled]: (state, { payload }) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.data = payload;
        },
        [getsendClaimMessage.rejected]: (state, { payload }) => {
            state.isLoading = false;
            state.isSuccess = false;
            state.errorMessage = payload
        }
    }
})
    .reducer;

export const claimChatMessageSlice = createSlice({
    name: 'claimChatMessage',
    initialState: {
        isLoading: false,
        isSuccess: false,
        errorMessage: '',
        data: []

    },
    extraReducers: {
        [getclaimChatMessage.pending]: (state) => {
            state.isLoading = true;
        },
        [getclaimChatMessage.fulfilled]: (state, { payload }) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.data = payload;
        },
        [getclaimChatMessage.rejected]: (state, { payload }) => {
            state.isLoading = false;
            state.isSuccess = false;
            state.errorMessage = payload
        }
    }
})
    .reducer;
export const claimCloseTicketSlice = createSlice({
    name: 'claimCloseTicket',
    initialState: {
        isLoading: false,
        isSuccess: false,
        errorMessage: '',
        data: {}

    },
    extraReducers: {
        [getclaimCloseTicket.pending]: (state) => {
            state.isLoading = true;
        },
        [getclaimCloseTicket.fulfilled]: (state, { payload }) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.data = payload;
        },
        [getclaimCloseTicket.rejected]: (state, { payload }) => {
            state.isLoading = false;
            state.isSuccess = false;
            state.errorMessage = payload
        }
    }
})
    .reducer;
export const claimRemarkSlice = createSlice({
    name: 'claimRemark',
    initialState: {
        isLoading: false,
        isSuccess: false,
        errorMessage: '',
        // data: []
        data: {
            data:[]

        },

    },
    extraReducers: {
        [getclaimRemark.pending]: (state) => {
            state.isLoading = true;
        },
        [getclaimRemark.fulfilled]: (state, { payload }) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.data = payload;
        },
        [getclaimRemark.rejected]: (state, { payload }) => {
            state.isLoading = false;
            state.isSuccess = false;
            state.errorMessage = payload
        }
    }
})
    .reducer;
export const setClaimRemarkSlice = createSlice({
    name: 'setClaimRemark',
    initialState: {
        isLoading: false,
        isSuccess: false,
        errorMessage: '',
        data: []

    },
    extraReducers: {
        [getsetClaimRemark.pending]: (state) => {
            state.isLoading = true;
        },
        [getsetClaimRemark.fulfilled]: (state, { payload }) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.data = payload;
        },
        [getsetClaimRemark.rejected]: (state, { payload }) => {
            state.isLoading = false;
            state.isSuccess = false;
            state.errorMessage = payload
        }
    }
})
    .reducer;
export const claimMediaSlice = createSlice({
    name: 'claimMedia',
    initialState: {
        isLoading: false,
        isSuccess: false,
        errorMessage: '',
        data: {}

    },
    extraReducers: {
        [getclaimMedia.pending]: (state) => {
            state.isLoading = true;
        },
        [getclaimMedia.fulfilled]: (state, { payload }) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.data = payload;
        },
        [getclaimMedia.rejected]: (state, { payload }) => {
            state.isLoading = false;
            state.isSuccess = false;
            state.errorMessage = payload
        }
    }
})
    .reducer;
export const claimStatusChangeSlice = createSlice({
    name: 'claimStatusChange',
    initialState: {
        isLoading: false,
        isSuccess: false,
        errorMessage: '',
        data: []

    },
    extraReducers: {
        [getclaimStatusChange.pending]: (state) => {
            state.isLoading = true;
        },
        [getclaimStatusChange.fulfilled]: (state, { payload }) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.data = payload;
        },
        [getclaimStatusChange.rejected]: (state, { payload }) => {
            state.isLoading = false;
            state.isSuccess = false;
            state.errorMessage = payload
        }
    }
})
    .reducer;
export const ticketCloseDropdownSlice = createSlice({
    name: 'ticketCloseDropdown',
    initialState: {
        isLoading: false,
        isSuccess: false,
        errorMessage: '',
        data: []

    },
    extraReducers: {
        [getticketCloseDropdown.pending]: (state) => {
            state.isLoading = true;
        },
        [getticketCloseDropdown.fulfilled]: (state, { payload }) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.data = payload;
        },
        [getticketCloseDropdown.rejected]: (state, { payload }) => {
            state.isLoading = false;
            state.isSuccess = false;
            state.errorMessage = payload
        }
    }
})
    .reducer;
export const ticketOpenDropdownSlice = createSlice({
    name: 'ticketOpenDropdown',
    initialState: {
        isLoading: false,
        isSuccess: false,
        errorMessage: '',
        data: []

    },
    extraReducers: {
        [getticketOpenDropdown.pending]: (state) => {
            state.isLoading = true;
        },
        [getticketOpenDropdown.fulfilled]: (state, { payload }) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.data = payload;
        },
        [getticketOpenDropdown.rejected]: (state, { payload }) => {
            state.isLoading = false;
            state.isSuccess = false;
            state.errorMessage = payload
        }
    }
})
    .reducer;
export const claimStatusDropdownSlice = createSlice({
    name: 'claimStatusDropdown',
    initialState: {
        isLoading: false,
        isSuccess: false,
        errorMessage: '',
        data: []

    },
    extraReducers: {
        [getclaimStatusDropdown.pending]: (state) => {
            state.isLoading = true;
        },
        [getclaimStatusDropdown.fulfilled]: (state, { payload }) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.data = payload;
        },
        [getclaimStatusDropdown.rejected]: (state, { payload }) => {
            state.isLoading = false;
            state.isSuccess = false;
            state.errorMessage = payload
        }
    }
})
    .reducer;


//kamlesh ji Forward and Backward Import

export const forwardBackwardSlice = createSlice({
    name: 'forwardBackward',
    initialState: {
        isLoading: false,
        isSuccess: false,
        errorMessage: '',
        data: []

    },
    extraReducers: {
        [getforwardBackward.pending]: (state) => {
            state.isLoading = true;
        },
        [getforwardBackward.fulfilled]: (state, { payload }) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.data = payload;
        },
        [getforwardBackward.rejected]: (state, { payload }) => {
            state.isLoading = false;
            state.isSuccess = false;
            state.errorMessage = payload
        }
    }
})
    .reducer;
// dashbord-api sachin ji
export const podCounterSlice = createSlice({
    name: 'podCounter',
    initialState: {
        isLoading: false,
        isSuccess: false,
        errorMessage: '',
        data: []

    },
    extraReducers: {
        [getpodCounter.pending]: (state) => {
            state.isLoading = true;
        },
        [getpodCounter.fulfilled]: (state, { payload }) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.data = payload;
        },
        [getpodCounter.rejected]: (state, { payload }) => {
            state.isLoading = false;
            state.isSuccess = false;
            state.errorMessage = payload
        }
    }
})
    .reducer;
export const secondaryCounterMatricSlice = createSlice({
    name: 'secondaryCounterMatric',
    initialState: {
        isLoading: false,
        isSuccess: false,
        errorMessage: '',
        data: []

    },
    extraReducers: {
        [getsecondaryCounterMatric.pending]: (state) => {
            state.isLoading = true;
        },
        [getsecondaryCounterMatric.fulfilled]: (state, { payload }) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.data = payload;
        },
        [getsecondaryCounterMatric.rejected]: (state, { payload }) => {
            state.isLoading = false;
            state.isSuccess = false;
            state.errorMessage = payload
        }
    }
})
    .reducer;
export const claimCounterMatricSlice = createSlice({
    name: 'claimCounterMatric',
    initialState: {
        isLoading: false,
        isSuccess: false,
        errorMessage: '',
        data: []

    },
    extraReducers: {
        [getclaimCounterMatric.pending]: (state) => {
            state.isLoading = true;
        },
        [getclaimCounterMatric.fulfilled]: (state, { payload }) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.data = payload;
        },
        [getclaimCounterMatric.rejected]: (state, { payload }) => {
            state.isLoading = false;
            state.isSuccess = false;
            state.errorMessage = payload
        }
    }
})
    .reducer;
export const graphPortalWiseSlice = createSlice({
    name: 'graphPortalWise',
    initialState: {
        isLoading: false,
        isSuccess: false,
        errorMessage: '',
        data: []

    },
    extraReducers: {
        [getgraphPortalWise.pending]: (state) => {
            state.isLoading = true;
        },
        [getgraphPortalWise.fulfilled]: (state, { payload }) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.data = payload;
        },
        [getgraphPortalWise.rejected]: (state, { payload }) => {
            state.isLoading = false;
            state.isSuccess = false;
            state.errorMessage = payload
        }
    }
})
    .reducer;
export const graphClaimAcceptSlice = createSlice({
    name: 'graphClaimAccept',
    initialState: {
        isLoading: false,
        isSuccess: false,
        errorMessage: '',
        data: {
            "xaxis": {
                "categories": []
            },
            "series": []
        }

    },
    extraReducers: {
        [getgraphClaimAccept.pending]: (state) => {
            state.isLoading = true;
        },
        [getgraphClaimAccept.fulfilled]: (state, { payload }) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.data = payload;
        },
        [getgraphClaimAccept.rejected]: (state, { payload }) => {
            state.isLoading = false;
            state.isSuccess = false;
            state.errorMessage = payload
        }
    }
})
    .reducer;
export const graphPortalwiseAmountSlice = createSlice({
    name: 'graphPortalwiseAmount',
    initialState: {
        isLoading: false,
        isSuccess: false,
        errorMessage: '',
        data: {
            claim_reasons: [],
            data:{}

        }

    },
    extraReducers: {
        [getgraphPortalwiseAmount.pending]: (state) => {
            state.isLoading = true;
        },
        [getgraphPortalwiseAmount.fulfilled]: (state, { payload }) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.data = payload;
        },
        [getgraphPortalwiseAmount.rejected]: (state, { payload }) => {
            state.isLoading = false;
            state.isSuccess = false;
            state.errorMessage = payload
        }
    }
})
    .reducer;
export const graphClaimAcceptAmountSlice = createSlice({
    name: 'graphClaimAcceptAmount',
    initialState: {
        isLoading: false,
        isSuccess: false,
        errorMessage: '',
        data: {
            "xaxis": {
                "categories": []
            },
            "series": []
        }

    },
    extraReducers: {
        [getgraphClaimAcceptAmount.pending]: (state) => {
            state.isLoading = true;
        },
        [getgraphClaimAcceptAmount.fulfilled]: (state, { payload }) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.data = payload;
        },
        [getgraphClaimAcceptAmount.rejected]: (state, { payload }) => {
            state.isLoading = false;
            state.isSuccess = false;
            state.errorMessage = payload
        }
    }
})
    .reducer;
export const graphLostAmountScanDelaySlice = createSlice({
    name: 'graphLostAmountScanDelay',
    initialState: {
        isLoading: false,
        isSuccess: false,
        errorMessage: '',
        data: {
            "xaxis": {
                "categories": []
            },
            "series": []
        }

    },
    extraReducers: {
        [getgraphLostAmountScanDelay.pending]: (state) => {
            state.isLoading = true;
        },
        [getgraphLostAmountScanDelay.fulfilled]: (state, { payload }) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.data = payload;
        },
        [getgraphLostAmountScanDelay.rejected]: (state, { payload }) => {
            state.isLoading = false;
            state.isSuccess = false;
            state.errorMessage = payload
        }
    }
})
    .reducer;
export const notRespondingByUsSlice = createSlice({
    name: 'notRespondingByUs',
    initialState: {
        isLoading: false,
        isSuccess: false,
        errorMessage: '',
        data: []

    },
    extraReducers: {
        [getnotRespondingByUs.pending]: (state) => {
            state.isLoading = true;
        },
        [getnotRespondingByUs.fulfilled]: (state, { payload }) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.data = payload;
        },
        [getnotRespondingByUs.rejected]: (state, { payload }) => {
            state.isLoading = false;
            state.isSuccess = false;
            state.errorMessage = payload
        }
    }
})
    .reducer;
export const notRespondingByPortalSlice = createSlice({
    name: 'notRespondingByPortal',
    initialState: {
        isLoading: false,
        isSuccess: false,
        errorMessage: '',
        data: []

    },
    extraReducers: {
        [getnotRespondingByPortal.pending]: (state) => {
            state.isLoading = true;
        },
        [getnotRespondingByPortal.fulfilled]: (state, { payload }) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.data = payload;
        },
        [getnotRespondingByPortal.rejected]: (state, { payload }) => {
            state.isLoading = false;
            state.isSuccess = false;
            state.errorMessage = payload
        }
    }
})
    .reducer;
export const boxDashboardWebReturnCountsSlice = createSlice({
    name: 'boxDashboardWebReturnCounts',
    initialState: {
        isLoading: false,
        isSuccess: false,
        errorMessage: '',
        data: []

    },
    extraReducers: {
        [getboxDashboardWebReturnCounts.pending]: (state) => {
            state.isLoading = true;
        },
        [getboxDashboardWebReturnCounts.fulfilled]: (state, { payload }) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.data = payload;
        },
        [getboxDashboardWebReturnCounts.rejected]: (state, { payload }) => {
            state.isLoading = false;
            state.isSuccess = false;
            state.errorMessage = payload
        }
    }
})
    .reducer;
    export const returnedContainerBoxSummaryDropdownSlice = createSlice({
        name: 'returnedContainerBoxSummaryDropdown',
        initialState: {
            isLoading: false,
            isSuccess: false,
            errorMessage: '',
            data: {
                returned_container_numbers:[]
            }
    
        },
        extraReducers: {
            [getreturnedContainerBoxSummaryDropdown.pending]: (state) => {
                state.isLoading = true;
            },
            [getreturnedContainerBoxSummaryDropdown.fulfilled]: (state, { payload }) => {
                state.isLoading = false;
                state.isSuccess = true;
                state.data = payload;
            },
            [getreturnedContainerBoxSummaryDropdown.rejected]: (state, { payload }) => {
                state.isLoading = false;
                state.isSuccess = false;
                state.errorMessage = payload
            }
        }
    })
        .reducer;
export const boxDashboardWebReturnInventorySlice = createSlice({
    name: 'boxDashboardWebReturnInventory',
    initialState: {
        isLoading: false,
        isSuccess: false,
        errorMessage: '',
        data: []

    },
    extraReducers: {
        [getboxDashboardWebReturnInventory.pending]: (state) => {
            state.isLoading = true;
        },
        [getboxDashboardWebReturnInventory.fulfilled]: (state, { payload }) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.data = payload;
        },
        [getboxDashboardWebReturnInventory.rejected]: (state, { payload }) => {
            state.isLoading = false;
            state.isSuccess = false;
            state.errorMessage = payload
        }
    }
})
    .reducer;
export const boxDashboardWebReturnInventoryProcessWiseSlice = createSlice({
    name: 'boxDashboardWebReturnInventoryProcessWise',
    initialState: {
        isLoading: false,
        isSuccess: false,
        errorMessage: '',
        data: []

    },
    extraReducers: {
        [getboxDashboardWebReturnInventoryProcessWise.pending]: (state) => {
            state.isLoading = true;
        },
        [getboxDashboardWebReturnInventoryProcessWise.fulfilled]: (state, { payload }) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.data = payload;
        },
        [getboxDashboardWebReturnInventoryProcessWise.rejected]: (state, { payload }) => {
            state.isLoading = false;
            state.isSuccess = false;
            state.errorMessage = payload
        }
    }
})
    .reducer;

/// claim-email
export const claimEmailTemplateDropdownSlice = createSlice({
    name: 'claimEmailTemplateDropdown',
    initialState: {
        isLoading: false,
        isSuccess: false,
        errorMessage: '',
        data: {
            "claim_reason": [],
            "parties": []
        }

    },
    extraReducers: {
        [getclaimEmailTemplateDropdown.pending]: (state) => {
            state.isLoading = true;
        },
        [getclaimEmailTemplateDropdown.fulfilled]: (state, { payload }) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.data = payload;
        },
        [getclaimEmailTemplateDropdown.rejected]: (state, { payload }) => {
            state.isLoading = false;
            state.isSuccess = false;
            state.errorMessage = payload
        }
    }
})
    .reducer;
const claimEmailTemplateCreateSlices = createSlice({
    name: 'claimEmailTemplateCreate',
    initialState: {
        isLoading: false,
        isSuccess: false,
        errorMessage: '',
        data: []

    },
    reducers: {
        resetclaimEmailTemplateCreateState: (state) => {
            return {
                ...state,
                isLoading: false,
                isSuccess: false,
                errorMessage: '',
                data: {
                }
            };
        },
    },
    extraReducers: {
        [getclaimEmailTemplateCreate.pending]: (state) => {
            state.isLoading = true;
        },
        [getclaimEmailTemplateCreate.fulfilled]: (state, { payload }) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.data = payload;
        },
        [getclaimEmailTemplateCreate.rejected]: (state, { payload }) => {
            state.isLoading = false;
            state.isSuccess = false;
            state.errorMessage = payload
        }
    }
})
export const { resetclaimEmailTemplateCreateState } = claimEmailTemplateCreateSlices.actions;
export const claimEmailTemplateCreateSlice = claimEmailTemplateCreateSlices.reducer;

export const claimEmailTemplateReadSlice = createSlice({
    name: 'claimEmailTemplateRead',
    initialState: {
        isLoading: false,
        isSuccess: false,
        errorMessage: '',
        // data: []
        data: {
           data:[],
            total: 0,
            list: [], total_request: 0
        },

    },
    extraReducers: {
        [getclaimEmailTemplateRead.pending]: (state) => {
            state.isLoading = true;
        },
        [getclaimEmailTemplateRead.fulfilled]: (state, { payload }) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.data = payload;
        },
        [getclaimEmailTemplateRead.rejected]: (state, { payload }) => {
            state.isLoading = false;
            state.isSuccess = false;
            state.errorMessage = payload
        }
    }
})
    .reducer;
export const claimEmailTemplateEditSlice = createSlice({
    name: 'claimEmailTemplateEdit',
    initialState: {
        isLoading: false,
        isSuccess: false,
        errorMessage: '',
        data: {}

    },
    extraReducers: {
        [getclaimEmailTemplateEdit.pending]: (state) => {
            state.isLoading = true;
        },
        [getclaimEmailTemplateEdit.fulfilled]: (state, { payload }) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.data = payload;
        },
        [getclaimEmailTemplateEdit.rejected]: (state, { payload }) => {
            state.isLoading = false;
            state.isSuccess = false;
            state.errorMessage = payload
        }
    }
})
    .reducer;
export const claimEmailTemplateUpdateSlice = createSlice({
    name: 'claimEmailTemplateUpdate',
    initialState: {
        isLoading: false,
        isSuccess: false,
        errorMessage: '',
        data: []

    },
    extraReducers: {
        [getclaimEmailTemplateUpdate.pending]: (state) => {
            state.isLoading = true;
        },
        [getclaimEmailTemplateUpdate.fulfilled]: (state, { payload }) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.data = payload;
        },
        [getclaimEmailTemplateUpdate.rejected]: (state, { payload }) => {
            state.isLoading = false;
            state.isSuccess = false;
            state.errorMessage = payload
        }
    }
})
    .reducer;
const claimCreateSlices = createSlice({
    name: 'claimCreate',
    initialState: {
        isLoading: false,
        isSuccess: false,
        errorMessage: '',
        data: []

    },
    reducers: {
        resetclaimCreateState: (state) => {
            return {
                ...state,
                isLoading: false,
                isSuccess: false,
                errorMessage: '',
                data: {
                }
            };
        },
    },
    extraReducers: {
        [getclaimCreate.pending]: (state) => {
            state.isLoading = true;
        },
        [getclaimCreate.fulfilled]: (state, { payload }) => {
            state.isLoading = false;
            state.isSuccess = true;
            state.data = payload;
        },
        [getclaimCreate.rejected]: (state, { payload }) => {
            state.isLoading = false;
            state.isSuccess = false;
            state.errorMessage = payload
        }
    }
})
export const { resetclaimCreateState } = claimCreateSlices.actions;
export const claimCreateSlice = claimCreateSlices.reducer;
