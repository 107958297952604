import React, { useEffect, useState } from 'react'
import { cardData } from '../../../constants/dashboardjson'
import { useDispatch, useSelector } from 'react-redux'
import { getboxDashboardWebReturnCounts, getdashboardCard } from '../../../store/user/actions'
const optionsSelect = [

    { value: "today", label: "Today" },
    { value: "this_week", label: "This Week" },
    { value: "last_week", label: "Last Week" },
    { value: "this_month", label: "This Month" },
    { value: "last_month", label: "Last Month" },
    { value: "last_three_month", label: "Last 3 Month" },
    { value: "this_year", label: "This Year" },
    { value: "last_year", label: "Last Year" },
    { value: "all", label: "All" },
  ];
const DashboardCard = () => {
    const [filter, setFilter] = useState("today");
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");

    const boxDashboardWebReturnCounts = useSelector(state => state.boxDashboardWebReturnCounts.data)
    const [selectedDuration, setSelectedDuration] = useState(
        optionsSelect[0].value // Set default value to "duration"
      );
        // Set default value to "duration" (not in options));
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(getboxDashboardWebReturnCounts({ filter }));
    }, [filter]);

    useEffect(() => {
        // Example: Set startDate to the first day of the current month
        const currentDate = new Date();
        const firstDayOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
        const formattedStartDate = formatDate(firstDayOfMonth);
        setStartDate(formattedStartDate);

        // Example: Set endDate to the current date
        const formattedEndDate = formatDate(currentDate);
        setEndDate(formattedEndDate);
    }, []);

    // Function to format date in "YYYY-MM-DD" format
    const formatDate = (date) => {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
    };

   
    //   const persionValue =(value) =>{
    //     dispatch(getcollectionWiseCompletion({period:value}))
    //   }
    console.log(boxDashboardWebReturnCounts);
    // const handleChange = (event) => {
    //     setSelectedDuration(event.target.value);
    //   };
    const handleFilterChange = (e) => {
        setFilter(e.target.value);
    }
    return (
        <div className=''>
             <div className='heading_select'>
                <h2>Box Dashboard</h2>
                <div className="custom-select">
                    <select className='selectArea' 
                      value={filter}
                      onChange={handleFilterChange}
                        >
                            {
                                optionsSelect && optionsSelect.map((item)=>(
                                <option value={item.value}>{item.label}</option>
                                ))
                            } 
                            </select>
                </div>
                </div>
            <div className='row padding_left'>
                <div className='col-lg-3 col-md-3 col-12 card-common'>
                    <div className="pale-red">
                        <h2>{boxDashboardWebReturnCounts.total_box_opened}</h2> 
                        <p>Total Box Opened</p>
                    </div>
                </div>
                <div className='col-lg-3 col-md-3 col-12 card-common'>
                    <div className="alice-blue">
                        <h2>{boxDashboardWebReturnCounts.total_packages_secondory_scan}</h2> 
                        <p>Total Packages Secondary Scan</p>
                    </div>
                </div>
                <div className='col-lg-3 col-md-3 col-12 card-common'>
                    <div className="light-green">
                         <h2>{boxDashboardWebReturnCounts.total_box_received}</h2> 
                        <p>Total Box Received</p>
                    </div>
                </div>
                <div className='col-lg-3 col-md-3 col-12 card-common'>
                    <div className="light-orange">
                         <h2>{boxDashboardWebReturnCounts.total_packages_short}</h2> 
                        <p>Total Short Qty Received</p>
                    </div>
                </div>
                <div className='col-lg-3 col-md-3 col-12 card-common'>
                    <div className="light-pink">
                         <h2>{boxDashboardWebReturnCounts.secondory_cost_per_package} </h2> 
                        <p>Total Secondary Scan</p>
                    </div>
                </div>
             
            </div>
            {/* <div className='heading_select'>
                <h2>Claims</h2>
            </div> */}
            {/* <div className='row padding_left'>
            <div className='col-lg-3 col-md-3 col-12 card-common'>
                    <div className="dark-gray">
                       
                        <p>Total Claims Filed</p>
                    </div>
                </div>
                <div className='col-lg-3 col-md-3 col-12 card-common'>
                    <div className="pale-red">
                     
                        <p>Total Claims Accepted </p>
                    </div>
                </div>
            <div className='col-lg-3 col-md-3 col-12 card-common'>
                    <div className="dark-brown">
                        
                        <p>Acceptation Percentage</p>
                    </div>
                </div>
                <div className='col-lg-3 col-md-3 col-12 card-common'>
                    <div className="light-yellow">
                      
                        <p>Not Responding by Portal</p>
                    </div>
                </div>
                <div className='col-lg-3 col-md-3 col-12 card-common'>
                    <div className="dark-green">
                       
                        <p>Not Responding by Us</p>
                    </div>
                </div>
            </div> */}
        </div>
    )
}

export default DashboardCard
