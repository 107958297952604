import React from 'react'
import BagTable from './component/BagTable'

const BagSummary = () => {
  return (
    <div>
      <BagTable/>
    </div>
  )
}

export default BagSummary
