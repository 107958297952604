import React, { useEffect, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom';
import Dropdown from '../../component/Dropdown/DropDownControlled';
import { DatePicker } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { getcourierOption, getgateEntryPassOption, getgatekeeperNameOption, getprimaryScanForm, gettokenNumberOption } from '../../store/user/actions';
import { showNotification } from '../../util/utility';
import Loader from '../../component/Loader';
import { resetFormDataState } from '../../store/user/userSlice';
import moment from 'moment';
import Scanner from './Scanner';
import DefaultModal from '../../component/DefaultModal/DefaultModal';

const PackageScanOne = () => {
  const navigate = useNavigate();
  const initialValues = {
    pod_number: "",
    handover_date: "",
    total_packages: "",
    token_id: "",
    gate_incoming_pass_id: "",
    user_id: "",
    carier_id: "",
    scan_type: 0,
    is_close: 0
  };
  const [formValues, setFormValue] = useState(initialValues);
  const [filteredGateEntryPassOptions, setFilteredGateEntryPassOptions] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [formErrors, setFormErrors] = useState({});
  // const [showScanner, setShowScanner] = useState(false);
  // const [showScannerModal, setShowScannerModal] = useState(false);
  const primaryScanForm = useSelector(state => state.primaryScanForm)
  const courierOption = useSelector(state => state.courierOption)
  const gateEntryPassOption = useSelector(state => state.gateEntryPassOption)
  const gatekeeperNameOption = useSelector(state => state.gatekeeperNameOption)
  const tokenNumberOption = useSelector(state => state.tokenNumberOption)
  const dispatch = useDispatch()
  const { id } = useParams();
  useEffect(() => {
    dispatch(getcourierOption({}))
    dispatch(getgateEntryPassOption({}))
    dispatch(getgatekeeperNameOption({}))
    dispatch(gettokenNumberOption({}))
  }, [])

  useEffect(() => {
    if (primaryScanForm.isSuccess) {
      localStorage.setItem('primaryScanData', JSON.stringify(primaryScanForm.data));
      // reset-dispatch-pending
      dispatch(resetFormDataState())
      const { id } = primaryScanForm.data.LooseReturn;
      if (formValues.scan_type == 1) {
        navigate('/package-scan-two-add/'+primaryScanForm.data.LooseReturn.id);
      } else {
        // navigate('/upload-count-without/'+id)
        navigate(`/upload-count-without/${id}?total_packages=${formValues.total_packages}`);
        console.log(primaryScanForm.data.LooseReturn.id);
        // navigate('/upload-count/'+id)
      }
    }
    
  }, [primaryScanForm])

  const handleChange = (name, value) => {
    let data = { ...formValues };
    data[name] = value;
    setFormValue(data);
  };
  // const handleChange = (name, value) => {
  //   let data = { ...formValues };
  //   if (name === "total_packages") {
  //     // Check if the entered value is less than 0
  //     value = Math.max(value, 1); // Set value to 0 if it's less than 0
  //   }
  //   data[name] = value;
  //   setFormValue(data);
  // };
 
  
  
  
  
  useEffect(() => {
    const fetchData = async () => {
      if (formValues.carier_id && gateEntryPassOption.data) {
        const filteredOptions = await gateEntryPassOption.data.filter(option => {
          return option.carier_id == formValues.carier_id
        });
        setFilteredGateEntryPassOptions(filteredOptions);
        // console.log(filteredOptions);
      }
    };

    fetchData();
  }, [formValues.carier_id, gateEntryPassOption.data]);


  const handleGoBack = () => {
    // Navigate back to the previous item
    navigate(-1);
  };

  const handleSubmit = () => {
    setError(null);
    setLoading(true);
    let check = validate(formValues);
    setFormErrors(check.errors);
    // console.log(primaryScanForm);
    if (check.isValid) {
      if (formValues.total_packages < 0) {
        setLoading(false);
        showNotification("error", "Total packages cannot be negative");
      } else {
        setLoading(false);
        dispatch(getprimaryScanForm(formValues));
      }
    } else {
      setLoading(false);
      showNotification("error", "Form is not valid");
    }
  }

  // validation-start
  useEffect(() => {
    if (Object.keys(formErrors).length > 0) {
    }
  }, [formErrors]);

  const validate = (values) => {
    let isValid = true;
    const errors = {};
    if (!values.pod_number) {
      isValid = false;
      errors.pod_number = "pod number is required ";
    }
    if (!values.carier_id) {
      isValid = false;
      errors.carier_id = "courier name is required";
    }
    if (!values.total_packages) {
      isValid = false;
      errors.total_packages = "Total packages is required";
    }
    if (!values.handover_date) {
      isValid = false;
      errors.handover_date = "date is required";
    }
    if (!values.user_id) {
      isValid = false;
      errors.user_id = "Guard name is required";
    }
    if (!values.gate_incoming_pass_id) {
      isValid = false;
      errors.gate_incoming_pass_id = "Gate Entry Number is required";
    }
    if (!values.token_id) {
      isValid = false;
      errors.token_id = "Token id is required";
    }
    return {
      isValid,
      errors,
    };
  };
  // const handleScannerOpen = () => {
  //   setShowScanner(true);
  // }


  return (
    <div className='primary_height_sec'>
      <div className='heading_select'>
        <div className="d-flex align-items-baseline" onClick={handleGoBack}>
          <img style={{ cursor: "pointer" }} src="/Images/arrow-left.svg" />
          <h1>Scan Details</h1>
        </div>

      </div>
      <form onSubmit={handleSubmit} className="primary_bg">
        <div className="row primary_input_section">
          <div className='col-md-4 col-lg-4 col-12 mb-4 position-relative'>
            <label className='select-label'>POD Number</label>
            <input
              // placeholder='PO1234567'
              className='input-custom-primary'
              type='text'
              onChange={(e) => handleChange("pod_number", e.target.value)}
              value={formValues.pod_number}
            />
             <img src="/Images/scan_input.svg" className='scan_input_icon' 
            //  onClick={handleScannerOpen}
              />
        
            <span className='error-msg'>{formErrors.pod_number}</span>
          </div>
          <div className='col-md-4 col-lg-4 col-12 mb-4'>
            <label className='select-label'>Courier Name</label>
            <Dropdown
              allowSearch
              // variant="outlinedGreen"
              border="1px solid #F8B552"
              color="#1C1C1C"
              fontWeight="700"
              width="100%"
              height="48px"
              selectedId={formValues?.carier_id}
              onOptionClick={(item) => handleChange("carier_id", item?.id)}
              options={courierOption.data}
            />

            <span className='error-msg'>{formErrors.carier_id}</span>
          </div>
          <div className='col-md-4 col-lg-4 col-12 mb-4'>
            <label className='select-label'>Date of Handover</label>
            <DatePicker
              className='input-custom-primary'
              placeholder="Date of Handover"
              onChange={(e) => handleChange("handover_date", e)}
              value={formValues.handover_date}
              disabledDate={(current) => current && current < moment().startOf('day')}
            />
            <span className='error-msg'>{formErrors.handover_date}</span>
          </div>
          <div className='col-md-4 col-lg-4 col-12 mb-4'>
            <label className='select-label'>Total Number of Packages</label>
            <input
              placeholder='Total number of packages'
              className='input-custom-primary'
              type='number'
              onChange={(e) => handleChange("total_packages", e.target.value)}
              value={formValues.total_packages}
            />
            <span className='error-msg'>{formErrors.total_packages}</span>
            {formValues.total_packages < 0 && <span className='error-msg'>Please add a positive value</span>}
          </div>
          <div className='col-md-4 col-lg-4 col-12 mb-4'>
            <label className='select-label'>Gatekeeper Name (Guard)</label>
            <Dropdown
              allowSearch
              // variant="outlinedGreen"
              border="1px solid #F8B552"
              color="#1C1C1C"
              fontWeight="700"
              width="100%"
              height="48px"
              selectedId={formValues?.user_id}
              onOptionClick={(item) => handleChange("user_id", item?.id)}
              options={gatekeeperNameOption.data.map(item => ({ id: item.id, label: item.name }))}
            />
            <span className='error-msg'>{formErrors.user_id}</span>
          </div>
          <div className='col-md-4 col-lg-4 col-12 mb-4'>
            <label className='select-label'>Gate Entry Number</label>
            <Dropdown
              allowSearch
              // variant="outlinedGreen"
              border="1px solid #F8B552"
              // defaultText="Gate Entry Number"
              color="#1C1C1C"
              fontWeight="700"
              width="100%"
              height="48px"
              selectedId={formValues?.gate_incoming_pass_id}
              onOptionClick={(item) => handleChange("gate_incoming_pass_id", item?.id)}
              options={filteredGateEntryPassOptions}
            />
            <span className='error-msg'>{formErrors.gate_incoming_pass_id}</span>
          </div>
          <div className='col-md-4 col-lg-4 col-12 mb-4'>
            <label className='select-label'>Token Number</label>
            <Dropdown
              allowSearch
              // variant="outlinedGreen"
              border="1px solid #F8B552"
              // defaultText="Token Number"
              color="#1C1C1C"
              fontWeight="700"
              width="100%"
              height="48px"
              selectedId={formValues?.token_id}
              onOptionClick={(item) => handleChange("token_id", item?.id)}
              options={tokenNumberOption.data.map(item => ({ id: item.id, label: item.name }))}
            />
            <span className='error-msg'>{formErrors.token_id}</span>
          </div>
        </div>
        {
          primaryScanForm.isLoading ? <Loader /> :
            <div className='package_butn mt-2'>
              {/* to="/upload-count" / */}
              <button type="button" className='add_bag' onClick={() => { handleChange("scan_type", 1); handleSubmit() }}>Continue with Primary Scan</button>
              <button type="button" className='suffix_bag' onClick={() => { handleChange("scan_type", 0); handleSubmit() }}>Continue without Primary Scan</button>
            </div>
        }
      </form>
      {/* <DefaultModal
                show={showScannerModal}
                onHide={() => setShowScannerModal(false)}
                className="add-modal"
                style={{ paddingRight: "0px" }}
                centered={false}
                showHeader={false}
                size=""
                showFooter={false}
                
            >
                <div className="modal-heading">
                <h4></h4>
                <div className="close-icon">
                    <img src="/Images/closeBlack.svg" onClick={() => setShowScannerModal(false)} />
                </div>
                </div>
      {showScanner && <Scanner />}
      </DefaultModal> */}
    </div>
  )
}

export default PackageScanOne
