


import React, { useEffect, useState } from 'react'
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import { useDropzone } from 'react-dropzone'
import { getreturnedContainerBoxCreate, getreturnedContainerBoxRead } from '../../store/user/actions';
import { useDispatch, useSelector } from 'react-redux';
import { resetreturnedContainerBoxCreateState } from '../../store/user/userSlice';

const BoxEditScanTwoNoteFill = () => {
    const params = useParams()
  const initialValues = {
    box_no: "",
    total_pieces: "",
    boxes: [],
    no_of_boxes_received:params.box
  };
  const navigate = useNavigate();
  const location = useLocation();
  const [loading, setLoading] = useState(false); // Loading state
  const [formValues, setFormValue] = useState(initialValues);
  const [files, setFiles] = useState([]);
  const [uploadedImage, setUploadedImage] = useState(null);
  const [isDropzoneVisible, setDropzoneVisibility] = useState(true);
  const [documentFiles, setDocumentFiles] = useState([]);
  const [boxData, setBoxData] = useState(null);
  const returnedContainerBoxRead = useSelector(state => state.returnedContainerBoxRead)
  const returnedContainerBoxCreate = useSelector(state => state.returnedContainerBoxCreate)
  const dispatch = useDispatch()

  // Image upload state





  const handleFileChange = (e, index) => {
    const selectedFiles = e.target.files;
    const oldFiles = [...files];
    const newFiles = []
    for (let i = 0; i < selectedFiles.length; i++) {
      newFiles.push(selectedFiles[i]);
    }
    oldFiles[index] = newFiles
    setFiles(oldFiles);
  };


  const handleDocFileChange = (e, index) => {
    const selectedFiles = e.target.files;
    const oldFiles = [...documentFiles];
    const newFiles = []
    for (let i = 0; i < selectedFiles.length; i++) {
      newFiles.push(selectedFiles[i]);
    }
    oldFiles[index] = newFiles
    setDocumentFiles(oldFiles);
  };

  const handleChange = (index, name, value) => {
    const updatedBoxes = [...formValues.boxes];
    updatedBoxes[index] = { ...updatedBoxes[index], [name]: value };
    setFormValue({ ...formValues, boxes: updatedBoxes });
  };
  const handleGoBack = () => {
    navigate(-1);
  };
  const handleRemoveFile = (index, fileindex) => {
    const newFiles = [...files];
    newFiles[index].splice(fileindex, 1);

    setFiles(newFiles);
  };

  const handleRemoveDocFile = (index, fileindex) => {
    const newFiles = [...documentFiles];
    newFiles[index].splice(fileindex, 1);

    setDocumentFiles(newFiles);
  };

  const createBox = () => {
     setLoading(true);
    const formData = new FormData();
    formValues.boxes.forEach((box, index) => {
      const filesForBox = files[index] || []; // Ensure files array exists
      const docFilesForBox = documentFiles[index] || []; // Ensure docFiles array exists
      for (const file of filesForBox) {
        formData.append(`boxes[${index}][pictures][]`, file); // Append images
      }
      for (const docFile of docFilesForBox) {
        formData.append(`boxes[${index}][documents][]`, docFile); // Append documents
      }
      // Append other box data
      formData.append(`boxes[${index}][box_no]`, box.box_no);
      formData.append(`boxes[${index}][total_pieces]`, box.total_pieces);
    });
    formData.append("returned_container_id", params.id);
    dispatch(getreturnedContainerBoxCreate(formData))
  };
  useEffect(() => {
    if (returnedContainerBoxCreate.isSuccess || returnedContainerBoxCreate.status === 1) {
      dispatch(resetreturnedContainerBoxCreateState())
      navigate('/box-scan-image/'+params.id);

    }
  }, [returnedContainerBoxCreate.isSuccess, returnedContainerBoxCreate.status]);


//   useEffect(() => {
//     // const storedFormData = JSON.parse(localStorage.getItem('formData'));
//     const updatedFormData = {
//       ...storedFormData,
//       boxes: storedFormData.boxes || [], // Initialize boxes as empty array if needed
//     };
//     setFormValue(updatedFormData);
//   }, []);

  
  useEffect(() => {
    // const storedFormData = JSON.parse(localStorage.getItem('formData'));
   dispatch(getreturnedContainerBoxRead({ returned_container_id: params.id }));
  }, [params.id]);
  console.log(returnedContainerBoxRead);
  useEffect(() => {
    if (returnedContainerBoxRead.data) {
      setBoxData(returnedContainerBoxRead.data);
    }
  }, [returnedContainerBoxRead.data]);

  const truncateFileName = (fileName, maxLength = 5) => {
    if (fileName.length <= maxLength) {
      return fileName;
    } else {
      return fileName.substring(0, maxLength) + '...';
    }
  };
  return (
    <div>
      <div className='heading_select'>
        <div className="d-flex align-items-baseline" onClick={handleGoBack}>
          <img style={{ cursor: "pointer" }} src="/Images/arrow-left.svg" />
          <h1>Box Scan Form</h1>
        </div>
      </div>
      <div className='row'>
        {
          Array.from({ length: formValues.no_of_boxes_received }).map((item, index) => {
            return <>
              <div className='col-lg-4'>
                <div className='scan_box'>
                  <div className='gray_box'>
                    BOX-{index + 1}
                  </div>

                  <div key={index} className="file-preview-container position-relative image-upload-box">
                    <input
                      type="file"
                      multiple
                      className="hidden-file-input"
                      onChange={(e) => handleFileChange(e, index)}
                      accept='image/*'
                    />
                    <div className='d-flex justify-content-center mb-2'>
                      <img src="/Images/camera.svg" />
                      <div>
                        <h5>Upload Image</h5>
                        <small>You can upload word file</small>
                      </div>
                    </div>
                    <div className='upload_image-wrap'>
                      {files[index] && files[index].map((file, fileIndex) => (
                        <div className="file-preview">
                          <img
                            src={URL.createObjectURL(file)}
                            alt={`Preview ${index}`}
                          />
                          <button onClick={() => handleRemoveFile(index, fileIndex)} className='close_red'><img src='/Images/redClose.svg' /></button>
                        </div>
                      ))}
                    </div>
                  </div>

                  <div key={index} className="file-preview-container position-relative doc-upload-box">
                    <input
                      className="hidden-doc-input"
                      type="file"
                      multiple
                      onChange={(e) => handleDocFileChange(e, index)}
                    />
                    <div className='d-flex justify-content-center mb-2'>
                      <img src="/Images/cloud.svg" />
                      <div>
                        <h5>Upload Document</h5>
                        <small>You can upload word file</small>
                      </div>
                    </div>
                    <div className='upload_image-wrap'>
                      {documentFiles[index] && documentFiles[index].map((file, fileIndex) => (
                        <div className="file-preview">
                          <label>{truncateFileName(file.name)}</label>
                          <button onClick={() => handleRemoveDocFile(index, fileIndex)} className='close_red'><img src='/Images/redClose.svg' /></button>
                        </div>
                      ))}
                    </div>
                  </div>

                  <div className='mt-2 mb-2 position-relative'>
                    <label className='select-label'>Total Pieces </label>
                    <input
                      placeholder='Total Pieces'
                      className='input-custom-primary'
                      type='text' // Change type to text
                      onChange={(e) => handleChange(index, "total_pieces", e.target.value)}
                      value={formValues.boxes[index]?.total_pieces || ''}
                    />
                  </div>
                  <div className='mb-4 position-relative'>
                    <label className='select-label'>Box Number</label>
                    <input
                      placeholder='Box Number'
                      className='input-custom-primary'
                      type='text' // Change type to text
                      onChange={(e) => handleChange(index, "box_no", e.target.value)}
                      value={formValues.boxes[index]?.box_no || ''}
                    />
                  </div>
                </div>
              </div>
            </>
          })
        }

      </div>
      <div className='package_butn sub_margin'>
          <button className='suffix_bag' onClick={createBox} disabled={loading}>{loading ? 'Loading...' : 'Submit'}</button>
        </div>
    </div>
  )
}

export default BoxEditScanTwoNoteFill
