import React, { useState } from 'react'
import { Link } from 'react-router-dom';

const ReturnOptionModal = () => {
    const [activeImage, setActiveImage] = useState(null);

    const handleImageClick = (imageId) => {
        setActiveImage((prevActiveImage) =>
          prevActiveImage === imageId ? null : imageId
        );
      };
  return (
    <div className='return-box-modal'>
      <p>To proceed please enter
      your email address and password.</p>

    <div className='loose_return_img'>
       <div><img src="/Images/Line_border.svg"/></div> 
       <Link to="/package-scan-one">
    <img
        src="/Images/looseReturnColor.svg"
        alt="Image 1"
        className={activeImage === 1 ? 'blur_img' : 'color_active'}
        onClick={() => handleImageClick(1)}
      />
     </Link>
     <Link
      to="/box-scan-one">
      <img
        src="/Images/boxReturnColor.svg"
        alt="Image 2"
        className={activeImage === 2 ? 'color_active' : 'blur_img'}
        onClick={() => handleImageClick(2)}
      />
     </Link>
    </div>
    </div>
  )
}

export default ReturnOptionModal




 