import React from 'react'
import MrpTable from './component/MrpTable'

const MRPCreation = () => {
  return (
    <div>
        <div className='heading_select'>
              <h1>MRP Creation </h1>
          </div>
      <MrpTable/>
    </div>
  )
}

export default MRPCreation
