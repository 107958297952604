import React, { useEffect, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom';
import DefaultModal from '../../component/DefaultModal/DefaultModal';
import Dropdown from '../../component/Dropdown/DropDownControlled';
import { useDispatch, useSelector } from 'react-redux';
import { getclaimChatMessage, getclaimCloseTicket, getsendClaimMessage, getticketCloseDropdown } from '../../store/user/actions';

const Communication = () => {
  const initialValues = {
    portal_id: "",
    remark: '',
  };
  const [formValues, setFormValue] = useState(initialValues);
  const [loading, setLoading] = useState(false); // Loading state
  const [show, setShow] = useState(false);
  const claimChatMessage = useSelector(state => state.claimChatMessage)
  const ticketCloseDropdown = useSelector(state => state.ticketCloseDropdown)
  const [message, setMessage] = useState('');
  const firstMessage = claimChatMessage.data[0]; // Get the first message
  const initialSubject = firstMessage ? firstMessage.subject : ''; // Extract the subject or default to an empty string
  const { id, claimTicketId } = useParams();

  const dispatch = useDispatch()
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(getclaimChatMessage({claim_ticket_id:claimTicketId}))
  }, [])
console.log(id, claimTicketId);
  useEffect(() => {
    dispatch(getticketCloseDropdown({}))
  }, [])
  
  const handleGoBack = () => {
    // Navigate back to the previous item
    navigate(-1);
  };
  const sendMessage = () => {
    setLoading(true);
    dispatch(getsendClaimMessage({ 
      claim_id: id,
      claim_ticket_id:claimTicketId, 
      message: message,
      subject:initialSubject
    })).then(() => {
      setLoading(false); // Set loading to false when request is complete
      dispatch(getclaimChatMessage({ claim_ticket_id: claimTicketId })); // Re-fetch messages
      setMessage(''); // Clear the message input
    }).catch(() => {
      setLoading(false); // Set loading to false if request fails
    });
  };
  const handleChange = (name, value) => {
    let data = { ...formValues };
    data[name] = value;
    setFormValue(data);
  };
  const closeTicket = () => {
    dispatch(getclaimCloseTicket({
      claim_ticket_id: id, 
      claim_reason_id: formValues.portal_id,
      remark: formValues.remark,
    }));
  };
  return (
    <div>
      <div className='heading_select'>
        <div className="d-flex align-items-baseline" onClick={handleGoBack}>
          <img style={{ cursor: "pointer" }} src="/Images/arrow-left.svg" />
          <h1>Communications</h1>
        </div>
        <div className='close_but'>
          <Link onClick={() => setShow(true)}><img src="/Images/close-circle.svg" />Close Ticket</Link>
        </div>

      </div>
      {claimChatMessage && claimChatMessage.data && claimChatMessage.data.length > 0 ? (
      <div className='communication-box'>
        {
          claimChatMessage && claimChatMessage.data.map((item) => (
            <div>
             
              <div className='d-flex justify-content-between'>
                <div className='d-flex'>
                  {/* <img src={item.profile_image} /> */}
                  <div className='pt-3'>
                    <h5>{item.sender_name}</h5>
                    <small>{item.created_by}</small>
                    <p><b>Subject</b> : {item.subject}</p>
                  </div>
                </div>
                <div>
                <h6>
               {item.is_read === "1" ? 'Read' : 'Unread'}
              </h6>
                </div>
              </div>
              <p>{item.message}</p>
              <div className='border-bottom-br'></div>
            </div>
          ))
        }


        <div>
          <label>Reply</label>
          <textarea
            placeholder='Please write here'
            className='text_area'
            value={message}
            onChange={(e) => setMessage(e.target.value)}
          />
        </div>
        <div className='package_butn sub_margin'>
          <button className='suffix_bag' onClick={() => sendMessage()} disabled={loading}>{loading ? 'Loading...' : 'Send'}</button>
        </div>
      </div>
      ) : (
        <div className='no-message'>
          No messages received.
        </div>
      )}
      <DefaultModal
        show={show}
        onHide={() => setShow(false)}
        className="add-modal comm_lablel"
        style={{ paddingRight: "0px" }}
        centered={false}
        showHeader={false}
        size=""
        showFooter={false}
      >
        <div className="modal-heading">
          <h4>Close Ticket</h4>
          <div className="close-icon">
            <img src="/Images/closeBlack.svg" onClick={() => setShow(false)} />
          </div>
        </div>
        <div>
          <div className='col-md-12 col-lg-12 col-12 mb-4 pt-4'>
            <label className='select-label'>Reason</label>
            <Dropdown
              allowSearch
              // variant="outlinedGreen"
              border="1px solid #F8B552"
              defaultText="Portal is not responding Properly"
              color="#1C1C1C"
              fontWeight="700"
              width="100%"
              height="48px"
              selectedId={formValues?.portal_id}
              onOptionClick={(item) => handleChange("portal_id", item?.id)}
              options={ticketCloseDropdown.data.map(item => ({ id: item.id, label: item.name }))}
            />
          </div>
          <div className='col-md-12 col-lg-12 col-12 mb-4'>
            <label className='select-label'>Remarks</label>
            <textarea
              value={formValues.remark}
              onChange={(e) => handleChange("remark", e.target.value)}
            />
          </div>
          <div className='package_butn sub_margin'>
            <button className='suffix_bag' onClick={closeTicket}>SAVE</button>
          </div>
        </div>
      </DefaultModal>
    </div>
  )
}

export default Communication
