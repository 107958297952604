import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import Dropdown from '../../component/Dropdown/DropDownControlled';
import { useDispatch, useSelector } from 'react-redux';
import { getrepackingCreate, getrepackingDropdowns } from '../../store/user/actions';
import { resetrrepackingState } from '../../store/user/userSlice';

const SerialCodeScan = () => {
    const initialValues = {
        batch_bundle_serial_code: "",
        disposition_id: "",
      };
      
    const [formValues, setFormValue] = useState(initialValues);
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false); // Loading state
      const repackingCreate = useSelector(state => state.repackingCreate)
      const repackingDropdowns = useSelector(state => state.repackingDropdowns)
      const dispatch = useDispatch()
    
      
    const handleGoBack = () => {
        // Navigate back to the previous item
        navigate(-1);
      };
      const handleChange = (name, value) => {
        console.log("alka");
        let data = { ...formValues };
        data[name] = value;
        setFormValue(data);
      };
      const handleSubmit = () => {
        setLoading(true);
        dispatch(getrepackingCreate(formValues)).then(() => {
          setLoading(false); // Set loading to false when request is complete
        }).catch(() => {
          setLoading(false); // Set loading to false if request fails
        });
      }
          // navigate call
    //  useEffect(() => {
    //   if (repackingCreate.isSuccess) {
    //     dispatch(resetrrepackingState())
    //      navigate('/repackaging');
    //    }
    // }, [repackingCreate]);
      useEffect(() => {
        dispatch(getrepackingDropdowns({}))
      }, [])
    //  console.log(repackingCreate);
  return (
    <div >
        <div className='heading_select'>
                    
                    <div className="d-flex align-items-baseline" onClick={handleGoBack}>
                    <img style={{ cursor: "pointer" }} src="/Images/arrow-left.svg" />
                        <h1>Serial Code Scan</h1>
                        </div>
                   <div className="history-but">
                    <Link>View History</Link>
                   </div>
                </div>
            <div className='bg_color mt-4'>
           <div className='row'>
            <div className='col-md-4 col-lg-4 col-12 mb-4'>
            <label className='select-label'>Enter Serial Code</label>
            <input
              placeholder='Enter Serial Code'
              className='input-custom-primary'
             
              onChange={(e) => handleChange("batch_bundle_serial_code", e.target.value)}
              value={formValues.batch_bundle_serial_code}
            />
           
          </div>
          <div className='col-md-4 col-lg-4 col-12 mb-4'>
            <label className='select-label'>Select Disposition</label>
            <Dropdown
              allowSearch
              // variant="outlinedGreen"
              border="1px solid #F8B552"
              color="#1C1C1C"
              fontWeight="700"
              width="100%"
              height="48px"
              selectedId={formValues?.disposition_id}
              // onOptionClick={(item) => handleChange("disposition_id", item?.id)}
              //  options={Object.entries(repackingDropdowns.data.disposition_grades).map(item => ({ id: item.id, label: item.name }))}
              onOptionClick={(item) => handleChange("disposition_id", item?.id)}
              options={repackingDropdowns?.data?.disposition_grades?.map(item => ({ id: item.id, label: item.name }))}
            />
          </div>
          </div>
         <div className='package_butn'><button className='suffix_bag'
          // onClick={()=>handleSubmit()}
          onClick={() => handleSubmit()} disabled={loading}>{loading ? 'Loading...' : 'Submit'}
          </button>
          </div>
         <div className='sku_box'>
     <div className='row'>
        <div className='col-lg-4'>
            <div className='gray_label'>
             SKU Code
            </div>
           <div className='gray_in'>
           {repackingCreate.data.sku_code}
           </div>
         </div>
         <div className='col-lg-4'>
            <div className='gray_label'>
            Number of times returns 
            </div>
           <div className='gray_in'>
           {repackingCreate.data.number_of_times_returns}
           </div>
         </div>
         <div className='col-lg-4'>
            <div className='gray_label'>
            Last Portal
            </div>
           <div className='gray_in'>
           {repackingCreate.data.last_portal}
           </div>
         </div>
         <div className='col-lg-4'>
            <div className='gray_label'>
            Last Courier
            </div>
           <div className='gray_in'>
           {repackingCreate.data.last_courier}
           </div>
         </div>
         <div className='col-lg-4'>
            <div className='gray_label'>
            Last Date and Time
            </div>
           <div className='gray_in'>
           {repackingCreate.data.last_date_and_time}
           </div>
         </div>
         </div>
         <div className='repacking_img'>
         {
            repackingCreate.data.images.map((item) =>(
                <img src={item.image}/>
            ))
         }
         </div>
         </div>
         </div>
    </div>
  )
}

export default SerialCodeScan
