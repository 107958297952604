
import React, { useEffect, useState } from 'react';
import Table from '../../../component/Table/Table';
import { Pagination, Modal } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import Loader from '../../../component/Loader';
import { getreturnedContainerBoxSummaryItemDetail, getreturnedContainerBoxSummaryItemDetailExport } from '../../../store/user/actions';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { resettrailDataState } from '../../../store/user/userSlice';
import DefaultModal from '../../../component/DefaultModal/DefaultModal';

const OtherProduct = () => {
    const [formValues, setFormValue] = useState([]);
    const [current, setCurrent] = useState(1);
    const [tableData, setTableData] = useState([]);
    const [limitData, setLimitData] = useState(10);
    const [totalData, setTotalData] = useState(0);
    const [showFull, setShowFull] = useState(false); // Changed initial state to false
    const [selectedImage, setSelectedImage] = useState(''); // Added state to store the selected image URL
    const returnedContainerBoxSummaryItemDetail = useSelector(state => state.returnedContainerBoxSummaryItemDetail);
    const returnedContainerBoxSummaryItemDetailExport = useSelector(state => state.returnedContainerBoxSummaryItemDetailExport);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { id } = useParams();

    useEffect(() => {
        let data = { ...formValues };
        data["returned_container_box_id"] = id;
        data["page"] = current
        data["limit"] = 10
        dispatch(getreturnedContainerBoxSummaryItemDetail(data));
    }, []);
    const handlePageChange = (page,limit) => {
        setCurrent(page);
        setLimitData(limit)
        dispatch(getreturnedContainerBoxSummaryItemDetail({page,limit:limit}))
    };
    useEffect(() => {
        // setTotalData(returnedContainerBoxSummaryItemDetail.data.total);
        let sno = (current - 1) * 10 + 1;
        const FakeTableData = returnedContainerBoxSummaryItemDetail && returnedContainerBoxSummaryItemDetail.data.data.map((item, i) => {
            return [
                <h6>{sno + i}</h6>,
                <h6>{item.name}</h6>,
                <div><h6></h6></div>,
                <h6>{item.unit_of_measurement}</h6>,
                <div className='image_prod'>
                    {/* Update onClick to directly set showFull to true and selectedImage to item.large_image_url */}
                    <img src={item.other_product_image} alt="img" onClick={() => { setSelectedImage(item.other_product_image); setShowFull(true); }} style={{height:"47px"}} />
                </div>,
            ];
        });
        setTableData(FakeTableData);
    }, [returnedContainerBoxSummaryItemDetail]);

    const TemplateTableData = [
        { heading: "Sr.No." },
        { heading: "Product Name" },
        { heading: "Qty" },
        { heading: "UOM" },
        { heading: "Product Picture" },
    ];

    const downloadUrl = (limit) => {
        dispatch(getreturnedContainerBoxSummaryItemDetailExport({ page: current, limit: limit, returned_container_box_id: id }));
    };

    useEffect(() => {
        if (returnedContainerBoxSummaryItemDetailExport.isSuccess) {
            window.open(returnedContainerBoxSummaryItemDetailExport.data.download_url);
            dispatch(resettrailDataState());
        }
    }, [returnedContainerBoxSummaryItemDetailExport]);

    return (
        <div>
            <div className='d-flex justify-content-end mb-4'>
                <div className="primary_but">
                    <Link onClick={() => downloadUrl()} rel="noopener noreferrer" className='but_blue'>
                        <img src="/Images/exportFile.svg" />Export File
                    </Link>
                </div>
            </div>
            {returnedContainerBoxSummaryItemDetail.isLoading && <div className="loader_bg"><Loader /></div>}
            <div className='table-bg'>
                <Table
                    gridTemplateColumnsHeader="20% 20% 20% 20% 20%"
                    gridTemplateColumnsBody="20% 20% 20% 20% 20%"
                    columns={TemplateTableData}
                    data={tableData}
                    Loader={true}
                />
                <Pagination
                    current={current}
                    onChange={handlePageChange}
                    total={returnedContainerBoxSummaryItemDetail.pagination ? returnedContainerBoxSummaryItemDetail.pagination.total : 0}
                    pageSize={limitData}
                />
            </div>
            <DefaultModal
                show={showFull}
                onHide={() => setShowFull(false)}
                className="add-modal"
                style={{ paddingRight: "0px" }}
                centered={false}
                showHeader={false}
                size=""
                showFooter={false}
            >
                <div className="modal-heading">
                    <h4></h4>
                    <div className="close-icon">
                        <img src="/Images/closeBlack.svg" onClick={() => setShowFull(false)} />
                    </div>
                </div>
                {/* Render the selected image in the modal */}
                <img src={selectedImage} alt="Large Image" style={{ width: '100%', height: 'auto' }} />
                </DefaultModal>
        </div>
    );
};

export default OtherProduct;
