import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useParams } from 'react-router-dom';
import { getlooseReturnBagsStoreAll } from '../../../store/user/actions';
import Scanner from '../../PackageScanOne/Scanner';
import DefaultModal from '../../../component/DefaultModal/DefaultModal';

const AddNewBagModal = ({ partyOption,mClose,scanModal }) => {
  const initialValues = {
    loose_return_id: "",
    party_id: "",
    bag_number: "",
  };

  const [formValues, setFormValues] = useState([]);
  const [looseReturnId, setLooseReturnId] = useState (0)
  const looseReturnBagsStoreAll = useSelector((state) => state.looseReturnBagsStoreAll);
  const { id } = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    // Set the 'looseReturnId' state with the 'id' from the URL
    setLooseReturnId(id);
  }, [id]);

  // useEffect(() => {
  //   const storedFormData = JSON.parse(localStorage.getItem('primaryScanData'));
  //   if (storedFormData?.LooseReturn) {
  //     setLooseReturnId(storedFormData.LooseReturn.id)
  //   }
  // }, []);

  const handleInputValueChange = (index, party_id, value) => {
    var oldData={...formValues};
    oldData[index]={
    party_id,
    bag_number:value,
    loose_return_id:looseReturnId
    }
    setFormValues(oldData)

  };
console.log(formValues);
  const addLooseBag = () => {
    // Dispatch action with formValues data
    dispatch(getlooseReturnBagsStoreAll({ data:formValues }));
    if (!looseReturnBagsStoreAll.isLoading) {
      mClose(false);
    }
  };

  return (
    <div>
      <div className="pt-4">
        <div className="primary_input_section">
          {Array.isArray(partyOption.data) &&
            partyOption.data.map((item, index) => (
              <div key={index} className="col-12 mb-4 position-relative">
                <label className="select-label">{item.name}</label>
                <input
                  placeholder={`Enter ${item.name} Bag Number`}
                  className="input-custom-primary"
                  type="text"
                  onChange={(e) => handleInputValueChange(index,item.id, e.target.value)}
                  value={formValues[index]?.bag_number || ""}
                />
                  <img src="/Images/scan_input.svg" className='scan_input_icon'/> 
                {/* <img
                  src="/Images/scan-two.svg"
                  className="scan_input_icon"
                  alt={`Scan ${item.name}`}
                  onClick={handleScannerOpen}
                /> */}
              </div>
            ))}
          <div className="package_butn mt-4 add_butt">
            <Link className="suffix_bag" onClick={addLooseBag}>
              Submit
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddNewBagModal;
