import React, { useEffect, useState } from 'react'
import Dropdown from '../../../component/Dropdown/DropDownControlled';
import { useDispatch, useSelector } from 'react-redux';
import { getcourierOption, getgateEntryPassOption, getpartyOption } from '../../../store/user/actions';
import { DatePicker } from 'antd';
import { Link } from 'react-router-dom';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';


dayjs.extend(customParseFormat);
var utc = require('dayjs/plugin/utc')
var timezone = require('dayjs/plugin/timezone')
dayjs.extend(utc)
dayjs.extend(timezone)
const BagFilter = ({ filterValue }) => {
  const initialValues = {
    pod_number: "",
    party_id: "",
    opening_date: "",
    token_id: "",
    gate_incoming_pass_id: "",
    carier_id: "",
    delay_days: "",
    bag_breach_date: "",
  };
  const dateStr = "2024-03-05T15:54:54.000Z";

// Create a new Date object from the ISO date string
const date = new Date(dateStr);

// Extract day, month, and year
const day = String(date.getUTCDate()).padStart(2, '0');
const month = String(date.getUTCMonth() + 1).padStart(2, '0');
const year = date.getUTCFullYear();

// Format the date as dd/mm/yyyy
const formattedDate = `${day}/${month}/${year}`;
  const [formValues, setFormValue] = useState(initialValues);
  const [changeDate, setChangeDate] = useState(null);
  const courierOption = useSelector(state => state.courierOption)
  const partyOption = useSelector(state => state.partyOption)
  const gateEntryPassOption = useSelector(state => state.gateEntryPassOption)
  const dispatch = useDispatch()

  // useEffect(() => {
  //   filterValue(formValues)
  // }, [formValues])
  useEffect(() => {
    filterValue(cleanedFormValues()); // Send only the selected filter values
  }, [formValues]);
  useEffect(() => {
    dispatch(getcourierOption({}))
    dispatch(getgateEntryPassOption({}))
    dispatch(getpartyOption({}))
  }, [])
  const handleChange = (name, value) => {
    let data = { ...formValues };
    console.log("Filter Change:", formValues); // Debug log
    data[name] = value;
    setFormValue(data);
  };
  const onChange = (date, dateString) => {
    setChangeDate(dateString); // Store the selected date string in the state
    handleChange("bag_breach_date", dateString); // Update the breach_date field in the formValues state
  }
  const cleanedFormValues = () => {
    const cleanedValues = {};
    for (const key in formValues) {
      if (formValues[key] !== "") {
        cleanedValues[key] = formValues[key];
      }
    }
    return cleanedValues;
  };


  return (
    <div className='bag_filter'>
      <div className="row">
        <div className='col-md-3 col-lg-3 col-12 mb-4'>
          <input
            placeholder='POD Number'
            className='input-custom'
            type='text'
            onChange={(e) => handleChange("pod_number", e.target.value)}
            value={formValues.pod_number}
          />

        </div>

        <div className='col-md-3 col-lg-3 col-12 mb-4'>
          <Dropdown
            defaultText="Select portal"
            allowSearch
            // border="1px solid #F8B552"
            backgroundColor="white"
            boxShadow="0px 2px 6px #13124212"
            color="#1C1C1C"
            fontWeight="700"
            width="100%"
            height="48px"
            selectedId={formValues?.party_id}
            onOptionClick={(item) => handleChange("party_id", item?.id)}
            options={partyOption.data}
          />
        </div>
        <div className='col-md-3 col-lg-3 col-12 mb-4'>
          <Dropdown
            allowSearch
            defaultText="Select Courier Name"
            // variant="outlinedGreen"
            // border="1px solid #F8B552"
            backgroundColor="white"
            boxShadow="0px 2px 6px #13124212"
            color="#1C1C1C"
            fontWeight="700"
            width="100%"
            height="48px"
            selectedId={formValues?.carier_id}
            onOptionClick={(item) => handleChange("carier_id", item?.id)}
            options={courierOption.data}
          />
        </div>
        <div className='col-md-3 col-lg-3 col-12 mb-4'>
          <Dropdown
            allowSearch
            defaultText="Gate Entry Number"
            // variant="outlinedGreen"
            // border="1px solid #F8B552"
            backgroundColor="white"
            boxShadow="0px 2px 6px #13124212"
            color="#1C1C1C"
            fontWeight="700"
            width="100%"
            height="48px"
            selectedId={formValues?.gate_incoming_pass_id}
            onOptionClick={(item) => handleChange("gate_incoming_pass_id", item?.id)}
            options={gateEntryPassOption.data}
          />
        </div>
        <div className='col-md-3 col-lg-3 col-12 mb-4'>
          {/* <label className='select-label'>Delay Duration</label> */}
          <DatePicker
            className='input-custom'
            format="YYYY-MM-DD"
            placeholder="Breach Date"
            onChange={onChange} 
            // onChange={(e) => handleChange("breach_date", e)}
            // value={formValues.breach_date}
            
          />
        </div>



        <div className='col-md-3 col-lg-3 col-12 mb-4'>
          <input
            placeholder='Delay Time'
            className='input-custom'
            type='text'
            onChange={(e) => handleChange("delay_days", e.target.value)}
            value={formValues.delay_days}
          />
        </div>
        {/* <div className='col-md-3 col-lg-3 col-12 mb-4'>
         
          <DatePicker
            className='input-custom'
            placeholder="Actual Box opening Date"
            onChange={(e) => handleChange("opening_date", e)}
            value={formValues.handover_date}
          />
        </div> */}
        <div className='col-md-3 col-lg-3 col-12 mt-2'>
        <div className="primary_but">
          <Link onClick={() => setFormValue(initialValues)} className="clear_filter"><img src="/Images/trash.svg" /> Clear Filter</Link>
        </div>
        </div>
      </div>
    </div>
  )
}

export default BagFilter
