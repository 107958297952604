import React, { useEffect, useState } from 'react'
import { getboxDashboardWebReturnInventory, getboxDashboardWebReturnInventoryProcessWise } from '../../../store/user/actions'
import { useDispatch, useSelector } from 'react-redux'



const optionsSelect = [

    { value: "today", label: "Today" },
    { value: "this_week", label: "This Week" },
    { value: "last_week", label: "Last Week" },
    { value: "this_month", label: "This Month" },
    { value: "last_month", label: "Last Month" },
    { value: "last_three_month", label: "Last 3 Month" },
    { value: "this_year", label: "This Year" },
    { value: "last_year", label: "Last Year" },
    { value: "all", label: "All" },
  ];
const ReturnInventory = () => {
    const [filter, setFilter] = useState("today");
    const boxDashboardWebReturnInventory = useSelector(state => state.boxDashboardWebReturnInventory.data)
    const boxDashboardWebReturnInventoryProcessWise = useSelector(state => state.boxDashboardWebReturnInventoryProcessWise)
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(getboxDashboardWebReturnInventory({ filter}))
    }, [filter])
    useEffect(() => {
        dispatch(getboxDashboardWebReturnInventoryProcessWise({}))
    }, [])
    const handleFilterChange = (e) => {
        setFilter(e.target.value);
    }
    console.log(boxDashboardWebReturnInventoryProcessWise.data);
    return (
        <div>
            <div className='heading_select'>
                <h2>Return Inventory</h2>
                <div className="custom-select">
                    <select className='selectArea' 
                      value={filter}
                      onChange={handleFilterChange}
                        >
                            {
                                optionsSelect && optionsSelect.map((item)=>(
                                <option value={item.value}>{item.label}</option>
                                ))
                            } 
                            </select>
                </div>
                </div>
            <div className='row padding_left'>
                <div className='col-lg-3 col-md-3 col-12 card-common'>
                    <div className="pale-red">

                        <h2>{boxDashboardWebReturnInventory.primary_scan_inventory}</h2>
                        <p>Primary scan inventory</p>
                    </div>
                </div>
                <div className='col-lg-3 col-md-3 col-12 card-common'>
                    <div className="alice-blue">
                        <h2>{boxDashboardWebReturnInventory.secondory_scan_inventory}</h2>
                        <p>Secondary scan inventory</p>
                    </div>
                </div>
                <div className='col-lg-3 col-md-3 col-12 card-common'>
                    <div className="light-green">
                        <h2>{boxDashboardWebReturnInventory.repacking_scan_inventory}</h2>
                        <p>Repacking scan inventory</p>
                    </div>
                </div>
                <div className='col-lg-3 col-md-3 col-12 card-common'>

                    <div className="light-orange">
                        <h2>{boxDashboardWebReturnInventory.mrp_inventory}</h2>
                        <p>MRP inventory </p>
                    </div>
                </div>
                <div className='col-lg-3 col-md-3 col-12 card-common'>
                    <div className="light-pink">
                        <h2>{boxDashboardWebReturnInventory.sorting_inventory}</h2>
                        <p>Sorting  inventory</p>
                    </div>
                </div>

            </div>
            <div className='white_box'>
                <div className='heading_select pt-4 pb-4'>
                    <h2>Process Wise</h2>
                </div>
                <div className='scroll_sec_table'>
                    <table className="table table-bordered table-sm table_box">

                        <thead className='sticky-top'>
                            <tr>
                                <th>Process</th>
                                <th>0-7 Days</th>
                                <th>7-15 Days</th>
                                <th>15-30 Days</th>
                                <th>30+ Days</th>
                            </tr>
                        </thead>
                        <tbody>
                            {
                                boxDashboardWebReturnInventoryProcessWise.data.map((item) => (
                                    <tr>
                                        <td>{item.process}</td>
                                        <td>{item.today_to_last_seventh_day}</td>
                                        <td>{item.last_seventh_day_to_last_fifteenth_day}</td>
                                        <td>{item.last_fifteenth_day_to_last_thirtieth_day}</td>
                                        <td>{item.more_than_thirty_days}</td>

                                    </tr>
                                ))
                            }
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
}

export default ReturnInventory
