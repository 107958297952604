


import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import { useDropzone } from 'react-dropzone'
import Dropdown from '../../../component/Dropdown/DropDownControlled';
import { getforwardBackward, getreturnedContainerDropdowns } from '../../../store/user/actions';
import { useDispatch, useSelector } from 'react-redux';





const FbiForm = () => {
  const initialValues = {
    party_id: "",
    csv_file: "",
  };
  const navigate = useNavigate();
  const [documentFiles, setDocumentFiles] = useState([]);
  const [formValues, setFormValue] = useState(initialValues);
  const [files, setFiles] = useState([]);
  const [index, setIndex] = useState(0);
  const returnedContainerDropdowns = useSelector(state => state.returnedContainerDropdowns)
  const dispatch = useDispatch()
  const handleChange = (name, value) => {
    let data = { ...formValues };
    data[name] = value;
    setFormValue(data);
  };


  // const handleFileChange = (e, index) => {
  //   const selectedFiles = e.target.files;
  //   const oldFiles = [...files];
  //   const newFiles = [];
  //   for (let i = 0; i < selectedFiles.length; i++) {
  //     newFiles.push(selectedFiles[i]);
  //   }
  //   oldFiles[index] = newFiles;
  //   setFiles(oldFiles);
  // };
  const handleFileChange = (e) => {
    const selectedFiles = e.target.files;
    const newFiles = [...selectedFiles]; // Combine existing and new files
    setFiles(newFiles);
   
  };
  console.log(files);
  const handleRemoveFile = (index, fileindex) => {
    // const newFiles = [...files];
    // newFiles[index].splice(fileindex, 1);

    setFiles([]);
  };

  useEffect(() => {
    dispatch(getreturnedContainerDropdowns({}))
  }, [])

  const forwardSubmit = () => {
    const formData = new FormData();
    formData.append("party_id", formValues.party_id); // Add other form data properties if any

    // Assuming you have a single file input element with appropriate state or variable
    if (files) {
      formData.append("csv_file", files[0]); // Directly attach the single file
      console.log(files[0]);
    } else {
      // Handle scenario where no file is selected (optional)
      console.error("No file selected for upload");
    }
    dispatch(getforwardBackward(formData));
  };
  // const handleRemoveDocFile = (index, fileindex) => {
  //   const newFiles = [...documentFiles];
  //   newFiles[index].splice(fileindex, 1);

  //   setDocumentFiles(newFiles);
  // };
  const truncateFileName = (fileName, maxLength = 15) => {
    if (fileName.length <= maxLength) {
      return fileName;
    } else {
      return fileName.substring(0, maxLength) + '...';
    }
  };
  return (
    <div>
      <div className='upload_box fbi_upload mt-4'>
        <div className='primary_input_section'>

          <h2>Attach File</h2>
          <div className='col-md-12 col-lg-12 col-12 mb-4'>
            <label className='select-label'>Select Portal</label>
            <Dropdown
              allowSearch
              border="1px solid #F8B552"
              color="#1C1C1C"
              fontWeight="700"
              width="100%"
              height="48px"
              selectedId={formValues?.party_id}
              onOptionClick={(item) => handleChange("party_id", item?.id)}
              options={returnedContainerDropdowns.data.portal_names.map(item => ({ id: item.id, label: item.name }))}
            />
          </div>
          <div className="file-preview-container position-relative upload-pic-box">
            <input
              type="file"
              className="hidden-file-input"
              onChange={(e) => handleFileChange(e, index)}
              accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
            />
            <div className='mb-2'>
              <img src="/Images/camera.svg" />
              <div>
                <h5>Upload Document</h5>
                <small>File size minimum 10 MB</small>
              </div>
            </div>
            <div className='d-flex'>
              {files && files.map((file, fileIndex) => (
                <div className="file-preview">
                   <label>{truncateFileName(file.name)}</label>
                   <button onClick={() => handleRemoveFile(index, fileIndex)} className='close_red'><img src='/Images/redClose.svg' /></button> 
                </div>
              ))}
            </div>
          </div>

          <div className='fb_but'>
            <button className='suffix_bag d-block' onClick={forwardSubmit}>Submit</button>
          </div>
        </div>

      </div>
    </div>
  )
}

export default FbiForm
