
import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import Dropdown from '../../component/Dropdown/DropDownControlled';
import { getclaimCreate, getclaimEmailTemplateDropdown, getreturnedContainerDropdowns } from '../../store/user/actions';
import DefaultModal from '../../component/DefaultModal/DefaultModal';
import { resetclaimCreateState } from '../../store/user/userSlice';

const AddManualClaims = () => {
  const initialValues = {
    video_file: "",
    actual_count: "",
    manual_count: "",
    ticket_number: "",
    order_no:"",
    no:"",
    party_id:"",
    claim_reason_id:"",
    ext_ticket_no:"",
    description:""
  };
  const navigate = useNavigate();
  const [formValues, setFormValue] = useState(initialValues);
  const [show, setShow] = useState(false);
  const [documentFiles, setDocumentFiles] = useState([]);
  const [videoFiles, setVideoFiles] = useState([]);
  const [files, setFiles] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [formErrors, setFormErrors] = useState({});
  const claimEmailTemplateDropdown = useSelector(state => state.claimEmailTemplateDropdown)
  const claimCreate = useSelector(state => state.claimCreate)
  const dispatch = useDispatch()


  const handleChange = (name, value) => {
    let data = { ...formValues };
    data[name] = value;
    setFormValue(data);
  };

  const handleVideoFileChange = (e) => {
    const selectedFiles = e.target.files;
    setVideoFiles([...selectedFiles]);
  };
  const handleRemoveVideoFile = (fileIndex) => {
    const newFiles = [...videoFiles];
    newFiles.splice(fileIndex, 1);
    setVideoFiles(newFiles);
  };

    // validation-start
  useEffect(() => {
    if (Object.keys(formErrors).length > 0) {
    }
  }, [formErrors]);

  const validate = (values) => {
    let isValid = true;
    const errors = {};
    if (!values.party_id) {
      isValid = false;
      errors.party_id = "Party name is required ";
    }
    if (!values.claim_reason_id) {
      isValid = false;
      errors.claim_reason_id = "Claim reason is required";
    }
    if (!values.ext_ticket_no) {
      isValid = false;
      errors.ext_ticket_no = "Ticket number is required";
    }
    if (!values.description) {
      isValid = false;
      errors.description = "Description is required";
    }

    return {
      isValid,
      errors,
    };
  };
  const handleSubmit = async () => {
    setError(null);
    setLoading(true);
    let check = validate(formValues);
    setFormErrors(check.errors);
    // console.log(primaryScanForm);
    // console.log(check.errors);
    if (check.isValid) {
      console.log(check);
    const formData = {
      party_id: formValues.party_id,
      claim_reason_id: formValues.claim_reason_id,
      ext_ticket_no: formValues.ext_ticket_no,
      order_no: formValues.order_no || null ,
      no: formValues.no || null,
      description: formValues.description
    };
      // // Add order_number if it's filled out
      // if (formValues.order_number) {
      //   formData.order_number = formValues.order_number;
      // }
  
    try {
      // Dispatch the action and wait for the response
      await dispatch(getclaimCreate(formData));
      
      // If successful, show the modal
     
    } catch (error) {
      console.error('Error occurred:', error);
      // showNotification('Please fill valid info')
      // Handle error if needed
    }
  }
  };
       // navigate call
     useEffect(() => {
      if (claimCreate.isSuccess || claimCreate.status === 1) {
        dispatch(resetclaimCreateState())
        setShow(true);
         navigate('/return-claims');
       }
    }, [claimCreate]);
   
  //   // navigate call
  //   useEffect(() => {
  //     if (claimCreate.isSuccess || claimCreate.status === 1) {
  //         navigate('/email-templates');
  //     }
  // }, [claimCreate]);
  useEffect(() => {
    dispatch(getclaimEmailTemplateDropdown({}))
  }, [])
 
  return (
    <div>
      <div className='heading_select'>
        <div className="d-flex align-items-baseline">
          <h1>Add Manual Claims</h1>
        </div>

      </div>
      <div className='background_bg'>
        <div className='primary_input_section'>
          <div className='row'>
            <div className='col-md-4 col-lg-4 col-12 mb-4'>
              <label className='select-label'>Select Portal</label>
              <Dropdown
                allowSearch
                border="1px solid #F8B552"
                color="#1C1C1C"
                fontWeight="700"
                width="100%"
                height="48px"
                selectedId={formValues?.party_id}
                onOptionClick={(item) => handleChange("party_id", item?.id)}
                options={claimEmailTemplateDropdown.data.parties.map(item => ({ id: item.id, label: item.name }))}
              />
               <span className='error-msg'>{formErrors.party_id}</span>
            </div>
            <div className='col-md-4 col-lg-4 col-12 mb-4'>
              <label className='select-label'>Claim Type</label>
              <Dropdown
                allowSearch
                border="1px solid #F8B552"
                color="#1C1C1C"
                fontWeight="700"
                width="100%"
                height="48px"
                selectedId={formValues?.claim_reason_id}
                onOptionClick={(item) => handleChange("claim_reason_id", item?.id)}
                options={claimEmailTemplateDropdown.data.claim_reason.map(item => ({ id: item.id, label: item.name }))}
              />
               <span className='error-msg'>{formErrors.claim_reason_id}</span>
            </div>
            <div className='col-md-4 col-lg-4 col-12 mb-4'>
              <label className='select-label'>External Ticket Number</label>
              <input
                placeholder=''
                className='input-custom-primary'
                type='text'
                onChange={(e) => handleChange("ext_ticket_no", e.target.value)}
                value={formValues.ext_ticket_no}
              />
               <span className='error-msg'>{formErrors.ext_ticket_no}</span>
            </div>
            <div className='col-md-4 col-lg-4 col-12 mb-4'>
              <label className='select-label'>Order Number</label>
              <input
                placeholder='Order Number'
                className='input-custom-primary'
                type='text'
                onChange={(e) => handleChange("order_no", e.target.value)}
                value={formValues.order_no}
              />
               
            </div>
            <div className='col-md-4 col-lg-4 col-12 mb-4'>
              <label className='select-label'>Claim Number</label>
              <input
                placeholder='Claim Number'
                className='input-custom-primary'
                type='text'
                onChange={(e) => handleChange("no", e.target.value)}
                value={formValues.no}
              />
               
            </div>
           
          </div>
          <div className='col-md-12 col-lg-12 col-12 mb-4'>
            <label className='select-label'>Description</label>
            <textarea
              className='input-custom-primary'
              type='text'
              onChange={(e) => handleChange("description", e.target.value)}
              value={formValues.description}
            />
              <span className='error-msg'>{formErrors.description}</span>
          </div>
          <div className='col-lg-4 col-md-4 mx-auto'>
            {/* <div className="video-upload-box media_up position-relative">

              <input
                className="hidden-doc-input"
                type="file"
                multiple
                onChange={handleVideoFileChange}
              />
              <div>
                <img src="/Images/cloud.svg" />
                <div>
                  <h5>Upload Media</h5>
                  <small>File size minimum 10 MB</small>
                </div>
              </div>
              <div className='d-flex'>
                {videoFiles.map((file, fileIndex) => (
                  <div className="file-preview" key={fileIndex}>
                    <label>{file.name}</label>
                    <button onClick={() => handleRemoveVideoFile(fileIndex)} className='close_red'><img src='/Images/redClose.svg' /></button>
                  </div>
                ))}
              </div>
            </div> */}

            <div className='package_butn sub_margin'>
              <Link className='suffix_bag' onClick={handleSubmit}>Submit</Link>
            </div>
          </div>
        </div>

      </div>
      <DefaultModal
                show={show}
                onHide={() => setShow(false)}
                className="image-doc-modal success_modal_green"
                style={{ paddingRight: "0px" }}
                centered={false}
                showHeader={false}
                size=""
                showFooter={false}
            >
                <div className="modal-heading">
                    <h4></h4>
                    <div className="close-icon">
                        <img src="/Images/closeBlack.svg" onClick={() => setShow(false)} />
                    </div>
                </div>
                <div className='success_modal'>
                  <img src="/Images/greenCheck.svg"/>
                  <h3>Successfully Created</h3>
                  <p>Claim ID 123456MB571 has been issued for a claim that has been successfully processed and created.</p>
                </div>
            
            </DefaultModal>
    </div>
  )
}

export default AddManualClaims

