import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { getturnAroundTimeWise } from '../../../store/user/actions';

const TATCard = () => {
    const [filter, setFilter] = useState("last_month");
   
    const turnAroundTimeWise = useSelector(state => state.turnAroundTimeWise.data)
    const dispatch = useDispatch()
    // Calculate percentages
    const totalBags = turnAroundTimeWise.total_bags_to_open || 1; // Avoid division by zero
    const totalPackages = turnAroundTimeWise.total_packages_to_be_scan || 1; // Avoid division by zero

    const totalBagsOpenedOnTimePercentage = ((turnAroundTimeWise.total_bags_opened_on_time / totalBags) * 100).toFixed(2);
    const totalPackageOpenOnTimePercentage = ((turnAroundTimeWise.total_package_open_on_time / totalPackages) * 100).toFixed(2);

    useEffect(() => {
        dispatch(getturnAroundTimeWise({filter}))
    }, [filter])
  return (
    <div>
      <div className='heading_select'>
                <h2>TAT - Turn around time </h2>
        </div>
        <div className='row padding_left'>
        <div className='col-lg-3 col-md-3 col-12 card-common'>
                    <div className="dark-brown">
                    <h2>{turnAroundTimeWise.total_packages_to_be_scan}</h2> 
                        <p>Total package to be scan</p>
                    </div>
                </div>
                <div className='col-lg-3 col-md-3 col-12 card-common'>
                    <div className="light-pitch">
                        {/* <h2>{dashboardCard.totalPandingSampleRequest}</h2> */}
                        <h2>{turnAroundTimeWise.total_bags_to_open}</h2> 
                        <p>Total bags to open</p>
                    </div>
                </div>
            <div className='col-lg-3 col-md-3 col-12 card-common'>
                    <div className="dark-gray">
                        {/* <h2>{dashboardCard.totalCompleteSampleRequest}</h2> */}
                           <h2>
                            {turnAroundTimeWise.total_bags_opened_on_time} 
                            <span style={{fontSize:"17px"}}> ({totalBagsOpenedOnTimePercentage}%)</span>
                            </h2>
                        <p>Total bags opened on time</p>
                    </div>
                </div>
                <div className='col-lg-3 col-md-3 col-12 card-common'>
                    <div className="pale-red">
                    <h2>
                    {turnAroundTimeWise.total_package_open_on_time} 
                    <span style={{fontSize:"17px"}}> ({totalPackageOpenOnTimePercentage}%)</span>
                    </h2>
                        <p>Total Claims Accepted</p>
                    </div>
                </div>
           
              
            </div>
    </div>
  )
}

export default TATCard
