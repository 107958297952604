
import React, { useEffect, useState } from 'react'
import { Link, useNavigate, useParams, useLocation, useSearchParams } from 'react-router-dom';
import { useDropzone } from 'react-dropzone'
import { getuploadCount } from '../../store/user/actions';
import { useDispatch, useSelector } from 'react-redux';
import Loader from '../../component/Loader';





const UploadCountWithout = () => {
    const { id, total_packages } = useParams(); 
    const initialValues = {
        short_qty: 0,
        received_packages: ""
    };
    const navigate = useNavigate();
    const [formValues, setFormValue] = useState(initialValues);
    // const [recivedInput, setRecivedInput] = useState(false);
    const [packageCounter, setPackageCounter] = useState(total_packages);
    const [formErrors, setFormErrors] = useState({});
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [scanCount, setScanCount] = useState(0);
    // const { total, totalScan } = useParams();
    const [files, setFiles] = useState([]);
    const [index, setIndex] = useState(0);
    const [scanCounter, setScanCounter] = useState(0);
    const uploadCount = useSelector(state => state.uploadCount)

    const dispatch = useDispatch()
    // const id = useParams()
    const [looseReturnId, setLooseReturnId] = useState(0)
    const location = useLocation();

    // Extracting query parameters
    const queryParams = new URLSearchParams(location.search);
    // const total = queryParams.get('total_packages');
    const totalFromSearch = queryParams.get('total_packages');
    const totalRecive = queryParams.get('received_packages');
  
    const handleChange = (value) => {
        const receivedPackages = parseInt(value);
        if (!isNaN(receivedPackages)) {
            const receivedPackagesNonNegative = Math.max(receivedPackages, 0);
          const totalPackages = parseInt(packageCounter || totalFromSearch || 0); // Use default value if total_packages or totalFromSearch is not available
          const shortQty = totalPackages - receivedPackagesNonNegative > 0 ? totalPackages - receivedPackagesNonNegative : 0;
            setFormValue(prevState => ({ ...prevState, short_qty: shortQty, received_packages: receivedPackagesNonNegative }));
        } 
       else {
          setFormValue(prevState => ({ ...prevState, received_packages: value }));
        }
      };

    // const handleChange = (value) => {
    //     const receivedPackages = parseInt(value);
    //     if (!isNaN(receivedPackages)) {
    //         // Ensure receivedPackages is not negative
    //         const receivedPackagesNonNegative = Math.max(receivedPackages, 0);
    //         const totalPackages = parseInt(packageCounter || totalFromSearch || 0);
    //         const shortQty = totalPackages - receivedPackagesNonNegative > 0 ? totalPackages - receivedPackagesNonNegative : 0;
    //         setFormValue(prevState => ({ ...prevState, short_qty: shortQty, received_packages: receivedPackagesNonNegative }));
    //     }
    //     else {
    //         setFormValue(prevState => ({ ...prevState, received_packages: value }));
    //     }
    // };
    
      useEffect(() => {
        if (packageCounter || totalFromSearch) { // Use default value if total_packages or totalFromSearch is not available
          const totalPackages = parseInt(packageCounter || totalFromSearch);
          const receivedPackages = parseInt(formValues.received_packages);
          const shortQty =( totalPackages - receivedPackages)>0?totalPackages - receivedPackages:0;
        //   console.log("Total Packages:", totalPackages);
        //   console.log("Received Packages:", receivedPackages);
        //   console.log("Short Qty:", shortQty);
          setFormValue(prevState => ({ ...prevState, short_qty: shortQty, received_packages: receivedPackages }));
        }
      }, [packageCounter, totalFromSearch, formValues.received_packages]);
    useEffect(() => {
        const storedFormData = JSON.parse(localStorage.getItem('primaryScanData'));
        if (storedFormData?.LooseReturn) {
            setLooseReturnId(storedFormData.LooseReturn.id)
            setPackageCounter(storedFormData.LooseReturn.total_packages); // Update packageCounter upon initial data retrieval
          }
    }, [])

    // console.log(formValues.short_qty);
    // useEffect(() => {
    //     const storedFormData = JSON.parse(localStorage.getItem('primaryScanData'));
    //     // setRecivedInput(storedFormData.scan_type)
    //     const shortQty = formValues.total_packages_counter - formValues.total_scan_counter;
    //     // Update formValues with Short Qty
    //     setFormValue(prevState => ({
    //         ...prevState,
    //         short_qty: shortQty
    //     }));
    //     if (storedFormData?.LooseReturn) {
    //         setLooseReturnId(storedFormData.LooseReturn.id)
    //     }
    //     // setScanCount(scanCount);

    // }, [formValues.total_packages_counter, formValues.total_scan_counter, id]);


    const handleGoBack = () => {
        // Navigate back to the previous item
        navigate(-1);
    };

    const handleFileChange = (e, index) => {
        const selectedFiles = e.target.files;
        const oldFiles = [...files];
        const newFiles = [];
        for (let i = 0; i < selectedFiles.length; i++) {
            newFiles.push(selectedFiles[i]);
        }
        oldFiles[index] = newFiles;
        setFiles(oldFiles);
    };

    const handleRemoveFile = (index, fileindex) => {
        const newFiles = [...files];
        newFiles[index].splice(fileindex, 1);

        setFiles(newFiles);
    };
    const handleSubmit = (e, name, value) => {
        setError(null);
        setLoading(true);
        let check = validate(formValues);
        let data = { ...initialValues };
        data[name] = value;
        e.preventDefault();
        setFormErrors(check.errors);
        if (check.isValid) {
            setLoading(false);
            const formData = new FormData();
            // formData.append('received_packages', total);
            if (files.length > 0) {
                for (let i = 0; i < files.length; i++) {
                    formData.append(`images[${i}]`, files[i][0]);
                }
            }
            // Construct boxes data
            formData.append('id', looseReturnId);
            formData.append("received_packages", formValues.received_packages)
            // formData.append("total_scan",id);
            dispatch(getuploadCount(formData));
        }
        // console.log(formData);
    };
    useEffect(() => {
        const storedFormData = JSON.parse(localStorage.getItem('primaryScanData'));
        // if(storedFormData.LooseReturn){
        if (storedFormData?.LooseReturn?.id && storedFormData?.LooseReturn?.is_close !== undefined) {
            const oldFormValue = { ...formValues }
            setPackageCounter(storedFormData.LooseReturn.total_packages);
            oldFormValue.loose_return_id = storedFormData.LooseReturn.id
            setFormValue(oldFormValue)
            setLooseReturnId(storedFormData.LooseReturn.id);
            // console.log(storedFormData.LooseReturn.total_packages);
        }


    }, [])
    useEffect(() => {
        let data = { ...formValues };
        if (id) {
            data["loose_return_id"] = id;
        } else {
            const storedFormData = JSON.parse(localStorage.getItem('primaryScanData'));
            const id = storedFormData.LooseReturn.id;
            data["loose_return_id"] = id;
        }
    }, [id, scanCounter])
    // navigate call
    useEffect(() => {
        if (uploadCount.isSuccess || uploadCount.status === 1) {
            navigate('/primary-scan');
        }
    }, [uploadCount.isSuccess, uploadCount.status]);
    // end
    // console.log(recivedInput);
    // validation-start
    useEffect(() => {
        if (Object.keys(formErrors).length > 0) {
        }
    }, [formErrors]);

    const validate = (values) => {
        let isValid = true;
        const errors = {};
        if (!files.length) {
            isValid = false;
            errors.files = "files is required";
        }
        if (!values.received_packages) {
            isValid = false;
            errors.received_packages = "Received Packages required";
        }
        return {
            isValid,
            errors,
        };
    };
    return (
        <div>
            <div className='heading_select'>
                <div className="d-flex align-items-baseline" onClick={handleGoBack}>
                    <img style={{ cursor: "pointer" }} src="/Images/arrow-left.svg" />
                    <h1>Upload Pictures</h1>
                </div>
            </div>
            <div className='upload_box '>
                <div className='primary_input_section'>
                    <div className='col-12 mb-4 position-relative'>
                        <label className='select-label'>Total Received Qty</label>
                       
                           
                            <input
                                className='input-custom-primary'
                                type='number'
                                min="0"
                                onChange={(e) => handleChange(e.target.value)}
                                value={formValues.received_packages}
                            />
                             <span className='error-msg'>{formErrors.received_packages}</span>

                    </div>
                    <div className='col-12 mb-4 position-relative'>
                        <label className='select-label'>Short Qty</label>
                        <div className='disabled_box'>{formValues.short_qty || 0}</div>
                    </div>
                    <div className="file-preview-container position-relative upload-pic-box">
                        <input
                            type="file"
                            multiple
                            className="hidden-file-input"
                            onChange={(e) => handleFileChange(e, index)}
                            accept='image/*'
                        />
                        <div className='mb-2'>
                            <img src="/Images/camera.svg" />
                            <div>
                                <h5>Upload Image</h5>
                                <small>You can upload JPG, PNG</small>
                            </div>
                        </div>
                        <div className='d-flex'>
                            {files[index] && files[index].map((file, fileIndex) => (
                                <div className="file-preview">
                                    <img
                                        src={URL.createObjectURL(file)}
                                        alt={`Preview ${index}`}
                                    />
                                    <button onClick={() => handleRemoveFile(index, fileIndex)} className='close_red'><img src='/Images/redClose.svg' /></button>
                                </div>
                            ))}
                        </div>
                    </div>

                </div>

                {
                    uploadCount.isLoading && <div className="loader_bg"><Loader /></div>
                }
                <span className='error-msg'>{formErrors.files}</span>
                <div className='package_butn mt-4 mb-2'>
                    <Link className='suffix_bag' to="/upload-count" onClick={handleSubmit}>Submit</Link>
                </div>
            </div>
        </div>
    )
}

export default UploadCountWithout

