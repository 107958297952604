


import React, { useState, useEffect } from "react";

import Table from '../../../component/Table/Table'

import { Pagination } from 'antd';
import { Link } from "react-router-dom";
import Dropdown from "../../../component/Dropdown/DropDownControlled";
import BoxFilter from "./BoxFilter";
import DefaultModal from "../../../component/DefaultModal/DefaultModal";
import { useDispatch, useSelector } from "react-redux";
import { getreturnContainerBoxSummary, getreturnedContainerBoxSummaryExport, getreturnedContainerBoxSummaryPictures } from '../../../store/user/actions'
import Loader from "../../../component/Loader";
import { resetExportBox } from "../../../store/user/userSlice";


const BoxTable = () => {

    //   const [currentRequest, setCurrentRequest] = useState(0);
    const [formValues, setFormValue] = useState([]);
    const [current, setCurrent] = useState(1);
    const [show, setShow] = useState(false);
    const [tableData, setTableData] = useState([]);
    const [totalData, setTotalData] = useState(0);
    const [limitData, setLimitData] = useState(10);
    const returnContainerBoxSummary = useSelector(state => state.returnContainerBoxSummary)
    const returnedContainerBoxSummaryExport = useSelector(state => state.returnedContainerBoxSummaryExport)
    const returnedContainerBoxSummaryPictures = useSelector(state => state.returnedContainerBoxSummaryPictures)
    const dispatch = useDispatch()
    const [selectBox, setSelectBox] = useState(true);
    const filterValue = (selectValue) => {
        setSelectBox(selectValue)
    }
    // useEffect(() => {
    // dispatch(getprimaryScanBagList(selectBox))
    // },[selectBox])

    // useEffect(() => {
    //     dispatch(getreturnContainerBoxSummary(selectBox))
    // }, [selectBox])
    useEffect(() => {
        setCurrent(1);
        const requestData = {
            ...formValues,
            ...selectBox,
            // filter: "all",
            page: current,
            limit: limitData,
        };
        dispatch(getreturnContainerBoxSummary(requestData));
    }, [formValues,selectBox]);
    const viewPicture = (id) => {
        dispatch(getreturnedContainerBoxSummaryPictures({ returned_container_box_id: id }))
    }
    const handlePageChange = (page,limit) => {
        setCurrent(page);
        setLimitData(limit)
        const requestData = {
            ...formValues,
            ...selectBox,
            // filter: "all",
            page: page,
            limit: limit,
        };
        dispatch(getreturnContainerBoxSummary(requestData));
    };
    // console.log(returnContainerBoxSummary);
    useEffect(() => {
        let sno = (current - 1) * 10 + 1;
        const FakeTableData = returnContainerBoxSummary && returnContainerBoxSummary.data.data.map((item, i) => {
            return [
                <>
                    <h6>{item.id}</h6>
                </>,

                <h6>{item.box_received_no}</h6>
                ,
            
                <>

                    <h6>{item.rtn_no}</h6>
                </>,
                <>
                    <h6>{item.gate_pass_no}</h6>
                </>,
                <>
                    <h6>{item.carier_name}</h6>
                </>,
                <>
                    <h6>{item.portal_name}</h6>
                </>,
                <>
                    <h6>{item.total_unit_count}</h6>
                </>,
                <>
                    <h6>{item.breached_date}</h6>
                </>,
                <div>
                    <h6>{item.opening_due_date}</h6>
                </div>,
                <div>
                    <h6>{item.delayed_time} Days</h6>
                </div>,
                <div className="view_but_blue">
                    <Link to="" onClick={() => { setShow(true); viewPicture(item.id); }}>View</Link>
                </div>,
                <div className="view_but">
                    <Link to={`/box-summary-details/${item.id}`}>View Pkg</Link>
                </div>,

            ]
        })
        setTableData(FakeTableData);
        // }
    }, [returnContainerBoxSummary])

    const TemplateTableData = [

        {
            heading: "Sr.No.",
        },
        {
            heading: "Box Received Number",
        },
        // {
        //     heading: "POD Number",
        // },
        {
            heading: "Reverse Transaction Number",
        },
        {
            heading: "Gate Entry Number",
        },
        {
            heading: "Courier Name",
        },
        {
            heading: "Portal Name",
        },
        {
            heading: "Total Unit Count",
        },
        {
            heading: "Box Opening Breach Date ",
        },
        {
            heading: "Actual Box Opening Date",
        },
        {
            heading: "Delay Time",
        },
        {
            heading: "Picture And Documents",
        },
        {
            heading: "Action",
        },

    ];
    const downloadUrl = (id) => {
        dispatch(getreturnedContainerBoxSummaryExport({ page: current, limit: limitData, filter:"all", returned_container_box_id: id, portal_id: id, courier_id: id }))
    }
    useEffect(() => {
        if (returnedContainerBoxSummaryExport.isSuccess) {
            window.open(returnedContainerBoxSummaryExport.data.download_url)
            dispatch(resetExportBox())
        }
    }, [returnedContainerBoxSummaryExport])
    // console.log(returnContainerBoxSummary.data.pagination);
    return (
        <div>
            <div className='heading_select'>
                <h1>Box Summary</h1>
                <div className="primary_but">
                    <Link onClick={() => downloadUrl()} rel="noopener noreferrer" className='but_blue'>
                        <img src="/Images/exportFile.svg" />Export File
                    </Link>
                </div>
            </div>
            <BoxFilter
            filterValue={filterValue}
            />
            <div className='white_box'>
                <div className="position-relative">
                    {
                        returnContainerBoxSummary.isLoading && <div className="loader_bg"><Loader /></div>
                    }
                    <div className='table-bg'>
                        <Table
                            gridTemplateColumnsHeader="5% 9% 10% 10% 10% 7% 7% 9% 7% 7% 7% 10%"
                            gridTemplateColumnsBody="5% 9% 10% 10% 10% 7% 7% 9% 7% 7% 7% 10%"
                            columns={TemplateTableData}
                            data={tableData}
                            Loader={true}
                        />
                        <Pagination
                            current={current}
                            onChange={handlePageChange}
                            total={returnContainerBoxSummary.data.pagination ? returnContainerBoxSummary.data.pagination.total : 0}
                            pageSize={limitData}
                            showSizeChanger

                        />
                    </div>

                </div>
            </div>
            <DefaultModal
                show={show}
                onHide={() => setShow(false)}
                className="image-doc-modal image_big_modal"
                style={{ paddingRight: "0px" }}
                centered={false}
                showHeader={false}
                size=""
                showFooter={false}
            >
                <div className="modal-heading">
                    <h4>Pictures and Documents</h4>
                    <div className="close-icon">
                        <img src="/Images/closeBlack.svg" onClick={() => setShow(false)} />
                    </div>
                </div>
                <div className="row">
                    {
                        returnedContainerBoxSummaryPictures.data &&
                        returnedContainerBoxSummaryPictures.data.returned_container_box_documents &&
                        returnedContainerBoxSummaryPictures.data.returned_container_box_documents.map((item) => (
                            <div className="col-lg-6 doc_gallery" key={item.id}>
                                  <a href={item.file_url} download target="blank">
                        <img src="/Images/download_pic.svg" alt="Download" /> <h6>Download Document</h6>
                    </a>
                            </div>
                        ))
                    }

                </div>
                <div className="row">
                    {
                        returnedContainerBoxSummaryPictures.data &&
                        returnedContainerBoxSummaryPictures.data.returned_container_box_pictures &&
                        returnedContainerBoxSummaryPictures.data.returned_container_box_pictures.map((item) => (
                            <div className="col-lg-6 image_gallery" key={item.id}>
                                <img src={item.file_url} alt="img"  
                                 style={{ width: '100%', height: 'auto', }} />
                            </div>
                        ))
                    }

                </div>
            </DefaultModal>




        </div>
    )
}

export default BoxTable

