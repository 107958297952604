

import React, { useEffect, useState } from 'react'
import { Link, useNavigate, useParams } from 'react-router-dom';
import Table from '../../component/Table/Table';
import { Pagination } from 'antd';
import AddNewBagModal from './component/AddNewBagModal';
import DefaultModal from '../../component/DefaultModal/DefaultModal';
import { useDispatch, useSelector } from 'react-redux';
import { getcloseBagDropdown, getcloseStatusUpdate, getlooseReturnPackages, getlooseReturnPackagesList, getpartyOption } from '../../store/user/actions';
import CloseBagModal from './component/CloseBagModal';
import Loader from '../../component/Loader';
import { resetlooseReturnPackagesState } from '../../store/user/userSlice';

const PackageScanTwoAdd = () => {
  const { received_packages } = useParams();
  const initialValues = {
    // scan_no: "",
    loose_return_id: 0,

  };
  const navigate = useNavigate();
  const [formValues, setFormValue] = useState(initialValues);
  const [serialNumber, setSerialNumber] = useState(1); // Initial serial number
  const [current, setCurrent] = useState(1);
  const [tableData, setTableData] = useState([]);
  const [totalData, setTotalData] = useState(0);
  const [show, setShow] = useState(false);
  const [showFull, setShowFull] = useState(false);
  const [showClose, setShowClose] = useState(false);
  const [showPresent, setShowPresent] = useState(false);
  const [showPackage, setShowPackage] = useState(false);
  const [showBagnotMatched, setShowBagnotMatched] = useState(false);
  const [scanCounter, setScanCounter] = useState(0);
  const [rowData, setRowData] = useState([]);
  const [packageCounter, setPackageCounter] = useState(received_packages);
  const closeStatusUpdate = useSelector(state => state.closeStatusUpdate)
  const looseReturnPackages = useSelector(state => state.looseReturnPackages)
  const closeBagDropdown = useSelector(state => state.closeBagDropdown)
  const [modalMessage, setModalMessage] = useState('');
  const looseReturnPackagesList = useSelector(state => state.looseReturnPackagesList)
  const [currentRequest, setCurrentRequest] = useState(0);
  const partyOption = useSelector(state => state.partyOption)
  const [limitData, setLimitData] = useState(10);
  const dispatch = useDispatch()
  const { id } = useParams();
  const [looseReturnId, setLooseReturnId] = useState(0);

  const handleChange = (name, value) => {
    let data = { ...formValues };
    data[name] = value;
    setFormValue(data);
  };
  // const handleClosePODSubmission = () => {
  //   // Dispatch the action to close the POD submission
  //   dispatch(getcloseStatusUpdate({ id: id, is_close: 1 }));
  // };
  const handleClosePODSubmission = () => {
    const id = formValues.loose_return_id;
    const is_close = 1; // Assuming 1 indicates closing a POD

    dispatch(getcloseStatusUpdate({id, is_close}));
    console.log(id,is_close);
  };

  const addNewBag = () => {
    dispatch(getpartyOption({}))
    setShow(true)
    setShowFull(false);// Close the first modal
  }
  const updateLooseReturnId = (newLooseReturnId) => {
    setLooseReturnId(newLooseReturnId);
  };
  const handleGoBack = () => {
    // Navigate back to the previous item
    navigate(-1);
  };
  useEffect(() => {
    const storedFormData = JSON.parse(localStorage.getItem('primaryScanData'));
    // if(storedFormData.LooseReturn){
    if (storedFormData?.LooseReturn?.id && storedFormData?.LooseReturn?.is_close !== undefined) {
      const oldFormValue = { ...formValues }
      setPackageCounter(storedFormData.LooseReturn.total_packages);
      oldFormValue.loose_return_id = storedFormData.LooseReturn.id
      setFormValue(oldFormValue)
      setLooseReturnId(storedFormData.LooseReturn.id);
      // console.log(storedFormData.LooseReturn.total_packages);
    }
    // if (storedFormData?.LooseReturn?.id) {
    //   setLooseReturnId(storedFormData.LooseReturn.id);
    // }

  }, [])
 
  useEffect(() => {
    setTotalData(looseReturnPackagesList.data.total)
    let sno = (current - 1) * 10 + 1;
    const FakeTableData = looseReturnPackagesList && looseReturnPackagesList?.data?.data?.data?.map((item, i) => {
      return [
        <>
          <h6>{sno + i}</h6>
        </>,

        <h6>{item.party_name}</h6>
        ,
        <div>
          <h6>{item.bag_number}</h6>
        </div>,
        <>

          <h6>{item.suffix_string}</h6>
        </>,
        <>
          <h6>{item.dispatch_date}</h6>
        </>,
        <>
          <h6>{item.no_of_items}</h6>
        </>,
        <>
          <h6>{item.order_no}
            {/* {item.dispatch_date} */}
          </h6>
        </>,
        <div className="image_pic">
          {item.images.slice(0, 2).map((picture, index) => (
            <img key={index} src={picture.thumbnail} alt={`Item ${index}`} />
          ))}
          {item.images.length > 3 && (
            <div className="image-overlay">{`${item.images.length - 3}+`}</div>
          )}
        </div>

      ]
    })
    setTableData(FakeTableData);
    const totalScanCount = looseReturnPackagesList?.data?.data.total || 0;
    setScanCounter(totalScanCount);
    console.log(totalScanCount);
  }, [looseReturnPackagesList])
// console.log(scanCounter);
  useEffect(() => {
    let data = { ...formValues };
    if (id) {
      data["loose_return_id"] = id;
    } else {
      const storedFormData = JSON.parse(localStorage.getItem('primaryScanData'));
      const id = storedFormData.LooseReturn.id;
      data["loose_return_id"] = id;
    }
     data["page"] = current
     data["limit"] = 10
     data["filter"]= "all"
    dispatch(getlooseReturnPackagesList(data));
  }, [id, scanCounter])
console.log(looseReturnPackagesList);
  const handlePackageResponse = (response) => {
    setScanCounter(response.data?.data?.total_scan);
    // 1. Check for Successful Response (status === 1)
    if (response.data.status === "1") {
      console.log("Successful response!")
      const scanCounter = response.data.data?.total_scan || 0; 
      const totalPackages = response.data.data.total_packages || 0;
      setScanCounter(scanCounter);
      // // Extract data for counters and dispatch an action if needed
      // console.log('Updated scanCounter:', scanCounter);
      setPackageCounter(response.data.data.total_packages);
      setPackageCounter(totalPackages);
      const storedFormData = JSON.parse(localStorage.getItem('primaryScanData'));
      var looseReturnId = 0
      if (id) {
        looseReturnId = id; // Default value in case id is not available
      } else {
        looseReturnId = storedFormData?.LooseReturn?.id || id; // Default value in case id is not available
      }
      dispatch(getlooseReturnPackagesList({ loose_return_id: looseReturnId }));
    } else {
      console.log("llll");
      const errorCode = response.data?.data?.error_code; // Use optional chaining
      switch (errorCode) {
        case 1:
          console.log("asss");
          setShowPackage(true);
          break;
        case 2:
          setShowFull(true);
          break;
        case 3:
          setShowPresent(true);
          break;
        case 4:
          setShowPackage(true);
          break;
        default:
          setShowFull(false); // Handle unknown errors
          break;
      }
      if (response.data.status === 0 && response.data.message) {
        // Update the state to show the message in the modal
        setModalMessage(response.data.message);
      }
    }
  };
  // useEffect(()=>{
  //   if(prevReSubmission.isSuccess){
  //     dispatch(resetprevReSubmissionState())
  //     refreshNodeData()
  //   }
  // },[prevReSubmission])
  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      let data = { ...formValues };
      if (id) {
        data["loose_return_id"] = id;
      } else {
        const storedFormData = JSON.parse(localStorage.getItem('primaryScanData'));
        const id = storedFormData.LooseReturn.id;
        data["loose_return_id"] = id;
      }
      console.log(data);
      dispatch(getlooseReturnPackages(data))
        // .then(response => {
        //   handlePackageResponse(response);
        // })
        .catch(error => {
          console.error('Error:', error);
        });
    }
  };

  useEffect(() => {
    if (looseReturnPackages.isSuccess) {
      handlePackageResponse(looseReturnPackages);
      dispatch(resetlooseReturnPackagesState())
    }
   
  }, [looseReturnPackages]);
console.log();

  const TemplateTableData = [

    {heading: "Sr.No."},
    {heading: "Portal Name"},
    {heading: "Bag Number"},
    { heading: "String Matches"},
    {heading: "Dispatch Date"},
    {heading: "Number of Items"},
    {heading: "Order Number"},
    {heading: "Item Pictures"}

  ];
  
  const handlePageChange = (page,limit) => {
    setCurrent(page);
    setLimitData(limit)
    // console.log(page,"mmm");
    dispatch(getlooseReturnPackagesList({page,loose_return_id: looseReturnId,limit:limit}))
    // console.log(page,"jjj");
};
const handleCloseAndOpenModal = () => {
  setShowPresent(false); // Close the current modal
  setShowClose(true);    // Open the new modal
};
  return (
    <div>
      <div className='heading_select'>
        <div className="d-flex align-items-baseline" onClick={handleGoBack}>
          <img style={{ cursor: "pointer" }} src="/Images/arrow-left.svg" />
          <h1>Scan Details</h1>
        </div>
        <div className='package_butn'>
          <Link className='add_bag' onClick={() => addNewBag()}>Add New Bag</Link>
          <Link to="/settings" className='suffix_bag'>Add New Suffix String</Link>
          <Link onClick={() => setShowClose(true)} className='close_bag'>Close Bag</Link>
          <Link
           onClick={(e) => { e.preventDefault(); handleClosePODSubmission(); }}
            className='pod_bag'
          >Close POD Submission</Link>
        </div>

      </div>
      <div className='pt-4 mt-4'>
        <div className="row primary_input_section">

          <div className='col-md-4 col-lg-4 col-12 mb-4 position-relative'>
            <label className='select-label'>Package Scan</label>
            <input
              // placeholder='PO1234567'
              className='input-custom-primary'
              type='text'
              onChange={(e) => handleChange("scan_no", e.target.value)}
              onKeyDown={handleKeyPress}
              value={formValues.scan_no}
              id={id}
            />
            <img src="/Images/scan_input.svg" className='scan_input_icon' />
          </div>
          <div className='col-md-4 col-lg-4 col-12 mb-4 position-relative'>

            <label className='select-label'>Total Scan - Counter</label>
            <div className='scan_counter'>
              {scanCounter}
            </div>

          </div>
          <div className='col-md-4 col-lg-4 col-12 mb-4 position-relative'>
            <label className='select-label'>Total Packages - Counter</label>
            <div className='scan_pacakge'>
              {packageCounter}
            </div>
          </div>
        </div>
      </div>
      <div className='white_box'>
        <div className="position-relative">
          {
        looseReturnPackagesList.isLoading && <div className="loader_bg"><Loader /></div>
      }
          <div className='table-bg'>
            <Table
              gridTemplateColumnsHeader="10% 10% 10% 15% 15% 10% 15% 15%"
              gridTemplateColumnsBody="10% 10% 10% 15% 15% 10% 15% 15%"
              columns={TemplateTableData}
              data={tableData}
              Loader={true}
            />
            <Pagination
              current={current}
              onChange={handlePageChange}
              total={looseReturnPackagesList.data.data ? looseReturnPackagesList.data.data.total : 0}
              pageSize={limitData}
              />
          </div>

        </div>
      </div>
      <div className='package_butn mt-4'>
        <Link className='suffix_bag' to={`/upload-count/${id}?total=${scanCounter}&totalScan=${packageCounter}`}>Next</Link>
      </div>                            
      <DefaultModal
        show={show}
        onHide={() => setShow(false)}
        className="add-modal"
        style={{ paddingRight: "0px" }}
        centered={false}
        showHeader={false}
        size=""
        showFooter={false}
      >
        <div className="modal-heading">
          <h4>Add New Bag</h4>
          <div className="close-icon">
            <img src="/Images/closeBlack.svg" onClick={() => setShow(false)} />
          </div>
        </div>
        <AddNewBagModal
          partyOption={partyOption}
          mClose={setShow}
          looseReturnId={looseReturnId}
          setLooseReturnId={updateLooseReturnId} // Pass the function here
        />

      </DefaultModal>

      <DefaultModal
        show={showClose}
        onHide={() => setShowClose(false)}
        className="add-modal"
        style={{ paddingRight: "0px" }}
        centered={false}
        showHeader={false}
        size=""
        showFooter={false}
      >
        <div className="modal-heading">
          <h4></h4>
          <div className="close-icon">
            <img src="/Images/closeBlack.svg" onClick={() => setShowClose(false)} />
          </div>
        </div>
        <CloseBagModal
          requestId={currentRequest}
          mClose={setShowClose}
          loose_return_id={id}
        />
      </DefaultModal>
      {/* //error case 1 */}
      {/* <DefaultModal
        show={showBagnotMatched}
        onHide={() => setShowBagnotMatched(false)}
        className="add-modal"
        style={{ paddingRight: "0px" }}
        centered={false}
        showHeader={false}
        size=""
        showFooter={false}
      >
        <div className="modal-heading">
          <h4></h4>
          <div className="close-icon">
            <img src="/Images/closeBlack.svg" onClick={() => setShowBagnotMatched(false)} />
          </div>
        </div>

        <div className='alert_modal'>
          <img src="/Images/BOX.svg" />
          <h2>Your bag not Matche</h2>
          <p>{modalMessage}</p>
          
        </div>
      </DefaultModal> */}
      {/* //error case 2 */}
      <DefaultModal
        show={showFull}
        onHide={() => setShowFull(false)}
        className="add-modal"
        style={{ paddingRight: "0px" }}
        centered={false}
        showHeader={false}
        size=""
        showFooter={false}
      >
        <div className="modal-heading">
          <h4></h4>
          <div className="close-icon">
            <img src="/Images/closeBlack.svg" onClick={() => setShowFull(false)} />
          </div>
        </div>

        <div className='alert_modal'>
          <img src="/Images/BOX.svg" />
          {/* <h2>Your bag is full</h2> */}
          <p className='mt-4'>{modalMessage}</p>
          <div className='alert_bag'>
            <Link onClick={() => addNewBag()}>Add New Bag</Link>
          </div>
        </div>
      </DefaultModal>
      {/* //error case 3 */}
      <DefaultModal
        show={showPresent}
        onHide={() => setShowPresent(false)}
        className="add-modal"
        style={{ paddingRight: "0px" }}
        centered={false}
        showHeader={false}
        size=""
        showFooter={false}
      >
        <div className="modal-heading">
          <h4></h4>
          <div className="close-icon">
            <img src="/Images/closeBlack.svg" onClick={() => setShowPresent(false)} />
          </div>
        </div>

        <div className='alert_modal'>
          <img src="/Images/BOX.svg" />
          {/* <h2>Bag is not present </h2> */}
          <p>{modalMessage}</p>
          <div className='alert_bag'>
            <Link onClick={() => handleCloseAndOpenModal()}>Close Bag</Link>
          </div>
        </div>
      </DefaultModal>
      {/* //error case 4 */}
      <DefaultModal
        show={showPackage}
        onHide={() => setShowPackage(false)}
        className="add-modal"
        style={{ paddingRight: "0px" }}
        centered={false}
        showHeader={false}
        size=""
        showFooter={false}
      >
        <div className="modal-heading">
          <h4></h4>
          <div className="close-icon">
            <img src="/Images/closeBlack.svg" onClick={() => setShowPackage(false)} />
          </div>
        </div>

        <div className='alert_modal'>
          <img src="/Images/BOX.svg" />
          {/* <h2>Package Already Scanned</h2> */}
          <p>{modalMessage}</p>
          <div className='alert_bag'>
            <Link onClick={() => setShowPackage(false)}>Back to Scan</Link>
          </div>
        </div>
      </DefaultModal>
    </div>
  )
}

export default PackageScanTwoAdd
