
import React, { useState, useEffect } from "react";

import Table from '../../../component/Table/Table'

import { Pagination } from 'antd';
import { Link, useParams } from "react-router-dom";
import DefaultModal from "../../../component/DefaultModal/DefaultModal";
import { useDispatch, useSelector } from "react-redux";
import { getclaimListing, getclaimRemark, getclaimStatusChange, getclaimStatusDropdown, getsetClaimRemark } from "../../../store/user/actions";
import Dropdown from "../../../component/Dropdown/DropDownControlled";
import Loader from "../../../component/Loader";
import ClaimStatus from "./ClaimStatus";
import ClaimRemark from "./ClaimRemark";


const ReturnTable = ({selectBox}) => {
    // const [formValues, setFormValue] = useState({ claim_id: id });
  
    const [formValues, setFormValue] = useState([])
    const handleChange = (name, value) => {
        let data = { ...formValues };
        data[name] = value;
        setFormValue(data);
    };
    const [showRemark, setShowRemark] = useState(false);
    const [showApprove, setShowApprove] = useState(false);
    const [claimId, setClaimId] = useState(null);
    const [claimTicketId, setClaimTicketId] = useState(null);
    const [current, setCurrent] = useState(1);
    const [tableData, setTableData] = useState([]);
    const [limitData, setLimitData] = useState(10);
    const [totalData, setTotalData] = useState(0);
    const claimListing = useSelector(state => state.claimListing)
    const dispatch = useDispatch()


    useEffect(() => {
        setCurrent(1);
        const requestData = {
            ...formValues,
            ...selectBox,
            filter: "all",
            page: current,
            limit: limitData,
            claim_reason_id:claimId
        };
        dispatch(getclaimListing(requestData));
        // console.log('Request sent:', requestData);
    }, [formValues,selectBox]);
  
    // useEffect(() => {
    //     dispatch(getclaimRemark({claim_id:id}));
    // }, [id]);
   

    // const remarkUpdate = () => {
    //     // Dispatch an action to update the remarks
    //     dispatch(getsetClaimRemark());
    // };

    const handleClaimApprove = (id) => {
        setClaimId(id);
    };
    // console.log("claimId:", claimId);
// console.log("claimTicketId:", claimTicketId);
    useEffect(() => {
        setTotalData(claimListing.data.total)
        // let sno = (current - 1) * 10 + 1;
        // console.log('Updated table data:', tableData);
        const FakeTableData = claimListing && claimListing.data?.data?.data.map((item, i) => {
            let statusColor;
            switch (item.claim_status) {
                case 'Pending':
                statusColor = 'gray';
                break;
                case 'Approved':
                statusColor = 'green';
                break;
                case 'Rejected':
                statusColor = 'red';
                break;
                case 'Filed':
                statusColor = 'blue';
                break;
                default:
                statusColor = 'black';
            }
            return [
                <>
                    <h6>{item.id} </h6>
                </>,

                <h6>{item.claim_no}</h6>
                ,
                <div className="table_word_wrap">
                    <h6>{item.order_no}</h6>
                </div>,
                <div>
                    <h6>{item.claim_reason}</h6>
                </div>,
                <>
                    <h6>{item.portal_name}</h6>
                </>,
                <>
                    <h6>₹{item.claim_amount_filed}</h6>
                </>,
                <>
                    <h6>₹{item.claim_amount_approved}</h6>
                </>,
                <>
                    <h6>{item.claim_qty}</h6>
                </>,
                <>
                    <h6>{item.date}</h6>
                </>,
                <div style={{ color: statusColor }}>
                    <h6>{item.claim_status}</h6>
                </div>,
                <div>
                    <h6>{item.ext_ticket_no}</h6>
                </div>,
                <div className="return_butns">
                    <Link to={`/communication/${item.id}/${item.claim_ticket_id}`} style={{ background: "#F8B552" }}>Communication</Link>
                    <Link to={`/claim-media/${item.id}`} style={{ background: "#95CBFF" }}>Claim Media</Link>
                    <Link style={{ background: "#FF96DF" }} 
                   
                    onClick={() => {
                        setShowRemark(true);
                        handleClaimApprove(item.id); // Pass the item's id to handleClaimApprove function
                    }}
                    >Claim Remark</Link>
                    <Link to="" style={{ background: "#5BF3C6" }} 
                    onClick={() => {
                        setShowApprove(true);
                        handleClaimApprove(item.id); // Pass the item's id to handleClaimApprove function
                    }}
                    >Claim Status</Link>
                </div>,
            ]
        })
        setTableData(FakeTableData);
    }, [claimListing])

    const TemplateTableData = [

        {
            heading: "Sr.No.",
        },
        {
            heading: "Claim Number",
        },
        {
            heading: "Order Number",
        },
        {
            heading: "Claim Type",
        },
        {
            heading: "Portal Name",
        },
        {
            heading: "Claim Amount Filed",
        },
        {
            heading: "Claim Amount Approved",
        },
        {
            heading: "Claim Qty",
        },
        {
            heading: "Claim Date",
        },
        {
            heading: "Claim Status",
        },
        {
            heading: "External Ticket Number",
        },
        {
            heading: "Action",
        },

    ];
    
    
    
    console.log(claimListing.data.data);
    const onRefreshTable = () => {
        // Dispatch the `getclaimEmailTemplateRead` action with the current page and limit
        dispatch(getclaimListing({
          page: current,
          limit: limitData,
          filter:"all"
        }));
      };

    const handlePageChange = (page,limit) => {
        setCurrent(page);
        setLimitData(limit)
        const requestData = {
            ...formValues,
            ...selectBox,
            filter: "all",
            page: page,
            limit: limitData,
            claim_reason_id:claimId
        };
        dispatch(getclaimListing(requestData));
    };
    return (
        <div className="white_box">
            <div className="position-relative">
            {
        claimListing.isLoading && <div className="loader_bg"><Loader /></div>
      } 
                <div className='table-bg'>
                    <Table
                        gridTemplateColumnsHeader="5% 6% 7% 10% 7% 10% 9% 7% 9% 7% 10% 12%"
                        gridTemplateColumnsBody="5% 6% 7% 10% 7% 10% 9% 7% 9% 7% 10% 12%"
                        columns={TemplateTableData}
                        data={tableData}
                        Loader={true}
                    />
                    <Pagination
                        current={current}
                        onChange={handlePageChange}
                        total={claimListing?.data?.data ? claimListing?.data?.data?.total : 0}
                        pageSize={limitData}
                        showSizeChanger
                    />
                </div>

            </div>
            <DefaultModal
                show={showRemark}
                onHide={() => setShowRemark(false)}
                className="add-modal comm_lablel"
                style={{ paddingRight: "0px" }}
                centered={false}
                showHeader={false}
                size=""
                showFooter={false}
            >
                <div className="modal-heading">
                    <h4>Remarks</h4>
                    <div className="close-icon">
                        <img src="/Images/closeBlack.svg" onClick={() => setShowRemark(false)} />
                    </div>
                </div>
               <ClaimRemark 
                claimId={claimId}
                mClose={setShowRemark}
                onRefreshTable={onRefreshTable}
               />
            </DefaultModal>
            <DefaultModal
                show={showApprove}
                onHide={() => setShowApprove(false)}
                className="add-modal comm_lablel"
                style={{ paddingRight: "0px" }}
                centered={false}
                showHeader={false}
                size=""
                showFooter={false}
            >
                <div className="modal-heading">
                    <h4>Claim Status</h4>
                    <div className="close-icon">
                        <img src="/Images/closeBlack.svg" onClick={() => setShowApprove(false)} />
                    </div>
                </div>
                <div>
                    <ClaimStatus
                    claimId={claimId}
                    mClose={setShowApprove}
                    onRefreshTable={onRefreshTable}
                    />
                </div>
            </DefaultModal>
        </div>
    )
}

export default ReturnTable


