

import React, { useEffect, useState } from 'react'
import {  useLocation, useNavigate } from 'react-router-dom';
import Box from '../../../component/Box';
import PrimaryTable from './PrimaryTable';
import PrimaryBoxTable from './PrimaryBoxTable';

const PrimaryTab = () => {
  const [selectedSegment, setselectedSegment] = useState("Information");
//   const [showComponent, setShowComponent] = useState("initial");
const location = useLocation();
const navigate = useNavigate();
// Extracting query parameters
const queryParams = new URLSearchParams(location.search);
const tab = queryParams.get('tab');
// console.log(tab);
  
useEffect(() => {
  if(tab){ 
    setselectedSegment(tab)
  }
  // console.log("info");
},[tab])
const segmentChange = (segment) => {
 navigate('/primary-scan?tab='+segment)
}
  const setSegment = (segment) => {
    setselectedSegment(segment);
  };
  return (
    <div>
       <Box display="flex" alignItems="center" cursor="pointer" justifyContent="left">
        <Box fontSize="22px" fontWeight={700}>{selectedSegment === "Information"}</Box>
        <ul className="d-flex tab_setting">
          <li
            className="information"
            style={{
              fontWeight: selectedSegment === "Information" ? "600" : "600",
              backgroundColor:
                selectedSegment === "Information" ? "#F8B552" : "#EBEEF2",
              color: selectedSegment === "Information" ? "#1C1C1C" : "#1C1C1C",
              marginRight:selectedSegment === "Information" ? "7px" : "7px",
              padding:selectedSegment === "Information" ? "7px" : "7px",
              borderRadius:
                selectedSegment === "Information"
                  ? "5px 5px 0px 0px"
                  : "5px 5px 0px 0px",
            }}
            onClick={() => segmentChange("Information")}
          >
                 Loose Parcel Scan{" "}
          </li>
          <li
            className="screen-details"
            style={{
              fontWeight: selectedSegment === "Screen details" ? "600" : "600",
              backgroundColor:
                selectedSegment === "Screen details" ? "#F8B552" : "#EBEEF2",
              color: selectedSegment === "Screen details" ? "#1C1C1C" : "#1C1C1C",
              borderRadius:
                selectedSegment === "Screen details"
                  ? "5px 5px 0px 0px"
                  : "5px 5px 0px 0px",
            }}
            onClick={() => segmentChange("Screen details")}
          >
           
           Box Scan{" "}
          </li>
         
       
        </ul>
      </Box>
      <Box className='tab_body'>
        {selectedSegment === "Information" ? (
            <PrimaryTable 
            setSegment={setSegment}
            />
        ) : null}
        {selectedSegment === "Screen details" ? (
            <PrimaryBoxTable
            setSegment={setSegment}
            />
        ) : null}
        
    
      </Box>
    </div>
  )
}

export default PrimaryTab

