
import React, { useEffect, useState } from 'react'
import ReactApexChart from 'react-apexcharts';
import { getgraphPortalWise } from '../../../store/user/actions';
import { useDispatch, useSelector } from 'react-redux';
import randomColor from 'randomcolor'; // Import randomcolor library
import DateRangePicker from '@wojtekmaj/react-daterange-picker';

const optionsSelect = [

  { value: "today", label: "Today" },
  { value: "this_week", label: "This Week" },
  { value: "last_week", label: "Last Week" },
  { value: "this_month", label: "This Month" },
  { value: "last_month", label: "Last Month" },
  { value: "last_three_month", label: "Last 3 Month" },
  { value: "this_year", label: "This Year" },
  { value: "last_year", label: "Last Year" },
  { value: "all", label: "All" },
];
const colorsArray = [
  '#FF0000','#4A3AFF','#2ED47E','#FF5733', '#33FF57', '#3357FF', '#F3FF33', '#33FFF3', '#FF33A1', '#A133FF', '#FFC733', '#33FFC7','#ED3A14','#EDE014','#BCED14','#14ED9B','#14B5ED','#ED1424','#ED149B'
  // Add more colors as needed
];
const PortalWiseChart = () => {
  const initialValues = {
    end_date: "",
    start_date: "",

  };
  const rangeDate = [new Date(), new Date]
  const [formValues, setFormValue] = useState(initialValues);
  const [filter, setFilter] = useState("select_date");
  const [rangePickerDate, setrangePickerDate] = useState(rangeDate);
  const dispatch = useDispatch()
  const graphPortalWise = useSelector(state => state.graphPortalWise)
  const [chartData, setChartData] = useState({
    series: [

    ],
    options: {
      chart: {
        type: 'bar',
        height: 450,
        stacked: true,
        borderRadius: 10,
        toolbar: {
          show: false,
        },
      },
      stroke: {
        width: 1,
        colors: ['#fff']
      },
      dataLabels: {
        rotate: -90,
        rotateAlways: true,
        orientation: 'horizontal',
        // rotate: 100,
        style: {
          fontSize: '12px',
          fontWeight: 500,
          fontFamily: 'Poppins',
        },
        // formatter: (val) => {
        //   return val / 1000 + 'K';
        // }
      },

      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: '35%',
          endingShape: "rounded",
          borderRadius: 10,
          // borderRadiusApplication: "end", // "around" / "end" 
          // borderRadiusWhenStacked: "last" // "all"/"last"  

        }
      },
      xaxis: {
        categories: [],
      },
      fill: {
        opacity: 1
      },
      colors: colorsArray,
      yaxis: {
        labels: {
          formatter: (val) => {
            return val
          }
        }
      },
      grid: {
        // show: true,
        borderColor: '#E5E5EF',
        strokeDashArray: 4,

        // padding: {
        //   bottom: 30
        // }
      },
      legend: {
        position: 'bottom',
        offsetY: 10,
        horizontalAlign: 'center',
      },
    }
  });



  useEffect(() => {
    dispatch(getgraphPortalWise({ 
      filter, 
      end_date: formValues.end_date,
       start_date: formValues.start_date }))
  }, [filter, formValues])

  useEffect(() => {

    if (graphPortalWise.isSuccess) {
      const transformData = (inputData) => {
        // Extract dynamic categories from the first object (excluding 'name')
        const categories = Object.keys(inputData[0]).filter(key => key !== 'name');

        const dataSeries = categories.map((category) => ({
          name: category,
          group: category.includes("Filed") ? "Filed" : "Accepted",
          data: inputData.map((entry) => entry[category]),
          // color: randomColor() // Generate a random color for each series`
        }));

        // Extract the name list
        const nameList = inputData.map((entry) => entry.name);

        return { dataSeries, nameList };
      };
      const { dataSeries, nameList } = transformData(graphPortalWise.data);
      // console.log(dataSeries);


      // Updating chart data
      setChartData(prevData => ({
        ...prevData,
        series: dataSeries,
        options: {
          ...prevData.options,
          xaxis: {
            ...prevData.options.xaxis,
            categories: nameList,
          },
        },
      }));
    }
  }, [graphPortalWise]);

  const handleFilterChange = (e) => {
    setFilter(e.target.value);
  }

  const onChange = (name, date) => {
    console.log("Name",name);
    setrangePickerDate(date);
    var currentDate = new Date(date[0]);
    // Get the year, month, and day from the date
    var year = currentDate.getFullYear();
    var month = String(currentDate.getMonth() + 1).padStart(2, '0'); // Month is 0-based
    var day = String(currentDate.getDate()).padStart(2, '0');
    // Format the date in "Y-m-d" format
    var toDate = `${year}-${month}-${day}`;

    var currentDate = new Date(date[1]);
    // Get the year, month, and day from the date
    var year = currentDate.getFullYear();
    var month = String(currentDate.getMonth() + 1).padStart(2, '0'); // Month is 0-based
    var day = String(currentDate.getDate()).padStart(2, '0');

    // Format the date in "Y-m-d" format
    var fromDate = `${year}-${month}-${day}`;
    let formdata = { ...formValues };
    formdata["start_date"] = toDate; // Update this line
  formdata["end_date"] = fromDate; // Update this line
    // formdata[name]=date
    setFormValue(formdata);
  };
  // console.log(formValues);
  return (
    <div className='white_box'>
      <div className='heading_select'>
        <h2>Portal wise - Claim </h2>
        <div className='select_box_area'>
          <div className="custom-select">
            <select className='selectArea'
              value={filter}
              onChange={handleFilterChange}
            >
              {
                optionsSelect && optionsSelect.map((item) => (
                  <option value={item.value}>{item.label}</option>
                ))
              }
            </select>
          </div>
          <div className="custom-select" style={{ marginRight: "10px" }}>
            <DateRangePicker
              // placeholder="Current Planned Date"
              onChange={(e) => onChange("start_date_", e)}
              value={rangePickerDate}
              yearAriaLabel="Year"
              clearIcon={false}
            />
          </div>
        </div>
      </div>
      <div className="border-wordload"></div>
      <div>
        <ReactApexChart options={chartData.options} series={chartData.series} type="bar" height={450} />
      </div>
    </div>
  )
}

export default PortalWiseChart
