import React, { useEffect, useState } from 'react'
import ReturnCard from './component/ReturnCard'
import ReturnFilter from './component/ReturnFilter'
import ReturnTab from './component/ReturnTab'
import ReturnTable from './component/ReturnTable'
import { getclaimExport } from '../../store/user/actions'
import { useDispatch, useSelector } from 'react-redux'
import { resetclaimExportState } from '../../store/user/userSlice'

const ReturnClaims = () => {
  const [selectBox, setSelectBox] = useState(true);
  const [limitData, setLimitData] = useState(500);
  const [current, setCurrent] = useState(1);
  const claimExport = useSelector(state => state.claimExport)
  const dispatch = useDispatch()
  const filterValue = (selectValue) => {
    setSelectBox(selectValue)
  }
  const downloadUrl = (page,limit) => {
    setCurrent(page);
    setLimitData(limit)
    dispatch(getclaimExport({ page: current, limit:limitData, filter: 'all' }))
  }
  useEffect(() => {
    if (claimExport.isSuccess) {
      window.open(claimExport.data.download_url)
      dispatch(resetclaimExportState())
    }
  }, [claimExport])
  return (
    <div>
      <div className='heading_select'>
        <h1>Return Claims</h1>
        <div className="primary_but">
          <button onClick={() => downloadUrl()} rel="noopener noreferrer"><img src="/Images/exportFile.svg" />Export File</button>
        </div>
      </div>
      <ReturnCard />
      <ReturnFilter
        filterValue={filterValue}
      />
      {/* <ReturnTab
        selectBox={selectBox}
      /> */}
      <ReturnTable
       selectBox={selectBox}
      />
    </div>
  )
}

export default ReturnClaims
