import React, { useEffect, useState } from 'react'
import { cardData } from '../../../constants/dashboardjson'
import { useDispatch, useSelector } from 'react-redux'
import { getclaimCounterMatric, getpodCounter, getsecondaryCounterMatric } from '../../../store/user/actions'

const DashboardCard = () => {
    const [filter, setFilter] = useState("today");
    const podCounter = useSelector(state => state.podCounter.data)
    const secondaryCounterMatric = useSelector(state => state.secondaryCounterMatric.data)
    const claimCounterMatric = useSelector(state => state.claimCounterMatric.data)
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(getpodCounter({filter}))
        dispatch(getsecondaryCounterMatric({filter}))
        dispatch(getclaimCounterMatric({filter}))
    }, [filter])
    const optionsSelect = [
        { value: "today", label: "Today" },
        { value: "this_week", label: "This Week" },
        { value: "last_week", label: "Last Week" },
        { value: "this_month", label: "This Month" },
        { value: "last_month", label: "Last Month" },
        { value: "last_three_month", label: "Last 3 Month" },
        { value: "this_year", label: "This Year" },
        { value: "last_year", label: "Last Year" },
        { value: "all", label: "All" },
    ];
    
    //   const persionValue =(value) =>{
    //     dispatch(getcollectionWiseCompletion({period:value}))
    //   }
    const handleFilterChange = (e) => {
        setFilter(e.target.value);
    }
    return (
        <div className=''>
             <div className='heading_select'>
                <h2>Loose Dashboard</h2>
                <div className="custom-select">
                    <select className='selectArea' 
                    value={filter}
                    onChange={handleFilterChange}
                            // onChange={(e)=> persionValue(e.target.value)} 
                        >
                            {
                                optionsSelect && optionsSelect.map((item)=>(
                                <option value={item.value}>{item.label}</option>
                                ))
                            } 
                            </select>
                </div>
                </div>
            <div className='row padding_left'>
                <div className='col-lg-3 col-md-3 col-12 card-common'>
                    <div className="pale-red">
                         <h2>{podCounter.total_packages_scanned}</h2> 
                        <p>Total Packages Scan</p>
                    </div>
                </div>
                <div className='col-lg-3 col-md-3 col-12 card-common'>
                    <div className="alice-blue">
                         <h2>{podCounter.total_pod_scan}</h2> 
                        <p>Total POD Scan</p>
                    </div>
                </div>
                <div className='col-lg-3 col-md-3 col-12 card-common'>
                    <div className="light-green">
                     <h2>{podCounter.total_packages_short}</h2> 
                        <p>Total Packages Short</p>
                    </div>
                </div>
                <div className='col-lg-3 col-md-3 col-12 card-common'>
                    <div className="light-orange">
                        <h2>{podCounter.total_bags_created}</h2> 
                        <p>Total Bags Created</p>
                    </div>
                </div>
                <div className='col-lg-3 col-md-3 col-12 card-common'>
                    <div className="light-pink">
                        <h2>{secondaryCounterMatric.totalSecondaryScan}</h2> 
                        <p>Total Secondary Scan</p>
                    </div>
                </div>
                <div className='col-lg-3 col-md-3 col-12 card-common'>
                    <div className="dark-green">
                         <h2>{secondaryCounterMatric.totalSecoCostPerPackage}</h2>
                        <p>Secondary Cost Per Package</p>
                    </div>
                </div>
                <div className='col-lg-3 col-md-3 col-12 card-common'>
                    <div className="dark-gray">
                         <h2>{secondaryCounterMatric.totalRepacking}</h2> 
                        <p>Total Repackaging</p>
                    </div>
                </div>
                <div className='col-lg-3 col-md-3 col-12 card-common'>
                    <div className="light-pitch">
                        <h2>{secondaryCounterMatric.totalRepackingCostPerPackage}</h2> 
                        <p>Repackaging Cost Per Package</p>
                    </div>
                </div>
                <div className='col-lg-3 col-md-3 col-12 card-common'>
                    <div className="pale-red">
                         <h2>{secondaryCounterMatric.totalMrpCreation}</h2> 
                        <p>Total MRP Creation </p>
                    </div>
                </div>
                <div className='col-lg-3 col-md-3 col-12 card-common'>
                    <div className="light-yellow">
                       <h2>{secondaryCounterMatric.totalMrpCreationCostPerPackage}</h2> 
                        <p>MRP Creation Cost Per Package </p>
                    </div>
                </div>
             
            </div>
            <div className='heading_select'>
                <h2>Claims</h2>
            </div>
            <div className='row padding_left'>
            <div className='col-lg-3 col-md-3 col-12 card-common'>
                    <div className="dark-brown">
                    <h2>{claimCounterMatric.totalClaims}</h2> 
                        <p>Total Claims</p>
                    </div>
                </div>
                <div className='col-lg-3 col-md-3 col-12 card-common'>
                    <div className="light-yellow">
                        <h2>{claimCounterMatric.totalPendingClaim}</h2> 
                        <p>Total Pending Claim</p>
                    </div>
                </div>
            <div className='col-lg-3 col-md-3 col-12 card-common'>
                    <div className="dark-gray">
                        <h2>{claimCounterMatric.totalFiledClaim}</h2> 
                        <p>Total Claims Filed</p>
                    </div>
                </div>
                <div className='col-lg-3 col-md-3 col-12 card-common'>
                    <div className="pale-red">
                        <h2>{claimCounterMatric.totalApprovedClaim}</h2> 
                        <p>Total Claims Accepted </p>
                    </div>
                </div>
                <div className='col-lg-3 col-md-3 col-12 card-common'>
                    <div className="light-yellow">
                        <h2>{claimCounterMatric.totalRejectedClaim}</h2> 
                        <p>Total rejected Claims</p>
                    </div>
                </div>
            <div className='col-lg-3 col-md-3 col-12 card-common'>
                    <div className="dark-brown">
                    <h2>{claimCounterMatric.totalClaimApprovedPercentage}</h2> 
                        <p>Acceptation Percentage</p>
                    </div>
                </div>
                <div className='col-lg-3 col-md-3 col-12 card-common'>
                    <div className="light-yellow">
                        <h2>{claimCounterMatric.totalNotRespondingByPortal}</h2> 
                        <p>Not Responding by Portal</p>
                    </div>
                </div>
                <div className='col-lg-3 col-md-3 col-12 card-common'>
                    <div className="dark-green">
                       <h2>{claimCounterMatric.totalNotRespondingByUs}</h2> 
                        <p>Not Responding by Us</p>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default DashboardCard
