import React from 'react'
import DashboardCard from './component/DashboardCard'
import DashboardTable from './component/DashboardTable'
import { Helmet } from 'react-helmet';
import PortalWiseChart from './component/PortalWiseChart';
import ClaimAcceptedChart from './component/ClaimAcceptedChart';
import ClaimAcceptedAmountChart from './component/ClaimAcceptedAmountChart';
import ClaimAmountChart from './component/ClaimAmountChart';
import TotalLossAmounChart from './component/TotalLossAmounChart';
import NotRespondingByUs from './component/NotRespondingByUs';
import OntimePackageScanChart from './component/OntimePackageScanChart';
import ManPowerTable from './component/ManPowerTable';
import ReturnInventory from './component/ReturnInventory';
import ProcessNodeWiseChart from './component/ProcessNodeWiseChart';
import NotResPondingByPortal from './component/NotResPondingByPortal';
const BoxDashboard = () => {
  return (
    <div>
      <Helmet>
        <meta charSet="utf-8" />
        <title>BoxDashboard | Courier Return Flow</title>
      </Helmet>

      <div>
        <DashboardCard />
        {/* <PortalWiseChart /> */}
        {/* <PortalWiseChart />
        <ClaimAcceptedChart/>
        <ClaimAmountChart/>
       <ClaimAcceptedAmountChart/> */}
       {/* <TotalLossAmounChart/> */}
       {/* <NotRespondingByUs/>
       <NotResPondingByPortal/> */}
       <ReturnInventory />
       {/* <ProcessNodeWiseChart/> */}
        {/* <DashboardTable
        />
        <OntimePackageScanChart/> */}
        {/* <ManPowerTable />
        <ReturnInventory/>
        <ProcessNodeWiseChart/> */}
      </div>

    </div>
  )
}

export default BoxDashboard
