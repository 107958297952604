

import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { gettatSettings, gettatSettingsStoreAll } from '../../../store/user/actions';

const ReceivedTat = () => {

  const [formValues, setFormValue] = useState([]);
  const [validationError, setValidationError] = useState('');
  const [tatInputValue, settatInputValue] = useState([]);
  const tatSettings = useSelector(state => state.tatSettings)
  const tatSettingsStoreAll = useSelector(state => state.tatSettingsStoreAll)
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(gettatSettings({ tat_for: "received", }))
  }, [])

  useEffect(() => {
    var openTat = []
    Object.entries(tatSettings.data).map(([index1,item]) => {
      item?.map((party) => {
      const index = `${index1}${party.id}_days`
      const partyId = party.id
      console.log(party);
      const value = party.tat_days?.days
      const existingItem = openTat[index];
      const updatedItem = { ...existingItem, days: value };
      var tat = null
      const type = 1 
      // console.log(openTat);
      if (existingItem) {
        openTat[index] = {
          ...existingItem,
          days: value,
        };
      } 
      else {
        // Create new item if it doesn't exist
        openTat[index] = 
          // ...openTat,
          {
            id: null, // Assuming you'll assign an ID later
            type,
            party_id: partyId,
            days: value,
            tat_for: "received",
          }
        
      }
      // console.log(openTat);
    })
    })
    // console.log(openTat);
    settatInputValue(openTat)
  }, [tatSettings])

const handleChange = (index, value, partyId, type) => {
  // Create a copy of the entire tatInputValue state to avoid mutation
  // console.log(updatedTatInputValue);
  const updatedTatInputValue = {...tatInputValue};
  const existingItem = updatedTatInputValue[index];
  // console.log(updatedTatInputValue);
  if (existingItem) {
    updatedTatInputValue[index] = {
      ...existingItem,
      days: value,
    };
    // console.log("mno");
  } else {
    
    updatedTatInputValue[index] = {
      id: null, // Assuming an ID might be assigned later
      party_id: partyId,
      days: value,
      tat_for: "received",
    };
  }
  

  // Set the updated state using the copied version
  settatInputValue(updatedTatInputValue);
};

console.log(tatInputValue);
  const getValue = (index) => {
    console.log(index);
    if (tatInputValue?.[index]) {
      return tatInputValue[index]
    }
    return ""

  }
  
  const handleSubmit = () => {
    console.log(Object.keys(tatInputValue).length);
    const userOption = Object.entries(tatInputValue).map(([index,item]) => {
      console.log(item);
      return({
      id: item.id, // Assuming these properties exist in item
      type: item.type,
      party_id: item.party_id,
      days: item.days,
      tat_for: "open", // Assuming all parties have the same tat_for
    })});
    const data = userOption.filter(item => item.days!=undefined)
    console.log(userOption,data);
    dispatch(gettatSettingsStoreAll(data));
  };

  return (
    <div className='suffix_title'>
      <h4>Loose Wise</h4>
      <div className="row">
        {tatSettings?.data?.LooseParties?.map((party) => (
          <div key={party.id} className='col-md-4 col-lg-4 col-12 mb-4 mt-2'>
            <label>{party.name}</label>
            <div>
              <input
                placeholder={`Enter Days for ${party.name}`}
                type={party.LooseParties}
                className='input-custom-common'
                onChange={(e) => handleChange(`LooseParties${party.id}_days`, e.target.value, party.id, 1
                )}
                // value={getValue[`LooseParties${party.id}_days`]}
                value={tatInputValue[`LooseParties${party.id}_days`]?.days}
              />
            </div>
          </div>
        ))}
      </div>

      <h4>Box Wise</h4>
      <div className="row">
        {tatSettings?.data?.boxParties?.map((party) => (
          <div key={party.id} className='col-md-4 col-lg-4 col-12 mb-4 mt-2'>
            <label>{party.name}</label>
            <div>
              <input
                placeholder={`Enter Days for ${party.name}`}
                type={party.boxParties}
                className='input-custom-common'
                onChange={(e) => handleChange(`boxParties${party.id}_days`, e.target.value, party.id, 0
                )}
                // value={getValue[`loose_party_${party.id}_days`]} // Assume formValues defined
                value={tatInputValue[`boxParties${party.id}_days`]?.days}
              />

              {/* {validationError && (
               <p style={{ color: 'red', marginTop: '5px',fontSize:"10px" }}>{validationError}</p>
               )} */}
            </div>
          </div>
        ))}
      </div>

      <div className='mx-auto text-center'>
        <button className='common_but' type='submit' onClick={handleSubmit}>
          Submit
        </button>
      </div>
    </div>
  )
}

export default ReceivedTat
