

import React, { useEffect, useState } from 'react'
import { Link, useNavigate,useLocation } from 'react-router-dom';
import Dropdown from '../../component/Dropdown/DropDownControlled';
import { useDispatch, useSelector } from 'react-redux';
import { getreturnedContainerBoxCreate, getreturnedContainerCreate, getreturnedContainerDropdowns } from '../../store/user/actions';
import { showNotification } from '../../util/utility';

const BoxScanOne = () => {
    const navigate = useNavigate();
    const initialValues = {
      no_of_boxes_received: "",
      rtn_no: "",
      gate_entry_number_id: "",
      courier_name_id: "",
      portal_name_id:"",
      gate_keeper_name_id:"",
      no_of_packages_received:"",
      token_number_id:""
    };
    const [formValues, setFormValue] = useState(initialValues);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(false);
    const [formErrors, setFormErrors] = useState({});
    const returnedContainerCreate = useSelector(state => state.returnedContainerCreate)
    const returnedContainerDropdowns = useSelector(state => state.returnedContainerDropdowns)
    const dispatch = useDispatch()
  
    useEffect(() => {
      dispatch(getreturnedContainerDropdowns({}))
    }, [])
    // const createForm = () => {
    //   localStorage.setItem('formData', JSON.stringify(formValues));
    //   dispatch(getreturnedContainerCreate({}))
    //   navigate('/box-form');
    // }
  const handleChange = (name, value) => {
      let data = { ...formValues };
      data[name] = value;
      setFormValue(data);
      console.log(value);
    };
    const handleGoBack = () => {
        // Navigate back to the previous item
        navigate(-1);
      };
      const handleSubmit = (e, name, value) => {
        setError(null);
        setLoading(true);
        let check = validate(formValues);
        let data = { ...initialValues };
        data[name] = value;
        e.preventDefault();
        setFormErrors(check.errors);
        if (check.isValid) {
          setLoading(false);
          localStorage.setItem('formData', JSON.stringify(formValues));
            dispatch(getreturnedContainerCreate(formValues)) 
        } else {
          setLoading(false);
          showNotification("error", "Form is not valid");
        }
      }
      // navigate call
      useEffect(() => {
        if (returnedContainerCreate.isSuccess || returnedContainerCreate.status === 1) {
          navigate('/box-form/'+returnedContainerCreate.data.id);
        }
      }, [returnedContainerCreate.isSuccess, returnedContainerCreate.status]);
    // end
      // validation-start
      useEffect(() => {
        if (Object.keys(formErrors).length > 0) {
        }
      }, [formErrors]);
    
      const validate = (values) => {
        let isValid = true;
        const errors = {};
        if (!values.no_of_boxes_received) {
          isValid = false;
          errors.no_of_boxes_received = "number box is required ";
        }
        if (!values.rtn_no) {
          isValid = false;
          errors.rtn_no = "Reverse Transaction Number is required";
        }
        if (!values.gate_entry_number_id) {
          isValid = false;
          errors.gate_entry_number_id = "Gate entry number is required";
        }
        if (!values.courier_name_id) {
          isValid = false;
          errors.courier_name_id = "Courier name is required";
        }
        if (!values.portal_name_id) {
          isValid = false;
          errors.portal_name_id = "Portal name is required";
        }
        if (!values.gate_keeper_name_id) {
          isValid = false;
          errors.gate_keeper_name_id = "Gatekeeper Name is required";
        }
        if (!values.token_number_id) {
          isValid = false;
          errors.token_number_id = "Token id is required";
        }
        return {
          isValid,
          errors,
        };
      };
  return (
    <div className='primary_height_sec'>
          <div className='heading_select'>
              <div className="d-flex align-items-baseline" onClick={handleGoBack}>
                  <img style={{ cursor: "pointer" }} src="/Images/arrow-left.svg" />
                  <h1>Box Scan</h1>
              </div>

          </div>
          <div className="primary_bg" onSubmit={handleSubmit}>
          <div className="row primary_input_section">
                <div className='col-md-4 col-lg-4 col-12 mb-4 position-relative'>
                <label className='select-label'>Box Received Number</label>
                    <input 
                    placeholder=''
                    className='input-custom-primary'
                    type='number'
                    onChange={(e) => handleChange("no_of_boxes_received", e.target.value)}
                    value={formValues.no_of_boxes_received}
                    />
                    <span className='error-msg'>{formErrors.no_of_boxes_received}</span>
                </div>
                <div className='col-md-4 col-lg-4 col-12 mb-4 position-relative'>
                <label className='select-label'>Reverse Transaction Number</label>
                    <input 
                    placeholder=''
                    className='input-custom-primary'
                    type='text'
                    onChange={(e) => handleChange("rtn_no", e.target.value)}
                    value={formValues.rtn_no}
                    />
                     <span className='error-msg'>{formErrors.rtn_no}</span>
                </div>
                <div className='col-md-4 col-lg-4 col-12 mb-4'>
                <label className='select-label'>Gate Entry Number</label>
                 <Dropdown
                allowSearch
                border="1px solid #F8B552"
                // defaultText="Courier Name"
                color="#1C1C1C"
                fontWeight="700"
                width="100%"
                height="48px"
                selectedId={formValues?.gate_entry_number_id}
                onOptionClick={(item) => handleChange("gate_entry_number_id", item?.id)}
                options={returnedContainerDropdowns.data.gate_entry_numbers.map(item => ({ id: item.id, label: item.name }))}
                />
                 <span className='error-msg'>{formErrors.gate_entry_number_id}</span>
              </div>
              <div className='col-md-4 col-lg-4 col-12 mb-4'>
                <label className='select-label'>Courier Name</label>
                 <Dropdown
                allowSearch
                border="1px solid #F8B552"
                // defaultText="Delhivery"
                color="#1C1C1C"
                fontWeight="700"
                width="100%"
                height="48px"
                selectedId={formValues?.courier_name_id}
                onOptionClick={(item) => handleChange("courier_name_id", item?.id)}
                options={returnedContainerDropdowns.data.courier_names.map(item => ({ id: item.id, label: item.name }))}
                />
                <span className='error-msg'>{formErrors.courier_name_id}</span>
              </div>
              <div className='col-md-4 col-lg-4 col-12 mb-4'>
                <label className='select-label'>Portal Name</label>
                 <Dropdown
                allowSearch
                border="1px solid #F8B552"
                // defaultText="Myntra"
                color="#1C1C1C"
                fontWeight="700"
                width="100%"
                height="48px"
                selectedId={formValues?.portal_name_id}
                onOptionClick={(item) => handleChange("portal_name_id", item?.id)}
                options={returnedContainerDropdowns.data.portal_names.map(item => ({ id: item.id, label: item.name }))}
                />
                <span className='error-msg'>{formErrors.portal_name_id}</span>
              </div>
              <div className='col-md-4 col-lg-4 col-12 mb-4'>
                <label className='select-label'>Gatekeeper Name</label>
                    <Dropdown
                allowSearch
                border="1px solid #F8B552"
                // defaultText="Rohit"
                color="#1C1C1C"
                fontWeight="700"
                width="100%"
                height="48px"
                selectedId={formValues?.gate_keeper_name_id}
                onOptionClick={(item) => handleChange("gate_keeper_name_id", item?.id)}
                options={returnedContainerDropdowns.data.gate_keeper_names.map(item => ({ id: item.id, label: item.full_name }))}
                />
                <span className='error-msg'>{formErrors.gate_keeper_name_id}</span>
                </div>

              <div className='col-md-4 col-lg-4 col-12 mb-4'>
                <label className='select-label'>Total Unit Count</label>
                    <input 
                    // placeholder='Total Unit Count'
                    className='input-custom-primary'
                    type='number'
                    onChange={(e) => handleChange("no_of_packages_received", e.target.value)}
                    value={formValues.no_of_packages_received}
                    />
                    
                </div>
                <div className='col-md-4 col-lg-4 col-12 mb-4'>
                <label className='select-label'>Token Number</label>
                   <Dropdown
                allowSearch
                border="1px solid #F8B552"
                // defaultText="1"
                color="#1C1C1C"
                fontWeight="700"
                width="100%"
                height="48px"
                selectedId={formValues?.token_number_id}
                onOptionClick={(item) => handleChange("token_number_id", item?.id)}
                options={returnedContainerDropdowns.data.token_numbers.map(item => ({ id: item.id, label: item.name }))}
                />
                <span className='error-msg'>{formErrors.token_number_id}</span>
                </div>
              
                
              </div>
              <div className='package_butn mt-2'>
                {/* <Link to="/box-form" className='suffix_bag' onClick={handleSubmit}>Next</Link> */}
                <Link className='suffix_bag' onClick={handleSubmit}>Next</Link>
                </div>
          </div>
    </div>
  )
}

export default BoxScanOne
