import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { getclaimEmailTemplateDropdown } from '../../../store/user/actions';
import { Link, useParams } from 'react-router-dom';
import Dropdown from '../../../component/Dropdown/DropDownControlled';

const EmailFilter = ({filterValue}) => {
  const initialValues = {
    "claim_reason_id": "",
    "party_id": ""

 }
 const [formValues, setFormValue] = useState(initialValues);
    const claimEmailTemplateDropdown = useSelector(state => state.claimEmailTemplateDropdown)
  const dispatch = useDispatch()
  const { id } = useParams();


  useEffect(() => {
    dispatch(getclaimEmailTemplateDropdown({}))
  }, [])
  const handleChange = (name, value) => {
    let data = { ...formValues };
    data[name] = value;
    setFormValue(data);
  };
  useEffect(() => {
    filterValue(formValues)
  }, [formValues])
  return (
    <div>
       {/* <div className='white_box'> */}
        <div className='primary_input_section'>
          <div className='row'>
            <div className='col-md-4 col-lg-4 col-12 mb-4'>
              <label className='select-label'>Select Portal</label>
              <Dropdown
                allowSearch
                border="1px solid #F8B552"
                color="#1C1C1C"
                fontWeight="700"
                width="100%"
                height="48px"
                selectedId={formValues?.party_id}
                onOptionClick={(item) => handleChange("party_id", item?.id)}
                options={claimEmailTemplateDropdown.data.parties.map(item => ({ id: item.id, label: item.name }))}
              />
            </div>
            <div className='col-md-4 col-lg-4 col-12 mb-4'>
              <label className='select-label'>Claim Type</label>
              <Dropdown
                allowSearch
                border="1px solid #F8B552"
                color="#1C1C1C"
                fontWeight="700"
                width="100%"
                height="48px"
                selectedId={formValues?.claim_reason_id}
                onOptionClick={(item) => handleChange("claim_reason_id", item?.id)}
                options={claimEmailTemplateDropdown.data.claim_reason.map(item => ({ id: item.id, label: item.name }))}
              />
            </div>
            <div className='col-md-4 col-lg-4 col-12 mt-3'>
            <label className='select-label'></label>
        <div className="primary_but">
          <Link onClick={() => setFormValue(initialValues)} className="clear_filter"><img src="/Images/trash.svg" /> Clear Filter</Link>
        </div>
        </div>
          </div>
        </div>
       
      {/* </div> */}


    </div>
  )
}

export default EmailFilter
